import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  Menu,
  MenuItem,
  Select,
  InputLabel,
  Autocomplete
} from '@mui/material';
import {
  MaterialReactTable,
  MRT_ToggleFiltersButton,
  MRT_ShowHideColumnsButton,
  MRT_ToggleFullScreenButton,
  MRT_ToggleGlobalFilterButton,
  useMaterialReactTable
} from 'material-react-table';
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import dayjs from 'dayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  ContentCopy,
  Edit,
  SimCardDownload,
  Upgrade,
  CloseOutlined,
  Delete,
  MenuOutlined,
  CircleRounded,
  CircleOutlined
} from '@mui/icons-material';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import msalInstance from '../msalInstance.js';
import autoTable from 'jspdf-autotable';
import { loginRequest } from '../authConfig.js';
import axios from 'axios';
import { jsPDF } from 'jspdf';
import * as XLSX from 'xlsx';
import ProgressSpinner from './ProgressSpinner';
import AlertMessage from './AlertMessage';
import useHasRole from '../hooks/useHasRole.js';
import PictureAsPdf from '@mui/icons-material/PictureAsPdf';
import Description from '@mui/icons-material/Description';
import TableChart from '@mui/icons-material/TableChart';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';
import theme from '../theme.js';

const statusColors = {
  'in work': '#ff9800d1',
  'in review': '#f44336e0',
  reviewed: '#9c27b0cc',
  'in release': '#3f51b5de',
  released: '#4caf50',
  uploaded: '#2196f3d4',
  cancelled: '#607d8be3'
};

const capitalizeWords = (text) => {
  return text.replace(/\b\w/g, (char) => char.toUpperCase());
};

const eQuoteStatusOptions = ['in work', 'in release', 'released', 'uploaded', 'cancelled'];

const eQuoteTypeOptions = [
  { label: 'pre-eQuote (ACQ Phase)', value: 'pre-eQuote' },
  { label: 'eQuote light (< €/$ 1 Mils)', value: 'eQuote light' },
  { label: 'eQuote (> €/$ 1 Mils)', value: 'eQuote' },
  { label: 'eQuote estimate', value: 'eQuote estimate' }
];

const eQuoteCharacterOptions = ['binding', 'non-binding'];

const requestTypeOptions = [
  'New Project(CAP)',
  'DCR/DCD',
  'CTP',
  'Purchasing(Request from PUR)',
  'Operations',
  'Engineering Support',
  'Other Request'
];

const formatDateForStorage = (date) => (date ? dayjs(date).toISOString() : null);

const statusCellRender = ({ cell }) => {
  const valueFormatted = cell.getValue();
  const color = statusColors[valueFormatted];

  return (
    <Box
      sx={{
        backgroundColor: color,
        borderRadius: '50px',
        color: 'white',
        textAlign: 'center',
        fontWeight: 'bold',
        padding: '8px 0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '85%'
      }}
    >
      {capitalizeWords(valueFormatted)}
    </Box>
  );
};

const CockpitPersonalView = ({ quoteData, viewData, userId, onDataChange, isLoading }) => {
  const [newTabName, setNewTabName] = useState('');
  const [deleteView, setDeleteView] = useState(false);
  const [renameView, setRenameView] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [userData, setUserData] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const { accounts, instance: msalInstance } = useMsal();
  const loggedInAccount = accounts[0];
  const loggedInUser = loggedInAccount?.idTokenClaims || {};
  const [data, setData] = useState([]);
  const [columnVisibility, setColumnVisibility] = useState();
  const [columnFilters, setColumnFilters] = useState([]);
  const [columnOrder, setColumnOrder] = useState([]);
  const [processedColumnFilter, setProcessedColumnFilter] = useState([]);
  const [isFilterUpdated, setIsFilterUpdated] = useState(false);
  const [isColumnUpdated, setIsColumnUpdated] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [editingRowData, setEditingRowData] = useState({});
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [userDetails, setUserDetails] = useState([]);
  const [error, setError] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const isAllowedRoles = useHasRole(['QVD.Admin', 'eQuote.Manager']);
  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      {
        accessorKey: 'eQuoteNumber',
        header: 'eQuote Number',
        Cell: ({ row }) => {
          const [anchorElQuotation, setAnchorElQuoatation] = useState(null);
          const openQuoatation = Boolean(anchorElQuotation);
          const { eQuoteLead = null, secondLead = null } = row.original;

          const hasAdminPrivileges =
            loggedInUser.roles.includes('QVD.Admin') ||
            loggedInUser.roles.includes('eQuote.Manager');

          const isProjectLead =
            loggedInUser.roles.includes('eQuote.ProjectLead') &&
            (loggedInUser.oid.toUpperCase() === eQuoteLead ||
              loggedInUser.oid.toUpperCase() === secondLead);

          const canEditOrUpgrade = hasAdminPrivileges || isProjectLead;

          const handleClickQuotation = (event) => {
            setAnchorElQuoatation(event.currentTarget);
          };
          const handleCloseQuoatation = () => {
            setAnchorElQuoatation(null);
          };
          const handleQuasiSelection = (eQuoteNumber) => {
            handleCloseQuoatation();
            navigate(`/quasi`, { state: { eQuoteNumber } });
          };

          const handleQuantumSelection = (eQuoteNumber) => {
            handleCloseQuoatation();
            navigate(`/quantum`, { state: { eQuoteNumber } });
          };

          return (
            <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
              <span>{row.original.eQuoteNumber}</span>
              {canEditOrUpgrade && (
                <>
                  <Tooltip title="Select Quotation">
                    <IconButton size="small" onClick={handleClickQuotation}>
                      <MenuOutlined />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    anchorEl={anchorElQuotation}
                    open={openQuoatation}
                    onClose={handleCloseQuoatation}
                  >
                    {row.original.eQuoteType !== 'eQuote' && (
                      <MenuItem onClick={() => handleQuasiSelection(row.original.eQuoteNumber)}>
                        <CircleRounded
                          fontSize="small"
                          sx={{ mr: 1, color: theme.palette.primary.main }}
                        />
                        Quasi
                      </MenuItem>
                    )}
                    {row.original.eQuoteType !== 'pre-eQuote' && (
                      <MenuItem onClick={() => handleQuantumSelection(row.original.eQuoteNumber)}>
                        <CircleOutlined
                          fontSize="small"
                          sx={{ mr: 1, color: theme.palette.primary.main }}
                        />
                        Quantum
                      </MenuItem>
                    )}
                  </Menu>
                </>
              )}
            </Box>
          );
        },
        enableEditing: false,
        enableHiding: false,
        muiTableBodyCellProps: {
          sx: {
            borderRight: '1px solid rgba(0, 0, 0, 0.12)'
          }
        }
      },
      {
        accessorKey: 'eQuoteVersion',
        header: 'eQuote Version',
        enableEditing: false,
        muiTableBodyCellProps: {
          align: 'center'
        }
      },
      {
        accessorKey: 'projectName',
        header: 'Project Name',
        filterVariant: 'autocomplete',
        enableEditing: false,
        size: 250
      },
      {
        accessorKey: 'eQuoteLeadName',
        header: 'eQuote Lead',
        filterVariant: 'autocomplete',
        enableEditing: false
      },
      {
        accessorKey: 'secondLeadName',
        header: 'eQuote 2nd Lead',
        filterVariant: 'autocomplete',
        enableEditing: false
      },
      {
        accessorKey: 'eQuoteType',
        header: 'eQuote Type',
        filterVariant: 'multi-select',
        enableEditing: false,
        filterFn: (row, columnId, filterValue) => {
          if (!filterValue || filterValue.length === 0) return true;
          const rowValue = row.getValue(columnId);
          return filterValue.includes(rowValue);
        }
      },

      {
        accessorKey: 'region',
        header: 'Region',
        filterVariant: 'multi-select',
        size: 150,
        enableEditing: false
      },
      {
        accessorKey: 'eQuoteShortName',
        header: 'eQuote Short Name',
        size: 250,
        filterVariant: 'autocomplete',
        Edit: ({ cell, column, row }) => {
          const [value, setValue] = useState(cell.getValue() || '');
          const handleChange = (event) => {
            const inputValue = event.target.value;
            setValue(inputValue);
            row._valuesCache[column.id] = inputValue;
          };
          return (
            <FormControl fullWidth>
              <TextField value={value} onChange={handleChange} variant="standard" />
            </FormControl>
          );
        }
      },
      {
        accessorKey: 'eQuoteDescription',
        header: 'eQuote Description',
        size: 250,
        enableEditing: false
      },
      {
        accessorKey: 'eQuoteCharacter',
        header: 'eQuote Character',
        filterVariant: 'multi-select',
        enableEditing: !loggedInUser.roles.includes('eQuote.ProjectLead'),
        Cell: ({ cell }) => capitalizeWords(cell.getValue()),
        filterFn: (row, columnId, filterValue) => {
          if (!filterValue || filterValue.length === 0) return true;
          const rowValue = row.getValue(columnId);
          return filterValue.includes(rowValue);
        },
        Edit: ({ cell, column, row }) => {
          const [value, setValue] = useState(cell.getValue() || '');
          const handleChange = (event) => {
            const newValue = event.target.value;
            setValue(newValue);
            row._valuesCache[column.id] = newValue;
          };

          return (
            <FormControl fullWidth>
              <Select value={value} onChange={handleChange} variant="standard">
                {eQuoteCharacterOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {capitalizeWords(option)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          );
        },
        size: 250
      },
      {
        accessorKey: 'requestType',
        header: 'Request Type',
        size: 250,
        filterVariant: 'autocomplete',
        enableEditing: !loggedInUser.roles.includes('eQuote.ProjectLead'),
        Edit: ({ cell, column, row }) => {
          const [value, setValue] = useState(cell.getValue() || null);
          const handleChange = (event, newValue) => {
            setValue(newValue);
            row._valuesCache[column.id] = newValue;
          };

          return (
            <FormControl fullWidth>
              <Autocomplete
                options={requestTypeOptions}
                value={value}
                onChange={handleChange}
                renderInput={(params) => <TextField {...params} variant="standard" />}
              />
            </FormControl>
          );
        }
      },

      {
        accessorFn: (row) => (row.creation_date ? dayjs(row.creation_date) : null),
        id: 'creation_date',
        header: 'Creation Date',
        filterVariant: 'date-range',
        Cell: ({ cell }) => (cell.getValue() ? dayjs(cell.getValue()).format('DD-MM-YYYY') : ''),
        enableEditing: false
      },
      {
        accessorKey: 'creatorName',
        header: 'Creator Name',
        filterVariant: 'autocomplete',
        enableEditing: false
      },
      {
        accessorFn: (row) =>
          row.eQuoteCustomerRequestDate ? dayjs(row.eQuoteCustomerRequestDate) : null,
        id: 'eQuoteCustomerRequestDate',
        header: 'eQuote Customer Request Date',
        filterVariant: 'date-range',
        Cell: ({ cell }) => (cell.getValue() ? dayjs(cell.getValue()).format('DD-MM-YYYY') : ''),
        size: 250,
        Edit: ({ cell, column, row }) => {
          const [value, setValue] = useState(dayjs(cell.getValue()) || null);

          const handleChange = (newValue) => {
            setValue(newValue);
            row._valuesCache[column.id] = newValue ? formatDateForStorage(newValue) : null;
          };

          return (
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={value}
                  onChange={handleChange}
                  slotProps={{ textField: { variant: 'standard' } }}
                />
              </LocalizationProvider>
            </FormControl>
          );
        }
      },
      {
        accessorFn: (row) => (row.eQuoteKickoffDate ? dayjs(row.eQuoteKickoffDate) : null),
        id: 'eQuoteKickoffDate',
        header: 'eQuote Kick-off Date',
        filterVariant: 'date-range',
        Cell: ({ cell }) => (cell.getValue() ? dayjs(cell.getValue()).format('DD-MM-YYYY') : ''),
        Edit: ({ cell, column, row }) => {
          const [value, setValue] = useState(dayjs(cell.getValue()) || null);

          const handleChange = (newValue) => {
            setValue(newValue);
            row._valuesCache[column.id] = newValue ? formatDateForStorage(newValue) : null;
          };

          return (
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={value}
                  onChange={handleChange}
                  slotProps={{ textField: { variant: 'standard' } }}
                />
              </LocalizationProvider>
            </FormControl>
          );
        }
      },
      {
        accessorFn: (row) => (row.quoteFinishTargetDate ? dayjs(row.quoteFinishTargetDate) : null),
        id: 'quoteFinishTargetDate',
        header: 'eQuote Finished Target Date',
        filterVariant: 'date-range',
        Cell: ({ cell }) => (cell.getValue() ? dayjs(cell.getValue()).format('DD-MM-YYYY') : ''),
        Edit: ({ cell, column, row }) => {
          const [value, setValue] = useState(dayjs(cell.getValue()) || null);

          const handleChange = (newValue) => {
            setValue(newValue);
            row._valuesCache[column.id] = newValue ? formatDateForStorage(newValue) : null;
          };

          return (
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={value}
                  onChange={handleChange}
                  slotProps={{ textField: { variant: 'standard' } }}
                />
              </LocalizationProvider>
            </FormControl>
          );
        }
      },
      {
        accessorFn: (row) => (row.eQuoteDueDate ? dayjs(row.eQuoteDueDate) : null),
        id: 'eQuoteDueDate',
        header: 'eQuote Due Date',
        filterVariant: 'date-range',
        Cell: ({ cell }) => (cell.getValue() ? dayjs(cell.getValue()).format('DD-MM-YYYY') : ''),
        Edit: ({ cell, column, row }) => {
          const [value, setValue] = useState(dayjs(cell.getValue()) || null);

          const handleChange = (newValue) => {
            setValue(newValue);
            row._valuesCache[column.id] = newValue ? formatDateForStorage(newValue) : null;
          };

          return (
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={value}
                  onChange={handleChange}
                  slotProps={{ textField: { variant: 'standard' } }}
                />
              </LocalizationProvider>
            </FormControl>
          );
        }
      },
      {
        accessorKey: 'eQuoteStatus',
        header: 'eQuote Status',
        filterVariant: 'multi-select',
        Cell: statusCellRender,
        Edit: ({ cell, column, row }) => {
          const [value, setValue] = useState(cell.getValue() || '');
          const handleChange = (event) => {
            const newValue = event.target.value;
            setValue(newValue);
            row._valuesCache[column.id] = newValue;
          };

          return (
            <FormControl fullWidth>
              <Select value={value} onChange={handleChange} variant="standard">
                {eQuoteStatusOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {capitalizeWords(option)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          );
        }
      },
      {
        accessorKey: 'DocumentationStatus',
        header: 'Documentation Status',
        Edit: ({ cell, column, row }) => {
          const [value, setValue] = useState(cell.getValue() || '');

          const handleChange = (event) => {
            const inputValue = event.target.value;
            setValue(inputValue);
            row._valuesCache[column.id] = inputValue;
          };
          return (
            <FormControl fullWidth>
              <TextField value={value} onChange={handleChange} variant="standard" />
            </FormControl>
          );
        }
      },
      {
        accessorKey: 'customerName',
        header: 'Customer Name',
        filterVariant: 'autocomplete',
        enableEditing: false
      },
      {
        accessorKey: 'contractPartner',
        header: 'Contract Partner',
        filterVariant: 'autocomplete',
        enableEditing: false
      },

      {
        accessorKey: 'platformAndProgram',
        header: 'Platform and Program',
        filterVariant: 'autocomplete',
        enableEditing: false
      },
      {
        accessorKey: 'mptProduct',
        header: 'MPT Product',
        filterVariant: 'autocomplete',
        enableEditing: false
      },
      {
        accessorKey: 'projectSpecific',
        header: 'Project Specific',
        filterVariant: 'autocomplete',
        size: 250,
        enableEditing: false
      },
      {
        accessorKey: 'projectLead',
        header: 'Project Lead',
        filterVariant: 'autocomplete',
        enableEditing: false
      },
      {
        accessorKey: 'clusterName',
        header: 'Cluster Name',
        filterVariant: 'autocomplete',
        enableEditing: false
      },

      {
        accessorKey: 'projectDescription',
        header: 'Project Description',
        size: 250,
        enableEditing: false
      },
      {
        accessorKey: 'simplifiedName',
        header: 'Simplified Name',
        filterVariant: 'autocomplete',
        enableEditing: false
      },
      { accessorKey: 'PVsequenceID', header: 'PV Sequence ID', enableEditing: false },
      { accessorKey: 'CFNumber', header: 'CF Number', enableEditing: false },
      {
        accessorFn: (row) => (row.Q1Q2Date ? dayjs(row.Q1Q2Date) : null),
        id: 'Q1Q2Date',
        header: 'Q1/Q2 Date',
        filterVariant: 'date-range',
        Cell: ({ cell }) => (cell.getValue() ? dayjs(cell.getValue()).format('DD-MM-YYYY') : ''),
        Edit: ({ cell, column, row }) => {
          const [value, setValue] = useState(dayjs(cell.getValue()) || null);

          const handleChange = (newValue) => {
            setValue(newValue);
            row._valuesCache[column.id] = newValue ? formatDateForStorage(newValue) : null;
          };

          return (
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={value}
                  onChange={handleChange}
                  slotProps={{ textField: { variant: 'standard' } }}
                />
              </LocalizationProvider>
            </FormControl>
          );
        }
      },

      {
        accessorFn: (row) => (row.customerDueDate ? dayjs(row.customerDueDate) : null),
        id: 'customerDueDate',
        header: 'Customer Due Date',
        filterVariant: 'date-range',
        Cell: ({ cell }) => (cell.getValue() ? dayjs(cell.getValue()).format('DD-MM-YYYY') : ''),
        size: 250,
        Edit: ({ cell, column, row }) => {
          const [value, setValue] = useState(dayjs(cell.getValue()) || null);

          const handleChange = (newValue) => {
            setValue(newValue);
            row._valuesCache[column.id] = newValue ? formatDateForStorage(newValue) : null;
          };

          return (
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={value}
                  onChange={handleChange}
                  slotProps={{ textField: { variant: 'standard' } }}
                />
              </LocalizationProvider>
            </FormControl>
          );
        }
      },
      {
        accessorFn: (row) => (row.releaseDate ? dayjs(row.releaseDate) : null),
        id: 'releaseDate',
        header: 'Release Date',
        filterVariant: 'date-range',
        Cell: ({ cell }) => (cell.getValue() ? dayjs(cell.getValue()).format('DD-MM-YYYY') : ''),
        Edit: ({ cell, column, row }) => {
          const [value, setValue] = useState(dayjs(cell.getValue()) || null);

          const handleChange = (newValue) => {
            setValue(newValue);
            row._valuesCache[column.id] = newValue ? formatDateForStorage(newValue) : null;
          };

          return (
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={value}
                  onChange={handleChange}
                  slotProps={{ textField: { variant: 'standard' } }}
                />
              </LocalizationProvider>
            </FormControl>
          );
        }
      },
      {
        accessorFn: (row) =>
          row.quoteGlobalReleaseDate ? dayjs(row.quoteGlobalReleaseDate) : null,
        id: 'quoteGlobalReleaseDate',
        header: 'Global Release Date',
        filterVariant: 'date-range',
        Cell: ({ cell }) => (cell.getValue() ? dayjs(cell.getValue()).format('DD-MM-YYYY') : ''),
        size: 250,
        Edit: ({ cell, column, row }) => {
          const [value, setValue] = useState(dayjs(cell.getValue()) || null);

          const handleChange = (newValue) => {
            setValue(newValue);
            row._valuesCache[column.id] = newValue ? formatDateForStorage(newValue) : null;
          };

          return (
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={value}
                  onChange={handleChange}
                  slotProps={{ textField: { variant: 'standard' } }}
                />
              </LocalizationProvider>
            </FormControl>
          );
        }
      },
      {
        accessorKey: 'releaseNotes',
        header: 'Release Notes',
        enableEditing: !loggedInUser.roles.includes('eQuote.ProjectLead'),
        Edit: ({ cell, column, row }) => {
          const [value, setValue] = useState(cell.getValue() || '');

          const handleChange = (event) => {
            const inputValue = event.target.value;
            setValue(inputValue);
            row._valuesCache[column.id] = inputValue;
          };
          return (
            <FormControl fullWidth>
              <TextField value={value} onChange={handleChange} variant="standard" />
            </FormControl>
          );
        }
      },
      {
        accessorKey: 'meetingParticipants',
        header: 'Meeting Participants',
        Edit: ({ cell, column, row }) => {
          const [value, setValue] = useState(cell.getValue() || '');

          const handleChange = (event) => {
            const inputValue = event.target.value;
            setValue(inputValue);
            row._valuesCache[column.id] = inputValue;
          };
          return (
            <FormControl fullWidth>
              <TextField value={value} onChange={handleChange} variant="standard" />
            </FormControl>
          );
        }
      },

      {
        accessorKey: 'fxRate',
        header: 'fx-Rate',
        enableEditing: !loggedInUser.roles.includes('eQuote.ProjectLead'),
        Edit: ({ cell, column, row }) => {
          const [value, setValue] = useState(cell.getValue() || '');

          const handleChange = (event) => {
            const inputValue = event.target.value;
            if (inputValue === '' || /^[0-9]+$/.test(inputValue)) {
              setValue(inputValue);
              row._valuesCache[column.id] = inputValue || null;
            } else {
              setValue(value);
            }
          };

          return (
            <FormControl fullWidth>
              <TextField
                variant="standard"
                value={value}
                onChange={handleChange}
                slotProps={{
                  input: {
                    inputMode: 'numeric',
                    pattern: '[0-9]*'
                  }
                }}
              />
            </FormControl>
          );
        }
      },
      {
        accessorKey: 'remarks',
        header: 'Remarks',
        Edit: ({ cell, column, row }) => {
          const [value, setValue] = useState(cell.getValue() || '');

          const handleChange = (event) => {
            const inputValue = event.target.value;
            setValue(inputValue);
            row._valuesCache[column.id] = inputValue;
          };
          return (
            <FormControl fullWidth>
              <TextField value={value} onChange={handleChange} variant="standard" />
            </FormControl>
          );
        }
      },
      {
        accessorKey: 'RS1OriginalCurrency',
        header: 'RS1 Original Currency',
        Edit: ({ cell, column, row }) => {
          const [value, setValue] = useState(cell.getValue() || '');

          const handleChange = (event) => {
            const inputValue = event.target.value;
            if (inputValue === '' || /^[0-9]+$/.test(inputValue)) {
              setValue(inputValue);
              row._valuesCache[column.id] = inputValue || null;
            } else {
              setValue(value);
            }
          };

          return (
            <FormControl fullWidth>
              <TextField
                variant="standard"
                value={value}
                onChange={handleChange}
                slotProps={{
                  input: {
                    inputMode: 'numeric',
                    pattern: '[0-9]*'
                  }
                }}
              />
            </FormControl>
          );
        }
      },
      {
        accessorKey: 'RS0OriginalCurrency',
        header: 'RS0 Original Currency',
        Edit: ({ cell, column, row }) => {
          const [value, setValue] = useState(cell.getValue() || '');

          const handleChange = (event) => {
            const inputValue = event.target.value;
            if (inputValue === '' || /^[0-9]+$/.test(inputValue)) {
              setValue(inputValue);
              row._valuesCache[column.id] = inputValue || null;
            } else {
              setValue(value);
            }
          };

          return (
            <FormControl fullWidth>
              <TextField
                variant="standard"
                value={value}
                onChange={handleChange}
                slotProps={{
                  input: {
                    inputMode: 'numeric',
                    pattern: '[0-9]*'
                  }
                }}
              />
            </FormControl>
          );
        }
      },
      {
        accessorKey: 'storageInformation',
        header: 'Storage Information',
        size: 250,
        Edit: ({ cell, column, row }) => {
          const [value, setValue] = useState(cell.getValue() || '');

          const handleChange = (event) => {
            const inputValue = event.target.value;
            setValue(inputValue);
            row._valuesCache[column.id] = inputValue;
          };
          return (
            <FormControl fullWidth>
              <TextField value={value} onChange={handleChange} variant="standard" />
            </FormControl>
          );
        }
      }
    ],
    []
  );
  const dateColumns = [
    'creation_date',
    'eQuoteCustomerRequestDate',
    'eQuoteKickoffDate',
    'quoteFinishTargetDate',
    'eQuoteDueDate',
    'Q1Q2Date',
    'customerDueDate',
    'releaseDate',
    'quoteGlobalReleaseDate'
  ];
  const todayDate = dayjs().format('YYYYMMDD');
  const open = Boolean(anchorEl);

  const apiUrl = process.env.REACT_APP_API_URL;

  const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
    filename: `Cockpit_${todayDate}`
  });

  useEffect(() => {
    setIsDataLoading(true);
    setData([...quoteData]);
    fetchUsernames();
    fetchProjects();
    setUserData(JSON.parse(localStorage.getItem(`user_${userId}`)));
    setNewTabName(viewData.newTabName);
    const filter = processDateFilters(viewData.columnFilterValue);
    setProcessedColumnFilter(filter);
    setColumnFilters(filter);
    setColumnVisibility(viewData.columnVisibilityValue);
    setColumnOrder(viewData.columnOrderValue);
    setIsDataLoading(false);
  }, [quoteData, viewData]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'c') {
        event.preventDefault();
        handleCopyToClipboard();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const triggerAlert = (message, severity) => {
    setAlertOpen(false);
    setAlertSeverity(severity);
    setAlertMessage(message);
    setAlertOpen(true);
  };

  const getToken = async () => {
    try {
      const tokenResponse = await msalInstance.acquireTokenSilent(loginRequest);
      const accessToken = tokenResponse.idToken;
      return accessToken;
    } catch (error) {
      console.log(error);
    }
  };

  const getAccessToken = async () => {
    try {
      const tokenResponse = await msalInstance.acquireTokenSilent(loginRequest);
      const accessToken = tokenResponse.accessToken;
      return accessToken;
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUsernames = async () => {
    try {
      const accessToken = await getToken();
      const response = await axios.get(`${apiUrl}/api/user/all`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        }
      });
      const userDataArr = response.data.filter((data) => data.qvdRole === 'eQuote.ProjectLead');
      setUserDetails([...userDataArr].sort((a, b) => a.username.localeCompare(b.username)));
    } catch (error) {
      console.error('Failed to fetch usernames:', error);
      triggerAlert('An error occurred while fetching usernames!', 'error');
    }
  };

  const fetchProjects = async () => {
    try {
      const accessToken = await getToken();
      const response = await axios.get(`${apiUrl}/api/projects`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        }
      });
      const data = response.data;
      const filtered = data
        .filter((item) => item.status === 'active')
        .map((item) => item.projectName);
      setProjectData(filtered);
    } catch (error) {
      console.error(error);
      const errorMessage =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : 'An error occurred during the API call.';
      triggerAlert(errorMessage, 'error');
    }
  };

  const handleOpenEditDialog = useCallback((row) => {
    setEditingRowData({ ...row, eQuoteDescription: '' });
    setIsEditDialogOpen(true);
  }, []);

  const handleOpenUploadDialog = useCallback((row) => {
    setEditingRowData({ ...row, eQuoteDescription: '' });
    setIsUploadDialogOpen(true);
  }, []);

  const handleCloseDialog = () => {
    setIsEditDialogOpen(false);
    setIsUploadDialogOpen(false);
    setEditingRowData({});
    setError(false);
  };

  const handleInputChange = useCallback((event) => {
    const { name, value } = event.target;
    setEditingRowData((prevState) => ({
      ...prevState,
      [name]: value
    }));

    if (name === 'eQuoteDescription' && value) setError(false);
  }, []);

  const handleDateChange = (name, newValue) => {
    setEditingRowData({
      ...editingRowData,
      [name]: formatDateForStorage(newValue)
    });
  };

  const handleSaveEdit = async (values, exitEditingMode) => {
    try {
      setLoading(true);
      setIsEditDialogOpen(false);
      exitEditingMode();

      const params = {
        projectID: values.projectID,
        eQuoteShortName: values.eQuoteShortName,
        requestType: values.requestType,
        eQuoteLead: values.eQuoteLead,
        eQuoteCharacter: values.eQuoteCharacter,
        eQuoteCustomerRequestDate: values.eQuoteCustomerRequestDate,
        eQuoteStatus: values.eQuoteStatus,
        documentationStatus: values.DocumentationStatus,
        eQuoteCustomerRequestDate: values.eQuoteCustomerRequestDate,
        eQuoteKickoffDate: values.eQuoteKickoffDate,
        quoteFinishTargetDate: values.quoteFinishTargetDate,
        eQuoteDueDate: values.eQuoteDueDate,
        Q1Q2Date: values.Q1Q2Date,
        customerDueDate: values.customerDueDate,
        releaseDate: values.releaseDate,
        quoteGlobalReleaseDate: values.quoteGlobalReleaseDate,
        releaseNotes: values.releaseNotes,
        meetingParticipants: values.meetingParticipants,
        fxRate: values.fxRate !== null && values.fxRate !== '' ? Number(values.fxRate) : null,
        remarks: values.remarks,
        RS1OriginalCurrency:
          values.RS1OriginalCurrency !== null && values.RS1OriginalCurrency !== ''
            ? Number(values.RS1OriginalCurrency)
            : null,
        RS0OriginalCurrency:
          values.RS0OriginalCurrency !== null && values.RS0OriginalCurrency !== ''
            ? Number(values.RS0OriginalCurrency)
            : null,
        storageInformation: values.storageInformation
      };
      const accessToken = await getToken();
      const response = await axios.patch(`${apiUrl}/api/equotes/${values.quoteID}`, params, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        }
      });
      setData((prevData) =>
        prevData.map((row) =>
          row.quoteID === values.quoteID
            ? { ...row, ...response.data, DocumentationStatus: response.data.documentationStatus }
            : row
        )
      );
      triggerAlert('eQuote edit successful!', 'success');
    } catch (error) {
      console.error(error);
      triggerAlert('An error occurred during the API call.', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleSaveUpload = async (event) => {
    event.preventDefault();

    if (!editingRowData.eQuoteDescription) {
      setError(true);
      return;
    }

    try {
      setLoading(true);
      setIsUploadDialogOpen(false);

      const params = {
        eQuoteLead: editingRowData.eQuoteLead,
        secondLead: editingRowData.secondLead,
        eQuoteType: editingRowData.eQuoteType,
        eQuoteDescription: editingRowData.eQuoteDescription,
        eQuoteCharacter: editingRowData.eQuoteCharacter,
        eQuoteCustomerRequestDate: editingRowData.eQuoteCustomerRequestDate,
        eQuoteKickoffDate: editingRowData.eQuoteKickoffDate,
        quoteFinishTargetDate: editingRowData.quoteFinishTargetDate,
        eQuoteDueDate: editingRowData.eQuoteDueDate
      };

      const filteredParams = Object.fromEntries(
        Object.entries(params).filter(
          ([key, value]) => value !== null && value !== undefined && value !== ''
        )
      );
      const accessToken = await getToken();
      const response = await axios.patch(
        `${apiUrl}/api/equotes/${editingRowData.quoteID}/version`,
        filteredParams,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      );

      setData((prevData) =>
        prevData
          .map((row) => {
            if (row.quoteID === editingRowData.quoteID) {
              if (isAllowedRoles) {
                triggerAlert('eQuote Version Updated!', 'success');
                return {
                  ...row,
                  ...response.data,
                  eQuoteLeadName: userDetails.find(
                    (user) => user.userID === response.data.eQuoteLead
                  )?.username,
                  secondLeadName: response.data.secondLead
                    ? userDetails.find((user) => user.userID === response.data.secondLead)?.username
                    : null
                };
              } else {
                triggerAlert('eQuote Version Update Requested!', 'warning');
                return null;
              }
            }
            return row;
          })
          .filter((row) => row !== null)
      );

      if (!isAllowedRoles) {
        const updatedEQuoteNumber = response.data.eQuoteNumber;
        await sendMail(updatedEQuoteNumber);
      }
    } catch (error) {
      console.error(error);
      if (error.response && error.response.data) {
        const { statusCode, message } = error.response.data;
        if (message === 'Exceeded version letter range.' && statusCode === 400) {
          triggerAlert('Exceeded version letter range.', 'error');
        } else {
          triggerAlert('An error occurred during the API call.', 'error');
        }
      } else {
        triggerAlert('An error occurred during the API call.', 'error');
      }
    } finally {
      setLoading(false);
    }
  };

  const sendMail = async (updatedEQuoteNumber) => {
    const accessToken = await getAccessToken();

    const regionEmails = {
      EU: process.env.REACT_APP_ADMIN_MAIL_EU || 'ijaz.m@partner.magna.com',
      CN: process.env.REACT_APP_ADMIN_MAIL_CN || 'ijaz.m@partner.magna.com',
      GL: process.env.REACT_APP_ADMIN_MAIL_GL || 'ijaz.m@partner.magna.com',
      NA: process.env.REACT_APP_ADMIN_MAIL_NA || 'ijaz.m@partner.magna.com'
    };

    const recipientEmail = (regionEmails[editingRowData.region] || 'ijaz.m@partner.magna.com')
      .split(',')
      .map((email) => ({ emailAddress: { address: email.trim() } }));
    const defaultBccEmail = process.env.REACT_APP_ADMIN_EMAIL_DEF || 'ijaz.m@partner.magna.com';
    const webUrl = `${window.location.origin}/equote-number-approval`;

    try {
      const response = await fetch('https://graph.microsoft.com/v1.0/me/sendMail', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          message: {
            subject: `[QuoVaDis] eQuote Number Approval Request ${updatedEQuoteNumber}`,
            body: {
              contentType: 'HTML',
              content: `
                <p>Hello,</p>
                <p>New request for eQuote Number approval:</p>
                <ul>
                  <li><strong>eQuote Number:</strong> ${updatedEQuoteNumber}</li>
                  <li><strong>Region:</strong> ${editingRowData.region}</li>
                  <li><strong>eQuote Short Name:</strong> ${editingRowData.eQuoteShortName}</li>
                </ul>
                <p>Please click the button below to approve, edit, or reject the request:</p>
                <a href="${webUrl}" style="
                  display: inline-block;
                  padding: 10px 20px;
                  margin-top: 10px;
                  color: #fff;
                  background-color: #800000;
                  text-decoration: none;
                  border-radius: 5px;
                  font-weight: bold;
                  ">VIEW REQUEST</a>
              `
            },
            toRecipients: recipientEmail,
            bccRecipients: [{ emailAddress: { address: defaultBccEmail } }]
          }
        })
      });

      if (response.ok) {
        setAlertSeverity('success');
        setAlertMessage('Notified Admin through mail successfully for eQuote Version Update!');
        setAlertOpen(true);
      } else {
        console.error('Failed to send mail', response);
        setAlertSeverity('warning');
        setAlertMessage('Update successful, but failed to notify admin via email.');
        setAlertOpen(true);
      }
    } catch (error) {
      console.error('Error sending mail:', error);
      setAlertSeverity('error');
      setAlertMessage('Error notifying Admin, please contact Admin manually.');
      setAlertOpen(true);
    }
  };

  const getPageSize = (columnCount) => {
    if (columnCount <= 6) return 'a4';
    if (columnCount <= 12) return 'a3';
    if (columnCount <= 20) return 'a2';
    if (columnCount <= 30) return 'a1';
    return 'a0';
  };

  const handleDownloadPdf = () => {
    const allRow = table.getPrePaginationRowModel().rows;
    const selectedRows = table.getSelectedRowModel().rows;
    const visibleColumns = table
      .getVisibleLeafColumns()
      .filter((column) => column.id !== 'mrt-row-select');

    const rows = selectedRows.length === 0 ? allRow : selectedRows;
    const pageSize = getPageSize(visibleColumns.length);
    const orientation = 'landscape';
    const doc = new jsPDF({
      orientation: orientation,
      format: pageSize,
      unit: 'mm'
    });

    const filename = `Cockpit_${todayDate}.pdf`;
    const tableHeaders = visibleColumns.map((column) => column.columnDef.header);
    const tableData = rows.map((row) =>
      visibleColumns.map((col) => {
        const value = row.getValue(col.id);

        if (dateColumns.includes(col.id)) {
          return value ? dayjs(value).format('DD-MM-YYYY') : '';
        }

        return value;
      })
    );
    autoTable(doc, {
      head: [tableHeaders],
      body: tableData
    });

    doc.save(filename);
    handleClose();
  };

  const handleDownloadCsv = () => {
    const allRow = table.getPrePaginationRowModel().rows;
    const selectedRows = table.getSelectedRowModel().rows;
    const visibleColumns = table
      .getVisibleLeafColumns()
      .filter((column) => column.id !== 'mrt-row-select');

    const rows = selectedRows.length === 0 ? allRow : selectedRows;

    const rowData = rows.map((row) =>
      visibleColumns.reduce((rowValues, col) => {
        const value = row.getValue(col.id);

        if (dateColumns.includes(col.id)) {
          rowValues[col.columnDef.header] = value ? dayjs(value).format('DD-MM-YYYY') : '';
        } else {
          rowValues[col.columnDef.header] = value;
        }
        return rowValues;
      }, {})
    );
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);

    handleClose();
  };

  const handleDownloadExcel = () => {
    const allRow = table.getPrePaginationRowModel().rows;
    const selectedRows = table.getSelectedRowModel().rows;
    const visibleColumns = table
      .getVisibleLeafColumns()
      .filter((column) => column.id !== 'mrt-row-select');

    const rows = selectedRows.length === 0 ? allRow : selectedRows;
    const tableHeaders = visibleColumns.map((column) => column.columnDef.header);
    const tableData = rows.map((row) =>
      visibleColumns.map((col) => {
        const value = row.getValue(col.id);

        if (dateColumns.includes(col.id)) {
          return value ? dayjs(value).format('DD-MM-YYYY') : '';
        }
        return value;
      })
    );
    const exportedData = [tableHeaders, ...tableData];
    const worksheet = XLSX.utils.aoa_to_sheet(exportedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');
    XLSX.writeFile(workbook, `Cockpit_${todayDate}.xlsx`);
    handleClose();
  };

  const handleCopyToClipboard = () => {
    const selectedRows = table.getSelectedRowModel().rows;
    const visibleColumns = table.getVisibleLeafColumns();
    const selectedText = window.getSelection().toString();
    let copyData = '';

    if (selectedRows.length !== 0) {
      copyData = selectedRows
        .map((row) => visibleColumns.map((column) => row.getValue(column.id)).join('\t'))
        .join('\n');
    } else if (selectedText) {
      copyData = selectedText;
    } else {
      console.error('No data to copy');
      return;
    }
    navigator.clipboard
      .writeText(copyData)
      .then(() => {
        triggerAlert('Selected row(s) data copied to clipboard!', 'info');
      })
      .catch((err) => {
        console.error('Could not copy text: ', err);
      });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (isFilterUpdated) {
      const validFilters = columnFilters.filter((filter) => {
        if (dateColumns.includes(filter.id)) {
          const [startDate, endDate] = filter.value;
          if ((!startDate && !endDate) || (startDate === '' && endDate === '')) {
            return false;
          }
        }
        const emptyFilterColumns = ['eQuoteCharacter', 'eQuoteType'];
        if (emptyFilterColumns.includes(filter.id) && filter.value.length === 0) {
          return false;
        }
        return true;
      });
      setProcessedColumnFilter(validFilters);
      updateData(validFilters);
    }
  }, [columnFilters]);

  useEffect(() => {
    if (isColumnUpdated) {
      updateData(processedColumnFilter);
    }
  }, [columnVisibility, columnOrder]);

  const handlFilterChange = (updatedFilters) => {
    setColumnFilters(updatedFilters);
    setIsFilterUpdated(true);
  };

  const handleColumnVisibilityChange = (updatedColumnVisibility) => {
    setColumnVisibility(updatedColumnVisibility);
    setIsColumnUpdated(true);
  };

  const updateData = (filters) => {
    const editUserData = {
      newTabName: newTabName,
      columnVisibilityValue: columnVisibility,
      columnFilterValue: filters,
      columnOrderValue: columnOrder
    };

    const updatedUserData = userData.map((item) =>
      item.newTabName === viewData.newTabName ? { ...item, ...editUserData } : item
    );

    localStorage.setItem(`user_${userId}`, JSON.stringify(updatedUserData));
  };

  const processDateFilters = (filters) => {
    return filters.map((filter) => {
      if (dateColumns.includes(filter.id)) {
        const [startDate, endDate] = filter.value;

        return {
          ...filter,
          value: [startDate ? dayjs(startDate) : '', endDate ? dayjs(endDate) : '']
        };
      }
      return filter;
    });
  };

  const hanldeColumnOrderChange = (updatedColumnOrder) => {
    setColumnOrder(updatedColumnOrder);
    setIsColumnUpdated(true);
  };

  const deleteData = () => {
    const updatedUserData = userData.filter((item) => item.newTabName !== viewData.newTabName);
    localStorage.setItem(`user_${userId}`, JSON.stringify(updatedUserData));
  };

  const handleRename = () => {
    setDialogOpen(true);
    setRenameView((prevValue) => !prevValue);
  };

  const handleDelete = () => {
    setDeleteView((prevValue) => !prevValue);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    if (renameView) {
      setRenameView((prevValue) => !prevValue);
    } else if (deleteView) {
      setDeleteView((prevValue) => !prevValue);
    }
  };

  const handleDialogSave = () => {
    updateData(processedColumnFilter);
    handleDialogClose();
    onDataChange('updated');
  };

  const handleDialogDelete = () => {
    deleteData();
    handleDialogClose();
    onDataChange('deleted');
  };

  const filteredOptions = eQuoteTypeOptions.filter((option) => {
    if (editingRowData) {
      return (
        !(
          editingRowData.eQuoteType === 'eQuote light' ||
          editingRowData.eQuoteType === 'eQuote estimate' ||
          editingRowData.eQuoteType === 'eQuote'
        ) || option.value !== 'pre-eQuote'
      );
    }
    return true;
  });

  const handleClearAllFilters = () => {
    table.resetColumnFilters();
    table.setShowColumnFilters(false);
  };

  const table = useMaterialReactTable({
    columns,
    data: data,
    enableStickyHeader: true,
    enableColumnOrdering: true,
    enableRowSelection: true,
    enableFacetedValues: true,
    enableRowVirtualization: true,
    enableColumnResizing: true,
    enablePagination: false,
    enableBottomToolbar: false,
    enableDensityToggle: false,
    enableEditing: true,
    editDisplayMode: 'row',
    getRowId: (row) => row.userId,
    state: { columnVisibility, columnFilters, columnOrder, showSkeletons: isLoading },
    onColumnVisibilityChange: handleColumnVisibilityChange,
    onColumnFiltersChange: handlFilterChange,
    onColumnOrderChange: hanldeColumnOrderChange,
    renderTopToolbarCustomActions: () => (
      <Box display="flex" m="0.5rem">
        {processedColumnFilter.length > 0 && (
          <Button
            onClick={handleClearAllFilters}
            variant="contained"
            sx={{
              mr: '1rem',
              textTransform: 'none'
            }}
          >
            Clear All Filter
          </Button>
        )}
      </Box>
    ),
    renderToolbarInternalActions: ({ table }) => (
      <Box display="flex" m="0.5rem">
        <MRT_ToggleGlobalFilterButton table={table} />
        <Tooltip title="Rename View">
          <IconButton onClick={handleRename}>
            <Edit />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete View">
          <IconButton onClick={handleDelete}>
            <Delete />
          </IconButton>
        </Tooltip>
        <Tooltip title="Copy to clipboard">
          <IconButton onClick={() => handleCopyToClipboard()}>
            <ContentCopy />
          </IconButton>
        </Tooltip>
        <Tooltip title="Export as:">
          <IconButton onClick={handleClick}>
            <SimCardDownload />
          </IconButton>
        </Tooltip>
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          <MenuItem onClick={() => handleDownloadPdf()}>
            <PictureAsPdf fontSize="small" sx={{ color: 'red', mr: 1 }} /> PDF
          </MenuItem>
          <MenuItem onClick={() => handleDownloadCsv()}>
            <Description fontSize="small" sx={{ color: 'blue', mr: 1 }} /> CSV
          </MenuItem>
          <MenuItem onClick={() => handleDownloadExcel()}>
            <TableChart fontSize="small" sx={{ color: 'green', mr: 1 }} /> XLSX
          </MenuItem>
        </Menu>
        <MRT_ToggleFiltersButton table={table} />
        <MRT_ShowHideColumnsButton table={table} />
        <MRT_ToggleFullScreenButton table={table} />
      </Box>
    ),
    renderEmptyRowsFallback: () =>
      !isDataLoading && quoteData.length === 0 ? (
        <div style={{ position: 'relative' }}>
          <span
            style={{
              position: 'absolute',
              top: '50%',
              left: '50vw',
              transform: 'translate(-50%, -50%)',
              textAlign: 'center',
              fontStyle: 'italic'
            }}
          >
            No records to display
          </span>
        </div>
      ) : (
        <div style={{ position: 'relative' }}>
          <span
            style={{
              position: 'absolute',
              top: '50%',
              left: '50vw',
              transform: 'translate(-50%, -50%)',
              textAlign: 'center',
              fontStyle: 'italic'
            }}
          >
            Loading records.......
          </span>
        </div>
      ),
    renderRowActions: ({ row }) => {
      const { eQuoteLead = null, secondLead = null } = row.original;

      const hasAdminPrivileges =
        loggedInUser.roles.includes('QVD.Admin') || loggedInUser.roles.includes('eQuote.Manager');

      const isProjectLead =
        loggedInUser.roles.includes('eQuote.ProjectLead') &&
        (loggedInUser.oid.toUpperCase() === eQuoteLead ||
          loggedInUser.oid.toUpperCase() === secondLead);

      const canEditOrUpgrade = hasAdminPrivileges || isProjectLead;

      return (
        <Box display="flex" alignItems="center" justifyContent="space-around" width="100%">
          {canEditOrUpgrade && (
            <>
              <Tooltip title="Edit eQuote">
                <IconButton
                  size="small"
                  onClick={() => {
                    setEditingRowData(row.original);
                    table.setEditingRow(row);
                  }}
                >
                  <Edit />
                </IconButton>
              </Tooltip>
              <Tooltip title="Update eQuote Version">
                <IconButton size="small" onClick={() => handleOpenUploadDialog(row.original)}>
                  <Upgrade />
                </IconButton>
              </Tooltip>
            </>
          )}
        </Box>
      );
    },
    onEditingRowSave: ({ row, exitEditingMode, values }) => {
      const updatedVal = { ...row.original, ...values };
      handleSaveEdit(updatedVal, exitEditingMode);
    },
    initialState: {
      columnPinning: { left: ['mrt-row-select', 'eQuoteNumber', 'mrt-row-actions'] },
      density: 'compact'
    },
    defaultColumn: {
      minSize: 50,
      maxSize: 400,
      size: 200
    },
    muiSkeletonProps: {
      animation: 'wave'
    },
    muiTableContainerProps: {
      sx: {
        height: isFullScreen ? '100%' : 'calc(100vh - 330px)'
      }
    },
    muiTableBodyCellProps: {
      sx: {
        whiteSpace: 'normal',
        wordBreak: 'break-word',
        textAlign: 'justify'
      }
    },
    muiToolbarAlertBannerProps: {
      sx: {
        display: 'none'
      }
    },
    muiTableHeadRowProps: {
      sx: {
        backgroundColor: '#f8f8f8'
      }
    },
    muiTableBodyProps: {
      sx: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
      }
    },
    displayColumnDefOptions: {
      'mrt-row-actions': {
        size: 100,
        grow: false
      }
    }
  });

  useEffect(() => {
    setIsFullScreen(table.getState().isFullScreen);
  }, [table.getState().isFullScreen]);

  return (
    <>
      {loading && <ProgressSpinner />}
      <AlertMessage
        open={alertOpen}
        message={alertMessage}
        severity={alertSeverity}
        onClose={() => setAlertOpen(false)}
      />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MaterialReactTable table={table} />

        {/* {isEditDialogOpen && (
          <Dialog open={isEditDialogOpen} onClose={handleCloseDialog}>
            <DialogTitle variant="h4" sx={{ textAlign: 'center' }}>
              Edit eQuote
            </DialogTitle>

            <DialogContent>
              <FormControl fullWidth margin="normal">
                <TextField
                  name="eQuoteNumber"
                  label="eQuote Number"
                  defaultValue={editingRowData?.eQuoteNumber}
                  disabled
                  fullWidth
                />
              </FormControl>
              <FormControl fullWidth margin="normal">
                <InputLabel id="projectName-label">eQuote Status</InputLabel>
                <Select
                  name="eQuoteStatus"
                  label="eQuote Status"
                  fullWidth
                  value={editingRowData?.eQuoteStatus || ''}
                  onChange={handleInputChange}
                  sx={{ textAlign: 'left' }}
                >
                  {eQuoteStatusOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {capitalizeWords(option)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth margin="normal">
                <TextField
                  name="eQuoteShortName"
                  label="eQuote ShortName"
                  value={editingRowData?.eQuoteShortName || ''}
                  onChange={handleInputChange}
                  fullWidth
                />
              </FormControl>

              <FormControl fullWidth margin="normal">
                <Autocomplete
                  id="requestType"
                  options={requestTypeOptions}
                  value={editingRowData?.requestType || ''}
                  renderInput={(params) => (
                    <TextField {...params} label="Request Type" placeholder="Select Request Type" />
                  )}
                  sx={{ textAlign: 'left' }}
                  onChange={(event, newValue) => {
                    handleInputChange({
                      target: {
                        name: 'requestType',
                        value: newValue
                      }
                    });
                  }}
                />
              </FormControl>

              <FormControl fullWidth margin="normal">
                <TextField
                  name="DocumentationStatus"
                  label="Documentation Status"
                  value={editingRowData?.DocumentationStatus || ''}
                  onChange={handleInputChange}
                  fullWidth
                />
              </FormControl>

              <FormControl fullWidth margin="normal">
                <DatePicker
                  label="Q1/Q2 Date"
                  value={editingRowData?.Q1Q2Date ? dayjs(editingRowData.Q1Q2Date) : null}
                  onChange={(newValue) => handleDateChange('Q1Q2Date', newValue)}
                />
              </FormControl>

              <FormControl fullWidth margin="normal">
                <DatePicker
                  label="Customer Due Date"
                  value={
                    editingRowData?.customerDueDate ? dayjs(editingRowData.customerDueDate) : null
                  }
                  onChange={(newValue) => handleDateChange('customerDueDate', newValue)}
                />
              </FormControl>

              <FormControl fullWidth margin="normal">
                <DatePicker
                  label="Release Date"
                  value={editingRowData?.releaseDate ? dayjs(editingRowData.releaseDate) : null}
                  onChange={(newValue) => handleDateChange('releaseDate', newValue)}
                />
              </FormControl>

              <FormControl fullWidth margin="normal">
                <DatePicker
                  label="Global Release Date"
                  value={
                    editingRowData?.quoteGlobalReleaseDate
                      ? dayjs(editingRowData?.quoteGlobalReleaseDate)
                      : null
                  }
                  onChange={(newValue) => handleDateChange('quoteGlobalReleaseDate', newValue)}
                />
              </FormControl>

              <FormControl fullWidth margin="normal">
                <TextField
                  name="releaseNotes"
                  label="Release Notes"
                  value={editingRowData?.releaseNotes || ''}
                  onChange={handleInputChange}
                  multiline
                  rows={2}
                />
              </FormControl>

              <FormControl fullWidth margin="normal">
                <TextField
                  name="meetingParticipants"
                  label="Meeting Participants"
                  value={editingRowData?.meetingParticipants || ''}
                  onChange={handleInputChange}
                  multiline
                  rows={2}
                />
              </FormControl>

              <FormControl fullWidth margin="normal">
                <TextField
                  name="fxRate"
                  label="fx-Rate"
                  variant="outlined"
                  value={editingRowData?.fxRate || ''}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value === '' || /^[0-9]+$/.test(value)) {
                      handleInputChange(e);
                    }
                  }}
                  slotProps={{
                    input: {
                      inputMode: 'numeric',
                      pattern: '[0-9]*'
                    }
                  }}
                />
              </FormControl>

              <FormControl fullWidth margin="normal">
                <TextField
                  id="remarks"
                  name="remarks"
                  label="Remarks"
                  value={editingRowData?.remarks || ''}
                  onChange={handleInputChange}
                  multiline
                  rows={2}
                />
              </FormControl>

              <FormControl fullWidth margin="normal">
                <TextField
                  id="RS1OriginalCurrency"
                  name="RS1OriginalCurrency"
                  label="RS1 Original Currency"
                  variant="outlined"
                  value={editingRowData?.RS1OriginalCurrency || ''}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value === '' || /^[0-9]+$/.test(value)) {
                      handleInputChange(e);
                    }
                  }}
                  slotProps={{
                    input: {
                      inputMode: 'numeric',
                      pattern: '[0-9]*'
                    }
                  }}
                />
              </FormControl>

              <FormControl fullWidth margin="normal">
                <TextField
                  id="RS0OriginalCurrency"
                  name="RS0OriginalCurrency"
                  label="RS0 Original Currency"
                  variant="outlined"
                  value={editingRowData?.RS0OriginalCurrency || ''}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value === '' || /^[0-9]+$/.test(value)) {
                      handleInputChange(e);
                    }
                  }}
                  slotProps={{
                    input: {
                      inputMode: 'numeric',
                      pattern: '[0-9]*'
                    }
                  }}
                />
              </FormControl>

              <FormControl fullWidth margin="normal">
                <TextField
                  id="storageInformation"
                  name="storageInformation"
                  label="Storage Information"
                  value={editingRowData?.storageInformation || ''}
                  onChange={handleInputChange}
                  multiline
                  rows={2}
                />
              </FormControl>
            </DialogContent>

            <DialogActions sx={{ justifyContent: 'center' }}>
              <Button onClick={handleCloseDialog} variant="outlined">
                Cancel
              </Button>
              <Button onClick={handleSaveEdit} variant="contained" color="primary">
                Save
              </Button>
            </DialogActions>
          </Dialog>
        )} */}

        {isUploadDialogOpen && (
          <Dialog open={isUploadDialogOpen} onClose={handleCloseDialog}>
            <DialogTitle variant="h4" sx={{ textAlign: 'center' }}>
              Update eQuote Version
            </DialogTitle>

            <DialogContent>
              <FormControl fullWidth margin="normal">
                <TextField
                  name="eQuoteNumber"
                  label="eQuote Number"
                  defaultValue={editingRowData?.eQuoteNumber}
                  disabled
                  fullWidth
                />
              </FormControl>

              <FormControl fullWidth margin="normal">
                <Autocomplete
                  id="eQuoteLeadName"
                  options={userDetails}
                  disableClearable
                  getOptionLabel={(option) => option.username}
                  value={
                    userDetails.find((option) => option.userID === editingRowData.eQuoteLead) ||
                    null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="eQuote Lead"
                      placeholder="Select eQuote Lead"
                      // helperText={
                      //   editingRowData.eQuoteLead
                      //     ? userDetails.find((user) => user.userID === editingRowData.eQuoteLead)
                      //         ?.email || null
                      //     : null
                      // }
                    />
                  )}
                  sx={{ textAlign: 'left' }}
                  isOptionEqualToValue={(option, value) => option.userID === value.userID}
                  onChange={(event, newValue) => {
                    handleInputChange({
                      target: {
                        name: 'eQuoteLeadName',
                        value: newValue.username
                      }
                    });
                    handleInputChange({
                      target: {
                        name: 'eQuoteLead',
                        value: newValue.userID
                      }
                    });
                  }}
                />
              </FormControl>

              <FormControl fullWidth margin="normal">
                <Autocomplete
                  id="secondLeadName"
                  options={userDetails}
                  getOptionLabel={(option) => option.username}
                  value={
                    userDetails.find((option) => option.userID === editingRowData.secondLead) ||
                    null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="eQuote 2nd Lead"
                      placeholder="Select eQuote Second Lead"
                      // helperText={
                      //   editingRowData.secondLead
                      //     ? userDetails.find((user) => user.userID === editingRowData.secondLead)
                      //         ?.email || null
                      //     : null
                      // }
                    />
                  )}
                  sx={{ textAlign: 'left' }}
                  isOptionEqualToValue={(option, value) => option.userID === value.userID}
                  onChange={(event, newValue) => {
                    handleInputChange({
                      target: {
                        name: 'secondLeadName',
                        value: newValue?.username || null
                      }
                    });
                    handleInputChange({
                      target: {
                        name: 'secondLead',
                        value: newValue?.userID || null
                      }
                    });
                  }}
                />
              </FormControl>

              {!loggedInUser.roles.includes('eQuote.ProjectLead') && (
                <FormControl fullWidth margin="normal">
                  <InputLabel id="projectName-label">eQuote Type</InputLabel>
                  <Select
                    name="eQuoteType"
                    label="eQuote Type"
                    fullWidth
                    value={editingRowData?.eQuoteType || ''}
                    onChange={handleInputChange}
                    sx={{ textAlign: 'left' }}
                  >
                    {filteredOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              <FormControl fullWidth margin="normal">
                <TextField
                  name="eQuoteDescription"
                  label="eQuote Description"
                  value={editingRowData?.eQuoteDescription || ''}
                  onChange={handleInputChange}
                  multiline
                  rows={2}
                  required
                  error={error && !editingRowData.eQuoteDescription}
                  helperText={
                    error && !editingRowData.eQuoteDescription
                      ? 'eQuote Description is required'
                      : ''
                  }
                />
              </FormControl>

              {!loggedInUser.roles.includes('eQuote.ProjectLead') && (
                <FormControl fullWidth margin="normal">
                  <InputLabel id="projectName-label">eQuote Character</InputLabel>
                  <Select
                    name="eQuoteCharacter"
                    label="eQuote Character"
                    fullWidth
                    value={editingRowData?.eQuoteCharacter || ''}
                    onChange={handleInputChange}
                    sx={{ textAlign: 'left' }}
                  >
                    {eQuoteCharacterOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {capitalizeWords(option)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              <FormControl fullWidth margin="normal">
                <DatePicker
                  label="eQuote Customer Request Date"
                  value={
                    editingRowData?.eQuoteCustomerRequestDate
                      ? dayjs(editingRowData.eQuoteCustomerRequestDate)
                      : null
                  }
                  onChange={(newValue) => handleDateChange('eQuoteCustomerRequestDate', newValue)}
                />
              </FormControl>

              <FormControl fullWidth margin="normal">
                <DatePicker
                  label="eQuote Kick-off Date"
                  value={
                    editingRowData?.eQuoteKickoffDate
                      ? dayjs(editingRowData.eQuoteKickoffDate)
                      : null
                  }
                  onChange={(newValue) => handleDateChange('eQuoteKickoffDate', newValue)}
                />
              </FormControl>

              <FormControl fullWidth margin="normal">
                <DatePicker
                  label="eQuote Finished Target Date"
                  value={
                    editingRowData?.quoteFinishTargetDate
                      ? dayjs(editingRowData.quoteFinishTargetDate)
                      : null
                  }
                  onChange={(newValue) => handleDateChange('quoteFinishTargetDate', newValue)}
                />
              </FormControl>

              <FormControl fullWidth margin="normal">
                <DatePicker
                  label="eQuote Due Date"
                  value={editingRowData?.eQuoteDueDate ? dayjs(editingRowData.eQuoteDueDate) : null}
                  onChange={(newValue) => handleDateChange('eQuoteDueDate', newValue)}
                />
              </FormControl>
            </DialogContent>

            <DialogActions sx={{ justifyContent: 'center' }}>
              <Button onClick={handleCloseDialog} variant="outlined">
                Cancel
              </Button>
              <Button variant="contained" color="primary" onClick={handleSaveUpload}>
                Update
              </Button>
            </DialogActions>
          </Dialog>
        )}

        {dialogOpen && (
          <Dialog
            open={dialogOpen}
            onClose={handleDialogClose}
            maxWidth="xs"
            fullWidth
            disableRestoreFocus
          >
            <IconButton
              onClick={handleDialogClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: '#800000'
              }}
            >
              <CloseOutlined />
            </IconButton>

            {deleteView && (
              <DialogTitle sx={{ color: '#800000', fontWeight: 'bold' }}>Delete View?</DialogTitle>
            )}

            {renameView && (
              <DialogTitle sx={{ color: '#800000', fontWeight: 'bold' }}>Rename View</DialogTitle>
            )}
            <DialogContent>
              {deleteView && (
                <Typography gutterBottom>Are you sure you want to delete this view?</Typography>
              )}

              {renameView && (
                <TextField
                  focused
                  autoFocus
                  margin="dense"
                  label="View Name"
                  fullWidth
                  variant="outlined"
                  value={newTabName}
                  slotProps={{
                    htmlInput: { maxLength: 30 }
                  }}
                  helperText={
                    newTabName.length >= 30
                      ? `Max characters allowed: 30`
                      : `${newTabName.length}/30`
                  }
                  onChange={(e) => setNewTabName(e.target.value)}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '&.Mui-focused fieldset': {
                        borderColor: '#6e6e6e'
                      }
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                      color: '#6e6e6e'
                    }
                  }}
                />
              )}
            </DialogContent>
            <DialogActions
              sx={{
                padding: '0 1.5rem 1rem 1.5rem'
              }}
            >
              <Button
                variant="outlined"
                onClick={handleDialogClose}
                sx={{
                  color: '#484547',
                  borderColor: '#484547',
                  textTransform: 'none',
                  borderRadius: '10px',
                  '&:hover': {
                    backgroundColor: '#d3d3d3',
                    borderColor: '#d3d3d3'
                  }
                }}
              >
                Cancel
              </Button>

              {renameView && (
                <Button
                  onClick={handleDialogSave}
                  variant="contained"
                  sx={{
                    textTransform: 'none',
                    borderRadius: '10px'
                  }}
                >
                  Save
                </Button>
              )}

              {deleteView && (
                <Button
                  onClick={handleDialogDelete}
                  variant="contained"
                  sx={{
                    textTransform: 'none',
                    borderRadius: '10px'
                  }}
                >
                  Delete
                </Button>
              )}
            </DialogActions>
          </Dialog>
        )}
      </LocalizationProvider>
    </>
  );
};

export default CockpitPersonalView;
