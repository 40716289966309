export const assumption = [
  { id: 'Vehicle Parameter' },
  { id: 'Vehicle Weight' },
  { id: 'Trailer Weight' },
  { id: 'Axle Load Distribution' },
  { id: 'Tire Size' },
  { id: 'Side Shafts' },
  { id: 'Track Width' },
  { id: 'Wheel Base' },
  { id: 'Wading Depth' },
  { id: 'Engine Parameter' },
  { id: 'New Engine' },
  { id: 'Engine Curve / Torque' },
  { id: 'Customer / Internal Requirement' },
  { id: 'NVH targets' },
  { id: 'Efficiency targets' },
  { id: 'Driveability targets' },
  { id: 'Durability targets' },
  { id: 'Shiftability targets' },
  { id: 'Oil service interval' },
  { id: 'Crash loads' },
  { id: 'Transmission-Assy' },
  { id: 'New transmission' },
  { id: 'Positioning of differential / diff drop' },
  { id: 'Mounting / Bracket loads' },
  { id: 'BB-010_Gearsets' },
  { id: 'Gears' },
  { id: 'Shafts' },
  { id: 'Final drive' },
  { id: 'Gearset' },
  { id: 'Bearings' },
  { id: 'BB-010_Differentials' },
  { id: 'Differential' },
  { id: 'Housing' },
  { id: 'Washer, Linings' },
  { id: 'BB-020_Synchro' },
  { id: 'Synchronizer system' },
  { id: 'Synchronizer tribo system' },
  { id: 'Synchronizer parts' },
  { id: 'BB-030_Shifting_System' },
  { id: 'Actuation drive' },
  { id: 'Shiftsystem actuator' },
  { id: 'Shiftfork / Linkages' },
  { id: 'Shift mass' },
  { id: 'Shift drum' },
  { id: 'BB-040_Housing_&_Covers' },
  { id: 'Transmission housing' },
  { id: 'Clutch housing' },
  { id: 'Covers' },
  { id: 'Sealing' },
  { id: 'Breather' },
  { id: 'BB-050_Clutches_&_Decoupling' },
  { id: 'Clutch inertia' },
  { id: 'Clutch carriers' },
  { id: 'Clutch hubs' },
  { id: 'Clutch actuation' },
  { id: 'Clutch support' },
  { id: 'Lamella' },
  { id: 'BB-060_Parklock' },
  { id: 'Parklock' },
  { id: 'Locking system' },
  { id: 'Park gear' },
  { id: 'Parklock actuation' },
  { id: 'BB-70_Oil' },
  { id: 'Oil level' },
  { id: 'Oil volume' },
  { id: 'Oil flow' },
  { id: 'Oil type' },
  { id: 'Oil temp. / cooling system' },
  { id: 'BB-080_Hydraulics' },
  { id: 'Pump' },
  { id: 'Oil management' },
  { id: 'Cooling & Filters' },
  { id: 'Hydraulic controls' },
  { id: 'BB-090_Control_HW_&_Actuation' },
  { id: 'TCU' },
  { id: 'Wiring harness' },
  { id: 'Sensors' },
  { id: 'e-Motors shift system' },
  { id: 'IPAM' },
  { id: 'Parklock solenoid' },
  { id: 'Solenoid seat valve' },
  { id: 'Inverter' },
  { id: 'BB-110-TractionEMotor' },
  { id: 'E-machine' },
  { id: 'Hybrid manager inhouse' },
  { id: 'Hybrid manager customer' },
  { id: 'BB-200_SW_&_Control Alorithms' },
  { id: 'Software' },
  { id: 'Calibration' }
];
