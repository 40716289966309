import React from 'react';
import { Navigate } from 'react-router-dom';
import useHasRole from '../hooks/useHasRole';

const RoleBasedRoute = ({ children, requiredRoles }) => {
  const hasRequiredRole = useHasRole(requiredRoles);

  if (!hasRequiredRole) {
    return <Navigate to="/unauthorized" replace />;
  }
  return children;
};

export default RoleBasedRoute;
