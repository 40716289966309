export const quasiTest = [
  {
    workpackageId: 1,
    workpackage: 'EPM-EPM Eng. Project Management',
    sortOrder: 1,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 1,
        orgResReqExp: 'EU EPM-EPM Eng. Project Management',
        orgResKey: '1854423',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 2,
        orgResReqExp: 'EU EPM-PMO Eng. Project Management Office',
        orgResKey: '1854433',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 3,
        orgResReqExp: 'NA EPM (TRY)-EPM Eng. Project Management',
        orgResKey: '1861783',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      },
      {
        organizationId: 4,
        orgResReqExp: 'NA EPM (TRY)-PMO Eng. Project Management Office',
        orgResKey: '1861782',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      },
      {
        organizationId: 5,
        orgResReqExp: 'NA EPM (RAM)-EPM Eng. Project Management',
        orgResKey: '1854320',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 6,
            qvLocation: 'RAM'
          }
        ]
      },
      {
        organizationId: 6,
        orgResReqExp: 'NA EPM (CON)-EPM Eng. Project Management',
        orgResKey: '1861786',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 7,
            qvLocation: 'CON'
          }
        ]
      },
      {
        organizationId: 7,
        orgResReqExp: 'NA EPM (TCI)-EPM Eng. Project Management',
        orgResKey: '1861768',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 9,
        orgResReqExp: 'CN EPM-EPM Eng. Project Management',
        orgResKey: '1855114',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 11,
        orgResReqExp: 'CN EPM-EPS Eng. Project Management',
        orgResKey: '1855132',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 1,
        subproject: 'SP01 Eng. Project Management'
      }
    ]
  },
  {
    workpackageId: 2,
    workpackage: 'Project Management Engineering MAN.3',
    effortType: 'requirement',
    parentId: 1,
    organizations: [
      {
        organizationId: 7,
        orgResReqExp: 'NA EPM (TCI)-EPM Eng. Project Management',
        orgResKey: '1861768',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 8,
        orgResReqExp: 'NA EPM (TCI)-PMO Eng. Project Management Office',
        orgResKey: '1871482',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 9,
        orgResReqExp: 'CN EPM-EPM Eng. Project Management',
        orgResKey: '1855114',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 10,
        orgResReqExp: 'CN EPM-PMO Eng. Project Management Office',
        orgResKey: '1855129',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 11,
        orgResReqExp: 'CN EPM-EPS Eng. Project Management',
        orgResKey: '1855132',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 12,
        orgResReqExp: 'EU SYS-PSE Concept Architecture',
        orgResKey: '1854317',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 3,
        orgResReqExp: 'NA EPM (TRY)-EPM Eng. Project Management',
        orgResKey: '1861783',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      },
      {
        organizationId: 5,
        orgResReqExp: 'NA EPM (RAM)-EPM Eng. Project Management',
        orgResKey: '1854320',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 6,
            qvLocation: 'RAM'
          }
        ]
      },
      {
        organizationId: 6,
        orgResReqExp: 'NA EPM (CON)-EPM Eng. Project Management',
        orgResKey: '1861786',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 7,
            qvLocation: 'CON'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 1,
        subproject: 'SP01 Eng. Project Management'
      }
    ]
  },
  {
    workpackageId: 3,
    workpackage: 'Tear-Down Analysis',
    effortType: 'requirement',
    parentId: 1,
    subproject: [
      {
        subprojectId: 1,
        subproject: 'SP01 Eng. Project Management'
      }
    ]
  },
  {
    workpackageId: 4,
    workpackage: 'Resident Engineer',
    effortType: 'requirement',
    parentId: 1,
    subproject: [
      {
        subprojectId: 1,
        subproject: 'SP01 Eng. Project Management'
      }
    ]
  },
  {
    workpackageId: 6,
    workpackage: 'FMEA',
    sortOrder: 5,
    effortType: 'requirement',
    subproject: [
      {
        subprojectId: 1,
        subproject: 'SP01 Eng. Project Management'
      }
    ]
  },
  {
    workpackageId: 7,
    workpackage: 'EPM-PMO Eng. Project Management Office',
    sortOrder: 2,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 4,
        orgResReqExp: 'NA EPM (TRY)-PMO Eng. Project Management Office',
        orgResKey: '1861782',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      },
      {
        organizationId: 8,
        orgResReqExp: 'NA EPM (TCI)-PMO Eng. Project Management Office',
        orgResKey: '1871482',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 10,
        orgResReqExp: 'CN EPM-PMO Eng. Project Management Office',
        orgResKey: '1855129',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 1,
        subproject: 'SP01 Eng. Project Management'
      }
    ]
  },
  {
    workpackageId: 8,
    workpackage: 'Risk Management MAN.5',
    effortType: 'requirement',
    parentId: 7,
    organizations: [
      {
        organizationId: 4,
        orgResReqExp: 'NA EPM (TRY)-PMO Eng. Project Management Office',
        orgResKey: '1861782',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      },
      {
        organizationId: 8,
        orgResReqExp: 'NA EPM (TCI)-PMO Eng. Project Management Office',
        orgResKey: '1871482',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 10,
        orgResReqExp: 'CN EPM-PMO Eng. Project Management Office',
        orgResKey: '1855129',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 1,
        subproject: 'SP01 Eng. Project Management'
      }
    ]
  },
  {
    workpackageId: 9,
    workpackage: 'Project Planning & Scheduling MAN.3',
    effortType: 'requirement',
    parentId: 7,
    organizations: [
      {
        organizationId: 4,
        orgResReqExp: 'NA EPM (TRY)-PMO Eng. Project Management Office',
        orgResKey: '1861782',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      },
      {
        organizationId: 8,
        orgResReqExp: 'NA EPM (TCI)-PMO Eng. Project Management Office',
        orgResKey: '1871482',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 10,
        orgResReqExp: 'CN EPM-PMO Eng. Project Management Office',
        orgResKey: '1855129',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 1,
        subproject: 'SP01 Eng. Project Management'
      }
    ]
  },
  {
    workpackageId: 10,
    workpackage: 'Problem Resolution Management SUP.9',
    effortType: 'requirement',
    parentId: 7,
    organizations: [
      {
        organizationId: 4,
        orgResReqExp: 'NA EPM (TRY)-PMO Eng. Project Management Office',
        orgResKey: '1861782',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      },
      {
        organizationId: 8,
        orgResReqExp: 'NA EPM (TCI)-PMO Eng. Project Management Office',
        orgResKey: '1871482',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 10,
        orgResReqExp: 'CN EPM-PMO Eng. Project Management Office',
        orgResKey: '1855129',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 1,
        subproject: 'SP01 Eng. Project Management'
      }
    ]
  },
  {
    workpackageId: 11,
    workpackage: 'Change Request Management SUP.10',
    effortType: 'requirement',
    parentId: 7,
    organizations: [
      {
        organizationId: 4,
        orgResReqExp: 'NA EPM (TRY)-PMO Eng. Project Management Office',
        orgResKey: '1861782',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      },
      {
        organizationId: 8,
        orgResReqExp: 'NA EPM (TCI)-PMO Eng. Project Management Office',
        orgResKey: '1871482',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 10,
        orgResReqExp: 'CN EPM-PMO Eng. Project Management Office',
        orgResKey: '1855129',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 1,
        subproject: 'SP01 Eng. Project Management'
      }
    ]
  },
  {
    workpackageId: 12,
    workpackage: 'Configuration Management SUP.8',
    effortType: 'requirement',
    parentId: 7,
    organizations: [
      {
        organizationId: 4,
        orgResReqExp: 'NA EPM (TRY)-PMO Eng. Project Management Office',
        orgResKey: '1861782',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      },
      {
        organizationId: 8,
        orgResReqExp: 'NA EPM (TCI)-PMO Eng. Project Management Office',
        orgResKey: '1871482',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 10,
        orgResReqExp: 'CN EPM-PMO Eng. Project Management Office',
        orgResKey: '1855129',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 1,
        subproject: 'SP01 Eng. Project Management'
      }
    ]
  },
  {
    workpackageId: 13,
    workpackage: 'EBO-EBO Quality Assurance',
    sortOrder: 3,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 277,
        orgResReqExp: 'EBO-EBO Quality Assurance',
        orgResKey: '1853935',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          },
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 1,
        subproject: 'SP01 Eng. Project Management'
      }
    ]
  },
  {
    workpackageId: 14,
    workpackage: 'Engineering Quality Assurance SUP.1',
    effortType: 'requirement',
    parentId: 13,
    organizations: [
      {
        organizationId: 277,
        orgResReqExp: 'EBO-EBO Quality Assurance',
        orgResKey: '1853935',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          },
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 1,
        subproject: 'SP01 Eng. Project Management'
      }
    ]
  },
  {
    workpackageId: 15,
    workpackage: 'EBO-EBO Quote Management',
    sortOrder: 4,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 278,
        orgResReqExp: 'EBO-EBO Quote Management',
        orgResKey: '1853930',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          },
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 1,
        subproject: 'SP01 Eng. Project Management'
      }
    ]
  },
  {
    workpackageId: 16,
    workpackage: 'Engineering Quoting',
    effortType: 'requirement',
    parentId: 15,
    organizations: [
      {
        organizationId: 278,
        orgResReqExp: 'EBO-EBO Quote Management',
        orgResKey: '1853930',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          },
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 1,
        subproject: 'SP01 Eng. Project Management'
      }
    ]
  },
  {
    workpackageId: 17,
    workpackage: 'SYS-PSE Product Line Mgmt & Design',
    sortOrder: 6,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 16,
        orgResReqExp: 'EU SYS-PSE Product Line mngnt u. design',
        orgResKey: '1854326',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 2,
        subproject: 'SP02 System Eng. & PFG'
      }
    ]
  },
  {
    workpackageId: 18,
    workpackage: 'Manage Eng. Project & PMO Planning Support',
    effortType: 'requirement',
    parentId: 18,
    organizations: [
      {
        organizationId: 16,
        orgResReqExp: 'EU SYS-PSE Product Line mngnt u. design',
        orgResKey: '1854326',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 2,
        subproject: 'SP02 System Eng. & PFG'
      }
    ]
  },
  {
    workpackageId: 19,
    workpackage: 'SYS-PSE Concept Architecture',
    sortOrder: 7,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 12,
        orgResReqExp: 'EU SYS-PSE Concept Architecture',
        orgResKey: '1854317',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 29,
        orgResReqExp: 'NA SYS (TRY)-PSE Concept Architecture',
        orgResKey: '1861764',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      },
      {
        organizationId: 40,
        orgResReqExp: 'NA SYS (RAM)-PSE Concept Architecture',
        orgResKey: '1854343',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 6,
            qvLocation: 'RAM'
          }
        ]
      },
      {
        organizationId: 48,
        orgResReqExp: 'CN SYS-PSE Concept Architecture',
        orgResKey: '1855106',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 2,
        subproject: 'SP02 System Eng. & PFG'
      }
    ]
  },
  {
    workpackageId: 20,
    workpackage: 'System Architecture SYS.2 SYS.3',
    effortType: 'requirement',
    parentId: 19,
    organizations: [
      {
        organizationId: 12,
        orgResReqExp: 'EU SYS-PSE Concept Architecture',
        orgResKey: '1854317',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 29,
        orgResReqExp: 'NA SYS (TRY)-PSE Concept Architecture',
        orgResKey: '1861764',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      },
      {
        organizationId: 40,
        orgResReqExp: 'NA SYS (RAM)-PSE Concept Architecture',
        orgResKey: '1854343',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 6,
            qvLocation: 'RAM'
          }
        ]
      },
      {
        organizationId: 48,
        orgResReqExp: 'CN SYS-PSE Concept Architecture',
        orgResKey: '1855106',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 2,
        subproject: 'SP02 System Eng. & PFG'
      }
    ]
  },
  {
    workpackageId: 21,
    workpackage: 'SYS-PSE Requirements Management',
    sortOrder: 8,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 13,
        orgResReqExp: 'EU SYS-PSE Requirements Management',
        orgResKey: '1854323',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 30,
        orgResReqExp: 'NA SYS (TRY)-PSE Requirements Management',
        orgResKey: '1861773',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      },
      {
        organizationId: 46,
        orgResReqExp: 'NA SYS (TCI)-PSE Requirements Management',
        orgResKey: '1865471',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 2,
        subproject: 'SP02 System Eng. & PFG'
      }
    ]
  },
  {
    workpackageId: 22,
    workpackage: 'Requirement Manag. SYS.2 SYS.3',
    effortType: 'requirement',
    parentId: 22,
    organizations: [
      {
        organizationId: 13,
        orgResReqExp: 'EU SYS-PSE Requirements Management',
        orgResKey: '1854323',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 30,
        orgResReqExp: 'NA SYS (TRY)-PSE Requirements Management',
        orgResKey: '1861773',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      },
      {
        organizationId: 46,
        orgResReqExp: 'NA SYS (TCI)-PSE Requirements Management',
        orgResKey: '1865471',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 2,
        subproject: 'SP02 System Eng. & PFG'
      }
    ]
  },
  {
    workpackageId: 23,
    workpackage: 'SYS-PSE Geometrical Integration',
    sortOrder: 9,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 14,
        orgResReqExp: 'EU SYS-PSE Geometrical Integration',
        orgResKey: '1854332',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 2,
        subproject: 'SP02 System Eng. & PFG'
      }
    ]
  },
  {
    workpackageId: 24,
    workpackage: 'Geometrical Integration SYS.3',
    effortType: 'requirement',
    parentId: 24,
    organizations: [
      {
        organizationId: 14,
        orgResReqExp: 'EU SYS-PSE Geometrical Integration',
        orgResKey: '1854332',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 2,
        subproject: 'SP02 System Eng. & PFG'
      }
    ]
  },
  {
    workpackageId: 25,
    workpackage: 'SYS-PSE Test & EOL Management',
    sortOrder: 10,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 15,
        orgResReqExp: 'EU SYS-PSE Test & EOL Management',
        orgResKey: '1854315',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 41,
        orgResReqExp: 'NA SYS (RAM)-PSE Test & EOL Management',
        orgResKey: '1854316',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 6,
            qvLocation: 'RAM'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 2,
        subproject: 'SP02 System Eng. & PFG'
      }
    ]
  },
  {
    workpackageId: 26,
    workpackage: 'EOL Koordination SYS.4',
    effortType: 'requirement',
    parentId: 26,
    organizations: [
      {
        organizationId: 15,
        orgResReqExp: 'EU SYS-PSE Test & EOL Management',
        orgResKey: '1854315',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 41,
        orgResReqExp: 'NA SYS (RAM)-PSE Test & EOL Management',
        orgResKey: '1854316',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 6,
            qvLocation: 'RAM'
          }
        ]
      },
      {
        organizationId: 31,
        orgResReqExp: 'NA SYS (TRY)-PSE Advanced Engineering',
        orgResKey: '1861761',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 2,
        subproject: 'SP02 System Eng. & PFG'
      }
    ]
  },
  {
    workpackageId: 27,
    workpackage: 'Test Management SYS.5',
    effortType: 'requirement',
    parentId: 26,
    organizations: [
      {
        organizationId: 15,
        orgResReqExp: 'EU SYS-PSE Test & EOL Management',
        orgResKey: '1854315',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 41,
        orgResReqExp: 'NA SYS (RAM)-PSE Test & EOL Management',
        orgResKey: '1854316',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 6,
            qvLocation: 'RAM'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 2,
        subproject: 'SP02 System Eng. & PFG'
      }
    ]
  },
  {
    workpackageId: 28,
    workpackage: 'SYS-PSE Advanced Engineering',
    sortOrder: 11,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 17,
        orgResReqExp: 'EU SYS-PSE Advanced Engineering',
        orgResKey: '1854324',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 2,
        subproject: 'SP02 System Eng. & PFG'
      }
    ]
  },
  {
    workpackageId: 29,
    workpackage: 'SYS-PSE Test Autom. Virt. Verific. & Sim.',
    sortOrder: 12,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 18,
        orgResReqExp: 'EU SYS-PSE Test Autom. Virt. Verific.& Sim.',
        orgResKey: '1854352',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 32,
        orgResReqExp: 'NA SYS (TRY)-PSE Test Autom. Virt. Verific.& Sim.',
        orgResKey: '1861772',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 2,
        subproject: 'SP02 System Eng. & PFG'
      }
    ]
  },
  {
    workpackageId: 30,
    workpackage: 'TA-VV-Sim Test automation infrastructure',
    effortType: 'requirement',
    parentId: 29,
    organizations: [
      {
        organizationId: 18,
        orgResReqExp: 'EU SYS-PSE Test Autom. Virt. Verific.& Sim.',
        orgResKey: '1854352',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 32,
        orgResReqExp: 'NA SYS (TRY)-PSE Test Autom. Virt. Verific.& Sim.',
        orgResKey: '1861772',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 2,
        subproject: 'SP02 System Eng. & PFG'
      }
    ]
  },
  {
    workpackageId: 31,
    workpackage: 'TA-VV-Sim TA Framework, HIL & sim. model',
    effortType: 'requirement',
    parentId: 29,
    organizations: [
      {
        organizationId: 18,
        orgResReqExp: 'EU SYS-PSE Test Autom. Virt. Verific.& Sim.',
        orgResKey: '1854352',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 32,
        orgResReqExp: 'NA SYS (TRY)-PSE Test Autom. Virt. Verific.& Sim.',
        orgResKey: '1861772',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 2,
        subproject: 'SP02 System Eng. & PFG'
      }
    ]
  },
  {
    workpackageId: 32,
    workpackage: 'SYS-F&A Test Functional',
    sortOrder: 13,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 19,
        orgResReqExp: 'EU SYS-F&A Test Functional',
        orgResKey: '1854306',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 33,
        orgResReqExp: 'NA SYS (TRY)-F&A Test Functional',
        orgResKey: '1861765',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 2,
        subproject: 'SP02 System Eng. & PFG'
      }
    ]
  },
  {
    workpackageId: 33,
    workpackage: 'SYT-F System Test Functional - Test Eng.',
    effortType: 'requirement',
    parentId: 32,
    organizations: [
      {
        organizationId: 19,
        orgResReqExp: 'EU SYS-F&A Test Functional',
        orgResKey: '1854306',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 33,
        orgResReqExp: 'NA SYS (TRY)-F&A Test Functional',
        orgResKey: '1861765',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 2,
        subproject: 'SP02 System Eng. & PFG'
      }
    ]
  },
  {
    workpackageId: 34,
    workpackage: 'SYS-F&A PFG10 Driving Perf. / Driveability',
    sortOrder: 14,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 20,
        orgResReqExp: 'EU SYS-F&A PFG10 Driving Perf. / Driveability',
        orgResKey: '1854341',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 34,
        orgResReqExp: 'NA SYS (TRY)-F&A PFG10 Driving Perf. / Driveabilit',
        orgResKey: '1861771',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      },
      {
        organizationId: 47,
        orgResReqExp: 'NA SYS (TCI)-F&A PFG10 Driving Perf. / Driveabilit',
        orgResKey: '1865472',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 2,
        subproject: 'SP02 System Eng. & PFG'
      }
    ]
  },
  {
    workpackageId: 35,
    workpackage: 'PFG10 TB incl. TO for PFG10 develop. tests',
    effortType: 'requirement',
    parentId: 34,
    organizations: [
      {
        organizationId: 20,
        orgResReqExp: 'EU SYS-F&A PFG10 Driving Perf. / Driveability',
        orgResKey: '1854341',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 34,
        orgResReqExp: 'NA SYS (TRY)-F&A PFG10 Driving Perf. / Driveabilit',
        orgResKey: '1861771',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      },
      {
        organizationId: 47,
        orgResReqExp: 'NA SYS (TCI)-F&A PFG10 Driving Perf. / Driveabilit',
        orgResKey: '1865472',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 2,
        subproject: 'SP02 System Eng. & PFG'
      }
    ]
  },
  {
    workpackageId: 36,
    workpackage: 'PFG10 Resident Engineer',
    effortType: 'requirement',
    parentId: 34,
    organizations: [
      {
        organizationId: 20,
        orgResReqExp: 'EU SYS-F&A PFG10 Driving Perf. / Driveability',
        orgResKey: '1854341',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 34,
        orgResReqExp: 'NA SYS (TRY)-F&A PFG10 Driving Perf. / Driveabilit',
        orgResKey: '1861771',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      },
      {
        organizationId: 47,
        orgResReqExp: 'NA SYS (TCI)-F&A PFG10 Driving Perf. / Driveabilit',
        orgResKey: '1865472',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 2,
        subproject: 'SP02 System Eng. & PFG'
      }
    ]
  },
  {
    workpackageId: 37,
    workpackage: 'PFG10 Type Certification',
    effortType: 'requirement',
    parentId: 34,
    organizations: [
      {
        organizationId: 20,
        orgResReqExp: 'EU SYS-F&A PFG10 Driving Perf. / Driveability',
        orgResKey: '1854341',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 34,
        orgResReqExp: 'NA SYS (TRY)-F&A PFG10 Driving Perf. / Driveabilit',
        orgResKey: '1861771',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      },
      {
        organizationId: 47,
        orgResReqExp: 'NA SYS (TCI)-F&A PFG10 Driving Perf. / Driveabilit',
        orgResKey: '1865472',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 2,
        subproject: 'SP02 System Eng. & PFG'
      }
    ]
  },
  {
    workpackageId: 38,
    workpackage: 'SYS-F&A PFG20 Efficiency, Energy Consumption',
    sortOrder: 15,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 21,
        orgResReqExp: 'EU SYS-F&A PFG20 Efficiency, Energy Consumption',
        orgResKey: '1854305',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 35,
        orgResReqExp: 'NA SYS (TRY)-F&A PFG20 Efficiency, Energy Consumpt',
        orgResKey: '1861762',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 2,
        subproject: 'SP02 System Eng. & PFG'
      }
    ]
  },
  {
    workpackageId: 39,
    workpackage: 'PFG20 Engineering',
    effortType: 'requirement',
    parentId: 38,
    organizations: [
      {
        organizationId: 21,
        orgResReqExp: 'EU SYS-F&A PFG20 Efficiency, Energy Consumption',
        orgResKey: '1854305',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 35,
        orgResReqExp: 'NA SYS (TRY)-F&A PFG20 Efficiency, Energy Consumpt',
        orgResKey: '1861762',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 2,
        subproject: 'SP02 System Eng. & PFG'
      }
    ]
  },
  {
    workpackageId: 40,
    workpackage: 'PFG20 TB incl. TO for FG20 develop. tests',
    effortType: 'requirement',
    parentId: 38,
    organizations: [
      {
        organizationId: 21,
        orgResReqExp: 'EU SYS-F&A PFG20 Efficiency, Energy Consumption',
        orgResKey: '1854305',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 35,
        orgResReqExp: 'NA SYS (TRY)-F&A PFG20 Efficiency, Energy Consumpt',
        orgResKey: '1861762',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 2,
        subproject: 'SP02 System Eng. & PFG'
      }
    ]
  },
  {
    workpackageId: 41,
    workpackage: 'SYS-F&A PFG30 Durability Reliab. Environment',
    sortOrder: 16,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 22,
        orgResReqExp: 'EU SYS-F&A PFG30 Durability Reliab. Environment',
        orgResKey: '1854351',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 37,
        orgResReqExp: 'NA SYS (TRY)-F&A PFG30 Durability Reliab. Environm',
        orgResKey: '1861757',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      },
      {
        organizationId: 43,
        orgResReqExp: 'NA SYS (RAM)-F&A PFG30 Durability Reliab. Environm',
        orgResKey: '1854336',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 6,
            qvLocation: 'RAM'
          }
        ]
      },
      {
        organizationId: 49,
        orgResReqExp: 'CN SYS-F&A PFG30 Durability Reliab. Environment',
        orgResKey: '1855101',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 2,
        subproject: 'SP02 System Eng. & PFG'
      }
    ]
  },
  {
    workpackageId: 42,
    workpackage: 'PFG30 Env System Engineering',
    effortType: 'requirement',
    parentId: 41,
    organizations: [
      {
        organizationId: 22,
        orgResReqExp: 'EU SYS-F&A PFG30 Durability Reliab. Environment',
        orgResKey: '1854351',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 37,
        orgResReqExp: 'NA SYS (TRY)-F&A PFG30 Durability Reliab. Environm',
        orgResKey: '1861757',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      },
      {
        organizationId: 43,
        orgResReqExp: 'NA SYS (RAM)-F&A PFG30 Durability Reliab. Environm',
        orgResKey: '1854336',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 6,
            qvLocation: 'RAM'
          }
        ]
      },
      {
        organizationId: 49,
        orgResReqExp: 'CN SYS-F&A PFG30 Durability Reliab. Environment',
        orgResKey: '1855101',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 2,
        subproject: 'SP02 System Eng. & PFG'
      }
    ]
  },
  {
    workpackageId: 43,
    workpackage: 'PFG30 Env System Test Engineering',
    effortType: 'requirement',
    parentId: 41,
    organizations: [
      {
        organizationId: 22,
        orgResReqExp: 'EU SYS-F&A PFG30 Durability Reliab. Environment',
        orgResKey: '1854351',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 37,
        orgResReqExp: 'NA SYS (TRY)-F&A PFG30 Durability Reliab. Environm',
        orgResKey: '1861757',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      },
      {
        organizationId: 43,
        orgResReqExp: 'NA SYS (RAM)-F&A PFG30 Durability Reliab. Environm',
        orgResKey: '1854336',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 6,
            qvLocation: 'RAM'
          }
        ]
      },
      {
        organizationId: 49,
        orgResReqExp: 'CN SYS-F&A PFG30 Durability Reliab. Environment',
        orgResKey: '1855101',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 2,
        subproject: 'SP02 System Eng. & PFG'
      }
    ]
  },
  {
    workpackageId: 44,
    workpackage: 'SYS-F&A PFG50 Thermal Management',
    sortOrder: 17,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 23,
        orgResReqExp: 'EU SYS-F&A PFG50 Thermal Management',
        orgResKey: '1854322',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 36,
        orgResReqExp: 'NA SYS (TRY)-F&A PFG50 Thermal Management',
        orgResKey: '1861769',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 2,
        subproject: 'SP02 System Eng. & PFG'
      }
    ]
  },
  {
    workpackageId: 45,
    workpackage: 'PFG50 TM System Engineering',
    effortType: 'requirement',
    parentId: 44,
    organizations: [
      {
        organizationId: 23,
        orgResReqExp: 'EU SYS-F&A PFG50 Thermal Management',
        orgResKey: '1854322',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 36,
        orgResReqExp: 'NA SYS (TRY)-F&A PFG50 Thermal Management',
        orgResKey: '1861769',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 2,
        subproject: 'SP02 System Eng. & PFG'
      }
    ]
  },
  {
    workpackageId: 46,
    workpackage: 'PFG50 Thermal Function Development',
    effortType: 'requirement',
    parentId: 44,
    organizations: [
      {
        organizationId: 23,
        orgResReqExp: 'EU SYS-F&A PFG50 Thermal Management',
        orgResKey: '1854322',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 36,
        orgResReqExp: 'NA SYS (TRY)-F&A PFG50 Thermal Management',
        orgResKey: '1861769',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 2,
        subproject: 'SP02 System Eng. & PFG'
      }
    ]
  },
  {
    workpackageId: 47,
    workpackage: 'PFG50 TM System Architecture & Simulation',
    effortType: 'requirement',
    parentId: 44,
    organizations: [
      {
        organizationId: 23,
        orgResReqExp: 'EU SYS-F&A PFG50 Thermal Management',
        orgResKey: '1854322',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 36,
        orgResReqExp: 'NA SYS (TRY)-F&A PFG50 Thermal Management',
        orgResKey: '1861769',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 2,
        subproject: 'SP02 System Eng. & PFG'
      }
    ]
  },
  {
    workpackageId: 48,
    workpackage: 'PFG50 Thermal Testing & Calibration',
    effortType: 'requirement',
    parentId: 44,
    organizations: [
      {
        organizationId: 23,
        orgResReqExp: 'EU SYS-F&A PFG50 Thermal Management',
        orgResKey: '1854322',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 36,
        orgResReqExp: 'NA SYS (TRY)-F&A PFG50 Thermal Management',
        orgResKey: '1861769',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 2,
        subproject: 'SP02 System Eng. & PFG'
      }
    ]
  },
  {
    workpackageId: 49,
    workpackage: 'SYS-F&A PFG60 NVH',
    sortOrder: 18,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 24,
        orgResReqExp: 'EU SYS-F&A PFG60 NVH',
        orgResKey: '1854321',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 38,
        orgResReqExp: 'NA SYS (TRY)-F&A PFG60 NVH',
        orgResKey: '1861770',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      },
      {
        organizationId: 42,
        orgResReqExp: 'NA SYS (RAM)-F&A PFG60 NVH',
        orgResKey: '1854309',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 6,
            qvLocation: 'RAM'
          }
        ]
      },
      {
        organizationId: 50,
        orgResReqExp: 'CN SYS-F&A PFG60 NVH',
        orgResKey: '1855102',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 2,
        subproject: 'SP02 System Eng. & PFG'
      }
    ]
  },
  {
    workpackageId: 50,
    workpackage: 'PFG60 NVH System Engineering',
    effortType: 'requirement',
    parentId: 49,
    organizations: [
      {
        organizationId: 24,
        orgResReqExp: 'EU SYS-F&A PFG60 NVH',
        orgResKey: '1854321',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 38,
        orgResReqExp: 'NA SYS (TRY)-F&A PFG60 NVH',
        orgResKey: '1861770',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      },
      {
        organizationId: 42,
        orgResReqExp: 'NA SYS (RAM)-F&A PFG60 NVH',
        orgResKey: '1854309',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 6,
            qvLocation: 'RAM'
          }
        ]
      },
      {
        organizationId: 50,
        orgResReqExp: 'CN SYS-F&A PFG60 NVH',
        orgResKey: '1855102',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 2,
        subproject: 'SP02 System Eng. & PFG'
      }
    ]
  },
  {
    workpackageId: 51,
    workpackage: 'PFG60 NVH Simulation',
    effortType: 'requirement',
    parentId: 49,
    organizations: [
      {
        organizationId: 24,
        orgResReqExp: 'EU SYS-F&A PFG60 NVH',
        orgResKey: '1854321',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 38,
        orgResReqExp: 'NA SYS (TRY)-F&A PFG60 NVH',
        orgResKey: '1861770',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      },
      {
        organizationId: 42,
        orgResReqExp: 'NA SYS (RAM)-F&A PFG60 NVH',
        orgResKey: '1854309',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 6,
            qvLocation: 'RAM'
          }
        ]
      },
      {
        organizationId: 50,
        orgResReqExp: 'CN SYS-F&A PFG60 NVH',
        orgResKey: '1855102',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 2,
        subproject: 'SP02 System Eng. & PFG'
      }
    ]
  },
  {
    workpackageId: 52,
    workpackage: 'PFG60 NVH Test Engineering',
    effortType: 'requirement',
    parentId: 49,
    organizations: [
      {
        organizationId: 24,
        orgResReqExp: 'EU SYS-F&A PFG60 NVH',
        orgResKey: '1854321',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 38,
        orgResReqExp: 'NA SYS (TRY)-F&A PFG60 NVH',
        orgResKey: '1861770',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      },
      {
        organizationId: 42,
        orgResReqExp: 'NA SYS (RAM)-F&A PFG60 NVH',
        orgResKey: '1854309',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 6,
            qvLocation: 'RAM'
          }
        ]
      },
      {
        organizationId: 50,
        orgResReqExp: 'CN SYS-F&A PFG60 NVH',
        orgResKey: '1855102',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 2,
        subproject: 'SP02 System Eng. & PFG'
      }
    ]
  },
  {
    workpackageId: 53,
    workpackage: 'SYS-F&A PFG70 FuSa',
    sortOrder: 19,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 25,
        orgResReqExp: 'EU SYS-F&A PFG70 FuSa',
        orgResKey: '1854329',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 39,
        orgResReqExp: 'NA SYS (TRY)-F&A PFG70 FuSa',
        orgResKey: '1861763',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      },
      {
        organizationId: 45,
        orgResReqExp: 'NA SYS (TCI)-F&A PFG70 FuSa',
        orgResKey: '1874454',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 2,
        subproject: 'SP02 System Eng. & PFG'
      }
    ]
  },
  {
    workpackageId: 54,
    workpackage: 'PFG70 FuSa',
    effortType: 'requirement',
    parentId: 53,
    organizations: [
      {
        organizationId: 25,
        orgResReqExp: 'EU SYS-F&A PFG70 FuSa',
        orgResKey: '1854329',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 39,
        orgResReqExp: 'NA SYS (TRY)-F&A PFG70 FuSa',
        orgResKey: '1861763',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      },
      {
        organizationId: 45,
        orgResReqExp: 'NA SYS (TCI)-F&A PFG70 FuSa',
        orgResKey: '1874454',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 2,
        subproject: 'SP02 System Eng. & PFG'
      }
    ]
  },
  {
    workpackageId: 55,
    workpackage: 'PFG70 Diagnostics (incl. OBD)',
    effortType: 'requirement',
    parentId: 53,
    organizations: [
      {
        organizationId: 25,
        orgResReqExp: 'EU SYS-F&A PFG70 FuSa',
        orgResKey: '1854329',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 39,
        orgResReqExp: 'NA SYS (TRY)-F&A PFG70 FuSa',
        orgResKey: '1861763',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      },
      {
        organizationId: 45,
        orgResReqExp: 'NA SYS (TCI)-F&A PFG70 FuSa',
        orgResKey: '1874454',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 2,
        subproject: 'SP02 System Eng. & PFG'
      }
    ]
  },
  {
    workpackageId: 56,
    workpackage: 'PFG70 FuSa External Assessments',
    effortType: 'requirement',
    parentId: 53,
    organizations: [
      {
        organizationId: 25,
        orgResReqExp: 'EU SYS-F&A PFG70 FuSa',
        orgResKey: '1854329',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 39,
        orgResReqExp: 'NA SYS (TRY)-F&A PFG70 FuSa',
        orgResKey: '1861763',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      },
      {
        organizationId: 45,
        orgResReqExp: 'NA SYS (TCI)-F&A PFG70 FuSa',
        orgResKey: '1874454',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 2,
        subproject: 'SP02 System Eng. & PFG'
      }
    ]
  },
  {
    workpackageId: 57,
    workpackage: 'SYS-F&A PFG70 Cyber Security',
    sortOrder: 20,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 26,
        orgResReqExp: 'EU SYS-F&A PFG70 Cyber Security',
        orgResKey: '1854330',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 44,
        orgResReqExp: 'NA SYS (TCI)-F&A PFG70 Cyber Security',
        orgResKey: '1871465',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 2,
        subproject: 'SP02 System Eng. & PFG'
      }
    ]
  },
  {
    workpackageId: 58,
    workpackage: 'PFG70 CySe',
    effortType: 'requirement',
    parentId: 57,
    organizations: [
      {
        organizationId: 26,
        orgResReqExp: 'EU SYS-F&A PFG70 Cyber Security',
        orgResKey: '1854330',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 44,
        orgResReqExp: 'NA SYS (TCI)-F&A PFG70 Cyber Security',
        orgResKey: '1871465',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 2,
        subproject: 'SP02 System Eng. & PFG'
      }
    ]
  },
  {
    workpackageId: 59,
    workpackage: 'PFG70 Continuous CySe monitoring (after SOP)',
    effortType: 'requirement',
    parentId: 57,
    organizations: [
      {
        organizationId: 26,
        orgResReqExp: 'EU SYS-F&A PFG70 Cyber Security',
        orgResKey: '1854330',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 44,
        orgResReqExp: 'NA SYS (TCI)-F&A PFG70 Cyber Security',
        orgResKey: '1871465',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 2,
        subproject: 'SP02 System Eng. & PFG'
      }
    ]
  },
  {
    workpackageId: 60,
    workpackage: 'SYS-F&A PFG80 Electromagnetic compat.',
    sortOrder: 21,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 27,
        orgResReqExp: 'EU SYS-F&A PFG80 Electromagnetic compat.',
        orgResKey: '1854334',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 2,
        subproject: 'SP02 System Eng. & PFG'
      }
    ]
  },
  {
    workpackageId: 61,
    workpackage: 'PFG80 EMC System Engineering',
    effortType: 'requirement',
    parentId: 60,
    organizations: [
      {
        organizationId: 27,
        orgResReqExp: 'EU SYS-F&A PFG80 Electromagnetic compat.',
        orgResKey: '1854334',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 2,
        subproject: 'SP02 System Eng. & PFG'
      }
    ]
  },
  {
    workpackageId: 62,
    workpackage: 'PFG80 EMC System Simulation',
    effortType: 'requirement',
    parentId: 60,
    organizations: [
      {
        organizationId: 27,
        orgResReqExp: 'EU SYS-F&A PFG80 Electromagnetic compat.',
        orgResKey: '1854334',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 2,
        subproject: 'SP02 System Eng. & PFG'
      }
    ]
  },
  {
    workpackageId: 63,
    workpackage: 'PFG80 EMC System Test Engineering',
    effortType: 'requirement',
    parentId: 60,
    organizations: [
      {
        organizationId: 27,
        orgResReqExp: 'EU SYS-F&A PFG80 Electromagnetic compat.',
        orgResKey: '1854334',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 2,
        subproject: 'SP02 System Eng. & PFG'
      }
    ]
  },
  {
    workpackageId: 64,
    workpackage: 'SYS-F&A PFG90 Sustainability',
    sortOrder: 22,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 28,
        orgResReqExp: 'EU SYS-F&A PFG90 Sustainability',
        orgResKey: '1854333',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 2,
        subproject: 'SP02 System Eng. & PFG'
      }
    ]
  },
  {
    workpackageId: 65,
    workpackage: 'PFG90 Sustainability System Engineering',
    effortType: 'requirement',
    parentId: 64,
    organizations: [
      {
        organizationId: 28,
        orgResReqExp: 'EU SYS-F&A PFG90 Sustainability',
        orgResKey: '1854333',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 2,
        subproject: 'SP02 System Eng. & PFG'
      }
    ]
  },
  {
    workpackageId: 66,
    workpackage: 'GBX-SYS Gearbox Architecture',
    sortOrder: 23,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 51,
        orgResReqExp: 'EU GBX-SYS Gearbox Architecture',
        orgResKey: '1854430',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 78,
        orgResReqExp: 'CN GBX-SYS Gearbox Architecture',
        orgResKey: '1855155',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 3,
        subproject: 'SP03 Gearbox & Mechatronics'
      }
    ]
  },
  {
    workpackageId: 67,
    workpackage: 'GBX Architecture & Specification',
    effortType: 'requirement',
    parentId: 66,
    organizations: [
      {
        organizationId: 51,
        orgResReqExp: 'EU GBX-SYS Gearbox Architecture',
        orgResKey: '1854430',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 78,
        orgResReqExp: 'CN GBX-SYS Gearbox Architecture',
        orgResKey: '1855155',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 3,
        subproject: 'SP03 Gearbox & Mechatronics'
      }
    ]
  },
  {
    workpackageId: 68,
    workpackage: 'GBX Requirements Management',
    effortType: 'requirement',
    parentId: 66,
    organizations: [
      {
        organizationId: 51,
        orgResReqExp: 'EU GBX-SYS Gearbox Architecture',
        orgResKey: '1854430',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 78,
        orgResReqExp: 'CN GBX-SYS Gearbox Architecture',
        orgResKey: '1855155',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 3,
        subproject: 'SP03 Gearbox & Mechatronics'
      }
    ]
  },
  {
    workpackageId: 69,
    workpackage: 'GBX-SYS Gearbox Design',
    sortOrder: 24,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 52,
        orgResReqExp: 'EU GBX-SYS Gearbox Design',
        orgResKey: '1854434',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 63,
        orgResReqExp: 'NA GBX (TRY)-SYS Gearbox Design',
        orgResKey: '1861777',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      },
      {
        organizationId: 70,
        orgResReqExp: 'NA GBX (RAM)-SYS Gearbox Design',
        orgResKey: '1854378',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 6,
            qvLocation: 'RAM'
          }
        ]
      },
      {
        organizationId: 76,
        orgResReqExp: 'NA GBX (TCI)-SYS Gearbox Design',
        orgResKey: '1865474',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 3,
        subproject: 'SP03 Gearbox & Mechatronics'
      }
    ]
  },
  {
    workpackageId: 70,
    workpackage: 'CAP Design',
    effortType: 'requirement',
    parentId: 69,
    organizations: [
      {
        organizationId: 52,
        orgResReqExp: 'EU GBX-SYS Gearbox Design',
        orgResKey: '1854434',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 63,
        orgResReqExp: 'NA GBX (TRY)-SYS Gearbox Design',
        orgResKey: '1861777',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      },
      {
        organizationId: 70,
        orgResReqExp: 'NA GBX (RAM)-SYS Gearbox Design',
        orgResKey: '1854378',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 6,
            qvLocation: 'RAM'
          }
        ]
      },
      {
        organizationId: 76,
        orgResReqExp: 'NA GBX (TCI)-SYS Gearbox Design',
        orgResKey: '1865474',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 3,
        subproject: 'SP03 Gearbox & Mechatronics'
      }
    ]
  },
  {
    workpackageId: 71,
    workpackage: 'PFG40 - Lubrication, Sealing, Corrosion',
    effortType: 'requirement',
    parentId: 69,
    organizations: [
      {
        organizationId: 52,
        orgResReqExp: 'EU GBX-SYS Gearbox Design',
        orgResKey: '1854434',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 63,
        orgResReqExp: 'NA GBX (TRY)-SYS Gearbox Design',
        orgResKey: '1861777',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      },
      {
        organizationId: 70,
        orgResReqExp: 'NA GBX (RAM)-SYS Gearbox Design',
        orgResKey: '1854378',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 6,
            qvLocation: 'RAM'
          }
        ]
      },
      {
        organizationId: 76,
        orgResReqExp: 'NA GBX (TCI)-SYS Gearbox Design',
        orgResKey: '1865474',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 3,
        subproject: 'SP03 Gearbox & Mechatronics'
      }
    ]
  },
  {
    workpackageId: 72,
    workpackage: 'GBX-SYS Gearbox Advanced Engineering',
    sortOrder: 25,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 53,
        orgResReqExp: 'EU GBX-SYS Gearbox Advanced Engineering',
        orgResKey: '1855880',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 3,
        subproject: 'SP03 Gearbox & Mechatronics'
      }
    ]
  },
  {
    workpackageId: 73,
    workpackage: 'GBX-MVV Gearbox Mechanic Verification',
    sortOrder: 26,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 54,
        orgResReqExp: 'EU GBX-MVV Gearbox Mechanic Verification',
        orgResKey: '1854420',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 79,
        orgResReqExp: 'CN GBX-MVV Gearbox Mechanic Verification',
        orgResKey: '1855146',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 3,
        subproject: 'SP03 Gearbox & Mechatronics'
      }
    ]
  },
  {
    workpackageId: 74,
    workpackage: 'Test Management & Coordination',
    effortType: 'requirement',
    parentId: 73,
    organizations: [
      {
        organizationId: 54,
        orgResReqExp: 'EU GBX-MVV Gearbox Mechanic Verification',
        orgResKey: '1854420',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 79,
        orgResReqExp: 'CN GBX-MVV Gearbox Mechanic Verification',
        orgResKey: '1855146',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 3,
        subproject: 'SP03 Gearbox & Mechatronics'
      }
    ]
  },
  {
    workpackageId: 75,
    workpackage: 'Structural Durability Support',
    effortType: 'requirement',
    parentId: 73,
    organizations: [
      {
        organizationId: 54,
        orgResReqExp: 'EU GBX-MVV Gearbox Mechanic Verification',
        orgResKey: '1854420',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 79,
        orgResReqExp: 'CN GBX-MVV Gearbox Mechanic Verification',
        orgResKey: '1855146',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 3,
        subproject: 'SP03 Gearbox & Mechatronics'
      }
    ]
  },
  {
    workpackageId: 76,
    workpackage: 'Project Support',
    effortType: 'requirement',
    parentId: 73,
    organizations: [
      {
        organizationId: 54,
        orgResReqExp: 'EU GBX-MVV Gearbox Mechanic Verification',
        orgResKey: '1854420',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 79,
        orgResReqExp: 'CN GBX-MVV Gearbox Mechanic Verification',
        orgResKey: '1855146',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 3,
        subproject: 'SP03 Gearbox & Mechatronics'
      }
    ]
  },
  {
    workpackageId: 77,
    workpackage: 'Rig setup',
    effortType: 'requirement',
    parentId: 73,
    organizations: [
      {
        organizationId: 54,
        orgResReqExp: 'EU GBX-MVV Gearbox Mechanic Verification',
        orgResKey: '1854420',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 79,
        orgResReqExp: 'CN GBX-MVV Gearbox Mechanic Verification',
        orgResKey: '1855146',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 3,
        subproject: 'SP03 Gearbox & Mechatronics'
      }
    ]
  },
  {
    workpackageId: 78,
    workpackage: 'GBX-PSE Geometrical Integration',
    sortOrder: 27,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 62,
        orgResReqExp: 'EU GBX-PSE Geometrical Integration',
        orgResKey: '1865684',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 3,
        subproject: 'SP03 Gearbox & Mechatronics'
      }
    ]
  },
  {
    workpackageId: 79,
    workpackage: 'GBX-MVV Gearbox Virtual Verification',
    sortOrder: 28,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 55,
        orgResReqExp: 'EU GBX-MVV Gearbox Virtual Verification',
        orgResKey: '1854428',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 64,
        orgResReqExp: 'NA GBX (TRY)-MVV Gearbox Virtual Verification',
        orgResKey: '1861776',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      },
      {
        organizationId: 72,
        orgResReqExp: 'NA GBX (RAM)-MVV Gearbox Virtual Verification',
        orgResKey: '1854397',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 6,
            qvLocation: 'RAM'
          }
        ]
      },
      {
        organizationId: 75,
        orgResReqExp: 'NA GBX (CON)-MVV Gearbox Virtual Verification',
        orgResKey: '1861785',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 7,
            qvLocation: 'CON'
          }
        ]
      },
      {
        organizationId: 80,
        orgResReqExp: 'CN GBX-MVV Gearbox Virtual Verification',
        orgResKey: '1855139',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 3,
        subproject: 'SP03 Gearbox & Mechatronics'
      }
    ]
  },
  {
    workpackageId: 80,
    workpackage: 'GBX CAE BB-010',
    effortType: 'requirement',
    parentId: 79,
    organizations: [
      {
        organizationId: 55,
        orgResReqExp: 'EU GBX-MVV Gearbox Virtual Verification',
        orgResKey: '1854428',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 64,
        orgResReqExp: 'NA GBX (TRY)-MVV Gearbox Virtual Verification',
        orgResKey: '1861776',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      },
      {
        organizationId: 72,
        orgResReqExp: 'NA GBX (RAM)-MVV Gearbox Virtual Verification',
        orgResKey: '1854397',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 6,
            qvLocation: 'RAM'
          }
        ]
      },
      {
        organizationId: 75,
        orgResReqExp: 'NA GBX (CON)-MVV Gearbox Virtual Verification',
        orgResKey: '1861785',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 7,
            qvLocation: 'CON'
          }
        ]
      },
      {
        organizationId: 80,
        orgResReqExp: 'CN GBX-MVV Gearbox Virtual Verification',
        orgResKey: '1855139',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 3,
        subproject: 'SP03 Gearbox & Mechatronics'
      }
    ]
  },
  {
    workpackageId: 81,
    workpackage: 'GBX CAE BB-020/030',
    effortType: 'requirement',
    parentId: 79,
    organizations: [
      {
        organizationId: 55,
        orgResReqExp: 'EU GBX-MVV Gearbox Virtual Verification',
        orgResKey: '1854428',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 64,
        orgResReqExp: 'NA GBX (TRY)-MVV Gearbox Virtual Verification',
        orgResKey: '1861776',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      },
      {
        organizationId: 72,
        orgResReqExp: 'NA GBX (RAM)-MVV Gearbox Virtual Verification',
        orgResKey: '1854397',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 6,
            qvLocation: 'RAM'
          }
        ]
      },
      {
        organizationId: 75,
        orgResReqExp: 'NA GBX (CON)-MVV Gearbox Virtual Verification',
        orgResKey: '1861785',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 7,
            qvLocation: 'CON'
          }
        ]
      },
      {
        organizationId: 80,
        orgResReqExp: 'CN GBX-MVV Gearbox Virtual Verification',
        orgResKey: '1855139',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 3,
        subproject: 'SP03 Gearbox & Mechatronics'
      }
    ]
  },
  {
    workpackageId: 82,
    workpackage: 'GBX CAE BB-040',
    effortType: 'requirement',
    parentId: 79,
    organizations: [
      {
        organizationId: 55,
        orgResReqExp: 'EU GBX-MVV Gearbox Virtual Verification',
        orgResKey: '1854428',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 64,
        orgResReqExp: 'NA GBX (TRY)-MVV Gearbox Virtual Verification',
        orgResKey: '1861776',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      },
      {
        organizationId: 72,
        orgResReqExp: 'NA GBX (RAM)-MVV Gearbox Virtual Verification',
        orgResKey: '1854397',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 6,
            qvLocation: 'RAM'
          }
        ]
      },
      {
        organizationId: 75,
        orgResReqExp: 'NA GBX (CON)-MVV Gearbox Virtual Verification',
        orgResKey: '1861785',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 7,
            qvLocation: 'CON'
          }
        ]
      },
      {
        organizationId: 80,
        orgResReqExp: 'CN GBX-MVV Gearbox Virtual Verification',
        orgResKey: '1855139',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 3,
        subproject: 'SP03 Gearbox & Mechatronics'
      }
    ]
  },
  {
    workpackageId: 83,
    workpackage: 'GBX CAE BB-050',
    effortType: 'requirement',
    parentId: 79,
    organizations: [
      {
        organizationId: 55,
        orgResReqExp: 'EU GBX-MVV Gearbox Virtual Verification',
        orgResKey: '1854428',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 64,
        orgResReqExp: 'NA GBX (TRY)-MVV Gearbox Virtual Verification',
        orgResKey: '1861776',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      },
      {
        organizationId: 72,
        orgResReqExp: 'NA GBX (RAM)-MVV Gearbox Virtual Verification',
        orgResKey: '1854397',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 6,
            qvLocation: 'RAM'
          }
        ]
      },
      {
        organizationId: 75,
        orgResReqExp: 'NA GBX (CON)-MVV Gearbox Virtual Verification',
        orgResKey: '1861785',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 7,
            qvLocation: 'CON'
          }
        ]
      },
      {
        organizationId: 80,
        orgResReqExp: 'CN GBX-MVV Gearbox Virtual Verification',
        orgResKey: '1855139',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 3,
        subproject: 'SP03 Gearbox & Mechatronics'
      }
    ]
  },
  {
    workpackageId: 84,
    workpackage: 'GBX CAE BB-060',
    effortType: 'requirement',
    parentId: 79,
    organizations: [
      {
        organizationId: 55,
        orgResReqExp: 'EU GBX-MVV Gearbox Virtual Verification',
        orgResKey: '1854428',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 64,
        orgResReqExp: 'NA GBX (TRY)-MVV Gearbox Virtual Verification',
        orgResKey: '1861776',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      },
      {
        organizationId: 72,
        orgResReqExp: 'NA GBX (RAM)-MVV Gearbox Virtual Verification',
        orgResKey: '1854397',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 6,
            qvLocation: 'RAM'
          }
        ]
      },
      {
        organizationId: 75,
        orgResReqExp: 'NA GBX (CON)-MVV Gearbox Virtual Verification',
        orgResKey: '1861785',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 7,
            qvLocation: 'CON'
          }
        ]
      },
      {
        organizationId: 80,
        orgResReqExp: 'CN GBX-MVV Gearbox Virtual Verification',
        orgResKey: '1855139',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 3,
        subproject: 'SP03 Gearbox & Mechatronics'
      }
    ]
  },
  {
    workpackageId: 85,
    workpackage: 'GBX CAE BB-070',
    effortType: 'requirement',
    parentId: 79,
    organizations: [
      {
        organizationId: 55,
        orgResReqExp: 'EU GBX-MVV Gearbox Virtual Verification',
        orgResKey: '1854428',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 64,
        orgResReqExp: 'NA GBX (TRY)-MVV Gearbox Virtual Verification',
        orgResKey: '1861776',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      },
      {
        organizationId: 72,
        orgResReqExp: 'NA GBX (RAM)-MVV Gearbox Virtual Verification',
        orgResKey: '1854397',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 6,
            qvLocation: 'RAM'
          }
        ]
      },
      {
        organizationId: 75,
        orgResReqExp: 'NA GBX (CON)-MVV Gearbox Virtual Verification',
        orgResKey: '1861785',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 7,
            qvLocation: 'CON'
          }
        ]
      },
      {
        organizationId: 80,
        orgResReqExp: 'CN GBX-MVV Gearbox Virtual Verification',
        orgResKey: '1855139',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 3,
        subproject: 'SP03 Gearbox & Mechatronics'
      }
    ]
  },
  {
    workpackageId: 86,
    workpackage: 'GBX CAE BB-080',
    effortType: 'requirement',
    parentId: 79,
    organizations: [
      {
        organizationId: 55,
        orgResReqExp: 'EU GBX-MVV Gearbox Virtual Verification',
        orgResKey: '1854428',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 64,
        orgResReqExp: 'NA GBX (TRY)-MVV Gearbox Virtual Verification',
        orgResKey: '1861776',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      },
      {
        organizationId: 72,
        orgResReqExp: 'NA GBX (RAM)-MVV Gearbox Virtual Verification',
        orgResKey: '1854397',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 6,
            qvLocation: 'RAM'
          }
        ]
      },
      {
        organizationId: 75,
        orgResReqExp: 'NA GBX (CON)-MVV Gearbox Virtual Verification',
        orgResKey: '1861785',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 7,
            qvLocation: 'CON'
          }
        ]
      },
      {
        organizationId: 80,
        orgResReqExp: 'CN GBX-MVV Gearbox Virtual Verification',
        orgResKey: '1855139',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 3,
        subproject: 'SP03 Gearbox & Mechatronics'
      }
    ]
  },
  {
    workpackageId: 87,
    workpackage: 'GBX CAE BB-090',
    effortType: 'requirement',
    parentId: 79,
    organizations: [
      {
        organizationId: 55,
        orgResReqExp: 'EU GBX-MVV Gearbox Virtual Verification',
        orgResKey: '1854428',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 64,
        orgResReqExp: 'NA GBX (TRY)-MVV Gearbox Virtual Verification',
        orgResKey: '1861776',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      },
      {
        organizationId: 72,
        orgResReqExp: 'NA GBX (RAM)-MVV Gearbox Virtual Verification',
        orgResKey: '1854397',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 6,
            qvLocation: 'RAM'
          }
        ]
      },
      {
        organizationId: 75,
        orgResReqExp: 'NA GBX (CON)-MVV Gearbox Virtual Verification',
        orgResKey: '1861785',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 7,
            qvLocation: 'CON'
          }
        ]
      },
      {
        organizationId: 80,
        orgResReqExp: 'CN GBX-MVV Gearbox Virtual Verification',
        orgResKey: '1855139',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 3,
        subproject: 'SP03 Gearbox & Mechatronics'
      }
    ]
  },
  {
    workpackageId: 88,
    workpackage: 'GBX-CUS Gearbox Gearset & Differentials',
    sortOrder: 29,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 57,
        orgResReqExp: 'EU GBX-CUS Gearbox Gearset & Differentials',
        orgResKey: '1854417',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 66,
        orgResReqExp: 'NA GBX (TRY)-CUS Gearbox Gearset & Differentials',
        orgResKey: '1861778',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      },
      {
        organizationId: 71,
        orgResReqExp: 'NA GBX (RAM)-CUS Gearbox Gearset & Differentials',
        orgResKey: '1854391',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 6,
            qvLocation: 'RAM'
          }
        ]
      },
      {
        organizationId: 77,
        orgResReqExp: 'NA GBX (TCI)-CUS Gearbox Gearset & Differentials',
        orgResKey: '1874453',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 82,
        orgResReqExp: 'CN GBX-CUS Gearbox Gearset & Differentials',
        orgResKey: '1855142',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 3,
        subproject: 'SP03 Gearbox & Mechatronics'
      }
    ]
  },
  {
    workpackageId: 89,
    workpackage: 'BB-010 Design, Calculation, Sim. & Testing',
    effortType: 'requirement',
    parentId: 88,
    organizations: [
      {
        organizationId: 57,
        orgResReqExp: 'EU GBX-CUS Gearbox Gearset & Differentials',
        orgResKey: '1854417',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 66,
        orgResReqExp: 'NA GBX (TRY)-CUS Gearbox Gearset & Differentials',
        orgResKey: '1861778',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      },
      {
        organizationId: 71,
        orgResReqExp: 'NA GBX (RAM)-CUS Gearbox Gearset & Differentials',
        orgResKey: '1854391',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 6,
            qvLocation: 'RAM'
          }
        ]
      },
      {
        organizationId: 77,
        orgResReqExp: 'NA GBX (TCI)-CUS Gearbox Gearset & Differentials',
        orgResKey: '1874453',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 82,
        orgResReqExp: 'CN GBX-CUS Gearbox Gearset & Differentials',
        orgResKey: '1855142',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 3,
        subproject: 'SP03 Gearbox & Mechatronics'
      }
    ]
  },
  {
    workpackageId: 90,
    workpackage: 'BB-010 Integration & Customer',
    effortType: 'requirement',
    parentId: 88,
    organizations: [
      {
        organizationId: 57,
        orgResReqExp: 'EU GBX-CUS Gearbox Gearset & Differentials',
        orgResKey: '1854417',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 66,
        orgResReqExp: 'NA GBX (TRY)-CUS Gearbox Gearset & Differentials',
        orgResKey: '1861778',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      },
      {
        organizationId: 71,
        orgResReqExp: 'NA GBX (RAM)-CUS Gearbox Gearset & Differentials',
        orgResKey: '1854391',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 6,
            qvLocation: 'RAM'
          }
        ]
      },
      {
        organizationId: 77,
        orgResReqExp: 'NA GBX (TCI)-CUS Gearbox Gearset & Differentials',
        orgResKey: '1874453',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 82,
        orgResReqExp: 'CN GBX-CUS Gearbox Gearset & Differentials',
        orgResKey: '1855142',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 3,
        subproject: 'SP03 Gearbox & Mechatronics'
      }
    ]
  },
  {
    workpackageId: 91,
    workpackage: 'BB-010 Plant/Supplier',
    effortType: 'requirement',
    parentId: 88,
    organizations: [
      {
        organizationId: 57,
        orgResReqExp: 'EU GBX-CUS Gearbox Gearset & Differentials',
        orgResKey: '1854417',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 66,
        orgResReqExp: 'NA GBX (TRY)-CUS Gearbox Gearset & Differentials',
        orgResKey: '1861778',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      },
      {
        organizationId: 71,
        orgResReqExp: 'NA GBX (RAM)-CUS Gearbox Gearset & Differentials',
        orgResKey: '1854391',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 6,
            qvLocation: 'RAM'
          }
        ]
      },
      {
        organizationId: 77,
        orgResReqExp: 'NA GBX (TCI)-CUS Gearbox Gearset & Differentials',
        orgResKey: '1874453',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 82,
        orgResReqExp: 'CN GBX-CUS Gearbox Gearset & Differentials',
        orgResKey: '1855142',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 3,
        subproject: 'SP03 Gearbox & Mechatronics'
      }
    ]
  },
  {
    workpackageId: 92,
    workpackage: 'GBX-SSY Gearbox Synchro, Shift Sys.&Parklock',
    sortOrder: 30,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 59,
        orgResReqExp: 'EU GBX-SSY Gearbox Synchro, Shift Sys.&Parklock',
        orgResKey: '1855878',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 67,
        orgResReqExp: 'NA GBX (TRY)-SSY Gearbox Synchro, Shift Sys.&Parkl',
        orgResKey: '1861780',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 3,
        subproject: 'SP03 Gearbox & Mechatronics'
      }
    ]
  },
  {
    workpackageId: 93,
    workpackage: 'BB-020/030 Design, Calc., Sim. & Testing',
    effortType: 'requirement',
    parentId: 92,
    organizations: [
      {
        organizationId: 59,
        orgResReqExp: 'EU GBX-SSY Gearbox Synchro, Shift Sys.&Parklock',
        orgResKey: '1855878',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 67,
        orgResReqExp: 'NA GBX (TRY)-SSY Gearbox Synchro, Shift Sys.&Parkl',
        orgResKey: '1861780',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 3,
        subproject: 'SP03 Gearbox & Mechatronics'
      }
    ]
  },
  {
    workpackageId: 94,
    workpackage: 'BB-020/030 Integration & Customer',
    effortType: 'requirement',
    parentId: 92,
    organizations: [
      {
        organizationId: 59,
        orgResReqExp: 'EU GBX-SSY Gearbox Synchro, Shift Sys.&Parklock',
        orgResKey: '1855878',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 67,
        orgResReqExp: 'NA GBX (TRY)-SSY Gearbox Synchro, Shift Sys.&Parkl',
        orgResKey: '1861780',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 3,
        subproject: 'SP03 Gearbox & Mechatronics'
      }
    ]
  },
  {
    workpackageId: 95,
    workpackage: 'BB-020/030 Plant/Supplier',
    effortType: 'requirement',
    parentId: 92,
    organizations: [
      {
        organizationId: 59,
        orgResReqExp: 'EU GBX-SSY Gearbox Synchro, Shift Sys.&Parklock',
        orgResKey: '1855878',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 67,
        orgResReqExp: 'NA GBX (TRY)-SSY Gearbox Synchro, Shift Sys.&Parkl',
        orgResKey: '1861780',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 3,
        subproject: 'SP03 Gearbox & Mechatronics'
      }
    ]
  },
  {
    workpackageId: 96,
    workpackage: 'BB-060 Design, Calc., Sim. & Testing',
    effortType: 'requirement',
    parentId: 92,
    organizations: [
      {
        organizationId: 59,
        orgResReqExp: 'EU GBX-SSY Gearbox Synchro, Shift Sys.&Parklock',
        orgResKey: '1855878',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 67,
        orgResReqExp: 'NA GBX (TRY)-SSY Gearbox Synchro, Shift Sys.&Parkl',
        orgResKey: '1861780',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 3,
        subproject: 'SP03 Gearbox & Mechatronics'
      }
    ]
  },
  {
    workpackageId: 97,
    workpackage: 'BB-060 Integration',
    effortType: 'requirement',
    parentId: 92,
    organizations: [
      {
        organizationId: 59,
        orgResReqExp: 'EU GBX-SSY Gearbox Synchro, Shift Sys.&Parklock',
        orgResKey: '1855878',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 67,
        orgResReqExp: 'NA GBX (TRY)-SSY Gearbox Synchro, Shift Sys.&Parkl',
        orgResKey: '1861780',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 3,
        subproject: 'SP03 Gearbox & Mechatronics'
      }
    ]
  },
  {
    workpackageId: 98,
    workpackage: 'BB-060 Plant/Supplier',
    effortType: 'requirement',
    parentId: 92,
    organizations: [
      {
        organizationId: 59,
        orgResReqExp: 'EU GBX-SSY Gearbox Synchro, Shift Sys.&Parklock',
        orgResKey: '1855878',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 67,
        orgResReqExp: 'NA GBX (TRY)-SSY Gearbox Synchro, Shift Sys.&Parkl',
        orgResKey: '1861780',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 3,
        subproject: 'SP03 Gearbox & Mechatronics'
      }
    ]
  },
  {
    workpackageId: 99,
    workpackage: 'GBX-CUS Gearbox Housing & Covers',
    sortOrder: 31,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 56,
        orgResReqExp: 'EU GBX-CUS Gearbox Housing & Covers',
        orgResKey: '1854414',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 65,
        orgResReqExp: 'NA GBX (TRY)-CUS Gearbox Housing & Covers',
        orgResKey: '1861781',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      },
      {
        organizationId: 69,
        orgResReqExp: 'NA GBX (RAM)-CUS Gearbox Housing & Covers',
        orgResKey: '1854388',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 6,
            qvLocation: 'RAM'
          }
        ]
      },
      {
        organizationId: 81,
        orgResReqExp: 'CN GBX-CUS Gearbox Housing & Covers',
        orgResKey: '1855138',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 3,
        subproject: 'SP03 Gearbox & Mechatronics'
      }
    ]
  },
  {
    workpackageId: 100,
    workpackage: 'BB-040 Design, Calc., Sim. & Testing',
    effortType: 'requirement',
    parentId: 99,
    organizations: [
      {
        organizationId: 56,
        orgResReqExp: 'EU GBX-CUS Gearbox Housing & Covers',
        orgResKey: '1854414',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 65,
        orgResReqExp: 'NA GBX (TRY)-CUS Gearbox Housing & Covers',
        orgResKey: '1861781',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      },
      {
        organizationId: 69,
        orgResReqExp: 'NA GBX (RAM)-CUS Gearbox Housing & Covers',
        orgResKey: '1854388',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 6,
            qvLocation: 'RAM'
          }
        ]
      },
      {
        organizationId: 81,
        orgResReqExp: 'CN GBX-CUS Gearbox Housing & Covers',
        orgResKey: '1855138',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 3,
        subproject: 'SP03 Gearbox & Mechatronics'
      }
    ]
  },
  {
    workpackageId: 101,
    workpackage: 'BB-040 Integration & Customer',
    effortType: 'requirement',
    parentId: 99,
    organizations: [
      {
        organizationId: 56,
        orgResReqExp: 'EU GBX-CUS Gearbox Housing & Covers',
        orgResKey: '1854414',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 65,
        orgResReqExp: 'NA GBX (TRY)-CUS Gearbox Housing & Covers',
        orgResKey: '1861781',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      },
      {
        organizationId: 69,
        orgResReqExp: 'NA GBX (RAM)-CUS Gearbox Housing & Covers',
        orgResKey: '1854388',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 6,
            qvLocation: 'RAM'
          }
        ]
      },
      {
        organizationId: 81,
        orgResReqExp: 'CN GBX-CUS Gearbox Housing & Covers',
        orgResKey: '1855138',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 3,
        subproject: 'SP03 Gearbox & Mechatronics'
      }
    ]
  },
  {
    workpackageId: 102,
    workpackage: 'BB-040 Plant/Supplier',
    effortType: 'requirement',
    parentId: 99,
    organizations: [
      {
        organizationId: 56,
        orgResReqExp: 'EU GBX-CUS Gearbox Housing & Covers',
        orgResKey: '1854414',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 65,
        orgResReqExp: 'NA GBX (TRY)-CUS Gearbox Housing & Covers',
        orgResKey: '1861781',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      },
      {
        organizationId: 69,
        orgResReqExp: 'NA GBX (RAM)-CUS Gearbox Housing & Covers',
        orgResKey: '1854388',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 6,
            qvLocation: 'RAM'
          }
        ]
      },
      {
        organizationId: 81,
        orgResReqExp: 'CN GBX-CUS Gearbox Housing & Covers',
        orgResKey: '1855138',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 3,
        subproject: 'SP03 Gearbox & Mechatronics'
      }
    ]
  },
  {
    workpackageId: 103,
    workpackage: 'GBX-SSY Gearbox Clutch & Oil',
    sortOrder: 32,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 60,
        orgResReqExp: 'EU GBX-SSY Gearbox Clutch & Oil',
        orgResKey: '1854425',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 68,
        orgResReqExp: 'NA GBX (TRY)-SSY Gearbox Clutch & Oil',
        orgResKey: '1861779',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      },
      {
        organizationId: 74,
        orgResReqExp: 'NA GBX (CON)-SSY Gearbox Clutch & Oil',
        orgResKey: '1861787',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 7,
            qvLocation: 'CON'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 3,
        subproject: 'SP03 Gearbox & Mechatronics'
      }
    ]
  },
  {
    workpackageId: 104,
    workpackage: 'BB-050 Design, Calculation, Simulation & Testing',
    effortType: 'requirement',
    parentId: 103,
    organizations: [
      {
        organizationId: 60,
        orgResReqExp: 'EU GBX-SSY Gearbox Clutch & Oil',
        orgResKey: '1854425',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 68,
        orgResReqExp: 'NA GBX (TRY)-SSY Gearbox Clutch & Oil',
        orgResKey: '1861779',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      },
      {
        organizationId: 74,
        orgResReqExp: 'NA GBX (CON)-SSY Gearbox Clutch & Oil',
        orgResKey: '1861787',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 7,
            qvLocation: 'CON'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 3,
        subproject: 'SP03 Gearbox & Mechatronics'
      }
    ]
  },
  {
    workpackageId: 105,
    workpackage: 'BB-050 Integration & Customer',
    effortType: 'requirement',
    parentId: 103,
    subproject: [
      {
        subprojectId: 3,
        subproject: 'SP03 Gearbox & Mechatronics'
      }
    ]
  },
  {
    workpackageId: 106,
    workpackage: 'BB-050 Plant/Supplier',
    effortType: 'requirement',
    parentId: 103,
    subproject: [
      {
        subprojectId: 3,
        subproject: 'SP03 Gearbox & Mechatronics'
      }
    ]
  },
  {
    workpackageId: 107,
    workpackage: 'BB-070 Design, Calc., Sim. & Testing',
    effortType: 'requirement',
    parentId: 103,
    subproject: [
      {
        subprojectId: 3,
        subproject: 'SP03 Gearbox & Mechatronics'
      }
    ]
  },
  {
    workpackageId: 108,
    workpackage: 'BB-070 Integration & Customer',
    effortType: 'requirement',
    parentId: 103,
    subproject: [
      {
        subprojectId: 3,
        subproject: 'SP03 Gearbox & Mechatronics'
      }
    ]
  },
  {
    workpackageId: 109,
    workpackage: 'BB-070 Plant/Supplier',
    effortType: 'requirement',
    parentId: 103,
    organizations: [
      {
        organizationId: 60,
        orgResReqExp: 'EU GBX-SSY Gearbox Clutch & Oil',
        orgResKey: '1854425',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 68,
        orgResReqExp: 'NA GBX (TRY)-SSY Gearbox Clutch & Oil',
        orgResKey: '1861779',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      },
      {
        organizationId: 74,
        orgResReqExp: 'NA GBX (CON)-SSY Gearbox Clutch & Oil',
        orgResKey: '1861787',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 7,
            qvLocation: 'CON'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 3,
        subproject: 'SP03 Gearbox & Mechatronics'
      }
    ]
  },
  {
    workpackageId: 110,
    workpackage: 'GBX-SSY Gearbox Hydraulics',
    sortOrder: 33,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 58,
        orgResReqExp: 'EU GBX-SSY Gearbox Hydraulics',
        orgResKey: '1855877',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 3,
        subproject: 'SP03 Gearbox & Mechatronics'
      }
    ]
  },
  {
    workpackageId: 111,
    workpackage: 'BB-050 Component Testing Clutch',
    effortType: 'requirement',
    parentId: 110,
    organizations: [
      {
        organizationId: 58,
        orgResReqExp: 'EU GBX-SSY Gearbox Hydraulics',
        orgResKey: '1855877',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 3,
        subproject: 'SP03 Gearbox & Mechatronics'
      }
    ]
  },
  {
    workpackageId: 112,
    workpackage: 'BB-080 Design, Calc., Sim. & Testing',
    effortType: 'requirement',
    parentId: 110,
    organizations: [
      {
        organizationId: 58,
        orgResReqExp: 'EU GBX-SSY Gearbox Hydraulics',
        orgResKey: '1855877',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 3,
        subproject: 'SP03 Gearbox & Mechatronics'
      }
    ]
  },
  {
    workpackageId: 113,
    workpackage: 'BB-080 Integration & Customer',
    effortType: 'requirement',
    parentId: 110,
    organizations: [
      {
        organizationId: 58,
        orgResReqExp: 'EU GBX-SSY Gearbox Hydraulics',
        orgResKey: '1855877',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 3,
        subproject: 'SP03 Gearbox & Mechatronics'
      }
    ]
  },
  {
    workpackageId: 114,
    workpackage: 'BB-080 Plant/Supplier',
    effortType: 'requirement',
    parentId: 110,
    organizations: [
      {
        organizationId: 58,
        orgResReqExp: 'EU GBX-SSY Gearbox Hydraulics',
        orgResKey: '1855877',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 3,
        subproject: 'SP03 Gearbox & Mechatronics'
      }
    ]
  },
  {
    workpackageId: 115,
    workpackage: 'BB-080 Component Testing Hydraulics',
    effortType: 'requirement',
    parentId: 110,
    organizations: [
      {
        organizationId: 58,
        orgResReqExp: 'EU GBX-SSY Gearbox Hydraulics',
        orgResKey: '1855877',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 3,
        subproject: 'SP03 Gearbox & Mechatronics'
      }
    ]
  },
  {
    workpackageId: 116,
    workpackage: 'GBX-MTR Gearbox Mechatronic Components',
    sortOrder: 34,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 61,
        orgResReqExp: 'EU GBX-MTR Gearbox Mechatronic Components',
        orgResKey: '1855879',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 73,
        orgResReqExp: 'NA GBX (RAM)-MTR Gearbox Mechatronic Components',
        orgResKey: '1854406',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 6,
            qvLocation: 'RAM'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 3,
        subproject: 'SP03 Gearbox & Mechatronics'
      }
    ]
  },
  {
    workpackageId: 117,
    workpackage: 'BB-090 Ach. and specification',
    effortType: 'requirement',
    parentId: 116,
    organizations: [
      {
        organizationId: 61,
        orgResReqExp: 'EU GBX-MTR Gearbox Mechatronic Components',
        orgResKey: '1855879',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 73,
        orgResReqExp: 'NA GBX (RAM)-MTR Gearbox Mechatronic Components',
        orgResKey: '1854406',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 6,
            qvLocation: 'RAM'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 3,
        subproject: 'SP03 Gearbox & Mechatronics'
      }
    ]
  },
  {
    workpackageId: 118,
    workpackage: 'BB-090 Requirement Management',
    effortType: 'requirement',
    parentId: 116,
    organizations: [
      {
        organizationId: 61,
        orgResReqExp: 'EU GBX-MTR Gearbox Mechatronic Components',
        orgResKey: '1855879',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 73,
        orgResReqExp: 'NA GBX (RAM)-MTR Gearbox Mechatronic Components',
        orgResKey: '1854406',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 6,
            qvLocation: 'RAM'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 3,
        subproject: 'SP03 Gearbox & Mechatronics'
      }
    ]
  },
  {
    workpackageId: 119,
    workpackage: 'BB-090 Design, Calc., Sim. & Testing',
    effortType: 'requirement',
    parentId: 116,
    organizations: [
      {
        organizationId: 61,
        orgResReqExp: 'EU GBX-MTR Gearbox Mechatronic Components',
        orgResKey: '1855879',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 73,
        orgResReqExp: 'NA GBX (RAM)-MTR Gearbox Mechatronic Components',
        orgResKey: '1854406',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 6,
            qvLocation: 'RAM'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 3,
        subproject: 'SP03 Gearbox & Mechatronics'
      }
    ]
  },
  {
    workpackageId: 120,
    workpackage: 'BB-090 Integration & Customer',
    effortType: 'requirement',
    parentId: 116,
    organizations: [
      {
        organizationId: 61,
        orgResReqExp: 'EU GBX-MTR Gearbox Mechatronic Components',
        orgResKey: '1855879',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 73,
        orgResReqExp: 'NA GBX (RAM)-MTR Gearbox Mechatronic Components',
        orgResKey: '1854406',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 6,
            qvLocation: 'RAM'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 3,
        subproject: 'SP03 Gearbox & Mechatronics'
      }
    ]
  },
  {
    workpackageId: 121,
    workpackage: 'BB-090 Plant/Supplier',
    effortType: 'requirement',
    parentId: 116,
    organizations: [
      {
        organizationId: 61,
        orgResReqExp: 'EU GBX-MTR Gearbox Mechatronic Components',
        orgResKey: '1855879',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 73,
        orgResReqExp: 'NA GBX (RAM)-MTR Gearbox Mechatronic Components',
        orgResKey: '1854406',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 6,
            qvLocation: 'RAM'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 3,
        subproject: 'SP03 Gearbox & Mechatronics'
      }
    ]
  },
  {
    workpackageId: 122,
    workpackage: 'BB-090 Component Testing (DV,PV)',
    effortType: 'requirement',
    parentId: 116,
    organizations: [
      {
        organizationId: 61,
        orgResReqExp: 'EU GBX-MTR Gearbox Mechatronic Components',
        orgResKey: '1855879',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 73,
        orgResReqExp: 'NA GBX (RAM)-MTR Gearbox Mechatronic Components',
        orgResKey: '1854406',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 6,
            qvLocation: 'RAM'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 3,
        subproject: 'SP03 Gearbox & Mechatronics'
      }
    ]
  },
  {
    workpackageId: 123,
    workpackage: 'EM-EPS Eng. Project Management',
    sortOrder: 35,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 83,
        orgResReqExp: 'GEE EM-EPS Eng. Project Management',
        orgResKey: '1854132',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 4,
        subproject: 'SP04 E-Motor'
      }
    ]
  },
  {
    workpackageId: 124,
    workpackage: 'EM-eMotor Innovative Manufactoring Tech.',
    sortOrder: 36,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 84,
        orgResReqExp: 'GEE EM-eMotor Innovative Manufactoring Tech.',
        orgResKey: '1854126',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 4,
        subproject: 'SP04 E-Motor'
      }
    ]
  },
  {
    workpackageId: 125,
    workpackage: 'EM-eMotor Design',
    sortOrder: 37,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 85,
        orgResReqExp: 'GEE EM-eMotor Design',
        orgResKey: '1854129',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 89,
        orgResReqExp: 'CN EM-eMotor Design',
        orgResKey: '1854124',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 4,
        subproject: 'SP04 E-Motor'
      }
    ]
  },
  {
    workpackageId: 126,
    workpackage: 'EM Requirements Engineering',
    effortType: 'requirement',
    parentId: 125,
    organizations: [
      {
        organizationId: 85,
        orgResReqExp: 'GEE EM-eMotor Design',
        orgResKey: '1854129',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 89,
        orgResReqExp: 'CN EM-eMotor Design',
        orgResKey: '1854124',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 4,
        subproject: 'SP04 E-Motor'
      }
    ]
  },
  {
    workpackageId: 127,
    workpackage: 'EM Architecture Design',
    effortType: 'requirement',
    parentId: 125,
    organizations: [
      {
        organizationId: 85,
        orgResReqExp: 'GEE EM-eMotor Design',
        orgResKey: '1854129',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 89,
        orgResReqExp: 'CN EM-eMotor Design',
        orgResKey: '1854124',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 4,
        subproject: 'SP04 E-Motor'
      }
    ]
  },
  {
    workpackageId: 128,
    workpackage: 'EM Detailed Design',
    effortType: 'requirement',
    parentId: 125,
    organizations: [
      {
        organizationId: 85,
        orgResReqExp: 'GEE EM-eMotor Design',
        orgResKey: '1854129',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 89,
        orgResReqExp: 'CN EM-eMotor Design',
        orgResKey: '1854124',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 4,
        subproject: 'SP04 E-Motor'
      }
    ]
  },
  {
    workpackageId: 129,
    workpackage: 'DFMEA/PFMEA',
    effortType: 'requirement',
    parentId: 125,
    organizations: [
      {
        organizationId: 85,
        orgResReqExp: 'GEE EM-eMotor Design',
        orgResKey: '1854129',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 89,
        orgResReqExp: 'CN EM-eMotor Design',
        orgResKey: '1854124',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 4,
        subproject: 'SP04 E-Motor'
      }
    ]
  },
  {
    workpackageId: 130,
    workpackage: 'EM supplier management',
    effortType: 'requirement',
    parentId: 125,
    organizations: [
      {
        organizationId: 85,
        orgResReqExp: 'GEE EM-eMotor Design',
        orgResKey: '1854129',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 89,
        orgResReqExp: 'CN EM-eMotor Design',
        orgResKey: '1854124',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 4,
        subproject: 'SP04 E-Motor'
      }
    ]
  },
  {
    workpackageId: 131,
    workpackage: 'Support EM Testing/EOL',
    effortType: 'requirement',
    parentId: 125,
    organizations: [
      {
        organizationId: 85,
        orgResReqExp: 'GEE EM-eMotor Design',
        orgResKey: '1854129',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 89,
        orgResReqExp: 'CN EM-eMotor Design',
        orgResKey: '1854124',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 4,
        subproject: 'SP04 E-Motor'
      }
    ]
  },
  {
    workpackageId: 132,
    workpackage: 'Callibrate Sim. with meas. & update MSI',
    effortType: 'requirement',
    parentId: 125,
    organizations: [
      {
        organizationId: 85,
        orgResReqExp: 'GEE EM-eMotor Design',
        orgResKey: '1854129',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 89,
        orgResReqExp: 'CN EM-eMotor Design',
        orgResKey: '1854124',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 4,
        subproject: 'SP04 E-Motor'
      }
    ]
  },
  {
    workpackageId: 133,
    workpackage: 'System Release Documentation',
    effortType: 'requirement',
    parentId: 125,
    organizations: [
      {
        organizationId: 85,
        orgResReqExp: 'GEE EM-eMotor Design',
        orgResKey: '1854129',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 89,
        orgResReqExp: 'CN EM-eMotor Design',
        orgResKey: '1854124',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 4,
        subproject: 'SP04 E-Motor'
      }
    ]
  },
  {
    workpackageId: 134,
    workpackage: 'DFM / Industrialization support',
    effortType: 'requirement',
    parentId: 125,
    organizations: [
      {
        organizationId: 85,
        orgResReqExp: 'GEE EM-eMotor Design',
        orgResKey: '1854129',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 89,
        orgResReqExp: 'CN EM-eMotor Design',
        orgResKey: '1854124',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 4,
        subproject: 'SP04 E-Motor'
      }
    ]
  },
  {
    workpackageId: 135,
    workpackage: 'EM-eMotor mech. Design',
    sortOrder: 38,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 86,
        orgResReqExp: 'GEE EM-eMotor mech. Design',
        orgResKey: '1854130',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 4,
        subproject: 'SP04 E-Motor'
      }
    ]
  },
  {
    workpackageId: 136,
    workpackage: 'EM mech. Requirements Engineering',
    effortType: 'requirement',
    parentId: 135,
    organizations: [
      {
        organizationId: 86,
        orgResReqExp: 'GEE EM-eMotor mech. Design',
        orgResKey: '1854130',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 4,
        subproject: 'SP04 E-Motor'
      }
    ]
  },
  {
    workpackageId: 137,
    workpackage: 'eMotor Component Mechanical Design',
    effortType: 'requirement',
    parentId: 135,
    organizations: [
      {
        organizationId: 86,
        orgResReqExp: 'GEE EM-eMotor mech. Design',
        orgResKey: '1854130',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 4,
        subproject: 'SP04 E-Motor'
      }
    ]
  },
  {
    workpackageId: 138,
    workpackage: 'eMotor Component Mechanical Integration',
    effortType: 'requirement',
    parentId: 135,
    organizations: [
      {
        organizationId: 86,
        orgResReqExp: 'GEE EM-eMotor mech. Design',
        orgResKey: '1854130',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 4,
        subproject: 'SP04 E-Motor'
      }
    ]
  },
  {
    workpackageId: 139,
    workpackage: 'SOR generation / supplier management',
    effortType: 'requirement',
    parentId: 135,
    organizations: [
      {
        organizationId: 86,
        orgResReqExp: 'GEE EM-eMotor mech. Design',
        orgResKey: '1854130',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 4,
        subproject: 'SP04 E-Motor'
      }
    ]
  },
  {
    workpackageId: 140,
    workpackage: 'EM-eMotor Testing',
    sortOrder: 39,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 87,
        orgResReqExp: 'GEE EM-eMotor Testing',
        orgResKey: '1854127',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 4,
        subproject: 'SP04 E-Motor'
      }
    ]
  },
  {
    workpackageId: 141,
    workpackage: 'Test Engineering',
    effortType: 'requirement',
    parentId: 140,
    organizations: [
      {
        organizationId: 87,
        orgResReqExp: 'GEE EM-eMotor Testing',
        orgResKey: '1854127',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 4,
        subproject: 'SP04 E-Motor'
      }
    ]
  },
  {
    workpackageId: 142,
    workpackage: 'Integration & Qualification Testing',
    effortType: 'requirement',
    parentId: 140,
    organizations: [
      {
        organizationId: 87,
        orgResReqExp: 'GEE EM-eMotor Testing',
        orgResKey: '1854127',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 4,
        subproject: 'SP04 E-Motor'
      }
    ]
  },
  {
    workpackageId: 143,
    workpackage: 'Material Tests',
    effortType: 'requirement',
    parentId: 140,
    organizations: [
      {
        organizationId: 87,
        orgResReqExp: 'GEE EM-eMotor Testing',
        orgResKey: '1854127',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 4,
        subproject: 'SP04 E-Motor'
      }
    ]
  },
  {
    workpackageId: 144,
    workpackage: 'Mechanical & Robustness Tests',
    effortType: 'requirement',
    parentId: 140,
    organizations: [
      {
        organizationId: 87,
        orgResReqExp: 'GEE EM-eMotor Testing',
        orgResKey: '1854127',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 4,
        subproject: 'SP04 E-Motor'
      }
    ]
  },
  {
    workpackageId: 145,
    workpackage: 'Durability Testing / Environmental Tests',
    effortType: 'requirement',
    parentId: 140,
    organizations: [
      {
        organizationId: 87,
        orgResReqExp: 'GEE EM-eMotor Testing',
        orgResKey: '1854127',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 4,
        subproject: 'SP04 E-Motor'
      }
    ]
  },
  {
    workpackageId: 146,
    workpackage: 'EM-eP Program Management',
    sortOrder: 40,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 88,
        orgResReqExp: 'GEE EM-eP Program Management',
        orgResKey: '1870694',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 4,
        subproject: 'SP04 E-Motor'
      }
    ]
  },
  {
    workpackageId: 147,
    workpackage: 'INV-EPS Eng. Project Management',
    sortOrder: 41,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 90,
        orgResReqExp: 'GEE INV-EPS Eng. Project Management',
        orgResKey: '1854370',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 5,
        subproject: 'SP05 Inverter'
      }
    ]
  },
  {
    workpackageId: 148,
    workpackage: 'Project Management INV',
    effortType: 'requirement',
    parentId: 147,
    organizations: [
      {
        organizationId: 90,
        orgResReqExp: 'GEE INV-EPS Eng. Project Management',
        orgResKey: '1854370',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 5,
        subproject: 'SP05 Inverter'
      }
    ]
  },
  {
    workpackageId: 149,
    workpackage: 'Project Management PCBA',
    effortType: 'requirement',
    parentId: 147,
    organizations: [
      {
        organizationId: 90,
        orgResReqExp: 'GEE INV-EPS Eng. Project Management',
        orgResKey: '1854370',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 5,
        subproject: 'SP05 Inverter'
      }
    ]
  },
  {
    workpackageId: 150,
    workpackage: 'Project Management SOR',
    effortType: 'requirement',
    parentId: 147,
    organizations: [
      {
        organizationId: 90,
        orgResReqExp: 'GEE INV-EPS Eng. Project Management',
        orgResKey: '1854370',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 5,
        subproject: 'SP05 Inverter'
      }
    ]
  },
  {
    workpackageId: 151,
    workpackage: 'INV-E Inverter Electronic Design',
    sortOrder: 42,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 91,
        orgResReqExp: 'GEE INV-E Inverter Electronic Design',
        orgResKey: '1854369',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 5,
        subproject: 'SP05 Inverter'
      }
    ]
  },
  {
    workpackageId: 152,
    workpackage: 'Concept',
    effortType: 'requirement',
    parentId: 151,
    organizations: [
      {
        organizationId: 91,
        orgResReqExp: 'GEE INV-E Inverter Electronic Design',
        orgResKey: '1854369',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 5,
        subproject: 'SP05 Inverter'
      }
    ]
  },
  {
    workpackageId: 153,
    workpackage: 'Electronic Design & Development Tests',
    effortType: 'requirement',
    parentId: 151,
    organizations: [
      {
        organizationId: 91,
        orgResReqExp: 'GEE INV-E Inverter Electronic Design',
        orgResKey: '1854369',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 5,
        subproject: 'SP05 Inverter'
      }
    ]
  },
  {
    workpackageId: 154,
    workpackage: 'INV-E Inverter PCBA Design & Indust.',
    sortOrder: 43,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 92,
        orgResReqExp: 'GEE INV-E Inverter PCBA Design & Indust.',
        orgResKey: '1854372',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 5,
        subproject: 'SP05 Inverter'
      }
    ]
  },
  {
    workpackageId: 155,
    workpackage: 'PCBA Design & Industrialisation',
    effortType: 'requirement',
    parentId: 154,
    organizations: [
      {
        organizationId: 92,
        orgResReqExp: 'GEE INV-E Inverter PCBA Design & Indust.',
        orgResKey: '1854372',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 5,
        subproject: 'SP05 Inverter'
      }
    ]
  },
  {
    workpackageId: 156,
    workpackage: 'INV-E Inverter PCBA Testing',
    sortOrder: 44,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 93,
        orgResReqExp: 'GEE INV-E Inverter PCBA Testing',
        orgResKey: '1854376',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 5,
        subproject: 'SP05 Inverter'
      }
    ]
  },
  {
    workpackageId: 157,
    workpackage: 'PCBA Testing',
    effortType: 'requirement',
    parentId: 156,
    organizations: [
      {
        organizationId: 93,
        orgResReqExp: 'GEE INV-E Inverter PCBA Testing',
        orgResKey: '1854376',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 5,
        subproject: 'SP05 Inverter'
      }
    ]
  },
  {
    workpackageId: 158,
    workpackage: 'INV-I Inverter Power Electronic Design',
    sortOrder: 45,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 94,
        orgResReqExp: 'GEE INV-I Inverter Power Electronic Design',
        orgResKey: '1854373',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 5,
        subproject: 'SP05 Inverter'
      }
    ]
  },
  {
    workpackageId: 159,
    workpackage: 'Powermodule Concept & Architecture',
    effortType: 'requirement',
    parentId: 159,
    organizations: [
      {
        organizationId: 94,
        orgResReqExp: 'GEE INV-I Inverter Power Electronic Design',
        orgResKey: '1854373',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 5,
        subproject: 'SP05 Inverter'
      }
    ]
  },
  {
    workpackageId: 160,
    workpackage: 'Powermodule Design & Dev Tests',
    effortType: 'requirement',
    parentId: 159,
    organizations: [
      {
        organizationId: 94,
        orgResReqExp: 'GEE INV-I Inverter Power Electronic Design',
        orgResKey: '1854373',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 5,
        subproject: 'SP05 Inverter'
      }
    ]
  },
  {
    workpackageId: 161,
    workpackage: 'Powermodule Supplier management (incl SORs)',
    effortType: 'requirement',
    parentId: 159,
    organizations: [
      {
        organizationId: 94,
        orgResReqExp: 'GEE INV-I Inverter Power Electronic Design',
        orgResKey: '1854373',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 5,
        subproject: 'SP05 Inverter'
      }
    ]
  },
  {
    workpackageId: 162,
    workpackage: 'Powermodule Industrialisation',
    effortType: 'requirement',
    parentId: 159,
    organizations: [
      {
        organizationId: 94,
        orgResReqExp: 'GEE INV-I Inverter Power Electronic Design',
        orgResKey: '1854373',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 5,
        subproject: 'SP05 Inverter'
      }
    ]
  },
  {
    workpackageId: 163,
    workpackage: 'Powermodule Plan. / Test case eng. / Docu',
    effortType: 'requirement',
    parentId: 159,
    organizations: [
      {
        organizationId: 94,
        orgResReqExp: 'GEE INV-I Inverter Power Electronic Design',
        orgResKey: '1854373',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 5,
        subproject: 'SP05 Inverter'
      }
    ]
  },
  {
    workpackageId: 164,
    workpackage: 'INV-I Inverter Mech Design',
    sortOrder: 46,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 95,
        orgResReqExp: 'GEE INV-I Inverter Mech Design',
        orgResKey: '1854375',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 5,
        subproject: 'SP05 Inverter'
      }
    ]
  },
  {
    workpackageId: 165,
    workpackage: 'Int. Dev. tests (vibration, temp cycling, …)',
    effortType: 'requirement',
    parentId: 164,
    organizations: [
      {
        organizationId: 95,
        orgResReqExp: 'GEE INV-I Inverter Mech Design',
        orgResKey: '1854375',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 5,
        subproject: 'SP05 Inverter'
      }
    ]
  },
  {
    workpackageId: 166,
    workpackage: 'INV-I Inverter Architecture & Req',
    sortOrder: 47,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 96,
        orgResReqExp: 'GEE INV-I Inverter Architecture & Req',
        orgResKey: '1854377',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 5,
        subproject: 'SP05 Inverter'
      }
    ]
  },
  {
    workpackageId: 167,
    workpackage: 'Int. Concept & Architecture',
    effortType: 'requirement',
    parentId: 166,
    organizations: [
      {
        organizationId: 96,
        orgResReqExp: 'GEE INV-I Inverter Architecture & Req',
        orgResKey: '1854377',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 5,
        subproject: 'SP05 Inverter'
      }
    ]
  },
  {
    workpackageId: 168,
    workpackage: 'Int. Req INV & PCBA',
    effortType: 'requirement',
    parentId: 166,
    organizations: [
      {
        organizationId: 96,
        orgResReqExp: 'GEE INV-I Inverter Architecture & Req',
        orgResKey: '1854377',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 5,
        subproject: 'SP05 Inverter'
      }
    ]
  },
  {
    workpackageId: 169,
    workpackage: 'Int. FuSa INV and PCBA',
    effortType: 'requirement',
    parentId: 166,
    organizations: [
      {
        organizationId: 96,
        orgResReqExp: 'GEE INV-I Inverter Architecture & Req',
        orgResKey: '1854377',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 5,
        subproject: 'SP05 Inverter'
      }
    ]
  },
  {
    workpackageId: 170,
    workpackage: 'Powermodule Requirement Engineering',
    effortType: 'requirement',
    parentId: 166,
    organizations: [
      {
        organizationId: 96,
        orgResReqExp: 'GEE INV-I Inverter Architecture & Req',
        orgResKey: '1854377',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 5,
        subproject: 'SP05 Inverter'
      }
    ]
  },
  {
    workpackageId: 171,
    workpackage: 'INV-I Inverter Testing',
    sortOrder: 48,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 97,
        orgResReqExp: 'GEE INV-I Inverter Testing',
        orgResKey: '1854371',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 5,
        subproject: 'SP05 Inverter'
      }
    ]
  },
  {
    workpackageId: 172,
    workpackage: 'Int. Test - Test coordination',
    effortType: 'requirement',
    parentId: 172,
    organizations: [
      {
        organizationId: 97,
        orgResReqExp: 'GEE INV-I Inverter Testing',
        orgResKey: '1854371',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 5,
        subproject: 'SP05 Inverter'
      }
    ]
  },
  {
    workpackageId: 173,
    workpackage:
      'Int. Test - Initial effort requirement review / test case and script creation (incl. EOL)',
    effortType: 'requirement',
    parentId: 172,
    organizations: [
      {
        organizationId: 97,
        orgResReqExp: 'GEE INV-I Inverter Testing',
        orgResKey: '1854371',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 5,
        subproject: 'SP05 Inverter'
      }
    ]
  },
  {
    workpackageId: 174,
    workpackage: 'Int. Test - Set up and commission testrig and prototype',
    effortType: 'requirement',
    parentId: 172,
    organizations: [
      {
        organizationId: 97,
        orgResReqExp: 'GEE INV-I Inverter Testing',
        orgResKey: '1854371',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 5,
        subproject: 'SP05 Inverter'
      }
    ]
  },
  {
    workpackageId: 175,
    workpackage: 'Int. Test - testing Eng',
    effortType: 'requirement',
    parentId: 172,
    organizations: [
      {
        organizationId: 97,
        orgResReqExp: 'GEE INV-I Inverter Testing',
        orgResKey: '1854371',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 5,
        subproject: 'SP05 Inverter'
      }
    ]
  },
  {
    workpackageId: 176,
    workpackage: 'Powermodule Testing',
    effortType: 'requirement',
    parentId: 172,
    organizations: [
      {
        organizationId: 97,
        orgResReqExp: 'GEE INV-I Inverter Testing',
        orgResKey: '1854371',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 5,
        subproject: 'SP05 Inverter'
      }
    ]
  },
  {
    workpackageId: 177,
    workpackage: 'SW-EPS Eng. Project Management',
    sortOrder: 49,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 178,
    workpackage: 'SW-V Architecture',
    sortOrder: 50,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 179,
    workpackage: 'SW-V Requirements',
    sortOrder: 51,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 180,
    workpackage: 'SW-V Safety',
    sortOrder: 52,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 101,
        orgResReqExp: 'GEE SW-V Safety',
        orgResKey: '1854413',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 119,
        orgResReqExp: 'CN SW-V Safety',
        orgResKey: '1855153',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 181,
    workpackage: 'SW-V SW Tools',
    sortOrder: 53,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 182,
    workpackage: 'SW-V Powertrain Functions',
    sortOrder: 54,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 183,
    workpackage: 'SW-C Diagnostics & HW abstr.',
    sortOrder: 55,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 184,
    workpackage: 'SW-C eMotor control',
    sortOrder: 56,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 185,
    workpackage: 'SW-C Gearbox & Actuators',
    sortOrder: 57,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 186,
    workpackage: 'SW-B Base Software Cluster A',
    sortOrder: 58,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 187,
    workpackage: 'SW-B Base Software Cluster B',
    sortOrder: 59,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 188,
    workpackage: 'SW-B Base Software Cluster C',
    sortOrder: 60,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 109,
        orgResReqExp: 'GEE SW-B Base Software Cluster C',
        orgResKey: '1854395',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 125,
        orgResReqExp: 'CN SW-B Base Software Cluster C',
        orgResKey: '1855147',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 189,
    workpackage: 'SW-T Testautom., Framework & Virtualization',
    sortOrder: 61,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 190,
    workpackage: 'SW-T HIL Infrastructure',
    sortOrder: 62,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 191,
    workpackage: 'SW-T Funct. Diagnostics & Safety Verific.',
    sortOrder: 63,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 192,
    workpackage: 'SW-T Testmanagement & Project Test Lead',
    sortOrder: 64,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 193,
    workpackage: 'SW-T Functional SW Verification',
    sortOrder: 65,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 194,
    workpackage: 'SW-T Base SW Verification',
    sortOrder: 66,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 195,
    workpackage: 'SW-T Precomissioning',
    sortOrder: 67,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 196,
    workpackage: 'Coordination',
    effortType: 'requirement',
    parentId: 177,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 196,
    workpackage: 'Coordination',
    effortType: 'requirement',
    parentId: 178,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 196,
    workpackage: 'Coordination',
    effortType: 'requirement',
    parentId: 179,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 196,
    workpackage: 'Coordination',
    effortType: 'requirement',
    parentId: 181,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 196,
    workpackage: 'Coordination',
    effortType: 'requirement',
    parentId: 182,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 196,
    workpackage: 'Coordination',
    effortType: 'requirement',
    parentId: 183,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 196,
    workpackage: 'Coordination',
    effortType: 'requirement',
    parentId: 184,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 196,
    workpackage: 'Coordination',
    effortType: 'requirement',
    parentId: 185,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 196,
    workpackage: 'Coordination',
    effortType: 'requirement',
    parentId: 186,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 196,
    workpackage: 'Coordination',
    effortType: 'requirement',
    parentId: 187,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 196,
    workpackage: 'Coordination',
    effortType: 'requirement',
    parentId: 190,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 196,
    workpackage: 'Coordination',
    effortType: 'requirement',
    parentId: 191,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 196,
    workpackage: 'Coordination',
    effortType: 'requirement',
    parentId: 192,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 196,
    workpackage: 'Coordination',
    effortType: 'requirement',
    parentId: 193,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 196,
    workpackage: 'Coordination',
    effortType: 'requirement',
    parentId: 194,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 196,
    workpackage: 'Coordination',
    effortType: 'requirement',
    parentId: 195,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 197,
    workpackage: 'Function Development & Requirements Engineering',
    effortType: 'requirement',
    parentId: 177,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 197,
    workpackage: 'Function Development & Requirements Engineering',
    effortType: 'requirement',
    parentId: 178,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 197,
    workpackage: 'Function Development & Requirements Engineering',
    effortType: 'requirement',
    parentId: 179,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 197,
    workpackage: 'Function Development & Requirements Engineering',
    effortType: 'requirement',
    parentId: 181,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 197,
    workpackage: 'Function Development & Requirements Engineering',
    effortType: 'requirement',
    parentId: 182,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 197,
    workpackage: 'Function Development & Requirements Engineering',
    effortType: 'requirement',
    parentId: 183,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 197,
    workpackage: 'Function Development & Requirements Engineering',
    effortType: 'requirement',
    parentId: 184,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 197,
    workpackage: 'Function Development & Requirements Engineering',
    effortType: 'requirement',
    parentId: 185,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 197,
    workpackage: 'Function Development & Requirements Engineering',
    effortType: 'requirement',
    parentId: 186,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 197,
    workpackage: 'Function Development & Requirements Engineering',
    effortType: 'requirement',
    parentId: 187,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 197,
    workpackage: 'Function Development & Requirements Engineering',
    effortType: 'requirement',
    parentId: 190,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 197,
    workpackage: 'Function Development & Requirements Engineering',
    effortType: 'requirement',
    parentId: 191,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 197,
    workpackage: 'Function Development & Requirements Engineering',
    effortType: 'requirement',
    parentId: 192,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 197,
    workpackage: 'Function Development & Requirements Engineering',
    effortType: 'requirement',
    parentId: 193,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 197,
    workpackage: 'Function Development & Requirements Engineering',
    effortType: 'requirement',
    parentId: 194,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 197,
    workpackage: 'Function Development & Requirements Engineering',
    effortType: 'requirement',
    parentId: 195,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 198,
    workpackage: 'SW Architecture & Concepts',
    effortType: 'requirement',
    parentId: 178,
    organizations: [
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 198,
    workpackage: 'SW Architecture & Concepts',
    effortType: 'requirement',
    parentId: 179,
    organizations: [
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 198,
    workpackage: 'SW Architecture & Concepts',
    effortType: 'requirement',
    parentId: 182,
    organizations: [
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 198,
    workpackage: 'SW Architecture & Concepts',
    effortType: 'requirement',
    parentId: 186,
    organizations: [
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 198,
    workpackage: 'SW Architecture & Concepts',
    effortType: 'requirement',
    parentId: 187,
    organizations: [
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 199,
    workpackage: 'SW Implementation',
    effortType: 'requirement',
    parentId: 178,
    organizations: [
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 199,
    workpackage: 'SW Implementation',
    effortType: 'requirement',
    parentId: 179,
    organizations: [
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 199,
    workpackage: 'SW Implementation',
    effortType: 'requirement',
    parentId: 182,
    organizations: [
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 199,
    workpackage: 'SW Implementation',
    effortType: 'requirement',
    parentId: 183,
    organizations: [
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 199,
    workpackage: 'SW Implementation',
    effortType: 'requirement',
    parentId: 184,
    organizations: [
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 199,
    workpackage: 'SW Implementation',
    effortType: 'requirement',
    parentId: 185,
    organizations: [
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 199,
    workpackage: 'SW Implementation',
    effortType: 'requirement',
    parentId: 186,
    organizations: [
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 199,
    workpackage: 'SW Implementation',
    effortType: 'requirement',
    parentId: 187,
    organizations: [
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 200,
    workpackage: 'Unit & Component Test',
    effortType: 'requirement',
    parentId: 178,
    organizations: [
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 200,
    workpackage: 'Unit & Component Test',
    effortType: 'requirement',
    parentId: 179,
    organizations: [
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 200,
    workpackage: 'Unit & Component Test',
    effortType: 'requirement',
    parentId: 182,
    organizations: [
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 200,
    workpackage: 'Unit & Component Test',
    effortType: 'requirement',
    parentId: 183,
    organizations: [
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 200,
    workpackage: 'Unit & Component Test',
    effortType: 'requirement',
    parentId: 184,
    organizations: [
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 200,
    workpackage: 'Unit & Component Test',
    effortType: 'requirement',
    parentId: 185,
    organizations: [
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 200,
    workpackage: 'Unit & Component Test',
    effortType: 'requirement',
    parentId: 186,
    organizations: [
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 200,
    workpackage: 'Unit & Component Test',
    effortType: 'requirement',
    parentId: 187,
    organizations: [
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 201,
    workpackage: 'SW Integration & Test',
    effortType: 'requirement',
    parentId: 177,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 201,
    workpackage: 'SW Integration & Test',
    effortType: 'requirement',
    parentId: 181,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 201,
    workpackage: 'SW Integration & Test',
    effortType: 'requirement',
    parentId: 186,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 201,
    workpackage: 'SW Integration & Test',
    effortType: 'requirement',
    parentId: 187,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 201,
    workpackage: 'SW Integration & Test',
    effortType: 'requirement',
    parentId: 190,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 201,
    workpackage: 'SW Integration & Test',
    effortType: 'requirement',
    parentId: 191,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 201,
    workpackage: 'SW Integration & Test',
    effortType: 'requirement',
    parentId: 192,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 201,
    workpackage: 'SW Integration & Test',
    effortType: 'requirement',
    parentId: 193,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 201,
    workpackage: 'SW Integration & Test',
    effortType: 'requirement',
    parentId: 194,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 201,
    workpackage: 'SW Integration & Test',
    effortType: 'requirement',
    parentId: 195,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 202,
    workpackage: 'Application & Functional Integration',
    effortType: 'requirement',
    parentId: 177,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 202,
    workpackage: 'Application & Functional Integration',
    effortType: 'requirement',
    parentId: 178,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 202,
    workpackage: 'Application & Functional Integration',
    effortType: 'requirement',
    parentId: 179,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 202,
    workpackage: 'Application & Functional Integration',
    effortType: 'requirement',
    parentId: 182,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 202,
    workpackage: 'Application & Functional Integration',
    effortType: 'requirement',
    parentId: 183,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 202,
    workpackage: 'Application & Functional Integration',
    effortType: 'requirement',
    parentId: 184,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 202,
    workpackage: 'Application & Functional Integration',
    effortType: 'requirement',
    parentId: 185,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 202,
    workpackage: 'Application & Functional Integration',
    effortType: 'requirement',
    parentId: 186,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 202,
    workpackage: 'Application & Functional Integration',
    effortType: 'requirement',
    parentId: 187,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 203,
    workpackage: 'EOL & Plant Support',
    effortType: 'requirement',
    parentId: 177,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 203,
    workpackage: 'EOL & Plant Support',
    effortType: 'requirement',
    parentId: 178,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 203,
    workpackage: 'EOL & Plant Support',
    effortType: 'requirement',
    parentId: 179,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 203,
    workpackage: 'EOL & Plant Support',
    effortType: 'requirement',
    parentId: 181,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 203,
    workpackage: 'EOL & Plant Support',
    effortType: 'requirement',
    parentId: 182,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 203,
    workpackage: 'EOL & Plant Support',
    effortType: 'requirement',
    parentId: 183,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 203,
    workpackage: 'EOL & Plant Support',
    effortType: 'requirement',
    parentId: 184,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 203,
    workpackage: 'EOL & Plant Support',
    effortType: 'requirement',
    parentId: 185,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 203,
    workpackage: 'EOL & Plant Support',
    effortType: 'requirement',
    parentId: 186,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 203,
    workpackage: 'EOL & Plant Support',
    effortType: 'requirement',
    parentId: 187,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 203,
    workpackage: 'EOL & Plant Support',
    effortType: 'requirement',
    parentId: 190,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 203,
    workpackage: 'EOL & Plant Support',
    effortType: 'requirement',
    parentId: 191,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 203,
    workpackage: 'EOL & Plant Support',
    effortType: 'requirement',
    parentId: 192,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 203,
    workpackage: 'EOL & Plant Support',
    effortType: 'requirement',
    parentId: 193,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 203,
    workpackage: 'EOL & Plant Support',
    effortType: 'requirement',
    parentId: 194,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 203,
    workpackage: 'EOL & Plant Support',
    effortType: 'requirement',
    parentId: 195,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 204,
    workpackage: 'Support Activities',
    effortType: 'requirement',
    parentId: 177,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 204,
    workpackage: 'Support Activities',
    effortType: 'requirement',
    parentId: 178,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 204,
    workpackage: 'Support Activities',
    effortType: 'requirement',
    parentId: 179,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 204,
    workpackage: 'Support Activities',
    effortType: 'requirement',
    parentId: 181,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 204,
    workpackage: 'Support Activities',
    effortType: 'requirement',
    parentId: 182,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 204,
    workpackage: 'Support Activities',
    effortType: 'requirement',
    parentId: 183,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 204,
    workpackage: 'Support Activities',
    effortType: 'requirement',
    parentId: 184,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 204,
    workpackage: 'Support Activities',
    effortType: 'requirement',
    parentId: 185,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 204,
    workpackage: 'Support Activities',
    effortType: 'requirement',
    parentId: 186,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 204,
    workpackage: 'Support Activities',
    effortType: 'requirement',
    parentId: 187,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 204,
    workpackage: 'Support Activities',
    effortType: 'requirement',
    parentId: 190,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 204,
    workpackage: 'Support Activities',
    effortType: 'requirement',
    parentId: 191,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 204,
    workpackage: 'Support Activities',
    effortType: 'requirement',
    parentId: 192,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 204,
    workpackage: 'Support Activities',
    effortType: 'requirement',
    parentId: 193,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 204,
    workpackage: 'Support Activities',
    effortType: 'requirement',
    parentId: 194,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 204,
    workpackage: 'Support Activities',
    effortType: 'requirement',
    parentId: 195,
    organizations: [
      {
        organizationId: 98,
        orgResReqExp: 'GEE SW-EPS Eng. Project Management',
        orgResKey: '1854408',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 99,
        orgResReqExp: 'GEE SW-V Architecture',
        orgResKey: '1854409',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 100,
        orgResReqExp: 'GEE SW-V Requirements',
        orgResKey: '1854389',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 103,
        orgResReqExp: 'GEE SW-V Powertrain Functions',
        orgResKey: '1854385',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 104,
        orgResReqExp: 'GEE SW-C Diagnostics & HW abstr.',
        orgResKey: '1854404',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 105,
        orgResReqExp: 'GEE SW-C eMotor control',
        orgResKey: '1854401',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 106,
        orgResReqExp: 'GEE SW-C Gearbox & Actuators',
        orgResKey: '1854405',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 107,
        orgResReqExp: 'GEE SW-B Base Software Cluster A',
        orgResKey: '1854390',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 108,
        orgResReqExp: 'GEE SW-B Base Software Cluster B',
        orgResKey: '1854400',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 117,
        orgResReqExp: 'CN SW-V Architecture',
        orgResKey: '1855156',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 118,
        orgResReqExp: 'CN SW-V Requirements',
        orgResKey: '1855141',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 121,
        orgResReqExp: 'CN SW-V Powertrain Functions',
        orgResKey: '1855144',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 122,
        orgResReqExp: 'CN SW-C Diagnostics & HW abstr.',
        orgResKey: '1855149',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 123,
        orgResReqExp: 'CN SW-C eMotor control',
        orgResKey: '1855140',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 124,
        orgResReqExp: 'CN SW-C Gearbox & Actuators',
        orgResKey: '1855150',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 205,
    workpackage: 'SW Qualification Test',
    effortType: 'requirement',
    parentId: 181,
    organizations: [
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 205,
    workpackage: 'SW Qualification Test',
    effortType: 'requirement',
    parentId: 190,
    organizations: [
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 205,
    workpackage: 'SW Qualification Test',
    effortType: 'requirement',
    parentId: 191,
    organizations: [
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 205,
    workpackage: 'SW Qualification Test',
    effortType: 'requirement',
    parentId: 192,
    organizations: [
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 205,
    workpackage: 'SW Qualification Test',
    effortType: 'requirement',
    parentId: 193,
    organizations: [
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 205,
    workpackage: 'SW Qualification Test',
    effortType: 'requirement',
    parentId: 194,
    organizations: [
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 205,
    workpackage: 'SW Qualification Test',
    effortType: 'requirement',
    parentId: 195,
    organizations: [
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 206,
    workpackage: 'HIL & SIL Environment',
    effortType: 'requirement',
    parentId: 181,
    organizations: [
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 206,
    workpackage: 'HIL & SIL Environment',
    effortType: 'requirement',
    parentId: 190,
    organizations: [
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 206,
    workpackage: 'HIL & SIL Environment',
    effortType: 'requirement',
    parentId: 191,
    organizations: [
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 206,
    workpackage: 'HIL & SIL Environment',
    effortType: 'requirement',
    parentId: 192,
    organizations: [
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 206,
    workpackage: 'HIL & SIL Environment',
    effortType: 'requirement',
    parentId: 193,
    organizations: [
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 206,
    workpackage: 'HIL & SIL Environment',
    effortType: 'requirement',
    parentId: 194,
    organizations: [
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 206,
    workpackage: 'HIL & SIL Environment',
    effortType: 'requirement',
    parentId: 195,
    organizations: [
      {
        organizationId: 102,
        orgResReqExp: 'GEE SW-V SW Tools',
        orgResKey: '1854411',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 110,
        orgResReqExp: 'GEE SW-T Testautom., Framework & Virtualization',
        orgResKey: '1854403',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 111,
        orgResReqExp: 'GEE SW-T HIL Infrastructure',
        orgResKey: '1854384',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 112,
        orgResReqExp: 'GEE SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1854380',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 113,
        orgResReqExp: 'GEE SW-T Testmanagement & Project Test Lead',
        orgResKey: '1854402',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 114,
        orgResReqExp: 'GEE SW-T Functional SW Verification',
        orgResKey: '1854392',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 115,
        orgResReqExp: 'GEE SW-T Base SW Verification',
        orgResKey: '1854407',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 116,
        orgResReqExp: 'GEE SW-T Precomissioning',
        orgResKey: '1854383',
        regions: [
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 120,
        orgResReqExp: 'CN SW-V SW Tools',
        orgResKey: '1855154',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 126,
        orgResReqExp: 'CN SW-T Testautom., Framework & Virtualization',
        orgResKey: '1855152',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 127,
        orgResReqExp: 'CN SW-T HIL Infrastructure',
        orgResKey: '1855145',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 128,
        orgResReqExp: 'CN SW-T Funct. Diagnostics & Safety Verific.',
        orgResKey: '1855148',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 129,
        orgResReqExp: 'CN SW-T Base SW Verification',
        orgResKey: '1855157',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 130,
        orgResReqExp: 'CN SW-T Precomissioning',
        orgResKey: '1855143',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 6,
        subproject: 'SP06 Software'
      }
    ]
  },
  {
    workpackageId: 207,
    workpackage: 'PT Management & Purchasing',
    sortOrder: 68,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 131,
        orgResReqExp: 'EU PTPE-P LAN Management & Purchasing',
        orgResKey: '1854358',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          }
        ]
      },
      {
        organizationId: 132,
        orgResReqExp: 'EU PTPE-P UBA Management & Purchasing',
        orgResKey: '1854368',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          }
        ]
      },
      {
        organizationId: 133,
        orgResReqExp: 'EU PTPE-P VAN Management & Purchasing',
        orgResKey: '1854363',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          }
        ]
      },
      {
        organizationId: 141,
        orgResReqExp: 'NA PT (TRY)-P TRY Management & Purchasing',
        orgResKey: '1854307',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 7,
        subproject: 'SP07 Prototyping'
      },
      {
        subprojectId: 8,
        subproject: 'SP07 Prototyping & Testing'
      }
    ]
  },
  {
    workpackageId: 208,
    workpackage: 'Prototype Coordination',
    effortType: 'requirement',
    parentId: 207,
    organizations: [
      {
        organizationId: 131,
        orgResReqExp: 'EU PTPE-P LAN Management & Purchasing',
        orgResKey: '1854358',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          }
        ]
      },
      {
        organizationId: 132,
        orgResReqExp: 'EU PTPE-P UBA Management & Purchasing',
        orgResKey: '1854368',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          }
        ]
      },
      {
        organizationId: 133,
        orgResReqExp: 'EU PTPE-P VAN Management & Purchasing',
        orgResKey: '1854363',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 7,
        subproject: 'SP07 Prototyping'
      }
    ]
  },
  {
    workpackageId: 209,
    workpackage: 'Prototype Delivery Test',
    effortType: 'requirement',
    parentId: 207,
    organizations: [
      {
        organizationId: 131,
        orgResReqExp: 'EU PTPE-P LAN Management & Purchasing',
        orgResKey: '1854358',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          }
        ]
      },
      {
        organizationId: 132,
        orgResReqExp: 'EU PTPE-P UBA Management & Purchasing',
        orgResKey: '1854368',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          }
        ]
      },
      {
        organizationId: 133,
        orgResReqExp: 'EU PTPE-P VAN Management & Purchasing',
        orgResKey: '1854363',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          }
        ]
      },
      {
        organizationId: 135,
        orgResReqExp: 'EU PTPM-P UBA Assembly Mechanics',
        orgResKey: '1854365',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          }
        ]
      },
      {
        organizationId: 137,
        orgResReqExp: 'EU PTPM-P LAN Assembly Mechanics',
        orgResKey: '1854359',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          }
        ]
      },
      {
        organizationId: 138,
        orgResReqExp: 'EU PTPM-P VAN Assembly Mechanics',
        orgResKey: '1855158',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 7,
        subproject: 'SP07 Prototyping'
      }
    ]
  },
  {
    workpackageId: 210,
    workpackage: 'PT Logistic, Quality & Maintenance',
    sortOrder: 69,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 134,
        orgResReqExp: 'EU PTPM-P UBA Logistic, Quality & Maintenance',
        orgResKey: '1854362',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          }
        ]
      },
      {
        organizationId: 136,
        orgResReqExp: 'EU PTPM-P LAN Logistic, Quality & Maintenance',
        orgResKey: '1854357',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          }
        ]
      },
      {
        organizationId: 143,
        orgResReqExp: 'NA PT (RAM)-P RAM Logistic, Quality & Maintenance',
        orgResKey: '1861759',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 6,
            qvLocation: 'RAM'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 7,
        subproject: 'SP07 Prototyping'
      },
      {
        subprojectId: 8,
        subproject: 'SP07 Prototyping & Testing'
      }
    ]
  },
  {
    workpackageId: 211,
    workpackage: 'Prototype Additional Request',
    effortType: 'requirement',
    parentId: 210,
    organizations: [
      {
        organizationId: 134,
        orgResReqExp: 'EU PTPM-P UBA Logistic, Quality & Maintenance',
        orgResKey: '1854362',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          }
        ]
      },
      {
        organizationId: 136,
        orgResReqExp: 'EU PTPM-P LAN Logistic, Quality & Maintenance',
        orgResKey: '1854357',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 7,
        subproject: 'SP07 Prototyping'
      }
    ]
  },
  {
    workpackageId: 212,
    workpackage: 'Prototype Logistics',
    effortType: 'requirement',
    parentId: 210,
    organizations: [
      {
        organizationId: 134,
        orgResReqExp: 'EU PTPM-P UBA Logistic, Quality & Maintenance',
        orgResKey: '1854362',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          }
        ]
      },
      {
        organizationId: 136,
        orgResReqExp: 'EU PTPM-P LAN Logistic, Quality & Maintenance',
        orgResKey: '1854357',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 7,
        subproject: 'SP07 Prototyping'
      }
    ]
  },
  {
    workpackageId: 213,
    workpackage: 'PT Assembly Mechanics',
    sortOrder: 70,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 135,
        orgResReqExp: 'EU PTPM-P UBA Assembly Mechanics',
        orgResKey: '1854365',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          }
        ]
      },
      {
        organizationId: 137,
        orgResReqExp: 'EU PTPM-P LAN Assembly Mechanics',
        orgResKey: '1854359',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          }
        ]
      },
      {
        organizationId: 138,
        orgResReqExp: 'EU PTPM-P VAN Assembly Mechanics',
        orgResKey: '1855158',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          }
        ]
      },
      {
        organizationId: 142,
        orgResReqExp: 'NA PT (TRY)-P TRY Assembly Mechanics',
        orgResKey: '1854311',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      },
      {
        organizationId: 144,
        orgResReqExp: 'NA PT (RAM)-P RAM Assembly Mechanics',
        orgResKey: '1861774',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 6,
            qvLocation: 'RAM'
          }
        ]
      },
      {
        organizationId: 145,
        orgResReqExp: 'CN PT-P CZ Assembly Mechanics',
        orgResKey: '1855105',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 7,
        subproject: 'SP07 Prototyping'
      },
      {
        subprojectId: 8,
        subproject: 'SP07 Prototyping & Testing'
      }
    ]
  },
  {
    workpackageId: 214,
    workpackage: 'Prototype Assembly',
    effortType: 'requirement',
    parentId: 213,
    organizations: [
      {
        organizationId: 135,
        orgResReqExp: 'EU PTPM-P UBA Assembly Mechanics',
        orgResKey: '1854365',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          }
        ]
      },
      {
        organizationId: 137,
        orgResReqExp: 'EU PTPM-P LAN Assembly Mechanics',
        orgResKey: '1854359',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          }
        ]
      },
      {
        organizationId: 138,
        orgResReqExp: 'EU PTPM-P VAN Assembly Mechanics',
        orgResKey: '1855158',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 7,
        subproject: 'SP07 Prototyping'
      }
    ]
  },
  {
    workpackageId: 215,
    workpackage: 'PT Manufacturing Engineer',
    sortOrder: 71,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 139,
        orgResReqExp: 'EU PTME-M UBA Manufacturing Engineer',
        orgResKey: '1854418',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 7,
        subproject: 'SP07 Prototyping'
      }
    ]
  },
  {
    workpackageId: 216,
    workpackage: 'Prototype Tooling',
    effortType: 'requirement',
    parentId: 215,
    organizations: [
      {
        organizationId: 139,
        orgResReqExp: 'EU PTME-M UBA Manufacturing Engineer',
        orgResKey: '1854418',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          }
        ]
      },
      {
        organizationId: 140,
        orgResReqExp: 'EU PTMM-M UBA Manufacturing Mechanic',
        orgResKey: '1854350',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 7,
        subproject: 'SP07 Prototyping'
      }
    ]
  },
  {
    workpackageId: 217,
    workpackage: 'PT Manufacturing Mechanic',
    sortOrder: 72,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 140,
        orgResReqExp: 'EU PTMM-M UBA Manufacturing Mechanic',
        orgResKey: '1854350',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 7,
        subproject: 'SP07 Prototyping'
      }
    ]
  },
  {
    workpackageId: 218,
    workpackage: 'Test Engineer',
    sortOrder: 73,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 180,
        orgResReqExp: 'EU TE-T UBA Test Engineer',
        orgResKey: '1854410',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          }
        ]
      },
      {
        organizationId: 147,
        orgResReqExp: 'NA PT (TRY)-T TRY Test Engineer',
        orgResKey: '1854340',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      },
      {
        organizationId: 149,
        orgResReqExp: 'NA PT (RAM)-T RAM Test Engineer',
        orgResKey: '1861775',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 6,
            qvLocation: 'RAM'
          }
        ]
      },
      {
        organizationId: 151,
        orgResReqExp: 'NA PT (CON)-T CON Test Engineer',
        orgResKey: '1861789',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 7,
            qvLocation: 'CON'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 8,
        subproject: 'SP07 Prototyping & Testing'
      },
      {
        subprojectId: 9,
        subproject: 'SP0X Test Operations'
      }
    ]
  },
  {
    workpackageId: 219,
    workpackage: 'Int. Test - Set up and commision testrig and prototype',
    effortType: 'requirement',
    parentId: 218,
    organizations: [
      {
        organizationId: 180,
        orgResReqExp: 'EU TE-T UBA Test Engineer',
        orgResKey: '1854410',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          }
        ]
      },
      {
        organizationId: 282,
        orgResReqExp: 'EU TM-T VAN Test Mechanic',
        orgResKey: '1855160',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          }
        ]
      },
      {
        organizationId: 283,
        orgResReqExp: 'EU TM-T LAN Test Mechanic',
        orgResKey: '1855159',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          }
        ]
      },
      {
        organizationId: 284,
        orgResReqExp: 'EU TM-T UBA Test Mechanics',
        orgResKey: '1854360',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 7,
        subproject: 'SP07 Prototyping'
      }
    ]
  },
  {
    workpackageId: 220,
    workpackage: 'Int. Test - rig and test operation',
    effortType: 'requirement',
    parentId: 218,
    organizations: [
      {
        organizationId: 180,
        orgResReqExp: 'EU TE-T UBA Test Engineer',
        orgResKey: '1854410',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          }
        ]
      },
      {
        organizationId: 282,
        orgResReqExp: 'EU TM-T VAN Test Mechanic',
        orgResKey: '1855160',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          }
        ]
      },
      {
        organizationId: 283,
        orgResReqExp: 'EU TM-T LAN Test Mechanic',
        orgResKey: '1855159',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          }
        ]
      },
      {
        organizationId: 284,
        orgResReqExp: 'EU TM-T UBA Test Mechanics',
        orgResKey: '1854360',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 7,
        subproject: 'SP07 Prototyping'
      }
    ]
  },
  {
    workpackageId: 221,
    workpackage: 'Powermodule Design & Dev Tests',
    effortType: 'requirement',
    parentId: 218,
    organizations: [
      {
        organizationId: 180,
        orgResReqExp: 'EU TE-T UBA Test Engineer',
        orgResKey: '1854410',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          }
        ]
      },
      {
        organizationId: 181,
        orgResReqExp: 'EU TE-T LAN Test Engineer',
        orgResKey: '1854379',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          }
        ]
      },
      {
        organizationId: 182,
        orgResReqExp: 'EU TE-T LAN Test Engineer',
        orgResKey: '1854379',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          }
        ]
      },
      {
        organizationId: 183,
        orgResReqExp: 'EU TE-T VAN Test Engineer',
        orgResKey: '1854393',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      },
      {
        organizationId: 184,
        orgResReqExp: 'EU TE-T TCI Testing Engineer',
        orgResKey: '1854381',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 282,
        orgResReqExp: 'EU TM-T VAN Test Mechanic',
        orgResKey: '1855160',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          }
        ]
      },
      {
        organizationId: 283,
        orgResReqExp: 'EU TM-T LAN Test Mechanic',
        orgResKey: '1855159',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          }
        ]
      },
      {
        organizationId: 284,
        orgResReqExp: 'EU TM-T UBA Test Mechanics',
        orgResKey: '1854360',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 7,
        subproject: 'SP07 Prototyping'
      }
    ]
  },
  {
    workpackageId: 222,
    workpackage: 'Powermodule Testing',
    effortType: 'requirement',
    parentId: 218,
    organizations: [
      {
        organizationId: 180,
        orgResReqExp: 'EU TE-T UBA Test Engineer',
        orgResKey: '1854410',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          }
        ]
      },
      {
        organizationId: 181,
        orgResReqExp: 'EU TE-T LAN Test Engineer',
        orgResKey: '1854379',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          }
        ]
      },
      {
        organizationId: 182,
        orgResReqExp: 'EU TE-T LAN Test Engineer',
        orgResKey: '1854379',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          }
        ]
      },
      {
        organizationId: 183,
        orgResReqExp: 'EU TE-T VAN Test Engineer',
        orgResKey: '1854393',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      },
      {
        organizationId: 184,
        orgResReqExp: 'EU TE-T TCI Testing Engineer',
        orgResKey: '1854381',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 282,
        orgResReqExp: 'EU TM-T VAN Test Mechanic',
        orgResKey: '1855160',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          }
        ]
      },
      {
        organizationId: 283,
        orgResReqExp: 'EU TM-T LAN Test Mechanic',
        orgResKey: '1855159',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          }
        ]
      },
      {
        organizationId: 284,
        orgResReqExp: 'EU TM-T UBA Test Mechanics',
        orgResKey: '1854360',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 7,
        subproject: 'SP07 Prototyping'
      }
    ]
  },
  {
    workpackageId: 223,
    workpackage: 'Prototype Delivery Test',
    effortType: 'requirement',
    parentId: 218,
    organizations: [
      {
        organizationId: 180,
        orgResReqExp: 'EU TE-T UBA Test Engineer',
        orgResKey: '1854410',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          }
        ]
      },
      {
        organizationId: 181,
        orgResReqExp: 'EU TE-T LAN Test Engineer',
        orgResKey: '1854379',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          }
        ]
      },
      {
        organizationId: 182,
        orgResReqExp: 'EU TE-T LAN Test Engineer',
        orgResKey: '1854379',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          }
        ]
      },
      {
        organizationId: 183,
        orgResReqExp: 'EU TE-T VAN Test Engineer',
        orgResKey: '1854393',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      },
      {
        organizationId: 184,
        orgResReqExp: 'EU TE-T TCI Testing Engineer',
        orgResKey: '1854381',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      },
      {
        organizationId: 282,
        orgResReqExp: 'EU TM-T VAN Test Mechanic',
        orgResKey: '1855160',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          }
        ]
      },
      {
        organizationId: 283,
        orgResReqExp: 'EU TM-T LAN Test Mechanic',
        orgResKey: '1855159',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          }
        ]
      },
      {
        organizationId: 284,
        orgResReqExp: 'EU TM-T UBA Test Mechanics',
        orgResKey: '1854360',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 7,
        subproject: 'SP07 Prototyping'
      }
    ]
  },
  {
    workpackageId: 224,
    workpackage: 'Test Mechanic',
    sortOrder: 74,
    effortType: 'requirement',
    organizations: [
      {
        organizationId: 282,
        orgResReqExp: 'EU TM-T VAN Test Mechanic',
        orgResKey: '1855160',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          }
        ]
      },
      {
        organizationId: 283,
        orgResReqExp: 'EU TM-T LAN Test Mechanic',
        orgResKey: '1855159',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          }
        ]
      },
      {
        organizationId: 284,
        orgResReqExp: 'EU TM-T UBA Test Mechanics',
        orgResKey: '1854360',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          }
        ]
      },
      {
        organizationId: 146,
        orgResReqExp: 'NA PT (TRY)-T TRY Test Mechanic',
        orgResKey: '1854355',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      },
      {
        organizationId: 148,
        orgResReqExp: 'NA PT (RAM)-T RAM Test Mechanic',
        orgResKey: '1861766',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 6,
            qvLocation: 'RAM'
          }
        ]
      },
      {
        organizationId: 150,
        orgResReqExp: 'NA PT (CON)-T CON Test Mechanic',
        orgResKey: '1861788',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 7,
            qvLocation: 'CON'
          }
        ]
      },
      {
        organizationId: 152,
        orgResReqExp: 'CN PT-T CZ Test Mechanic',
        orgResKey: '1855108',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 8,
        subproject: 'SP07 Prototyping & Testing'
      },
      {
        subprojectId: 9,
        subproject: 'SP0X Test Operations'
      }
    ]
  },
  {
    workpackageId: 225,
    workpackage: 'Dyno-1M-48V',
    effortType: 'requirement',
    parentId: 307,
    organizations: [
      {
        organizationId: 248,
        orgResReqExp: 'TR-020-Testrigs ECV 4236 (VAN)-Dyno-1M-48V',
        orgResKey: '1638732',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 10,
        subproject: 'SP0X Testrigs'
      }
    ]
  },
  {
    workpackageId: 226,
    workpackage: 'Dyno-1M-HV',
    effortType: 'requirement',
    parentId: 307,
    organizations: [
      {
        organizationId: 153,
        orgResReqExp: 'TR-020-Testrigs (TRY)-Dyno-1M-HV',
        orgResKey: '1337418',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      },
      {
        organizationId: 247,
        orgResReqExp: 'TR-020-Testrigs ECV 4236 (VAN)-Dyno-1M-HV',
        orgResKey: '1638731',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 8,
        subproject: 'SP07 Prototyping & Testing'
      },
      {
        subprojectId: 10,
        subproject: 'SP0X Testrigs'
      }
    ]
  },
  {
    workpackageId: 227,
    workpackage: 'Dyno-1M-CON-48V',
    effortType: 'requirement',
    parentId: 307,
    organizations: [
      {
        organizationId: 246,
        orgResReqExp: 'TR-020-Testrigs ECV 4236 (VAN)-Dyno-1M-CON-48V',
        orgResKey: '1638730',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 10,
        subproject: 'SP0X Testrigs'
      }
    ]
  },
  {
    workpackageId: 228,
    workpackage: 'Dyno-1M-CON-HV',
    effortType: 'requirement',
    parentId: 307,
    organizations: [
      {
        organizationId: 219,
        orgResReqExp: 'TR-020-Testrigs (TRA)-Dyno-1M-CON-HV',
        orgResKey: '627189',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 10,
        subproject: 'SP0X Testrigs'
      }
    ]
  },
  {
    workpackageId: 229,
    workpackage: 'Dyno-2M',
    effortType: 'requirement',
    parentId: 307,
    organizations: [
      {
        organizationId: 204,
        orgResReqExp: 'TR-020-Testrigs (LAN)-Dyno-2M',
        orgResKey: '329768',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          }
        ]
      },
      {
        organizationId: 243,
        orgResReqExp: 'TR-020-Testrigs ECV 4236 (VAN)-Dyno-2M',
        orgResKey: '855137',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 10,
        subproject: 'SP0X Testrigs'
      }
    ]
  },
  {
    workpackageId: 230,
    workpackage: 'Dyno-2M-48V',
    effortType: 'requirement',
    parentId: 307,
    organizations: [
      {
        organizationId: 185,
        orgResReqExp: 'TR-020-Testrigs (UBA)-Dyno-2M-48V',
        orgResKey: '1277990',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 10,
        subproject: 'SP0X Testrigs'
      }
    ]
  },
  {
    workpackageId: 231,
    workpackage: 'Dyno-2M-HV',
    effortType: 'requirement',
    parentId: 307,
    organizations: [
      {
        organizationId: 154,
        orgResReqExp: 'TR-020-Testrigs (TRY)-Dyno-2M-HV',
        orgResKey: '1278025',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      },
      {
        organizationId: 172,
        orgResReqExp: 'TR-020-Testrigs (CZ)-Dyno-2M-HV',
        orgResKey: '1277841',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 205,
        orgResReqExp: 'TR-020-Testrigs (LAN)-Dyno-2M-HV',
        orgResKey: '959055',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          }
        ]
      },
      {
        organizationId: 220,
        orgResReqExp: 'TR-020-Testrigs (TRA)-Dyno-2M-HV',
        orgResKey: '1278008',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          }
        ]
      },
      {
        organizationId: 251,
        orgResReqExp: 'TR-020-Testrigs ECV 4236 (VAN)-Dyno-2M-HV',
        orgResKey: '855129',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 8,
        subproject: 'SP07 Prototyping & Testing'
      },
      {
        subprojectId: 10,
        subproject: 'SP0X Testrigs'
      }
    ]
  },
  {
    workpackageId: 232,
    workpackage: 'Dyno-2M-HV-NVH',
    effortType: 'requirement',
    parentId: 307,
    organizations: [
      {
        organizationId: 155,
        orgResReqExp: 'TR-020-Testrigs (TRY)-Dyno-2M-HV-NVH',
        orgResKey: '1706602',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 10,
        subproject: 'SP0X Testrigs'
      }
    ]
  },
  {
    workpackageId: 233,
    workpackage: 'Dyno-2M-NVH-HV',
    effortType: 'requirement',
    parentId: 307,
    organizations: [
      {
        organizationId: 254,
        orgResReqExp: 'TR-020-Testrigs ECV 4236 (VAN)-Dyno-2M-NVH-HV',
        orgResKey: '855131',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 10,
        subproject: 'SP0X Testrigs'
      }
    ]
  },
  {
    workpackageId: 234,
    workpackage: 'Dyno-2M-NVH',
    effortType: 'requirement',
    parentId: 307,
    organizations: [
      {
        organizationId: 242,
        orgResReqExp: 'TR-020-Testrigs ECV 4236 (VAN)-Dyno-2M-NVH',
        orgResKey: '855204',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 10,
        subproject: 'SP0X Testrigs'
      }
    ]
  },
  {
    workpackageId: 235,
    workpackage: 'Dyno-2M-DSP-48V',
    effortType: 'requirement',
    parentId: 307,
    organizations: [
      {
        organizationId: 186,
        orgResReqExp: 'TR-020-Testrigs (UBA)-Dyno-2M-DSP-48V',
        orgResKey: '1277970',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 10,
        subproject: 'SP0X Testrigs'
      }
    ]
  },
  {
    workpackageId: 236,
    workpackage: 'Dyno-2M-PSP-48V',
    effortType: 'requirement',
    parentId: 307,
    organizations: [
      {
        organizationId: 187,
        orgResReqExp: 'TR-020-Testrigs (UBA)-Dyno-2M-PSP-48V',
        orgResKey: '1277979',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 10,
        subproject: 'SP0X Testrigs'
      }
    ]
  },
  {
    workpackageId: 237,
    workpackage: 'Dyno-2M-EOL-HV',
    effortType: 'requirement',
    parentId: 307,
    organizations: [
      {
        organizationId: 250,
        orgResReqExp: 'TR-020-Testrigs ECV 4236 (VAN)-Dyno-2M-EOL-HV',
        orgResKey: '1278016',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 10,
        subproject: 'SP0X Testrigs'
      }
    ]
  },
  {
    workpackageId: 238,
    workpackage: 'Dyno-2M-CON-HV',
    effortType: 'requirement',
    parentId: 307,
    organizations: [
      {
        organizationId: 252,
        orgResReqExp: 'TR-020-Testrigs ECV 4236 (VAN)-Dyno-2M-CON-HV',
        orgResKey: '1278009',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 10,
        subproject: 'SP0X Testrigs'
      }
    ]
  },
  {
    workpackageId: 239,
    workpackage: 'Dyno-3M',
    effortType: 'requirement',
    parentId: 307,
    organizations: [
      {
        organizationId: 156,
        orgResReqExp: 'TR-020-Testrigs (TRY)-Dyno-3M',
        orgResKey: '1278024',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      },
      {
        organizationId: 165,
        orgResReqExp: 'TR-020-Testrigs (RAM)-Dyno-3M',
        orgResKey: '916005',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 6,
            qvLocation: 'RAM'
          }
        ]
      },
      {
        organizationId: 173,
        orgResReqExp: 'TR-020-Testrigs (CZ)-Dyno-3M',
        orgResKey: '1278001',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 206,
        orgResReqExp: 'TR-020-Testrigs (LAN)-Dyno-3M',
        orgResKey: '959056',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          }
        ]
      },
      {
        organizationId: 241,
        orgResReqExp: 'TR-020-Testrigs ECV 4236 (VAN)-Dyno-3M',
        orgResKey: '855135',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 8,
        subproject: 'SP07 Prototyping & Testing'
      },
      {
        subprojectId: 10,
        subproject: 'SP0X Testrigs'
      }
    ]
  },
  {
    workpackageId: 240,
    workpackage: 'Dyno-3M-HV',
    effortType: 'requirement',
    parentId: 307,
    organizations: [
      {
        organizationId: 157,
        orgResReqExp: 'TR-020-Testrigs (TRY)-Dyno-3M-HV',
        orgResKey: '1278026',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      },
      {
        organizationId: 188,
        orgResReqExp: 'TR-020-Testrigs (UBA)-Dyno-3M-HV',
        orgResKey: '1277976',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          }
        ]
      },
      {
        organizationId: 207,
        orgResReqExp: 'TR-020-Testrigs (LAN)-Dyno-3M-HV',
        orgResKey: '959052',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 8,
        subproject: 'SP07 Prototyping & Testing'
      },
      {
        subprojectId: 10,
        subproject: 'SP0X Testrigs'
      }
    ]
  },
  {
    workpackageId: 241,
    workpackage: 'Dyno-3M-NVH',
    effortType: 'requirement',
    parentId: 307,
    organizations: [
      {
        organizationId: 158,
        orgResReqExp: 'TR-020-Testrigs (TRY)-Dyno-3M-NVH',
        orgResKey: '1278027',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      },
      {
        organizationId: 208,
        orgResReqExp: 'TR-020-Testrigs (LAN)-Dyno-3M-NVH',
        orgResKey: '971982',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 8,
        subproject: 'SP07 Prototyping & Testing'
      },
      {
        subprojectId: 10,
        subproject: 'SP0X Testrigs'
      }
    ]
  },
  {
    workpackageId: 242,
    workpackage: 'Dyno-3M-NVH-HV',
    effortType: 'requirement',
    parentId: 307,
    organizations: [
      {
        organizationId: 209,
        orgResReqExp: 'TR-020-Testrigs (LAN)-Dyno-3M-NVH-HV',
        orgResKey: '959053',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 10,
        subproject: 'SP0X Testrigs'
      }
    ]
  },
  {
    workpackageId: 243,
    workpackage: 'Dyno-3M-NVH-48V',
    effortType: 'requirement',
    parentId: 307,
    organizations: [
      {
        organizationId: 189,
        orgResReqExp: 'TR-020-Testrigs (UBA)-Dyno-3M-NVH-48V',
        orgResKey: '1277975',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 10,
        subproject: 'SP0X Testrigs'
      }
    ]
  },
  {
    workpackageId: 244,
    workpackage: 'Dyno-3M-EOL',
    effortType: 'requirement',
    parentId: 307,
    organizations: [
      {
        organizationId: 245,
        orgResReqExp: 'TR-020-Testrigs ECV 4236 (VAN)-Dyno-3M-EOL',
        orgResKey: '855555',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 10,
        subproject: 'SP0X Testrigs'
      }
    ]
  },
  {
    workpackageId: 245,
    workpackage: 'Dyno-3M-PT-48V',
    effortType: 'requirement',
    parentId: 307,
    organizations: [
      {
        organizationId: 190,
        orgResReqExp: 'TR-020-Testrigs (UBA)-Dyno-3M-PT-48V',
        orgResKey: '1277988',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 10,
        subproject: 'SP0X Testrigs'
      }
    ]
  },
  {
    workpackageId: 246,
    workpackage: 'Dyno-4M',
    effortType: 'requirement',
    parentId: 307,
    organizations: [
      {
        organizationId: 244,
        orgResReqExp: 'TR-020-Testrigs ECV 4236 (VAN)-Dyno-4M',
        orgResKey: '1278015',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 10,
        subproject: 'SP0X Testrigs'
      }
    ]
  },
  {
    workpackageId: 247,
    workpackage: 'Dyno-4M-48V',
    effortType: 'requirement',
    parentId: 307,
    organizations: [
      {
        organizationId: 191,
        orgResReqExp: 'TR-020-Testrigs (UBA)-Dyno-4M-48V',
        orgResKey: '1277989',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 10,
        subproject: 'SP0X Testrigs'
      }
    ]
  },
  {
    workpackageId: 248,
    workpackage: 'Dyno-FCT',
    effortType: 'requirement',
    parentId: 307,
    organizations: [
      {
        organizationId: 159,
        orgResReqExp: 'TR-020-Testrigs (TRY)-Dyno-FCT',
        orgResKey: '1337465',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      },
      {
        organizationId: 167,
        orgResReqExp: 'TR-020-Testrigs (RAM)-Dyno-FCT',
        orgResKey: '1278022',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 6,
            qvLocation: 'RAM'
          }
        ]
      },
      {
        organizationId: 175,
        orgResReqExp: 'TR-020-Testrigs (CZ)-Dyno-FCT',
        orgResKey: '1278003',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 194,
        orgResReqExp: 'TR-020-Testrigs (UBA)-Dyno-FCT',
        orgResKey: '1277973',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          }
        ]
      },
      {
        organizationId: 212,
        orgResReqExp: 'TR-020-Testrigs (LAN)-Dyno-FCT',
        orgResKey: '1278005',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          }
        ]
      },
      {
        organizationId: 260,
        orgResReqExp: 'TR-020-Testrigs ECV 4239 (VAN)-Dyno-FCT',
        orgResKey: '855248',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 8,
        subproject: 'SP07 Prototyping & Testing'
      },
      {
        subprojectId: 10,
        subproject: 'SP0X Testrigs'
      }
    ]
  },
  {
    workpackageId: 249,
    workpackage: 'Dyno-FCT-CLU',
    effortType: 'requirement',
    parentId: 307,
    organizations: [
      {
        organizationId: 160,
        orgResReqExp: 'TR-020-Testrigs (TRY)-Dyno-FCT-CLU',
        orgResKey: '1337436',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      },
      {
        organizationId: 168,
        orgResReqExp: 'TR-020-Testrigs (RAM)-Dyno-FCT-CLU',
        orgResKey: '1278018',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 6,
            qvLocation: 'RAM'
          }
        ]
      },
      {
        organizationId: 197,
        orgResReqExp: 'TR-020-Testrigs (UBA)-Dyno-FCT-CLU',
        orgResKey: '1277966',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 8,
        subproject: 'SP07 Prototyping & Testing'
      },
      {
        subprojectId: 10,
        subproject: 'SP0X Testrigs'
      }
    ]
  },
  {
    workpackageId: 250,
    workpackage: 'Dyno-FCT-LUB',
    effortType: 'requirement',
    parentId: 307,
    organizations: [
      {
        organizationId: 161,
        orgResReqExp: 'TR-020-Testrigs (TRY)-Dyno-FCT-LUB',
        orgResKey: '1337468',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      },
      {
        organizationId: 176,
        orgResReqExp: 'TR-020-Testrigs (CZ)-Dyno-FCT-LUB',
        orgResKey: '329784',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 201,
        orgResReqExp: 'TR-020-Testrigs (UBA)-Dyno-FCT-LUB',
        orgResKey: '1277972',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          }
        ]
      },
      {
        organizationId: 215,
        orgResReqExp: 'TR-020-Testrigs (LAN)-Dyno-FCT-LUB',
        orgResKey: '329776',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          }
        ]
      },
      {
        organizationId: 239,
        orgResReqExp: 'TR-020-Testrigs ECV 4236 (VAN)-Dyno-FCT-LUB',
        orgResKey: '855160',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 8,
        subproject: 'SP07 Prototyping & Testing'
      },
      {
        subprojectId: 10,
        subproject: 'SP0X Testrigs'
      }
    ]
  },
  {
    workpackageId: 251,
    workpackage: 'Dyno-FCT-PULSE',
    effortType: 'requirement',
    parentId: 307,
    organizations: [
      {
        organizationId: 162,
        orgResReqExp: 'TR-020-Testrigs (TRY)-Dyno-FCT-PULSE',
        orgResKey: '1337528',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      },
      {
        organizationId: 169,
        orgResReqExp: 'TR-020-Testrigs (RAM)-Dyno-FCT-PULSE',
        orgResKey: '1278019',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 6,
            qvLocation: 'RAM'
          }
        ]
      },
      {
        organizationId: 177,
        orgResReqExp: 'TR-020-Testrigs (CZ)-Dyno-FCT-PULSE',
        orgResKey: '1278002',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 216,
        orgResReqExp: 'TR-020-Testrigs (LAN)-Dyno-FCT-PULSE',
        orgResKey: '959041',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 8,
        subproject: 'SP07 Prototyping & Testing'
      },
      {
        subprojectId: 10,
        subproject: 'SP0X Testrigs'
      }
    ]
  },
  {
    workpackageId: 252,
    workpackage: 'Dyno-FCT-SPIN',
    effortType: 'requirement',
    parentId: 307,
    organizations: [
      {
        organizationId: 163,
        orgResReqExp: 'TR-020-Testrigs (TRY)-Dyno-FCT-SPIN',
        orgResKey: '1337471',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      },
      {
        organizationId: 170,
        orgResReqExp: 'TR-020-Testrigs (RAM)-Dyno-FCT-SPIN',
        orgResKey: '1278021',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 6,
            qvLocation: 'RAM'
          }
        ]
      },
      {
        organizationId: 178,
        orgResReqExp: 'TR-020-Testrigs (CZ)-Dyno-FCT-SPIN',
        orgResKey: '1278004',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 217,
        orgResReqExp: 'TR-020-Testrigs (LAN)-Dyno-FCT-SPIN',
        orgResKey: '959045',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          }
        ]
      },
      {
        organizationId: 255,
        orgResReqExp: 'TR-020-Testrigs ECV 4236 (VAN)-Dyno-B2B-CON-EME',
        orgResKey: '1278014',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 8,
        subproject: 'SP07 Prototyping & Testing'
      },
      {
        subprojectId: 10,
        subproject: 'SP0X Testrigs'
      }
    ]
  },
  {
    workpackageId: 253,
    workpackage: 'Dyno-FCT-ACT',
    effortType: 'requirement',
    parentId: 307,
    organizations: [
      {
        organizationId: 196,
        orgResReqExp: 'TR-020-Testrigs (UBA)-Dyno-FCT-ACT',
        orgResKey: '1277977',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          }
        ]
      },
      {
        organizationId: 213,
        orgResReqExp: 'TR-020-Testrigs (LAN)-Dyno-FCT-ACT',
        orgResKey: '1074403',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          }
        ]
      },
      {
        organizationId: 256,
        orgResReqExp: 'TR-020-Testrigs ECV 4236 (VAN)-Dyno-3M-EOL-HV',
        orgResKey: '1278018',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 10,
        subproject: 'SP0X Testrigs'
      }
    ]
  },
  {
    workpackageId: 254,
    workpackage: 'Dyno-FCT-48V',
    effortType: 'requirement',
    parentId: 307,
    organizations: [
      {
        organizationId: 195,
        orgResReqExp: 'TR-020-Testrigs (UBA)-Dyno-FCT-48V',
        orgResKey: '1277971',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 10,
        subproject: 'SP0X Testrigs'
      }
    ]
  },
  {
    workpackageId: 256,
    workpackage: 'Dyno-FCT-HV',
    effortType: 'requirement',
    parentId: 307,
    organizations: [
      {
        organizationId: 214,
        orgResReqExp: 'TR-020-Testrigs (LAN)-Dyno-FCT-HV',
        orgResKey: '966799',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 10,
        subproject: 'SP0X Testrigs'
      }
    ]
  },
  {
    workpackageId: 257,
    workpackage: 'Dyno-FCT-STATIC',
    effortType: 'requirement',
    parentId: 307,
    organizations: [
      {
        organizationId: 179,
        orgResReqExp: 'TR-020-Testrigs (CZ)-Dyno-FCT-STATIC',
        orgResKey: '1277991',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      },
      {
        organizationId: 218,
        orgResReqExp: 'TR-020-Testrigs (LAN)-Dyno-FCT-STATIC',
        orgResKey: '329772',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 8,
        subproject: 'SP07 Prototyping & Testing'
      },
      {
        subprojectId: 10,
        subproject: 'SP0X Testrigs'
      }
    ]
  },
  {
    workpackageId: 258,
    workpackage: 'Dyno-FCT-DSP',
    effortType: 'requirement',
    parentId: 307,
    organizations: [
      {
        organizationId: 198,
        orgResReqExp: 'TR-020-Testrigs (UBA)-Dyno-FCT-DSP',
        orgResKey: '1277968',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 10,
        subproject: 'SP0X Testrigs'
      }
    ]
  },
  {
    workpackageId: 259,
    workpackage: 'Dyno-FCT-DSP-48V',
    effortType: 'requirement',
    parentId: 307,
    organizations: [
      {
        organizationId: 199,
        orgResReqExp: 'TR-020-Testrigs (UBA)-Dyno-FCT-DSP-48V',
        orgResKey: '1277969',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 10,
        subproject: 'SP0X Testrigs'
      }
    ]
  },
  {
    workpackageId: 260,
    workpackage: 'Dyno-FCT-EOL-48V',
    effortType: 'requirement',
    parentId: 307,
    organizations: [
      {
        organizationId: 200,
        orgResReqExp: 'TR-020-Testrigs (UBA)-Dyno-FCT-EOL-48V',
        orgResKey: '1277967',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 10,
        subproject: 'SP0X Testrigs'
      }
    ]
  },
  {
    workpackageId: 261,
    workpackage: 'Dyno-FCT-SHAKER',
    effortType: 'requirement',
    parentId: 307,
    organizations: [
      {
        organizationId: 202,
        orgResReqExp: 'TR-020-Testrigs (UBA)-Dyno-FCT-SHAKER',
        orgResKey: '1277974',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 10,
        subproject: 'SP0X Testrigs'
      }
    ]
  },
  {
    workpackageId: 262,
    workpackage: 'Dyno-HIL',
    effortType: 'requirement',
    parentId: 307,
    organizations: [
      {
        organizationId: 164,
        orgResReqExp: 'TR-020-Testrigs (TRY)-Dyno-HIL',
        orgResKey: '1706613',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 8,
        subproject: 'SP07 Prototyping & Testing'
      }
    ]
  },
  {
    workpackageId: 263,
    workpackage: 'Dyno-Veh',
    effortType: 'requirement',
    parentId: 307,
    organizations: [
      {
        organizationId: 171,
        orgResReqExp: 'TR-020-Testrigs (RAM)-Dyno-Veh',
        orgResKey: '1278023',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 6,
            qvLocation: 'RAM'
          }
        ]
      },
      {
        organizationId: 203,
        orgResReqExp: 'TR-020-Testrigs (UBA)-Dyno-Veh',
        orgResKey: '1277981',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          }
        ]
      },
      {
        organizationId: 228,
        orgResReqExp: 'TR-020-Testrigs ES 2807 (VAN)-Dyno-Veh',
        orgResKey: '585875',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 8,
        subproject: 'SP07 Prototyping & Testing'
      },
      {
        subprojectId: 10,
        subproject: 'SP0X Testrigs'
      }
    ]
  },
  {
    workpackageId: 264,
    workpackage: 'Dyno-Veh-MHA',
    effortType: 'requirement',
    parentId: 307,
    organizations: [
      {
        organizationId: 223,
        orgResReqExp: 'TR-020-Testrigs ES 2805 (VAN)-Dyno-Veh-MHA',
        orgResKey: '779045',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 10,
        subproject: 'SP0X Testrigs'
      }
    ]
  },
  {
    workpackageId: 265,
    workpackage: 'Dyno-Veh-MHF',
    effortType: 'requirement',
    parentId: 307,
    organizations: [
      {
        organizationId: 224,
        orgResReqExp: 'TR-020-Testrigs ES 2805 (VAN)-Dyno-Veh-MHF',
        orgResKey: '779044',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 10,
        subproject: 'SP0X Testrigs'
      }
    ]
  },
  {
    workpackageId: 266,
    workpackage: 'Dyno-Veh-CVS',
    effortType: 'requirement',
    parentId: 307,
    organizations: [
      {
        organizationId: 225,
        orgResReqExp: 'TR-020-Testrigs ES 2805 (VAN)-Dyno-Veh-CVS',
        orgResKey: '779042',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 10,
        subproject: 'SP0X Testrigs'
      }
    ]
  },
  {
    workpackageId: 267,
    workpackage: 'Dyno-Veh-KON',
    effortType: 'requirement',
    parentId: 307,
    organizations: [
      {
        organizationId: 226,
        orgResReqExp: 'TR-020-Testrigs ES 2805 (VAN)-Dyno-Veh-KON',
        orgResKey: '779043',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 10,
        subproject: 'SP0X Testrigs'
      }
    ]
  },
  {
    workpackageId: 268,
    workpackage: 'Dyno-Veh-PULSE',
    effortType: 'requirement',
    parentId: 307,
    organizations: [
      {
        organizationId: 227,
        orgResReqExp: 'TR-020-Testrigs ES 2807 (VAN)-Dyno-Veh-PULSE',
        orgResKey: '1653961',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 10,
        subproject: 'SP0X Testrigs'
      }
    ]
  },
  {
    workpackageId: 269,
    workpackage: 'Dyno-B2B',
    effortType: 'requirement',
    parentId: 307,
    organizations: [
      {
        organizationId: 192,
        orgResReqExp: 'TR-020-Testrigs (UBA)-Dyno-B2B',
        orgResKey: '1277980',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 10,
        subproject: 'SP0X Testrigs'
      }
    ]
  },
  {
    workpackageId: 270,
    workpackage: 'Dyno-B2B-HV',
    effortType: 'requirement',
    parentId: 307,
    organizations: [
      {
        organizationId: 210,
        orgResReqExp: 'TR-020-Testrigs (LAN)-Dyno-B2B-HV',
        orgResKey: '959048',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 10,
        subproject: 'SP0X Testrigs'
      }
    ]
  },
  {
    workpackageId: 271,
    workpackage: 'Dyno-B2B-CON-HV',
    effortType: 'requirement',
    parentId: 307,
    organizations: [
      {
        organizationId: 240,
        orgResReqExp: 'TR-020-Testrigs ECV 4236 (VAN)-Dyno-B2B-CON-HV',
        orgResKey: '1638600',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 10,
        subproject: 'SP0X Testrigs'
      }
    ]
  },
  {
    workpackageId: 272,
    workpackage: 'Dyno-B2B-CON-48V',
    effortType: 'requirement',
    parentId: 307,
    organizations: [
      {
        organizationId: 249,
        orgResReqExp: 'TR-020-Testrigs ECV 4236 (VAN)-Dyno-B2B-CON-48V',
        orgResKey: '1638729',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 10,
        subproject: 'SP0X Testrigs'
      }
    ]
  },
  {
    workpackageId: 273,
    workpackage: 'Dyno-B2B-EOL-48V',
    effortType: 'requirement',
    parentId: 307,
    organizations: [
      {
        organizationId: 253,
        orgResReqExp: 'TR-020-Testrigs ECV 4236 (VAN)-Dyno-B2B-EOL-48V',
        orgResKey: '1638728',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 10,
        subproject: 'SP0X Testrigs'
      }
    ]
  },
  {
    workpackageId: 274,
    workpackage: 'Dyno-ENV-CON',
    effortType: 'requirement',
    parentId: 307,
    organizations: [
      {
        organizationId: 259,
        orgResReqExp: 'TR-020-Testrigs ECV 4239 (VAN)-Dyno-INV-EME-48V',
        orgResKey: '855247',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 10,
        subproject: 'SP0X Testrigs'
      }
    ]
  },
  {
    workpackageId: 275,
    workpackage: 'Dyno-ENV',
    effortType: 'requirement',
    parentId: 307,
    organizations: [
      {
        organizationId: 193,
        orgResReqExp: 'TR-020-Testrigs (UBA)-Dyno-ENV',
        orgResKey: '1277978',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          }
        ]
      },
      {
        organizationId: 230,
        orgResReqExp: 'TR-020-Testrigs ES 2817 (VAN)-Dyno-ENV',
        orgResKey: '861111',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          }
        ]
      },
      {
        organizationId: 266,
        orgResReqExp: 'TR-020-Testrigs ECV 4239 (VAN)-Dyno-ENV',
        orgResKey: '855245',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 10,
        subproject: 'SP0X Testrigs'
      }
    ]
  },
  {
    workpackageId: 276,
    workpackage: 'Dyno-ENV-CON',
    effortType: 'requirement',
    parentId: 307,
    organizations: [
      {
        organizationId: 166,
        orgResReqExp: 'TR-020-Testrigs (RAM)-Dyno-ENV-CON',
        orgResKey: '916182',
        regions: [
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          }
        ],
        locations: [
          {
            qvLocationId: 6,
            qvLocation: 'RAM'
          }
        ]
      },
      {
        organizationId: 211,
        orgResReqExp: 'TR-020-Testrigs (LAN)-Dyno-ENV-CON',
        orgResKey: '1074404',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          }
        ]
      },
      {
        organizationId: 222,
        orgResReqExp: 'TR-020-Testrigs (TRA)-Dyno-ENV-CON',
        orgResKey: '1278007',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          }
        ]
      },
      {
        organizationId: 237,
        orgResReqExp: 'TR-020-Testrigs ES 2812 (VAN)-Dyno-ENV-CON',
        orgResKey: '1806902',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          }
        ]
      },
      {
        organizationId: 257,
        orgResReqExp: 'TR-020-Testrigs ECV 4239 (VAN)-Dyno-ENV-CON',
        orgResKey: '855246',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 8,
        subproject: 'SP07 Prototyping & Testing'
      },
      {
        subprojectId: 10,
        subproject: 'SP0X Testrigs'
      }
    ]
  },
  {
    workpackageId: 277,
    workpackage: 'Dyno-ENV-HV',
    effortType: 'requirement',
    parentId: 307,
    organizations: [
      {
        organizationId: 267,
        orgResReqExp: 'TR-020-Testrigs ECV 4239 (VAN)-Dyno-ENV-HV',
        orgResKey: '855173',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 10,
        subproject: 'SP0X Testrigs'
      }
    ]
  },
  {
    workpackageId: 278,
    workpackage: 'Dyno-ENV-PULSE',
    effortType: 'requirement',
    parentId: 307,
    organizations: [
      {
        organizationId: 265,
        orgResReqExp: 'TR-020-Testrigs ECV 4239 (VAN)-Dyno-ENV-PULSE',
        orgResKey: '1140678',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 10,
        subproject: 'SP0X Testrigs'
      }
    ]
  },
  {
    workpackageId: 279,
    workpackage: 'Dyno-INV-EME-HV',
    effortType: 'requirement',
    parentId: 307,
    organizations: [
      {
        organizationId: 221,
        orgResReqExp: 'TR-020-Testrigs (TRA)-Dyno-INV-EME-HV',
        orgResKey: '635590',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          }
        ]
      },
      {
        organizationId: 258,
        orgResReqExp: 'TR-020-Testrigs ECV 4239 (VAN)-Dyno-INV-EME-HV',
        orgResKey: '1278017',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          }
        ]
      },
      {
        organizationId: 221,
        orgResReqExp: 'TR-020-Testrigs (TRA)-Dyno-INV-EME-HV',
        orgResKey: '635590',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          }
        ]
      },
      {
        organizationId: 258,
        orgResReqExp: 'TR-020-Testrigs ECV 4239 (VAN)-Dyno-INV-EME-HV',
        orgResKey: '1278017',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 8,
        subproject: 'SP07 Prototyping & Testing'
      },
      {
        subprojectId: 10,
        subproject: 'SP0X Testrigs'
      }
    ]
  },
  {
    workpackageId: 280,
    workpackage: 'Dyno-Puls',
    effortType: 'requirement',
    parentId: 307,
    organizations: [
      {
        organizationId: 261,
        orgResReqExp: 'TR-020-Testrigs ECV 4239 (VAN)-Dyno-INV-EOL-HV',
        orgResKey: '855244',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          }
        ]
      },
      {
        organizationId: 229,
        orgResReqExp: 'TR-020-Testrigs ES 2817 (VAN)-Dyno-Puls',
        orgResKey: '585876',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 10,
        subproject: 'SP0X Testrigs'
      }
    ]
  },
  {
    workpackageId: 281,
    workpackage: 'Dyno-INV-EOL-48V',
    effortType: 'requirement',
    parentId: 307,
    organizations: [
      {
        organizationId: 262,
        orgResReqExp: 'TR-020-Testrigs ECV 4239 (VAN)-Dyno-INV-EOL-48V',
        orgResKey: '855250',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 10,
        subproject: 'SP0X Testrigs'
      }
    ]
  },
  {
    workpackageId: 282,
    workpackage: 'Dyno-INV-CON-HV',
    effortType: 'requirement',
    parentId: 307,
    organizations: [
      {
        organizationId: 263,
        orgResReqExp: 'TR-020-Testrigs ECV 4239 (VAN)-Dyno-INV-CON-HV',
        orgResKey: '1278013',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 10,
        subproject: 'SP0X Testrigs'
      }
    ]
  },
  {
    workpackageId: 283,
    workpackage: 'Dyno-INV-HV',
    effortType: 'requirement',
    parentId: 307,
    organizations: [
      {
        organizationId: 264,
        orgResReqExp: 'TR-020-Testrigs ECV 4239 (VAN)-Dyno-INV-HV',
        orgResKey: '855249',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 10,
        subproject: 'SP0X Testrigs'
      }
    ]
  },
  {
    workpackageId: 284,
    workpackage: 'Dyno-BS-HV1000',
    effortType: 'requirement',
    parentId: 307,
    organizations: [
      {
        organizationId: 174,
        orgResReqExp: 'TR-020-Testrigs (CZ)-Dyno-BS-HV1000',
        orgResKey: '635593',
        regions: [
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 8,
        subproject: 'SP07 Prototyping & Testing'
      }
    ]
  },
  {
    workpackageId: 285,
    workpackage: 'Dyno-Comp',
    effortType: 'requirement',
    parentId: 307,
    organizations: [
      {
        organizationId: 231,
        orgResReqExp: 'TR-020-Testrigs ES 2817 (VAN)-Dyno-Comp',
        orgResKey: '861112',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 10,
        subproject: 'SP0X Testrigs'
      }
    ]
  },
  {
    workpackageId: 286,
    workpackage: 'Dyno-ENG-PT-Dura',
    effortType: 'requirement',
    parentId: 307,
    organizations: [
      {
        organizationId: 232,
        orgResReqExp: 'TR-020-Testrigs ES 2812 (VAN)-Dyno-ENG-PT-Dura',
        orgResKey: '585877',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          }
        ]
      },
      {
        organizationId: 238,
        orgResReqExp: 'TR-020-Testrigs ES 2813 (VAN)-Dyno-ENG-PT-Dura',
        orgResKey: '585878',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 10,
        subproject: 'SP0X Testrigs'
      }
    ]
  },
  {
    workpackageId: 287,
    workpackage: 'Dyno-ENG-PT-Func',
    effortType: 'requirement',
    parentId: 307,
    organizations: [
      {
        organizationId: 233,
        orgResReqExp: 'TR-020-Testrigs ES 2812 (VAN)-Dyno-ENG-PT-Func',
        orgResKey: '779050',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 10,
        subproject: 'SP0X Testrigs'
      }
    ]
  },
  {
    workpackageId: 288,
    workpackage: 'Dyno-EDR',
    effortType: 'requirement',
    parentId: 307,
    organizations: [
      {
        organizationId: 234,
        orgResReqExp: 'TR-020-Testrigs ES 2812 (VAN)-Dyno-EDR',
        orgResKey: '857707',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 10,
        subproject: 'SP0X Testrigs'
      }
    ]
  },
  {
    workpackageId: 289,
    workpackage: 'Dyno-KON',
    effortType: 'requirement',
    parentId: 307,
    organizations: [
      {
        organizationId: 235,
        orgResReqExp: 'TR-020-Testrigs ES 2812 (VAN)-Dyno-KON',
        orgResKey: '857803',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 10,
        subproject: 'SP0X Testrigs'
      }
    ]
  },
  {
    workpackageId: 290,
    workpackage: 'Dyno-TAS',
    effortType: 'requirement',
    parentId: 307,
    organizations: [
      {
        organizationId: 236,
        orgResReqExp: 'TR-020-Testrigs ES 2812 (VAN)-Dyno-TAS',
        orgResKey: '857806',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 10,
        subproject: 'SP0X Testrigs'
      }
    ]
  },
  {
    workpackageId: 291,
    workpackage: 'Dyno-EMC-HV',
    effortType: 'requirement',
    parentId: 307,
    organizations: [
      {
        organizationId: 268,
        orgResReqExp: 'TR-020-Testrigs ECV 4239 (VAN)-Dyno-EMC-HV',
        orgResKey: '855240',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          }
        ],
        locations: [
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 10,
        subproject: 'SP0X Testrigs'
      }
    ]
  },
  {
    workpackageId: 299
  },
  {
    workpackageId: 300,
    workpackage: 'Travel',
    sortOrder: 76,
    effortType: 'expenditure',
    organizations: [
      {
        organizationId: 269,
        orgResReqExp: 'Travel',
        orgResKey: '12775',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          },
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          },
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          },
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          },
          {
            qvLocationId: 6,
            qvLocation: 'RAM'
          },
          {
            qvLocationId: 7,
            qvLocation: 'CON'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 11,
        subproject: 'SP0X Expenses'
      }
    ]
  },
  {
    workpackageId: 301,
    workpackage: 'Material',
    sortOrder: 77,
    effortType: 'expenditure',
    organizations: [
      {
        organizationId: 270,
        orgResReqExp: 'Material',
        orgResKey: '2864',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          },
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          },
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          },
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          },
          {
            qvLocationId: 6,
            qvLocation: 'RAM'
          },
          {
            qvLocationId: 7,
            qvLocation: 'CON'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 11,
        subproject: 'SP0X Expenses'
      }
    ]
  },
  {
    workpackageId: 302,
    workpackage: 'Risk',
    sortOrder: 78,
    effortType: 'expenditure',
    organizations: [
      {
        organizationId: 271,
        orgResReqExp: 'Risk',
        orgResKey: '12776',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          },
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          },
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          },
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          },
          {
            qvLocationId: 6,
            qvLocation: 'RAM'
          },
          {
            qvLocationId: 7,
            qvLocation: 'CON'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 11,
        subproject: 'SP0X Expenses'
      }
    ]
  },
  {
    workpackageId: 303,
    workpackage: 'Purchased Services - Component Dev',
    sortOrder: 79,
    effortType: 'expenditure',
    organizations: [
      {
        organizationId: 272,
        orgResReqExp: 'Purchased ServicesÊ- Component Dev',
        orgResKey: '3464',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          },
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          },
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          },
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          },
          {
            qvLocationId: 6,
            qvLocation: 'RAM'
          },
          {
            qvLocationId: 7,
            qvLocation: 'CON'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 11,
        subproject: 'SP0X Expenses'
      }
    ]
  },
  {
    workpackageId: 304,
    workpackage: 'Purchased Services - External Testing',
    sortOrder: 80,
    effortType: 'expenditure',
    organizations: [
      {
        organizationId: 273,
        orgResReqExp: 'Purchased ServicesÊ- External Testing',
        orgResKey: '1521540',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          },
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          },
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          },
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          },
          {
            qvLocationId: 6,
            qvLocation: 'RAM'
          },
          {
            qvLocationId: 7,
            qvLocation: 'CON'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 11,
        subproject: 'SP0X Expenses'
      }
    ]
  },
  {
    workpackageId: 305,
    workpackage: 'Purchased Services - External Workforce',
    sortOrder: 81,
    effortType: 'expenditure',
    organizations: [
      {
        organizationId: 274,
        orgResReqExp: 'Purchased ServicesÊ- External Workforce',
        orgResKey: '1521541',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          },
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          },
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          },
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          },
          {
            qvLocationId: 6,
            qvLocation: 'RAM'
          },
          {
            qvLocationId: 7,
            qvLocation: 'CON'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 11,
        subproject: 'SP0X Expenses'
      }
    ]
  },
  {
    workpackageId: 306,
    workpackage: 'IC Purchased Services',
    sortOrder: 82,
    effortType: 'expenditure',
    organizations: [
      {
        organizationId: 275,
        orgResReqExp: 'IC Purchased Services',
        orgResKey: '591542',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          },
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          },
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          },
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          },
          {
            qvLocationId: 6,
            qvLocation: 'RAM'
          },
          {
            qvLocationId: 7,
            qvLocation: 'CON'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 11,
        subproject: 'SP0X Expenses'
      }
    ]
  },
  {
    workpackageId: 307,
    workpackage: 'Testrigs Inhouse',
    sortOrder: 75,
    effortType: 'expenditure',
    organizations: [
      {
        organizationId: 276,
        orgResReqExp: 'Testrigs Inhouse',
        orgResKey: '78368',
        regions: [
          {
            qvRegionId: 1,
            qvRegion: 'EU'
          },
          {
            qvRegionId: 2,
            qvRegion: 'NA'
          },
          {
            qvRegionId: 3,
            qvRegion: 'CN'
          },
          {
            qvRegionId: 4,
            qvRegion: 'GE'
          }
        ],
        locations: [
          {
            qvLocationId: 1,
            qvLocation: 'UBA'
          },
          {
            qvLocationId: 2,
            qvLocation: 'LAN'
          },
          {
            qvLocationId: 3,
            qvLocation: 'TRA'
          },
          {
            qvLocationId: 4,
            qvLocation: 'VAN'
          },
          {
            qvLocationId: 5,
            qvLocation: 'TRY'
          },
          {
            qvLocationId: 6,
            qvLocation: 'RAM'
          },
          {
            qvLocationId: 7,
            qvLocation: 'CON'
          },
          {
            qvLocationId: 8,
            qvLocation: 'BAN'
          }
        ]
      }
    ],
    subproject: [
      {
        subprojectId: 10,
        subproject: 'SP0X Testrigs'
      }
    ]
  }
];
