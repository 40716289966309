import React, { useState, useEffect } from 'react';
import {
  Table,
  Button,
  Box,
  TableBody,
  TableCell,
  TableContainer,
  TextField,
  MenuItem,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tabs,
  Typography,
  Tab
} from '@mui/material';
import axios from 'axios';
import msalInstance from '../../msalInstance.js';
import { loginRequest } from '../../authConfig.js';
import ProgressSpinner from '../../Components/ProgressSpinner';
import BackToDashboard from '../../Components/BackToDashboard.jsx';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import useHasRole from '../../hooks/useHasRole.js';
import AlertMessage from '../../Components/AlertMessage.jsx';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

const EQuoteApprovalPage = () => {
  const [eQuotes, setEQuotes] = useState([]);
  const [projects, setProjects] = useState([]);
  const [users, setUsers] = useState([]);
  const [leads, setLeads] = useState([]);
  const [loading, setLoading] = useState(true);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [openDialog, setOpenDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [currentAction, setCurrentAction] = useState(null);
  const [selectedQuoteID, setSelectedQuoteID] = useState(null);
  const [rejectionReason, setRejectionReason] = useState('');
  const [tabValue, setTabValue] = useState(0);
  const [errors, setErrors] = useState({});
  const [editValues, setEditValues] = useState({
    eQuoteStatus: '',
    projectID: '',
    eQuoteCharacter: '',
    eQuoteCustomerRequestDate: '',
    quoteFinishTargetDate: '',
    eQuoteShortName: '',
    eQuoteDueDate: '',
    eQuoteKickoffDate: '',
    eQuoteLead: '',
    eQuoteSecondLead: '',
    eQuoteNumber: '',
    requestType: ''
  });

  const isAllowedRoles = useHasRole(['QVD.Admin', 'eQuote.Manager']);

  const apiUrl = process.env.REACT_APP_API_URL;

  const getToken = async () => {
    try {
      const tokenResponse = await msalInstance.acquireTokenSilent(loginRequest);
      const accessToken = tokenResponse.idToken;
      return accessToken;
    } catch (error) {
      console.log(error);
    }
  };

  const fetchEQuotes = async () => {
    try {
      if (currentAction !== 'edit') {
        setEQuotes([]);
      }
      setLoading(true);
      const token = await getToken();
      const approvalStatus = tabValue === 0 ? 'Pending' : 'Rejected';
      const eQuotesResponse = await axios.get(
        `${apiUrl}/api/equotes/approvalStatus?eQuoteNumberApproval=${approvalStatus}`,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      if (Array.isArray(eQuotesResponse.data) && eQuotesResponse.data.length === 0) {
        setAlertSeverity('info');
        setAlertMessage(`No ${approvalStatus === 'Pending' ? 'Pending' : 'Rejected'} eQuotes`);
        setAlertOpen(true);
      } else {
        setEQuotes(eQuotesResponse.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);

      const errorMessage =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : 'An error occurred while fetching eQuotes.';

      setAlertSeverity('error');
      setAlertMessage(errorMessage);
      setAlertOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const fetchProjectsAndUsers = async () => {
    try {
      const token = await getToken();
      const [projectsResponse, usersResponse] = await Promise.all([
        axios.get(`${apiUrl}/api/projects`, { headers: { Authorization: `Bearer ${token}` } }),
        axios.get(`${apiUrl}/api/user/all`, { headers: { Authorization: `Bearer ${token}` } })
      ]);

      const leads = usersResponse.data.filter((user) => user.qvdRole === 'eQuote.ProjectLead');

      setProjects(projectsResponse.data);
      setUsers(usersResponse.data);
      setLeads(leads);
    } catch (error) {
      console.error('Error fetching projects/users:', error);
    }
  };

  useEffect(() => {
    fetchProjectsAndUsers();
    fetchEQuotes();
  }, [tabValue]);

  const mapProjectName = (projectID) => {
    const project = projects.find((proj) => proj.projectID === projectID);
    return project ? project.projectName : 'N/A';
  };

  const mapCreatorName = (creatorID) => {
    const user = users.find((usr) => usr.userID === creatorID);
    return user ? user.username : 'N/A';
  };

  const getEmailById = (eQuoteLead) => {
    const user = users.find((usr) => usr.userID === eQuoteLead);
    return user ? user.email : 'Email not available';
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';

    const date = new Date(dateString);

    if (isNaN(date.getTime())) return 'N/A';

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  };

  const handleApprove = (quoteID) => {
    setCurrentAction('approve');
    setSelectedQuoteID(quoteID);
    setOpenDialog(true);
  };

  const handleReject = (quoteID) => {
    setCurrentAction('reject');
    setSelectedQuoteID(quoteID);
    setOpenDialog(true);
  };

  const confirmAction = async () => {
    setLoading(true);
    setOpenDialog(false);
    try {
      const token = await getToken();
      const payload = {
        eQuoteNumberApproval: currentAction === 'approve' ? 'Approved' : 'Rejected'
      };

      if (currentAction === 'reject') {
        payload.eQuoteNumberRejectionReason = rejectionReason;
      }

      const response = await axios.patch(
        `${apiUrl}/api/equotes/requested/${selectedQuoteID}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (response.status === 200) {
        setAlertSeverity('success');
        setAlertMessage(`eQuote ${currentAction} successful.`);

        try {
          const mailResponse = await sendAdminMail(selectedQuoteID, currentAction, rejectionReason);

          if (mailResponse.ok || mailResponse.status === 200) {
            setAlertMessage(`eQuote ${currentAction} successful, and notification email sent.`);
          } else {
            setAlertSeverity('warning');
            setAlertMessage(
              `eQuote ${currentAction} successful, but failed to send notification email.`
            );
          }
        } catch (mailError) {
          console.error('Error sending email:', mailError);
          setAlertSeverity('warning');
          setAlertMessage(
            `eQuote ${currentAction} successful, but failed to send notification email.`
          );
        }

        setAlertOpen(true);
      }
      await fetchEQuotes();
      setSelectedQuoteID(null);
      setRejectionReason('');
    } catch (error) {
      console.error('Error updating eQuote:', error);
      const errorMessage =
        error.response?.data?.message || 'An error occurred during the API call.';
      setAlertSeverity('error');
      setAlertMessage(`Failed to ${currentAction} eQuote: ${errorMessage}`);
      setAlertOpen(true);
    } finally {
      setLoading(false);
      setCurrentAction(null);
    }
  };

  const handleEdit = (quoteID) => {
    const eQuote = eQuotes.find((quote) => quote.quoteID === quoteID);
    if (eQuote) {
      setEditValues({
        eQuoteStatus: eQuote.eQuoteStatus || '',
        projectID: eQuote.projectID || '',
        eQuoteCharacter: eQuote.eQuoteCharacter || '',
        eQuoteCustomerRequestDate: eQuote.eQuoteCustomerRequestDate || '',
        quoteFinishTargetDate: eQuote.quoteFinishTargetDate || '',
        eQuoteDescription: eQuote.eQuoteDescription || '',
        eQuoteShortName: eQuote.eQuoteShortName || '',
        eQuoteDueDate: eQuote.eQuoteDueDate || '',
        eQuoteKickoffDate: eQuote.eQuoteKickoffDate || '',
        eQuoteLead: eQuote.eQuoteLead || '',
        eQuoteSecondLead: eQuote.secondLead || '',
        eQuoteNumber: eQuote.eQuoteNumber,
        requestType: eQuote.requestType || null
      });
      setSelectedQuoteID(quoteID);
      setCurrentAction('edit');
      setOpenEditDialog(true);
    }
  };

  const handleValidation = () => {
    const newErrors = {};

    if (!editValues.eQuoteStatus) newErrors.eQuoteStatus = 'Status is required';
    if (!editValues.projectID) newErrors.projectID = 'Project Name is required';
    if (!editValues.eQuoteLead) newErrors.eQuoteLead = 'eQuote Lead is required';
    if (!editValues.eQuoteCharacter) newErrors.eQuoteCharacter = 'Character is required';
    if (!editValues.eQuoteShortName) newErrors.eQuoteShortName = 'Short Name is required';
    if (!editValues.eQuoteCustomerRequestDate)
      newErrors.eQuoteCustomerRequestDate = 'Customer Request Date is required';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const confirmEdit = async () => {
    if (!handleValidation()) {
      return;
    }

    setLoading(true);
    setOpenEditDialog(false);
    try {
      const token = await getToken();
      const patchPayload = {
        eQuoteStatus: editValues.eQuoteStatus,
        projectID: editValues.projectID,
        eQuoteCharacter: editValues.eQuoteCharacter,
        eQuoteCustomerRequestDate: editValues.eQuoteCustomerRequestDate,
        quoteFinishTargetDate: editValues.quoteFinishTargetDate || null,
        eQuoteShortName: editValues.eQuoteShortName || null,
        eQuoteDueDate: editValues.eQuoteDueDate || null,
        eQuoteKickoffDate: editValues.eQuoteKickoffDate || null,
        eQuoteLead: editValues.eQuoteLead,
        secondLead: editValues.eQuoteSecondLead === '' ? null : editValues.eQuoteSecondLead,
        requestType: editValues.requestType === '' ? null : editValues.requestType
      };
      await axios.patch(`${apiUrl}/api/equotes/${selectedQuoteID}`, patchPayload, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      await fetchEQuotes();
      setAlertSeverity('success');
      setAlertMessage('Edit Successful');
      setAlertOpen(true);
    } catch (error) {
      console.error('Error updating eQuote:', error);
      const errorMessage =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : 'An error occurred during the API call.';
      setAlertSeverity('error');
      setAlertMessage(errorMessage);
      setAlertOpen(true);
    } finally {
      setLoading(false);
      setCurrentAction(null);
      setSelectedQuoteID(null);
      setEditValues({
        eQuoteStatus: '',
        projectID: '',
        eQuoteCharacter: '',
        eQuoteCustomerRequestDate: '',
        quoteFinishTargetDate: '',
        eQuoteDueDate: '',
        eQuoteLead: '',
        eQuoteSecondLead: '',
        eQuoteNumber: '',
        requestType: ''
      });
    }
  };

  const getAccessToken = async () => {
    try {
      const tokenResponse = await msalInstance.acquireTokenSilent(loginRequest);
      const accessToken = tokenResponse.accessToken;
      return accessToken;
    } catch (error) {
      console.log(error);
    }
  };

  const sendMail = async (quoteID) => {
    try {
      setLoading(true);

      const quoteDetails = eQuotes.find((quote) => quote.quoteID === quoteID);

      const accessToken = await getAccessToken();

      const regionEmails = {
        EU: process.env.REACT_APP_ADMIN_MAIL_EU || 'ijaz.m@partner.magna.com',
        CN: process.env.REACT_APP_ADMIN_MAIL_CN || 'ijaz.m@partner.magna.com',
        GL: process.env.REACT_APP_ADMIN_MAIL_GL || 'ijaz.m@partner.magna.com',
        NA: process.env.REACT_APP_ADMIN_MAIL_NA || 'ijaz.m@partner.magna.com'
      };

      const recipientEmail = (regionEmails[quoteDetails.region] || 'ijaz.m@partner.magna.com')
        .split(',')
        .map((email) => ({ emailAddress: { address: email.trim() } }));
      const defaultBccEmail = process.env.REACT_APP_ADMIN_EMAIL_DEF || 'ijaz.m@partner.magna.com';

      const webUrl = `${window.location.origin}/equote-number-approval`;

      const response = await fetch('https://graph.microsoft.com/v1.0/me/sendMail', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          message: {
            subject: `[QuoVaDis] eQuote Number Approval Request ${quoteDetails.eQuoteNumber}`,
            body: {
              contentType: 'HTML',
              content: `
                <p>Hello,</p>
                <p>New request for eQuote Number approval:</p>
                <ul>
                  <li><strong>eQuote Number:<strong> ${quoteDetails.eQuoteNumber}</li>
                  <li><strong>Region:<strong> ${quoteDetails.region}</li>
                  <li><strong>eQuote Short Name:<strong> ${quoteDetails.eQuoteShortName}</li>
                </ul>
                <p>Please click the button below to approve, edit, or reject the request:</p>
                <a href="${webUrl}" style="
                  display: inline-block;
                  padding: 10px 20px;
                  margin-top: 10px;
                  color: #fff;
                  background-color: #800000;
                  text-decoration: none;
                  border-radius: 5px;
                  font-weight: bold;
                  ">VIEW REQUEST</a>
              `
            },
            toRecipients: recipientEmail,
            bccRecipients: [
              {
                emailAddress: {
                  address: defaultBccEmail
                }
              }
            ]
          }
        })
      });

      if (response.ok) {
        console.log('Mail sent successfully');
        setAlertSeverity('success');
        setAlertMessage('Notified QVD Admin through mail successfully!');
        setAlertOpen(true);
      } else {
        console.error('Failed to send mail', response);
        setAlertSeverity('error');
        setAlertMessage('Failed to notify Admin. Please try again.');
        setAlertOpen(true);
      }
    } catch (error) {
      console.error('Error sending mail:', error);
      setAlertSeverity('error');
      setAlertMessage('Error notifying Admin. Please contact Admin manually.');
      setAlertOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const sendAdminMail = async (quoteID, currentAction, rejectionReason) => {
    try {
      const quoteDetails = eQuotes.find((quote) => quote.quoteID === quoteID);
      if (!quoteDetails) {
        console.error('Quote details not found.');
        return { ok: false, status: 404, statusText: 'Quote details not found' };
      }

      const accessToken = await getAccessToken();
      if (!accessToken) {
        console.error('Access token is unavailable.');
        return { ok: false, status: 401, statusText: 'Access token is unavailable' };
      }

      const emailAddress = getEmailById(quoteDetails.eQuoteLead);
      if (!emailAddress) {
        console.error('Email address for eQuoteLead not found.');
        return { ok: false, status: 404, statusText: 'Email address for eQuoteLead not found' };
      }

      let emailContent;
      const buttonStyle = `
        display: inline-block;
        padding: 10px 20px;
        margin-top: 10px;
        color: #fff;
        background-color: #800000;
        text-decoration: none;
        border-radius: 5px;
        font-weight: bold;
      `;

      if (currentAction === 'approve') {
        const cockpitUrl = `${window.location.origin}`;
        emailContent = `
          <p>eQuote Number Approved: The eQuote Number is ${quoteDetails.eQuoteNumber}. You can view it in the eQuote Cockpit:</p>
          <a href="${cockpitUrl}" style="${buttonStyle}">View in eQuote Cockpit</a>
        `;
      } else if (currentAction === 'reject') {
        const rejectionUrl = `${window.location.origin}/equote-number-approval`;
        emailContent = `
          <p>Your request for an eQuote Number has been rejected.</p>
          <p><strong>Reason:</strong> ${rejectionReason}</p>
          <a href="${rejectionUrl}" style="${buttonStyle}">View in QuoVaDis</a>
        `;
      } else {
        const statusUrl = `${window.location.origin}/equote-number-approval`;
        emailContent = `
          <p>Check the status of your request for an eQuote Number on the QuoVaDis Approvals page:</p>
          <a href="${statusUrl}" style="${buttonStyle}">View Status</a>
        `;
      }

      const response = await fetch('https://graph.microsoft.com/v1.0/me/sendMail', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          message: {
            subject: `[QuoVaDis] eQuote Number ${currentAction.charAt(0).toUpperCase() + currentAction.slice(1)} Notification`,
            body: {
              contentType: 'HTML',
              content: emailContent
            },
            toRecipients: [{ emailAddress: { address: emailAddress } }]
          }
        })
      });

      if (!response.ok) {
        console.error(`Failed to send email: ${response.statusText}`);
      }
      return response;
    } catch (error) {
      console.error('Error in sendAdminMail:', error);
      return { ok: false, status: 500, statusText: 'Internal error sending email' };
    }
  };

  return (
    <>
      {loading && <ProgressSpinner />}
      <AlertMessage
        open={alertOpen}
        message={alertMessage}
        severity={alertSeverity}
        onClose={() => setAlertOpen(false)}
      />
      <Box>
        <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
          <Box>
            <BackToDashboard />
          </Box>
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              overflowX: 'auto',
              padding: '20px'
            }}
          >
            <Tabs
              sx={{
                '& .MuiTab-root.Mui-selected': {
                  fontWeight: 700
                }
              }}
              value={tabValue}
              onChange={(event, newValue) => setTabValue(newValue)}
            >
              <Tab label="Pending" />
              <Tab label="Rejected" />
            </Tabs>

            <TableContainer
              sx={{
                maxWidth: '100%',
                '&::-webkit-scrollbar': {
                  height: '6px'
                },
                '&::-webkit-scrollbar-track': {
                  background: '#f1f1f1'
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#c2c1c1',
                  borderRadius: '10px'
                },
                '&::-webkit-scrollbar-thumb:hover': {
                  background: '#555'
                }
              }}
              component={Paper}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {isAllowedRoles && (
                      <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                        eQuote Number
                      </TableCell>
                    )}
                    {tabValue === 1 && (
                      <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                        Rejection Reason
                      </TableCell>
                    )}
                    {tabValue === 0 && (
                      <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                        Actions
                      </TableCell>
                    )}
                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                      Project Name
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                      Creator
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                      eQuote Short Name
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                      Region
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                      Status
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                      Type
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                      eQuote Lead
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                      eQuote 2nd Lead
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                      Version
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                      Creation Date
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                      Customer Request Date
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                      Request Type
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                      Quote Character
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                      eQuote Finished Target Date
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                      eQuote Description
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                      eQuote Kickoff Date
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                      eQuote Due Date
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {eQuotes.map((eQuote) => (
                    <TableRow key={eQuote.quoteID}>
                      {isAllowedRoles && <TableCell>{eQuote.eQuoteNumber || 'N/A'}</TableCell>}
                      {tabValue === 1 && (
                        <TableCell>{eQuote.eQuoteNumberRejectionReason || 'N/A'}</TableCell>
                      )}
                      {tabValue === 0 && (
                        <TableCell>
                          {isAllowedRoles ? (
                            <Box display="flex" gap={2}>
                              <Tooltip title="Edit">
                                <IconButton
                                  onClick={() => handleEdit(eQuote.quoteID)}
                                  sx={{ color: '#800000' }}
                                >
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Approve">
                                <IconButton
                                  onClick={() => handleApprove(eQuote.quoteID)}
                                  sx={{ color: 'green' }}
                                >
                                  <CheckIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Reject">
                                <IconButton
                                  onClick={() => handleReject(eQuote.quoteID)}
                                  sx={{ color: 'red' }}
                                >
                                  <CloseIcon />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          ) : (
                            <Box display="flex" gap={2}>
                              <Tooltip title="Notify Admin">
                                <IconButton
                                  onClick={() => sendMail(eQuote.quoteID)}
                                  sx={{
                                    color: 'primary.main'
                                  }}
                                >
                                  <MailOutlineIcon />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          )}
                        </TableCell>
                      )}
                      <TableCell>{mapProjectName(eQuote.projectID)}</TableCell>
                      <TableCell>{mapCreatorName(eQuote.creatorID)}</TableCell>
                      <TableCell>{eQuote.eQuoteShortName || 'N/A'}</TableCell>
                      <TableCell>{eQuote.region || 'N/A'}</TableCell>
                      <TableCell>{eQuote.eQuoteStatus || 'N/A'}</TableCell>
                      <TableCell>{eQuote.eQuoteType || 'N/A'}</TableCell>
                      <TableCell>{mapCreatorName(eQuote.eQuoteLead) || 'N/A'}</TableCell>
                      <TableCell>{mapCreatorName(eQuote.secondLead) || 'N/A'}</TableCell>
                      <TableCell>{eQuote.eQuoteVersion || 'N/A'}</TableCell>
                      <TableCell>{formatDate(eQuote.creation_date) || 'N/A'}</TableCell>
                      <TableCell>{formatDate(eQuote.eQuoteCustomerRequestDate) || 'N/A'}</TableCell>
                      <TableCell>{eQuote.requestType || 'N/A'}</TableCell>
                      <TableCell>{eQuote.eQuoteCharacter || 'N/A'}</TableCell>
                      <TableCell>{formatDate(eQuote.quoteFinishTargetDate) || 'N/A'}</TableCell>
                      <TableCell>{eQuote.eQuoteDescription || 'N/A'}</TableCell>
                      <TableCell>{formatDate(eQuote.eQuoteKickoffDate) || 'N/A'}</TableCell>
                      <TableCell>{formatDate(eQuote.eQuoteDueDate) || 'N/A'}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Dialog
              open={openEditDialog && currentAction === 'edit'}
              onClose={() => setOpenDialog(false)}
              maxWidth="sm"
              fullWidth
            >
              <DialogTitle>Edit eQuote</DialogTitle>
              <DialogContent>
                <TextField
                  required
                  fullWidth
                  label="eQuote Number"
                  value={editValues.eQuoteNumber}
                  disabled
                  margin="normal"
                />
                <TextField
                  required
                  select
                  fullWidth
                  label="Status"
                  value={editValues.eQuoteStatus}
                  onChange={(e) => setEditValues({ ...editValues, eQuoteStatus: e.target.value })}
                  margin="normal"
                >
                  <MenuItem value="in work">In Work</MenuItem>
                  <MenuItem value="in release">In Release</MenuItem>
                  <MenuItem value="released">Released</MenuItem>
                  <MenuItem value="uploaded">Uploaded</MenuItem>
                  <MenuItem value="cancelled">Cancelled</MenuItem>
                </TextField>
                <TextField
                  select
                  label="Project Name"
                  fullWidth
                  value={editValues.projectID}
                  onChange={(e) => setEditValues({ ...editValues, projectID: e.target.value })}
                  margin="normal"
                  required
                >
                  {projects.map((project) => (
                    <MenuItem key={project.projectID} value={project.projectID}>
                      {project.projectName}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  required
                  select
                  fullWidth
                  label="eQuote Lead"
                  value={editValues.eQuoteLead}
                  onChange={(e) => setEditValues({ ...editValues, eQuoteLead: e.target.value })}
                  margin="normal"
                >
                  {leads.map((lead) => (
                    <MenuItem key={lead.userID} value={lead.userID}>
                      {lead.username}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  select
                  fullWidth
                  label="eQuote 2nd Lead"
                  value={editValues.eQuoteSecondLead}
                  onChange={(e) =>
                    setEditValues({ ...editValues, eQuoteSecondLead: e.target.value })
                  }
                  margin="normal"
                >
                  {leads.map((lead) => (
                    <MenuItem key={lead.userID} value={lead.userID}>
                      {lead.username}
                    </MenuItem>
                  ))}
                  <MenuItem value="">--None--</MenuItem>
                </TextField>
                <TextField
                  required
                  select
                  fullWidth
                  label="Character"
                  value={editValues.eQuoteCharacter}
                  onChange={(e) =>
                    setEditValues({ ...editValues, eQuoteCharacter: e.target.value })
                  }
                  margin="normal"
                >
                  <MenuItem value="binding">Binding</MenuItem>
                  <MenuItem value="non-binding">Non-Binding</MenuItem>
                </TextField>
                <TextField
                  label="eQuote Short Name"
                  fullWidth
                  value={editValues.eQuoteShortName}
                  onChange={(e) =>
                    setEditValues({ ...editValues, eQuoteShortName: e.target.value })
                  }
                  margin="normal"
                  required
                  inputProps={{ maxLength: 50 }}
                />
                <TextField
                  label="eQuote Customer Request Date"
                  type="date"
                  fullWidth
                  value={editValues.eQuoteCustomerRequestDate.split('T')[0]}
                  onChange={(e) =>
                    setEditValues({ ...editValues, eQuoteCustomerRequestDate: e.target.value })
                  }
                  margin="normal"
                  required
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ inputProps: { min: '1900-01-01', max: '2099-12-31' } }}
                />
                <TextField
                  select
                  fullWidth
                  label="Request Type"
                  value={editValues.requestType}
                  onChange={(e) => setEditValues({ ...editValues, requestType: e.target.value })}
                  margin="normal"
                >
                  <MenuItem value="New Project(CAP)">New Project(CAP)</MenuItem>
                  <MenuItem value="DCR/DCD">DCR/DCD</MenuItem>
                  <MenuItem value="CTP">CTP</MenuItem>
                  <MenuItem value="Purchasing(Request from PUR)">
                    Purchasing (Request from PUR)
                  </MenuItem>
                  <MenuItem value="Operations">Operations</MenuItem>
                  <MenuItem value="Engineering Support">Engineering Support</MenuItem>
                  <MenuItem value="Other Request">Other Request</MenuItem>
                  <MenuItem value="">-- None --</MenuItem>
                </TextField>
                <TextField
                  label="eQuote Finished Target Date"
                  type="date"
                  fullWidth
                  value={
                    editValues.quoteFinishTargetDate
                      ? editValues.quoteFinishTargetDate.split('T')[0]
                      : ''
                  }
                  onChange={(e) =>
                    setEditValues({ ...editValues, quoteFinishTargetDate: e.target.value })
                  }
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ inputProps: { min: '1900-01-01', max: '2099-12-31' } }}
                />
                <TextField
                  label="eQuote Kick Off Date"
                  type="date"
                  fullWidth
                  value={
                    editValues.eQuoteKickoffDate ? editValues.eQuoteKickoffDate.split('T')[0] : ''
                  }
                  onChange={(e) =>
                    setEditValues({ ...editValues, eQuoteKickoffDate: e.target.value })
                  }
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ inputProps: { min: '1900-01-01', max: '2099-12-31' } }}
                />
                <TextField
                  label="eQuote Due Date"
                  type="date"
                  fullWidth
                  value={editValues.eQuoteDueDate ? editValues.eQuoteDueDate.split('T')[0] : ''}
                  onChange={(e) => setEditValues({ ...editValues, eQuoteDueDate: e.target.value })}
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ inputProps: { min: '1900-01-01', max: '2099-12-31' } }}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  variant="outlined"
                  sx={{
                    color: '#484547',
                    borderColor: '#484547',
                    textTransform: 'none',
                    borderRadius: '10px',
                    '&:hover': {
                      backgroundColor: '#d3d3d3',
                      borderColor: '#d3d3d3'
                    }
                  }}
                  onClick={() => {
                    setOpenEditDialog(false);
                    setCurrentAction(null);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  onClick={confirmEdit}
                  variant="contained"
                  sx={{
                    textTransform: 'none',
                    borderRadius: '10px'
                  }}
                >
                  Save
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
              <DialogTitle sx={{ color: '#800000', fontWeight: 'bold' }}>
                {currentAction === 'approve' ? 'Confirm Approval' : 'Confirm Rejection'}
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Are you sure you want to {currentAction} this eQuote?
                </DialogContentText>

                {currentAction === 'reject' && (
                  <>
                    <TextField
                      autoFocus
                      margin="dense"
                      label="Rejection Reason"
                      type="text"
                      fullWidth
                      variant="outlined"
                      value={rejectionReason}
                      onChange={(e) => setRejectionReason(e.target.value)}
                      error={!rejectionReason}
                      required
                    />
                  </>
                )}
              </DialogContent>
              <DialogActions>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setOpenDialog(false);
                    setRejectionReason('');
                    setCurrentAction(null);
                  }}
                  sx={{
                    color: '#484547',
                    borderColor: '#484547',
                    textTransform: 'none',
                    borderRadius: '10px',
                    '&:hover': {
                      backgroundColor: '#d3d3d3',
                      borderColor: '#d3d3d3'
                    }
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={confirmAction}
                  variant="contained"
                  sx={{
                    textTransform: 'none',
                    borderRadius: '10px'
                  }}
                  disabled={currentAction === 'reject' && !rejectionReason}
                >
                  Confirm
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default EQuoteApprovalPage;
