import { Box, Typography } from '@mui/material';
import React from 'react';
import { useLocation } from 'react-router-dom';
import BackToDashboard from '../../Components/BackToDashboard';

const Quantum = () => {
  const location = useLocation();
  const { eQuoteNumber } = location.state;

  return (
    <Box>
      <Box>
        <BackToDashboard />
      </Box>
      <Box display="flex" margin="10rem 0" justifyContent="center">
        <Typography>eQuoteNumber: {eQuoteNumber}</Typography>
      </Box>
    </Box>
  );
};

export default Quantum;
