import React, { useMemo, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './Assumptions.css';
import { Box, TextField, Container } from '@mui/material';
import BackToDashboard from '../../Components/BackToDashboard';
import { assumption as assumptionData } from '../../Data/assumption';

const Assumptions = () => {
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      resizable: true,
      editable: true,
      minWidth: 100
    };
  });

  const [rowData] = useState(assumptionData);

  const [colDefs] = useState([
    {
      headerName: 'Option 1',
      children: [
        {
          headerName: 'AMG - 7HDT300',
          field: 'id',
          pinned: 'left'
        }
      ]
    },
    {
      headerName: 'Des',
      children: [
        {
          headerName: 'C/O',
          field: 'CO',
          width: 50
        },
        {
          headerName: 'Minor Modifications',
          field: 'minor_modifications',
          width: 50
        },
        {
          headerName: 'Major Modification',
          field: 'major_modification',
          width: 50
        },
        {
          headerName: 'New',
          field: 'new',
          width: 50
        }
      ]
    },
    {
      headerName: 'Supp',
      children: [
        {
          headerName: 'Supplier Change',
          field: 'supplier_change',
          width: 50
        },
        {
          headerName: 'New Supplier',
          field: 'new_supplier',
          width: 50
        },
        {
          headerName: 'Localization',
          field: 'sup_localization',
          width: 50
        }
      ]
    },
    {
      headerName: 'Man',
      children: [
        {
          headerName: 'Process Change',
          field: 'process_change',
          width: 50
        },
        {
          headerName: 'Localization',
          field: 'man_localization',
          width: 50
        },
        {
          headerName: ' ',
          field: 'man_blank',
          width: 50
        }
      ]
    },
    {
      headerName: 'Req',
      children: [
        {
          headerName: 'Within Envelope',
          field: 'within_envelope',
          width: 50
        },
        {
          headerName: 'TBD',
          field: 'tbd',
          width: 50
        },
        {
          headerName: 'Exceeding Envelope',
          field: 'exceeding_envelope',
          width: 50
        }
      ]
    },
    {
      headerName: 'Description',
      field: 'description'
    }
  ]);

  const getRowStyle = (params) => {
    if (
      params.data.id === 'Vehicle Parameter' ||
      params.data.id === 'Engine Parameter' ||
      params.data.id === 'Customer / Internal Requirement' ||
      params.data.id === 'Transmission-Assy' ||
      params.data.id === 'BB-010_Gearsets' ||
      params.data.id === 'BB-010_Differentials' ||
      params.data.id === 'BB-020_Synchro' ||
      params.data.id === 'BB-030_Shifting_System' ||
      params.data.id === 'BB-040_Housing_&_Covers' ||
      params.data.id === 'BB-050_Clutches_&_Decoupling' ||
      params.data.id === 'BB-060_Parklock' ||
      params.data.id === 'BB-70_Oil' ||
      params.data.id === 'BB-080_Hydraulics' ||
      params.data.id === 'BB-090_Control_HW_&_Actuation' ||
      params.data.id === 'BB-110-TractionEMotor' ||
      params.data.id === 'BB-200_SW_&_Control Alorithms'
    ) {
      return { fontWeight: 'bold' };
    }
    return null;
  };

  const CustomTextField = ({ labelText }) => {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <TextField
          label={labelText}
          variant="standard"
          InputLabelProps={{ style: { fontSize: 20 } }}
          sx={{ ml: 1, width: 300 }}
        />
      </Box>
    );
  };

  return (
    <div>
      <BackToDashboard />
      <Container
        maxWidth="lg"
        style={{
          marginLeft: '0px'
        }}
      >
        <Box
          component="form"
          sx={{
            '& > :not(style)': { m: 1 },
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start'
          }}
          noValidate
          autoComplete="off"
        >
          <CustomTextField labelText="In Work" />
          <CustomTextField labelText="Project Name" />
          <CustomTextField labelText="Transmission" />
        </Box>
        <br />
      </Container>

      <div className="ag-theme-alpine" style={{ height: 'calc(100vh - 300px)', width: '100%' }}>
        <AgGridReact
          rowData={rowData}
          columnDefs={colDefs}
          defaultColDef={defaultColDef}
          domLayout="autoHeight"
          getRowStyle={getRowStyle}
        />
      </div>
    </div>
  );
};

export default Assumptions;
