import React from 'react';
import Table from 'react-bootstrap/Table';
import './Timing.css';
import BackToDashboard from '../../Components/BackToDashboard';

const Timing = () => {
  return (
    <div>
      <BackToDashboard />
      <div className="container" style={{ margin: 0, display: 'flex' }}>
        <div className="event-table">
          <table>
            <thead>
              <tr style={{ backgroundColor: '#a3032f', color: 'white' }}>
                <th>Event</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td>20-03-24</td>
              </tr>
              <tr>
                <td></td>
                <td className="grey">09-05-24</td>
              </tr>
              <tr>
                <td></td>
                <td>28-06-24</td>
              </tr>
              <tr>
                <td>Gate SD0</td>
                <td>02-08-24</td>
              </tr>
              <tr>
                <td>Gate SD1</td>
                <td>01-10-24</td>
              </tr>
              <tr>
                <td>Gate SD2</td>
                <td>30-12-24</td>
              </tr>
              <tr>
                <td className="grey">1st B</td>
                <td className="grey">21-01-25</td>
              </tr>
              <tr>
                <td>Gate SD3</td>
                <td>21-05-25</td>
              </tr>
              <tr>
                <td className="grey">1st C</td>
                <td className="grey">05-06-25</td>
              </tr>
              <tr>
                <td>Gate SD4</td>
                <td>01-09-25</td>
              </tr>
              <tr>
                <td className="grey">1st D</td>
                <td className="grey">16-09-25</td>
              </tr>
              <tr>
                <td>Gate SD5</td>
                <td>03-01-26</td>
              </tr>
              <tr>
                <td>Gate SD6</td>
                <td>03-04-26</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="timeline-table">
          <div className="timeline-header">
            <h2>Option 1: BMW - 7HDT300 -</h2>
          </div>
          <Table>
            <thead>
              <tr>
                <th className="empty-cell"> </th>
                <th colSpan={12}>2024</th>
                <th colSpan={12}>2025</th>
                <th colSpan={12}>2026</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th className="empty-cell"> </th>
                <th colSpan={3}> Q1 </th>
                <th colSpan={3}> Q2 </th>
                <th colSpan={3}> Q3 </th>
                <th colSpan={3}> Q4 </th>
                <th colSpan={3}> Q1 </th>
                <th colSpan={3}> Q2 </th>
                <th colSpan={3}> Q3 </th>
                <th colSpan={3}> Q4 </th>
                <th colSpan={3}> Q1 </th>
                <th colSpan={3}> Q2 </th>
                <th colSpan={3}> Q3 </th>
                <th colSpan={3}> Q4 </th>
              </tr>
              <tr>
                <td className="empty-cell"> </td>
                <td>01</td>
                <td>02</td>
                <td>03</td>
                <td>04</td>
                <td>05</td>
                <td>06</td>
                <td>07</td>
                <td>08</td>
                <td>09</td>
                <td>10</td>
                <td>11</td>
                <td>12</td>
                <td>01</td>
                <td>02</td>
                <td>03</td>
                <td>04</td>
                <td>05</td>
                <td>06</td>
                <td>07</td>
                <td>08</td>
                <td>09</td>
                <td>10</td>
                <td>11</td>
                <td>12</td>
                <td>01</td>
                <td>02</td>
                <td>03</td>
                <td>04</td>
                <td>05</td>
                <td>06</td>
                <td>07</td>
                <td>08</td>
                <td>09</td>
                <td>10</td>
                <td>11</td>
                <td>12</td>
              </tr>
              <tr>
                <td className="empty-cell"> </td>
                <td></td> <td></td> <td></td> <td></td> <td></td> <td></td> <td></td> <td></td>{' '}
                <td></td> <td></td> <td></td> <td></td>
                <td></td> <td></td> <td></td> <td></td> <td></td> <td></td> <td></td> <td></td>{' '}
                <td></td> <td></td> <td></td> <td></td>
                <td></td> <td></td> <td></td> <td></td> <td></td> <td></td> <td></td> <td></td>{' '}
                <td></td> <td></td> <td></td> <td></td>
              </tr>
              <tr>
                <td className="empty-cell"> Phase </td>
                <td></td> <td></td> <td></td> <td></td> <td></td> <td></td> <td></td>
                <td colSpan="2" className="blue-cell">
                  SD1
                </td>
                <td colSpan="3" className="blue-cell">
                  SD2
                </td>
                <td colSpan="4" className="blue-cell">
                  SD3
                </td>
                <td colSpan="4" className="blue-cell">
                  SD4
                </td>
                <td colSpan="4" className="blue-cell">
                  SD5
                </td>
                <td colSpan="3" className="blue-cell">
                  SD6
                </td>
                <td></td> <td></td> <td></td>
                <td></td> <td></td> <td></td> <td></td> <td></td> <td></td>
              </tr>
              <tr>
                <td className="empty-cell"> </td>
                <td></td> <td></td> <td></td> <td></td> <td></td> <td></td> <td></td> <td></td>{' '}
                <td></td> <td></td> <td></td> <td></td>
                <td></td> <td></td> <td></td> <td></td> <td></td> <td></td> <td></td> <td></td>{' '}
                <td></td> <td></td> <td></td> <td></td>
                <td></td> <td></td> <td></td> <td></td> <td></td> <td></td> <td></td> <td></td>{' '}
                <td></td> <td></td> <td></td> <td></td>
              </tr>
              <tr>
                <td className="empty-cell"> </td>
                <td></td> <td></td> <td></td> <td></td> <td></td> <td></td> <td></td> <td></td>{' '}
                <td></td> <td></td> <td></td> <td></td>
                <td></td> <td></td> <td></td> <td></td> <td></td> <td></td> <td></td> <td></td>{' '}
                <td></td> <td></td> <td></td> <td></td>
                <td></td> <td></td> <td></td> <td></td> <td></td> <td></td> <td></td> <td></td>{' '}
                <td></td> <td></td> <td></td> <td></td>
              </tr>
              <tr>
                <td className="empty-cell"> </td>
                <td></td> <td></td> <td></td> <td></td> <td></td> <td></td> <td></td> <td></td>{' '}
                <td></td> <td></td> <td></td> <td></td>
                <td></td> <td></td> <td></td> <td></td> <td></td> <td></td> <td></td> <td></td>{' '}
                <td></td> <td></td> <td></td> <td></td>
                <td></td> <td></td> <td></td> <td></td> <td></td> <td></td> <td></td> <td></td>{' '}
                <td></td> <td></td> <td></td> <td></td>
              </tr>
              <tr>
                <td className="empty-cell"> Sample </td>
                <td></td> <td></td> <td></td> <td></td> <td></td> <td></td> <td></td> <td></td>{' '}
                <td></td> <td></td> <td></td> <td></td>
                <td colSpan="5" className="green-cell">
                  B
                </td>{' '}
                <td></td>{' '}
                <td colSpan="3" className="green-cell">
                  C
                </td>{' '}
                <td></td>{' '}
                <td colSpan={4} className="green-cell">
                  D
                </td>
                <td></td> <td></td> <td></td> <td></td> <td></td> <td></td> <td></td> <td></td>{' '}
                <td></td> <td></td>
              </tr>
              <tr>
                <td className="empty-cell"> </td>
                <td></td> <td></td> <td></td> <td></td> <td></td> <td></td> <td></td> <td></td>{' '}
                <td></td> <td></td> <td></td> <td></td>
                <td></td> <td></td> <td></td> <td></td> <td></td> <td></td> <td></td> <td></td>{' '}
                <td></td> <td></td> <td></td> <td></td>
                <td></td> <td></td> <td></td> <td></td> <td></td> <td></td> <td></td> <td></td>{' '}
                <td></td> <td></td> <td></td> <td></td>
              </tr>
              <tr>
                <td className="empty-cell"> </td>
                <td></td> <td></td> <td></td> <td></td> <td></td> <td></td> <td></td> <td></td>{' '}
                <td></td> <td></td> <td></td> <td></td>
                <td></td> <td></td> <td></td> <td></td> <td></td> <td></td> <td></td> <td></td>{' '}
                <td></td> <td></td> <td></td> <td></td>
                <td></td> <td></td> <td></td> <td></td> <td></td> <td></td> <td></td> <td></td>{' '}
                <td></td> <td></td> <td></td> <td></td>
              </tr>
              <tr>
                <td className="empty-cell"> </td>
                <td></td> <td></td> <td></td> <td></td> <td></td> <td></td> <td></td> <td></td>{' '}
                <td></td> <td></td> <td></td> <td></td>
                <td></td> <td></td> <td></td> <td></td> <td></td> <td></td> <td></td> <td></td>{' '}
                <td></td> <td></td> <td></td> <td></td>
                <td></td> <td></td> <td></td> <td></td> <td></td> <td></td> <td></td> <td></td>{' '}
                <td></td> <td></td> <td></td> <td></td>
              </tr>
              <tr>
                <td className="empty-cell"> </td>
                <td></td> <td></td> <td></td> <td></td> <td></td> <td></td> <td></td> <td></td>{' '}
                <td></td> <td></td> <td></td> <td></td>
                <td></td> <td></td> <td></td> <td></td> <td></td> <td></td> <td></td> <td></td>{' '}
                <td></td> <td></td> <td></td> <td></td>
                <td></td> <td></td> <td></td> <td></td> <td></td> <td></td> <td></td> <td></td>{' '}
                <td></td> <td></td> <td></td> <td></td>
              </tr>
              <tr>
                <td className="empty-cell"> </td>
                <td></td> <td></td> <td></td> <td></td> <td></td> <td></td> <td></td> <td></td>{' '}
                <td></td> <td></td> <td></td> <td></td>
                <td></td> <td></td> <td></td> <td></td> <td></td> <td></td> <td></td> <td></td>{' '}
                <td></td> <td></td> <td></td> <td></td>
                <td></td> <td></td> <td></td> <td></td> <td></td> <td></td> <td></td> <td></td>{' '}
                <td></td> <td></td> <td></td> <td></td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default Timing;
