import {
  AppBar,
  Box,
  Badge,
  Button,
  Menu,
  Toolbar,
  MenuItem,
  Fade,
  IconButton,
  Tooltip,
  Typography,
  Link
} from '@mui/material';
import { useState, React, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import quoVaDisLogo from '../assets/quovadis.png';
import { AccountCircle, Menu as MenuIcon } from '@mui/icons-material';
import Sidebar from './Sidebar';
import LogoutIcon from '@mui/icons-material/Logout';
import { useMsal } from '@azure/msal-react';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useNotifications } from '../context/NotificationContext';
import useHasRole from '../hooks/useHasRole';

const Header = () => {
  const { instance } = useMsal();
  const navigate = useNavigate();
  const [anchorElProject, setAnchorElProject] = useState(null);
  const [anchorElInsert, setAnchorElInsert] = useState(null);
  const [anchorElAnalysys, setAnchorElAnalysys] = useState(null);
  const [anchorElSettings, setAnchorElSettings] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { hasPendingEQuotes, fetchPendingEQuotes } = useNotifications();
  const isAllowedRoles = useHasRole(['QVD.Admin', 'eQuote.Manager']);

  const handleNotificationIconClick = () => {
    navigate('/equote-number-approval');
  };

  useEffect(() => {
    fetchPendingEQuotes();
  }, [fetchPendingEQuotes]);

  const handleProjectMenuOpen = (event) => {
    setAnchorElProject(event.currentTarget);
  };

  const handleProjectMenuClose = (item) => {
    setAnchorElProject(null);
    if (item === 'configuration') navigate('/configuration');
    else if (item === 'assumptions') navigate('/assumptions');
    else if (item === 'psp_quote') navigate('/psp/equote');
    else if (item === 'pre_psp_quote') navigate('/psp/pre-equote');
    else navigate('/');
  };

  const handleInserMenuOpen = (event) => {
    setAnchorElInsert(event.currentTarget);
  };

  const handleInsertMenuClose = (item) => {
    setAnchorElInsert(null);
    if (item === 'work-plans') navigate('/work-plans');
    else if (item === 'work-package') navigate('/work-packages');
    else navigate('/');
  };
  const handleAnalysisMenuOpen = (event) => {
    setAnchorElAnalysys(event.currentTarget);
  };

  const handleAnalysisMenuClose = (item) => {
    setAnchorElAnalysys(null);
    if (item === 'totals') navigate('/totals');
    else if (item === 'review') navigate('/review');
    else navigate('/');
  };

  const handleSettingsMenuOpen = (event) => {
    setAnchorElSettings(event.currentTarget);
  };

  const handleSettingsMenuClose = () => {
    setAnchorElSettings(null);
  };

  const handleMenu = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: '/'
    });
  };

  const handleProfileIconMenu = () => {
    navigate('/profile');
  };

  const handleSidebarOpen = () => {
    setSidebarOpen(true);
  };

  const handleSidebarClose = () => {
    setSidebarOpen(false);
  };

  const openProject = Boolean(anchorElProject);
  const openInsert = Boolean(anchorElInsert);
  const openAnalysys = Boolean(anchorElAnalysys);
  const openSettings = Boolean(anchorElSettings);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ backgroundColor: '#ffff' }}>
        <Toolbar>
          <IconButton
            size="large"
            aria-label="open sidebar"
            onClick={handleSidebarOpen}
            sx={{ color: '#800000' }}
          >
            <MenuIcon />
          </IconButton>
          <Box display="flex" alignItems="center">
            <img src={quoVaDisLogo} alt="quovadisIcon" style={{ padding: '0 1rem' }} />
            <Typography
              variant="h4"
              style={{ fontWeight: 'bold', color: '#800000', width: '20rem' }}
            >
              eQuote Cockpit
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="flex-end"
            flexDirection="row"
            alignItems="center"
            width="100%"
            color="#800000"
          >
            {/* This code is commented out as it is intended for future implementation for page navigaton. */}

            {/* <Button
              color="inherit"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleProjectMenuOpen}
              sx={{ mx: '1rem', fontWeight: 'bold', fontSize: '1.3rem' }}
            >
              Project
            </Button>
            <Menu
              id="simple-menu"
              MenuListProps={{
                'aria-labelledby': 'fade-button'
              }}
              anchorEl={anchorElProject}
              keepMounted
              open={openProject}
              onClose={handleProjectMenuClose}
              TransitionComponent={Fade}
            >
              <MenuItem onClick={() => handleProjectMenuClose('configuration')}>
                Configurations
              </MenuItem>
              <MenuItem onClick={() => handleProjectMenuClose('assumptions')}>Assumptions</MenuItem>
              <MenuItem onClick={() => handleProjectMenuClose('timing')}>Timing</MenuItem>
              <MenuItem onClick={() => handleProjectMenuClose('psp_quote')}>PSP eQuote</MenuItem>
              <MenuItem onClick={() => handleProjectMenuClose('pre_psp_quote')}>
                PSP Pre-eQuote
              </MenuItem>
            </Menu> */}

            {/* <Button
              color="inherit"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleInserMenuOpen}
              sx={{ mx: '1rem', fontWeight: 'bold', fontSize: '1.3rem' }}>
              Insert
            </Button>
            <Menu
              id="simple-menu"
              MenuListProps={{
                'aria-labelledby': 'fade-button'
              }}
              anchorEl={anchorElInsert}
              keepMounted
              open={openInsert}
              onClose={handleInsertMenuClose}
              TransitionComponent={Fade}>
              <MenuItem onClick={() => handleInsertMenuClose('work_plans')}>Work Plans</MenuItem>
              <MenuItem onClick={() => handleInsertMenuClose('work_package')}>
                Work Package
              </MenuItem>
            </Menu>

            <Button
              color="inherit"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleAnalysisMenuOpen}
              sx={{ mx: '1rem', fontWeight: 'bold', fontSize: '1.3rem' }}
              >
              Analysis
            </Button>
            <Menu
              id="simple-menu"
              MenuListProps={{
                'aria-labelledby': 'fade-button'
              }}
              anchorEl={anchorElAnalysys}
              keepMounted
              open={openAnalysys}
              onClose={handleAnalysisMenuClose}
              TransitionComponent={Fade}
              >
              <MenuItem onClick={() => handleAnalysisMenuClose('totals')}>Totals</MenuItem>
              <MenuItem onClick={() => handleAnalysisMenuClose('review')}>Review</MenuItem>
            </Menu> */}
            {isAllowedRoles && (
              <Tooltip
                title={
                  hasPendingEQuotes
                    ? 'You have eQuotes pending for Approval!'
                    : 'No eQuotes pending for Approval.'
                }
              >
                <IconButton
                  size="large"
                  aria-label="notifications-section"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  color="inherit"
                  onClick={handleNotificationIconClick}
                >
                  <Badge
                    color="error"
                    variant="dot"
                    invisible={!hasPendingEQuotes}
                    sx={{
                      '& .MuiBadge-dot': {
                        marginRight: '12px'
                      }
                    }}
                  >
                    <NotificationsIcon />
                  </Badge>
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Profile">
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleProfileIconMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            </Tooltip>
            <Tooltip title="Logout">
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <LogoutIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Toolbar>
      </AppBar>
      <Sidebar open={sidebarOpen} handleClose={handleSidebarClose} />
    </Box>
  );
};

export default Header;
