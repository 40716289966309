import React, { useState } from 'react';
import {
  Typography,
  MenuList,
  MenuItem,
  ListItemText,
  Box,
  FormControl,
  Grid,
  TextField,
  Select,
  FormControlLabel,
  Button,
  Checkbox,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Divider
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import BackToDashboard from '../../Components/BackToDashboard';

const ProjectConfiguration = () => {
  const [selectedTab, setSelectedTab] = useState('Project');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const quotation = ['all', 'Q2', 'A'];
  const serialDevelopemnt = ['SD1', 'SD2', 'SD3', 'B', 'SD4', 'C', 'SD5', 'D', 'SD6'];

  const tasks = [
    {
      taskId: 'EPE',
      function: 'Engineering Project Engineer',
      managers: [
        { name: 'U. Franzmann', share: 30 },
        { name: 'A. Flaig', share: 70 }
      ]
    },
    {
      taskId: 'eQA',
      function: 'Quality Assurance',
      managers: [
        { name: 'W. Guja', share: 20 },
        { name: 'T. Hollnaeck', share: 80 }
      ]
    },
    {
      taskId: 'DVE',
      function: 'Design Verification Eng.',
      managers: [
        { name: 'H. Gremplini', share: 50 },
        { name: 'U. Franzmann', share: 50 }
      ]
    },
    {
      taskId: 'APD',
      function: 'Application Design BMW',
      managers: [
        { name: 'J. Bausch', share: 70 },
        { name: 'W. Nixdorf', share: 30 }
      ]
    },
    {
      taskId: 'SIE',
      function: 'System Integration Engineer',
      managers: [
        { name: 'A. Schick', share: 60 },
        { name: 'H. Gremplini', share: 40 }
      ]
    },
    {
      taskId: 'SIE-050',
      function: 'System Integration Engineer',
      managers: [
        { name: 'A. Schick', share: 80 },
        { name: 'W. Guja', share: 20 }
      ]
    },
    {
      taskId: 'SIE-110',
      function: 'System Integration Engineer',
      managers: [
        { name: 'A. Schick', share: 60 },
        { name: 'A. Nevilk', share: 40 }
      ]
    },
    {
      taskId: 'STE',
      function: 'System Test Engineer',
      managers: [
        { name: 'H. Gremplini', share: 30 },
        { name: 'W. Nixdorf', share: 70 }
      ]
    },
    {
      taskId: 'SFIE',
      function: 'System Function Integration Eng.',
      managers: [
        { name: 'T. Hollnaeck', share: 60 },
        { name: 'A. Schick', share: 40 }
      ]
    },
    {
      taskId: 'DRV',
      function: 'Driveability',
      managers: [
        { name: 'T. Hollnaeck', share: 45 },
        { name: 'H. Gremplini', share: 55 }
      ]
    },
    {
      taskId: 'VHT',
      function: 'Vehicle Testing',
      managers: [
        { name: 'T. Hollnaeck', share: 40 },
        { name: 'A. Flaig', share: 60 }
      ]
    },
    {
      taskId: 'EFF',
      function: 'Efficiency',
      managers: [
        { name: 'W. Nixdorf', share: 70 },
        { name: 'U. Franzmann', share: 30 }
      ]
    }
  ];

  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const renderContent = () => {
    switch (selectedTab) {
      case 'Project':
        return (
          <Box display="flex" flexDirection="column">
            <Box>
              <Grid container spacing={2} sx={{ alignItems: 'flex-end' }}>
                <Grid item xs={2}>
                  <Typography variant="h7" p="1">
                    Description
                  </Typography>
                  <Box display="flex" alignItems="center" mt="1">
                    <Typography variant="h7">1)</Typography>
                    <TextField fullWidth variant="outlined" size="small" />
                  </Box>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="h7">Remark</Typography>
                  <TextField fullWidth variant="outlined" size="small" />
                </Grid>
                <Grid item xs={2}>
                  <FormControl fullWidth>
                    <Typography variant="h7">Program</Typography>
                    <Select defaultValue="1" size="small">
                      <MenuItem value="1">ITP</MenuItem>
                      <MenuItem value="2">CTP</MenuItem>
                      <MenuItem value="3">CAP</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <Typography variant="h7">Project Category</Typography>
                    <Select defaultValue="1" size="small">
                      <MenuItem value="1">C1-New arct. with exiting sustem components</MenuItem>
                      <MenuItem value="2">
                        C2-Existing arct. w/major adapt. for new customer
                      </MenuItem>
                      <MenuItem value="3">
                        C3-Existing arct. w/minor adapt. for new customer
                      </MenuItem>
                      <MenuItem value="4">
                        C4-Existing arct. w/major adapt. for known customerts
                      </MenuItem>
                      <MenuItem value="5">
                        C5-Existing arct. w/minor adapt. for known customer
                      </MenuItem>
                      <MenuItem value="6">C6-c/o HW w/ new calibration for known customer</MenuItem>
                      <MenuItem value="7">C7-Add on or DCD for existing customer</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <FormControl fullWidth>
                    <Typography variant="h7">Customer</Typography>
                    <Select defaultValue="1" size="small">
                      <MenuItem value="1">BMW</MenuItem>
                      <MenuItem value="2">BMW M</MenuItem>
                      <MenuItem value="3">Brilliance</MenuItem>
                      <MenuItem value="4">Chrysler</MenuItem>
                      <MenuItem value="5">CTCS</MenuItem>
                      <MenuItem value="6">DAI</MenuItem>
                      <MenuItem value="7">DFLZ</MenuItem>
                      <MenuItem value="8">DFM</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={1}>
                  <FormControlLabel control={<Checkbox />} label="SW" />
                </Grid>
              </Grid>
            </Box>
          </Box>
        );
      case 'Product':
        return (
          <Box>
            <Grid container spacing={2} sx={{ alignItems: 'flex-end' }}>
              <Grid item xs={2}>
                <Typography variant="h7">Description</Typography>
                <Box display="flex" alignItems="center">
                  <Typography variant="h7">1)</Typography>
                  <TextField fullWidth variant="outlined" size="small" />
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="h7">TM - Remark</Typography>
                <TextField fullWidth variant="outlined" size="small" />
              </Grid>
              <Grid item xs={2}>
                <FormControl fullWidth>
                  <Typography variant="h7">Type</Typography>
                  <Select defaultValue="1" size="small">
                    <MenuItem value="1">DCT</MenuItem>
                    <MenuItem value="2">HDT</MenuItem>
                    <MenuItem value="3">DHD</MenuItem>
                    <MenuItem value="4">DCL</MenuItem>
                    <MenuItem value="5">MTx</MenuItem>
                    <MenuItem value="6">eDM</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <FormControl fullWidth>
                  <Typography variant="h7">Product</Typography>
                  <Select defaultValue="1" size="small">
                    <MenuItem value="1">8FeDCT</MenuItem>
                    <MenuItem value="2">7HDT400</MenuItem>
                    <MenuItem value="3">6HDT230</MenuItem>
                    <MenuItem value="4">7HDT400</MenuItem>
                    <MenuItem value="5">5HDT230</MenuItem>
                    <MenuItem value="6">4HDT230</MenuItem>
                    <MenuItem value="7">8FeDCT</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        );
      case 'GPMS':
        return (
          <Box>
            <Grid container spacing={2} sx={{ alignItems: 'flex-end' }}>
              <Grid item xs={2}>
                <Typography variant="h7">Description</Typography>
                <Box display="flex" alignItems="center">
                  <Typography variant="h7">1)</Typography>
                  <TextField fullWidth variant="outlined" size="small" />
                </Box>
              </Grid>
              <Grid item>
                <FormControl fullWidth>
                  <Typography variant="h7">Quotation</Typography>
                  <Box>
                    {quotation.map((label, index) => (
                      <FormControlLabel
                        key={index}
                        control={<Checkbox />}
                        label={label}
                        labelPlacement="top"
                        sx={{ margin: 0, padding: 0 }}
                      />
                    ))}
                  </Box>
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl fullWidth>
                  <Typography variant="h7">
                    Serial Development of Product & Production (SD)
                  </Typography>
                  <Box>
                    {serialDevelopemnt.map((label, index) => (
                      <FormControlLabel
                        key={index}
                        control={<Checkbox />}
                        label={label}
                        labelPlacement="top"
                        sx={{ margin: 0, padding: 0 }}
                      />
                    ))}
                  </Box>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        );
      case 'RASIC':
        return (
          <Box>
            <Grid item xs={2}>
              <Typography variant="h7">1BMW - 7HDT400</Typography>
            </Grid>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Task-ID</TableCell>
                    <TableCell>Function</TableCell>
                    <TableCell>Manager</TableCell>
                    <TableCell>Share</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {tasks.map((task) =>
                    task.managers.map((manager, index) => (
                      <TableRow key={index}>
                        {index === 0 && (
                          <>
                            <TableCell rowSpan={task.managers.length}>{task.taskId}</TableCell>
                            <TableCell rowSpan={task.managers.length}>{task.function}</TableCell>
                          </>
                        )}
                        <TableCell>{manager.name}</TableCell>
                        <TableCell>{manager.share}</TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={tasks.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        );
      default:
        return null;
    }
  };

  const CustomMenu = ({ menuItem }) => {
    return (
      <MenuItem
        onClick={() => setSelectedTab(menuItem)}
        selected={selectedTab === menuItem}
        sx={{
          '&.Mui-selected': {
            backgroundColor: '#800000',
            color: '#ffff'
          },
          '&.Mui-selected:hover': {
            backgroundColor: '#800000',
            color: '#ffff'
          },
          border: '1px solid #ffff',
          fontSize: '14px'
        }}
      >
        <ListItemText inset>{menuItem}</ListItemText>
      </MenuItem>
    );
  };

  return (
    <div>
      <BackToDashboard />
      <Box
        sx={{
          margin: '2rem 1rem 0 1rem',
          display: 'flex',
          height: '65vh'
        }}
      >
        <Box pr="1rem" flex="1">
          <MenuList>
            <CustomMenu menuItem="Project" />
            <CustomMenu menuItem="Product" />
            <CustomMenu menuItem="GPMS" />
            <CustomMenu menuItem="RASIC" />
          </MenuList>
        </Box>
        <Box>
          <Divider
            orientation="vertical"
            sx={{
              width: 2,
              height: '100%',
              backgroundColor: '#800000'
            }}
          />
        </Box>
        <Box display="flex" flexDirection="column" pl="2rem" flex="6">
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={1}>
                <Typography variant="h7" marginBottom="1rem">
                  Apps
                </Typography>
                <TextField fullWidth variant="outlined" value={'1'} size="small" />
              </Grid>
              <Grid item xs={3}>
                <Typography variant="h7">eQuote No.</Typography>
                <TextField fullWidth variant="outlined" size="small" />
              </Grid>
              <Grid item xs={3}>
                <Typography variant="h7">Project Name</Typography>
                <TextField fullWidth variant="outlined" size="small" />
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ overflowY: 'auto', overflowX: 'hidden', marginTop: '3rem' }}>
            {renderContent()}
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', margin: '0 2rem' }}>
        <Button
          variant="contained"
          color="primary"
          sx={{
            marginRight: 2,
            backgroundColor: '#800000',
            color: '#ffff'
          }}
          onClick={() => {
            alert('Saved!');
          }}
        >
          Save
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => {
            navigate('/');
          }}
        >
          Close
        </Button>
      </Box>
    </div>
  );
};

export default ProjectConfiguration;
