import {
  Box,
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  IconButton,
  DialogContent,
  FormControl,
  TextField,
  Autocomplete,
  DialogContentText,
  Tooltip,
  MenuItem
} from '@mui/material';
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import BackToDashboard from '../../Components/BackToDashboard';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import AlertMessage from '../../Components/AlertMessage';
import ProgressSpinner from '../../Components/ProgressSpinner';
import msalInstance from '../../msalInstance.js';
import { loginRequest } from '../../authConfig.js';
import axios from 'axios';
import { Delete, Edit } from '@mui/icons-material';

const ProjectActions = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [platformProgramsOptions, setPlatformProgramsOptions] = useState([]);
  const [mptProductsOptions, setMptProductsOptions] = useState([]);
  const [projectLeads, setProjectLeads] = useState([]);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [openDelDialog, setOpenDelDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;
  const clusterNameOptions = [
    'EPL Cluster AWD/4WD EU',
    'EPL Cluster eDrive 1 EU',
    'EPL Cluster eDrive 2 EU',
    'EPL Cluster T/M',
    'System Engineering EU',
    'Gearbox & Mechatronics EU',
    'eMotor',
    'Inverter',
    'Software',
    'EPL Cluster AWD/4WD NA',
    'EPL Cluster eDrive NA',
    'System Engineering NA',
    'Gearbox & Mechatronics NA',
    'Others'
  ];

  const columns = useMemo(
    () => [
      {
        accessorKey: 'projectID',
        header: 'Project ID',
        size: 150
      },
      {
        accessorKey: 'status',
        header: 'Status',
        Cell: ({ cell }) => {
          const value = cell.getValue();
          return value === 'active' ? 'Active' : value === 'paused' ? 'On Hold' : value;
        }
      },
      {
        accessorKey: 'customerName',
        header: 'Customer Name'
      },
      {
        accessorKey: 'contractPartner',
        header: 'Contract Partner'
      },
      {
        accessorKey: 'platformAndProgram',
        header: 'Platform and Program'
      },
      {
        accessorKey: 'mptProduct',
        header: 'MPT Product'
      },
      {
        accessorKey: 'projectSpecific',
        header: 'Project Specific'
      },
      {
        accessorKey: 'projectName',
        header: 'Project Name'
      },
      {
        accessorKey: 'projectLead',
        header: 'Project Lead'
      },
      {
        accessorKey: 'clusterName',
        header: 'Cluster Name'
      },
      {
        accessorKey: 'projectDescription',
        header: 'Project Description'
      },
      {
        accessorKey: 'simplifiedName',
        header: 'Simplified Name'
      },
      {
        accessorKey: 'PVsequenceID',
        header: 'PV SequenceID',
        size: 150
      },
      {
        accessorKey: 'CFNumber',
        header: 'CF Number',
        size: 150
      }
    ],
    []
  );

  useEffect(() => {
    fetchProjectsAndUsers();
  }, []);

  const triggerAlert = (message, severity) => {
    setAlertOpen(false);
    setAlertSeverity(severity);
    setAlertMessage(message);
    setAlertOpen(true);
  };

  const getToken = async () => {
    try {
      const tokenResponse = await msalInstance.acquireTokenSilent(loginRequest);
      const accessToken = tokenResponse.idToken;
      return accessToken;
    } catch (error) {
      console.log(error);
    }
  };

  const projectName = useMemo(() => {
    if (!selectedRow) return '';

    const { customerName, contractPartner, platformAndProgram, mptProduct, projectSpecific } =
      selectedRow;

    const parts = [
      customerName?.label || customerName,
      contractPartner?.label || contractPartner,
      platformAndProgram,
      mptProduct,
      projectSpecific
    ];

    return parts
      .filter((part) => part && part.trim() !== '')
      .join('_')
      .replace(/\s+/g, '_');
  }, [selectedRow]);

  useEffect(() => {
    if (selectedRow) {
      setSelectedRow((prevValues) => ({
        ...prevValues,
        projectName
      }));
    }
  }, [projectName, selectedRow]);

  const fetchProjectsAndUsers = async () => {
    try {
      setIsLoading(true);
      const token = await getToken();
      const [projectsResponse, usersResponse] = await Promise.all([
        axios.get(`${apiUrl}/api/projects`, { headers: { Authorization: `Bearer ${token}` } }),
        axios.get(`${apiUrl}/api/user/all`, { headers: { Authorization: `Bearer ${token}` } })
      ]);
      const leads = usersResponse.data;
      setData(projectsResponse.data);
      setProjectLeads([...leads].sort((a, b) => a.username.localeCompare(b.username)));
    } catch (error) {
      console.error('Error fetching projects/users:', error);
      triggerAlert('Projects/Users data could not be loaded!', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const openEditModal = (row) => {
    setSelectedRow(row);
    setOpenEditDialog(true);
  };

  const handleEditDialogClose = () => {
    setOpenEditDialog(false);
    setSelectedRow(null);
  };

  const handleEditProjectSave = async () => {
    try {
      setIsLoading(true);
      setOpenEditDialog(false);

      const params = {
        projectManagerId: selectedRow.projectManagerID,
        customerName: selectedRow.customerName.label,
        status: selectedRow.status,
        contractPartner: selectedRow.contractPartner?.label || null,
        projectName: selectedRow.projectName,
        mptProduct: selectedRow.mptProduct,
        platformAndProgram: selectedRow.platformAndProgram,
        projectLead: selectedRow.projectLead,
        clusterName: selectedRow.clusterName,
        projectSpecific: selectedRow.projectSpecific || null,
        projectDescription: selectedRow.projectDescription || null,
        simplifiedName: selectedRow.simplifiedName || null,
        PVsequenceID:
          selectedRow.PVsequenceID !== null && selectedRow.PVsequenceID !== ''
            ? Number(selectedRow.PVsequenceID)
            : null,
        CFNumber:
          selectedRow.CFNumber !== null && selectedRow.CFNumber !== ''
            ? Number(selectedRow.CFNumber)
            : null
      };
      const token = await getToken();
      const response = await axios.patch(
        `${apiUrl}/api/projects/${selectedRow.projectID}`,
        params,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      const updatedRow = response.data;
      setData((prevData) =>
        prevData.map((item) => (item.projectID === updatedRow.projectID ? updatedRow : item))
      );
      triggerAlert('Project edited sucessfully!', 'success');
    } catch (error) {
      console.error('Error editing the project:', error);
      triggerAlert('Project could not be edited!', 'error');
    } finally {
      setIsLoading(false);
      setSelectedRow(null);
    }
  };

  const handleEditChange = useCallback((event) => {
    const { name, value } = event.target;
    setSelectedRow((prev) => ({
      ...prev,
      [name]: value
    }));
  }, []);

  const handleAutoCompleteChange = useCallback((name, newValue) => {
    setSelectedRow((prev) => ({
      ...prev,
      [name]: newValue
    }));
  }, []);

  const openDeleteConfirmModal = (row) => {
    setSelectedRow(row);
    setOpenDelDialog(true);
  };

  const handleDelDialogClose = () => {
    setOpenDelDialog(false);
    setSelectedRow(null);
  };

  const handleDelProject = async () => {
    try {
      setIsLoading(true);
      setOpenDelDialog(false);
      const token = await getToken();
      await axios.delete(`${apiUrl}/api/projects/${selectedRow.projectID}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setData((prevData) => prevData.filter((item) => item.projectID !== selectedRow.projectID));
      setSelectedRow(null);
      triggerAlert('Project deleted sucessfully!', 'success');
    } catch (error) {
      console.error('Error deleteing the project:', error);
      const errorMessage =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : 'An error occurred during the API call.';
      triggerAlert(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const table = useMaterialReactTable({
    columns,
    data: data,
    enableSorting: false,
    enableTopToolbar: false,
    enablePagination: false,
    enableStickyHeader: true,
    enableColumnActions: false,
    enableBottomToolbar: false,
    enableDensityToggle: false,
    enableRowActions: true,
    positionActionsColumn: 'second',
    renderRowActions: ({ table, row }) => (
      <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
        <IconButton
          onClick={() => {
            openEditModal(row.original);
          }}
        >
          <Edit />
        </IconButton>
        <IconButton onClick={() => openDeleteConfirmModal(row.original)}>
          <Delete />
        </IconButton>
      </Box>
    ),
    state: { showSkeletons: isLoading },
    initialState: {
      columnPinning: { left: ['projectID', 'mrt-row-actions'] },
      density: 'compact'
    },
    muiToolbarAlertBannerProps: {
      sx: {
        display: 'none'
      }
    },
    muiTableHeadCellProps: {
      align: 'center',
      sx: (theme) => ({
        // background: '#db9c9c',
        background: '#c5c5c5',
        borderRight: '1px solid rgba(224,224,224,1)',
        color: theme.palette.text.primary,
        fontSize: '16px',
        alignContent: 'center'
      })
    },
    muiTableHeadProps: {
      sx: {
        height: '60px'
      }
    },
    muiTableBodyCellProps: {
      align: 'center',
      sx: {
        borderRight: '1px solid rgba(224,224,224,1)',
        whiteSpace: 'normal',
        wordBreak: 'break-word'
      }
    },
    defaultColumn: {
      minSize: 50,
      maxSize: 400,
      size: 250
    },
    muiTableContainerProps: {
      sx: {
        height: 'calc(100vh - 150px)'
      }
    },
    displayColumnDefOptions: {
      'mrt-row-actions': {
        size: 100,
        grow: false
      }
    }
  });

  const getCustomerData = async () => {
    try {
      setIsLoading(true);
      const accessToken = await getToken();
      const response = await axios.get(`${apiUrl}/api/customer`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        }
      });

      if (Array.isArray(response.data)) {
        const formattedData = response.data.map((customer) => ({
          label: customer.customerName,
          value: customer.customerID
        }));
        setCustomerData([...formattedData].sort((a, b) => a.label.localeCompare(b.label)));
      } else {
        throw new Error('Unexpected response format');
      }
    } catch (error) {
      console.error(error);
      const errorMessage =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : 'An error occurred during the API call.';
      setAlertSeverity('error');
      setAlertMessage(errorMessage);
      setAlertOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCustomerData();
  }, []);

  useEffect(() => {
    const fetchPlatformProgramsAndMptProducts = async () => {
      try {
        const accessToken = await getToken();

        const response = await axios.get(
          `${apiUrl}/api/projects/search?mptProduct=mptproduct&platformAndProgram=platformandprogram`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json'
            }
          }
        );

        const { platformAndProgram, mptProduct } = response.data;

        setPlatformProgramsOptions(platformAndProgram || []);
        setMptProductsOptions(mptProduct || []);
      } catch (error) {
        console.error('Error fetching platform and programs and MPT products:', error);
      }
    };
    fetchPlatformProgramsAndMptProducts();
  }, []);

  return (
    <>
      {isLoading && <ProgressSpinner />}
      <AlertMessage
        open={alertOpen}
        message={alertMessage}
        severity={alertSeverity}
        onClose={() => setAlertOpen(false)}
      />
      <Box
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Box>
          <BackToDashboard />
        </Box>
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            m: '1rem'
          }}
        >
          <MaterialReactTable table={table} />
        </Box>

        {openEditDialog && (
          <Dialog open={openEditDialog} disableRestoreFocus onClose={handleEditDialogClose}>
            <DialogTitle variant="h4" sx={{ textAlign: 'center', color: '#800000' }}>
              Edit Project
            </DialogTitle>

            <DialogContent>
              <FormControl fullWidth margin="normal">
                <TextField
                  name="projectID"
                  label="Project ID"
                  defaultValue={selectedRow?.projectID || ''}
                  disabled
                  fullWidth
                />
              </FormControl>

              <FormControl fullWidth margin="normal">
                <TextField
                  select
                  name="status"
                  value={selectedRow?.status || 'active'}
                  onChange={handleEditChange}
                  label="Status"
                  required
                >
                  <MenuItem value="active">Active</MenuItem>
                  <MenuItem value="paused">On Hold</MenuItem>
                </TextField>
              </FormControl>

              <FormControl fullWidth margin="normal">
                <Autocomplete
                  options={customerData}
                  value={selectedRow?.customerName}
                  onChange={(event, newValue) => handleAutoCompleteChange('customerName', newValue)}
                  renderInput={(params) => <TextField {...params} required label="Customer Name" />}
                  sx={{ flexGrow: 1 }}
                />
              </FormControl>

              <FormControl fullWidth margin="normal">
                <Autocomplete
                  options={customerData}
                  value={selectedRow?.contractPartner}
                  onChange={(event, newValue) =>
                    handleAutoCompleteChange('contractPartner', newValue)
                  }
                  renderInput={(params) => <TextField {...params} label="Contract Partner" />}
                />
              </FormControl>

              <FormControl fullWidth margin="normal">
                <Autocomplete
                  options={platformProgramsOptions.sort()}
                  getOptionLabel={(option) => option || ''}
                  value={selectedRow.platformAndProgram}
                  onChange={(event, newValue) =>
                    handleAutoCompleteChange('platformAndProgram', newValue)
                  }
                  onInputChange={(event, newInputValue) =>
                    handleAutoCompleteChange('platformAndProgram', newInputValue)
                  }
                  freeSolo
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      label="Platform and Programs"
                      variant="outlined"
                    />
                  )}
                />
              </FormControl>

              <FormControl fullWidth margin="normal">
                <Autocomplete
                  options={mptProductsOptions.sort()}
                  getOptionLabel={(option) => option || ''}
                  value={selectedRow.mptProduct}
                  onChange={(event, newValue) => handleAutoCompleteChange('mptProduct', newValue)}
                  onInputChange={(event, newInputValue) =>
                    handleAutoCompleteChange('mptProduct', newInputValue)
                  }
                  freeSolo
                  renderInput={(params) => (
                    <TextField {...params} required label="MPT Products" variant="outlined" />
                  )}
                />
              </FormControl>

              <FormControl fullWidth margin="normal">
                <TextField
                  name="projectSpecific"
                  value={selectedRow.projectSpecific}
                  onChange={handleEditChange}
                  label="Project Specific"
                />
              </FormControl>

              <FormControl fullWidth margin="normal">
                <Tooltip title={projectName} arrow placement="top">
                  <TextField
                    name="projectName"
                    value={projectName}
                    label="Project Name"
                    required
                    InputProps={{
                      readOnly: true,
                      sx: {
                        backgroundColor: 'action.disabledBackground',
                        '& .MuiInputBase-input': {
                          color: 'text.disabled',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap'
                        }
                      }
                    }}
                  />
                </Tooltip>
              </FormControl>

              <FormControl fullWidth margin="normal">
                <Autocomplete
                  options={projectLeads.map((option) => option.username)}
                  value={selectedRow?.projectLead || ''}
                  disableClearable
                  fullWidth
                  onChange={(event, newValue) => {
                    handleEditChange({
                      target: {
                        name: 'projectLead',
                        value: newValue || null
                      }
                    });
                  }}
                  renderInput={(params) => <TextField {...params} label="Project Lead" />}
                />
              </FormControl>

              <FormControl fullWidth margin="normal">
                <Autocomplete
                  options={clusterNameOptions}
                  value={selectedRow?.clusterName || ''}
                  disableClearable
                  fullWidth
                  onChange={(event, newValue) => {
                    handleEditChange({
                      target: {
                        name: 'clusterName',
                        value: newValue || null
                      }
                    });
                  }}
                  renderInput={(params) => <TextField {...params} label="Cluster Name" />}
                />
              </FormControl>

              <FormControl fullWidth margin="normal">
                <TextField
                  name="projectDescription"
                  label="Project Description"
                  value={selectedRow?.projectDescription || ''}
                  onChange={handleEditChange}
                  multiline
                  rows={2}
                  fullWidth
                />
              </FormControl>

              <FormControl fullWidth margin="normal">
                <TextField
                  name="simplifiedName"
                  label="Simplified Name"
                  value={selectedRow?.simplifiedName || ''}
                  onChange={handleEditChange}
                  fullWidth
                />
              </FormControl>

              <FormControl fullWidth margin="normal">
                <TextField
                  name="PVsequenceID"
                  label="PV SequenceID"
                  value={selectedRow?.PVsequenceID}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value === '' || /^[0-9]+$/.test(value)) {
                      handleEditChange(e);
                    }
                  }}
                  slotProps={{
                    input: {
                      inputMode: 'numeric',
                      pattern: '[0-9]*'
                    }
                  }}
                />
              </FormControl>

              <FormControl fullWidth margin="normal">
                <TextField
                  name="CFNumber"
                  label="CF Number"
                  value={selectedRow?.CFNumber}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value === '' || /^[0-9]+$/.test(value)) {
                      handleEditChange(e);
                    }
                  }}
                  slotProps={{
                    input: {
                      inputMode: 'numeric',
                      pattern: '[0-9]*'
                    }
                  }}
                />
              </FormControl>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center' }}>
              <Button onClick={handleEditDialogClose} variant="outlined">
                Cancel
              </Button>
              <Button onClick={handleEditProjectSave} variant="contained">
                Save
              </Button>
            </DialogActions>
          </Dialog>
        )}

        {openDelDialog && (
          <Dialog
            open={openDelDialog}
            disableRestoreFocus
            onClose={handleDelDialogClose}
            maxWidth="xs"
            fullWidth
          >
            <DialogTitle sx={{ color: '#800000', fontWeight: 'bold' }}>Delete Project?</DialogTitle>
            <DialogContent>
              <DialogContentText>Are yo sure you wanna delete the project?</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDelDialogClose} variant="outlined">
                No
              </Button>
              <Button onClick={handleDelProject} variant="contained">
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Box>
    </>
  );
};

export default ProjectActions;
