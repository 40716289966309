import { createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: '#800000',
      light: '#a33b3b',
      dark: '#6d1f1f',
      contrastText: '#ffffff'
    }
  },
  components: {
    MuiSwitch: {
      styleOverrides: {
        thumb: {
          width: 16,
          height: 16,
          color: '#800000'
        },
        track: {
          height: 12,
          borderRadius: 10
        },

        root: {
          marginRight: 5
        }
      }
    }
  }
});

export default theme;
