import React, { useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { Container, Typography, Button, Box, TextField, Divider } from '@mui/material';
import { loginRequest } from '../../authConfig.js';
import ProgressSpinner from '../../Components/ProgressSpinner.jsx';
import AlertMessage from '../../Components/AlertMessage.jsx';
import BackToDashboard from '../../Components/BackToDashboard.jsx';

const Support = () => {
  const { accounts, instance: msalInstance } = useMsal();
  const loggedInAccount = accounts[0];
  const roles = loggedInAccount?.idTokenClaims?.roles || [];
  const username = loggedInAccount?.name || 'User';
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [alertOpen, setAlertOpen] = useState(false);
  const [userMessage, setUserMessage] = useState('');

  const roleMapping = {
    'eQuote.Manager': 'eQuote Manager',
    'eQuote.ProjectLead': 'eQuote Lead',
    'eQuote.Reviewer': 'eQuote Reviewer',
    'eQuote.Approver': 'eQuote Approver',
    'eQuote.ReportReader': 'eQuote Report Reader',
    'eQuote.Uploader': 'eQuote Uploader',
    'QVD.Admin': 'QVD Admin'
  };

  const handleAlertClose = () => setAlertOpen(false);

  const getAccessToken = async () => {
    try {
      const tokenResponse = await msalInstance.acquireTokenSilent(loginRequest);
      return tokenResponse.accessToken;
    } catch (error) {
      console.log('Error acquiring token:', error);
    }
  };

  const sendMail = async () => {
    if (!userMessage) {
      setAlertMessage('Please enter a message before sending.');
      setAlertSeverity('warning');
      setAlertOpen(true);
      return;
    }

    setLoading(true);
    const accessToken = await getAccessToken();

    const mappedRoles = roles.map((role) => roleMapping[role] || role).join(', ');
    const formattedMessage = `Hello Support Team,\n\n${userMessage}\n\nBest Regards,\n${username}\nRole: ${mappedRoles}`;

    const mailData = {
      message: {
        subject: '[QuoVaDis] Support Request',
        body: {
          contentType: 'Text',
          content: formattedMessage
        },
        toRecipients: [
          {
            emailAddress: {
              address: process.env.REACT_APP_ADMIN_MAIL_EU
            }
          }
        ],
        bccRecipients: [
          {
            emailAddress: {
              address: process.env.REACT_APP_ADMIN_EMAIL_DEF
            }
          }
        ]
      }
    };

    try {
      await fetch('https://graph.microsoft.com/v1.0/me/sendMail', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(mailData)
      });

      setAlertMessage('Mail sent successfully to support team!');
      setAlertSeverity('success');
      setUserMessage('');
    } catch (error) {
      console.error('Error sending mail:', error);
      setAlertMessage('Failed to send mail. Please try again later.');
      setAlertSeverity('error');
    } finally {
      setLoading(false);
      setAlertOpen(true);
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#ebebeb'
      }}
    >
      <Box>
        <BackToDashboard />
      </Box>

      <Container maxWidth="sm" sx={{ marginTop: 4 }}>
        <Typography variant="body2" color="textSecondary" sx={{ marginBottom: 2 }}>
          Briefly describe your concern or queries.
        </Typography>

        <TextField
          label="Your Message"
          multiline
          rows={4}
          fullWidth
          variant="outlined"
          value={userMessage}
          onChange={(e) => setUserMessage(e.target.value)}
          sx={{ marginBottom: 2 }}
        />

        <Button
          variant="contained"
          color="primary"
          onClick={sendMail}
          disabled={loading}
          sx={{ marginTop: 2 }}
        >
          {loading ? <ProgressSpinner /> : 'Send Mail'}
        </Button>

        <AlertMessage
          open={alertOpen}
          severity={alertSeverity}
          onClose={handleAlertClose}
          message={alertMessage}
        />

        <Box sx={{ marginTop: 4 }}>
          <Divider />
          <Typography variant="h5" gutterBottom sx={{ marginTop: 2 }}>
            Mail Preview
          </Typography>
          <Typography variant="body1">
            <strong>To:</strong> equoting.eu.mpt@magna.com
          </Typography>
          <Typography variant="body1" sx={{ marginTop: 1 }}>
            <strong>Subject:</strong> "[QuoVaDis] Support Request"
          </Typography>
          <Typography variant="body1" sx={{ marginTop: 1, whiteSpace: 'pre-line' }}>
            <strong>Message:</strong>
            <br />
            Hello Support Team,
            <br />
            <br />
            {userMessage || 'Your message will appear here'}
            <br />
            <br />
            Best Regards,
            <br />
            {username}
            <br />
            Role: {roles.map((role) => roleMapping[role] || role).join(', ') || 'No roles assigned'}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Support;
