export const review = [
  {
    manager: 'Auweck, Malte',
    workPackageId: ['HYD']
  },
  {
    manager: 'Bassler, Michael',
    workPackageId: ['HOUS']
  },
  {
    manager: 'Bausch, Jochen',
    workPackageId: ['APD']
  },
  {
    manager: 'Bolga, Stephen',
    workPackageId: ['TOL']
  },
  {
    manager: 'Cokdogru, Inan',
    workPackageId: ['G7S', 'GEAR', 'MAT', 'DIFF', 'SEAL', 'OIL']
  },
  {
    manager: 'Faix, Gerrit',
    workPackageId: ['CLS', 'CSV', 'ASV']
  },
  {
    manager: 'Gremplini, Hansi',
    workPackageId: ['DVE', 'STE']
  },
  {
    manager: 'Hollnaeck, Tim',
    workPackageId: ['SFIE', 'DRV', 'VHT']
  },
  {
    manager: 'Ludwig, Michael',
    workPackageId: ['eHWM3', 'eHWPM', 'eHWE', 'eHWPL', 'eHWTS']
  },
  {
    manager: 'Meissner, Joerg',
    workPackageId: ['HYB']
  },
  {
    manager: 'Nixdorf, Wolfram',
    workPackageId: ['EFF', 'LUB', 'THM']
  },
  {
    manager: 'Rupp, Steffen',
    workPackageId: ['eDR']
  },
  {
    manager: 'Ruttloff, Torben',
    workPackageId: ['TMAT', 'TMES', 'TOP']
  },
  {
    manager: 'Schaarschmidt, Reihard',
    workPackageId: ['SYN', 'SHFT', 'SHFTV', 'PARK', 'PARKV']
  },
  {
    manager: 'Sommer, Klaus',
    workPackageId: ['PLV']
  },
  {
    manager: 'Trefz, Markus',
    workPackageId: ['CAE', 'HPDC', 'DUR']
  }
];
