import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Zoom,
  Menu,
  MenuItem
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ProgressSpinner from '../../Components/ProgressSpinner';
import AlertMessage from '../../Components/AlertMessage';
import Header from '../../Components/Header';
import axios from 'axios';
import msalInstance from '../../msalInstance.js';
import { loginRequest } from '../../authConfig.js';
import CockpitBoardView from '../../Components/CockpitBoardView.jsx';
import { TabContext, TabPanel } from '@mui/lab';
import CockpitTableView from '../../Components/CockpitTableView.jsx';
import {
  Add,
  CloseOutlined,
  DashboardCustomize,
  GridView,
  List,
  SpaceDashboard,
  Upgrade
} from '@mui/icons-material';
import CockpitPersonalView from '../../Components/CockpitPersonalView.jsx';
import useHasRole from '../../hooks/useHasRole.js';

const Cockpit = () => {
  const [loading, setLoading] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [tabValueUpdated, setTabValueUpdated] = useState(false);
  const [quoteData, setQuoteData] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [newTabName, setNewTabName] = useState('');
  const [existingData, setExistingData] = useState([]);
  const [tabValue, setTabValue] = useState('0');
  const [userId, setUserId] = useState();
  const [anchorEl, setAnchorEl] = useState(null);

  const navigate = useNavigate();

  const apiUrl = process.env.REACT_APP_API_URL;

  const isAllowedRoles = useHasRole(['QVD.Admin', 'eQuote.Manager', 'eQuote.ProjectLead']);

  const columnVisibilityValue = {
    eQuoteNumber: true,
    eQuoteVersion: true,
    projectName: true,
    eQuoteLeadName: true,
    secondLeadName: true,
    eQuoteType: true,
    region: true,
    eQuoteShortName: true,
    eQuoteDescription: true,
    eQuoteCharacter: true,
    requestType: true,
    creation_date: true,
    creatorName: true,
    eQuoteCustomerRequestDate: true,
    eQuoteKickoffDate: true,
    quoteFinishTargetDate: true,
    eQuoteDueDate: true,
    eQuoteStatus: true,
    DocumentationStatus: true,
    customerName: true,
    contractPartner: true,
    platformAndProgram: true,
    mptProduct: true,
    projectSpecific: true,
    projectLead: true,
    clusterName: true,
    projectDescription: true,
    simplifiedName: true,
    PVsequenceID: true,
    CFNumber: true,
    Q1Q2Date: true,
    customerDueDate: true,
    releaseDate: true,
    quoteGlobalReleaseDate: true,
    releaseNotes: true,
    meetingParticipants: true,
    fxRate: true,
    remarks: true,
    RS1OriginalCurrency: true,
    RS0OriginalCurrency: true,
    storageInformation: true
  };

  const columnOrderValue = [
    'mrt-row-select',
    'eQuoteNumber',
    'eQuoteVersion',
    'projectName',
    'eQuoteLeadName',
    'secondLeadName',
    'eQuoteType',
    'region',
    'eQuoteShortName',
    'eQuoteDescription',
    'eQuoteCharacter',
    'requestType',
    'creation_date',
    'creatorName',
    'eQuoteCustomerRequestDate',
    'eQuoteKickoffDate',
    'quoteFinishTargetDate',
    'eQuoteDueDate',
    'eQuoteStatus',
    'DocumentationStatus',
    'customerName',
    'contractPartner',
    'platformAndProgram',
    'mptProduct',
    'projectSpecific',
    'projectLead',
    'clusterName',
    'projectDescription',
    'simplifiedName',
    'PVsequenceID',
    'CFNumber',
    'Q1Q2Date',
    'customerDueDate',
    'releaseDate',
    'quoteGlobalReleaseDate',
    'releaseNotes',
    'meetingParticipants',
    'fxRate',
    'remarks',
    'RS1OriginalCurrency',
    'RS0OriginalCurrency',
    'storageInformation'
  ];

  const getToken = async () => {
    try {
      const tokenResponse = await msalInstance.acquireTokenSilent(loginRequest);
      const accessToken = tokenResponse.idToken;
      return accessToken;
    } catch (error) {
      console.log(error);
    }
  };

  const getUserData = async () => {
    try {
      setLoading(true);
      const accessToken = await getToken();
      const response = await axios.get(`${apiUrl}/api/user`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        }
      });
      const data = response.data;
      setUserId(data.userID);
      const storedData = JSON.parse(localStorage.getItem(`user_${data.userID}`)) || [];
      setExistingData(storedData);
      setTabValue(sessionStorage.getItem('tabValue') || '0');
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getToken();
        await axios.post(`${apiUrl}/api/user`, null, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  useEffect(() => {
    getUserData();
  }, []);

  const getData = async () => {
    try {
      setLoading(true);
      const accessToken = await getToken();

      const quoteResponse = await axios.get(
        `${apiUrl}/api/equotes/approvalStatus?eQuoteNumberApproval=Approved`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      );
      let eQuoteData = quoteResponse.data;
      try {
        const usersResponse = await axios.get(`${apiUrl}/api/user/all`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          }
        });
        const usersData = usersResponse.data;
        const userMap = usersData.reduce((acc, user) => {
          acc[user.userID] = user.username;
          return acc;
        }, {});

        eQuoteData = eQuoteData.map((item) => ({
          ...item,
          creatorName: userMap[item.creatorID],
          eQuoteLeadName: userMap[item.eQuoteLead],
          secondLeadName: userMap[item.secondLead]
        }));
      } catch (userError) {
        console.error('All User API failed:', userError);
        setAlertSeverity('warning');
        setAlertMessage(
          'Users data could not be loaded. Displaying quotes without Creator details.'
        );
        setAlertOpen(true);
      }
      try {
        const projectResponse = await axios.get(`${apiUrl}/api/projects`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          }
        });
        const projectData = projectResponse.data;

        eQuoteData = eQuoteData.map((quote) => {
          const matchedProject = projectData.find(
            (project) => project.projectID === quote.projectID
          );
          return {
            ...quote,
            ...matchedProject
          };
        });
      } catch (projectError) {
        console.error('Project API failed:', projectError);
        if (projectError.response && projectError.response.status === 404) {
          return;
        }
        setAlertSeverity('warning');
        setAlertMessage(
          'Projects data could not be loaded. Displaying quotes without project details.'
        );
        setAlertOpen(true);
      }
      const sortedQuoteData = [...eQuoteData].sort((a, b) => b.quoteID - a.quoteID);
      setQuoteData(sortedQuoteData);
    } catch (error) {
      console.error(error);
      const errorMessage =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : 'An error occurred during the API call.';
      setAlertSeverity('error');
      setAlertMessage(errorMessage);
      setAlertOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigate = (path) => {
    navigate(path);
    handleClose();
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (tabValueUpdated) {
      sessionStorage.setItem('tabValue', tabValue);
      const storedData = JSON.parse(localStorage.getItem(`user_${userId}`)) || [];
      setExistingData(storedData);
    }
  }, [tabValue]);

  const handleDataChange = (action) => {
    const storedData = JSON.parse(localStorage.getItem(`user_${userId}`)) || [];
    setExistingData(storedData);
    if (action === 'deleted') {
      setTabValue((tabValue - 1).toString());
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setTabValueUpdated(true);
    getData();
  };

  const handleAddTab = () => {
    setDialogOpen(true);
  };

  const handleDialogSave = () => {
    const storedData = existingData;
    const newTabData = {
      newTabName,
      columnFilterValue: [],
      columnVisibilityValue,
      columnOrderValue
    };
    storedData.push(newTabData);
    localStorage.setItem(`user_${userId}`, JSON.stringify(storedData));
    setExistingData(storedData);
    setNewTabName('');
    handleDialogClose();
    setTabValue((storedData.length + 1).toString());
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return (
    <>
      {loading && <ProgressSpinner />}
      <AlertMessage
        open={alertOpen}
        message={alertMessage}
        severity={alertSeverity}
        onClose={() => setAlertOpen(false)}
      />
      <Box
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Box>
          <Header />
        </Box>

        <TabContext value={tabValue}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <Box flex="2" display="flex" sx={{ maxWidth: 1200 }}>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
                sx={{
                  '& .MuiTab-root.Mui-selected': {
                    fontWeight: 700
                  }
                }}
              >
                <Tab label="List View" icon={<List />} value="0" iconPosition="start" />
                <Tab label="Board View" icon={<GridView />} value="1" iconPosition="start" />

                {existingData.map((item, index) => (
                  <Tab
                    key={index}
                    value={`${index + 2}`}
                    label={item.newTabName}
                    icon={<SpaceDashboard />}
                    iconPosition="start"
                  />
                ))}
              </Tabs>
              <Button
                disabled={existingData.length > 4}
                sx={{
                  color: '#6e6e6e',
                  margin: 'auto 1.5rem',
                  height: '2rem',
                  minWidth: 150,
                  '&:hover': {
                    backgroundColor: '#6d1f1f',
                    color: 'white'
                  },
                  '& .MuiButton-startIcon': {
                    marginRight: '0px'
                  }
                }}
                onClick={handleAddTab}
                startIcon={<DashboardCustomize />}
              >
                New View
              </Button>
            </Box>
            {isAllowedRoles && (
              <Box>
                <Tooltip
                  title="eQuote Options"
                  arrow
                  placement="top"
                  TransitionComponent={Zoom}
                  slotProps={{ tooltip: { sx: { fontSize: '0.9em' } } }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      mr: '1rem'
                    }}
                    onClick={handleClick}
                  >
                    <Add />
                  </Button>
                </Tooltip>

                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                  <MenuItem onClick={() => handleNavigate('/equote-generate')}>
                    <Add sx={{ mr: 1 }} /> Add eQuote
                  </MenuItem>
                  <MenuItem onClick={() => handleNavigate('/equote-version-update')}>
                    <Upgrade sx={{ mr: 1 }} /> Update eQuote Version
                  </MenuItem>
                </Menu>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              m: '1rem',
              backgroundColor: '#ebebeb',
              borderRadius: '10px',
              flex: 1,
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <TabPanel value="0">
              <CockpitTableView quoteData={quoteData} isLoading={loading} />
            </TabPanel>
            <TabPanel value="1">
              <CockpitBoardView quoteData={quoteData} />
            </TabPanel>

            {existingData.map((item, index) => (
              <TabPanel key={index} value={`${index + 2}`}>
                <CockpitPersonalView
                  quoteData={quoteData}
                  viewData={item}
                  userId={userId}
                  onDataChange={handleDataChange}
                  isLoading={loading}
                />
              </TabPanel>
            ))}
          </Box>
        </TabContext>

        <Dialog
          open={dialogOpen}
          onClose={handleDialogClose}
          maxWidth="xs"
          fullWidth
          disableRestoreFocus
        >
          <IconButton
            onClick={handleDialogClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: '#800000'
            }}
          >
            <CloseOutlined />
          </IconButton>

          <DialogTitle sx={{ color: '#800000', fontWeight: 'bold' }}>Add New View</DialogTitle>

          <DialogContent>
            <TextField
              focused
              autoFocus
              margin="dense"
              label="View Name"
              fullWidth
              variant="outlined"
              value={newTabName}
              slotProps={{
                htmlInput: { maxLength: 30 }
              }}
              helperText={
                newTabName.length >= 30 ? `Max characters allowed: 30` : `${newTabName.length}/30`
              }
              onChange={(e) => setNewTabName(e.target.value)}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    borderColor: '#6e6e6e'
                  }
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: '#6e6e6e'
                }
              }}
            />
          </DialogContent>
          <DialogActions
            sx={{
              padding: '0 1.5rem 1rem 1.5rem'
            }}
          >
            <Button
              variant="outlined"
              onClick={handleDialogClose}
              sx={{
                color: '#484547',
                borderColor: '#484547',
                textTransform: 'none',
                borderRadius: '10px',
                '&:hover': {
                  backgroundColor: '#d3d3d3',
                  borderColor: '#d3d3d3'
                }
              }}
            >
              Cancel
            </Button>

            <Button
              onClick={handleDialogSave}
              variant="contained"
              sx={{
                textTransform: 'none',
                borderRadius: '10px'
              }}
            >
              Add
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};

export default Cockpit;
