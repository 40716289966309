import React from 'react';
import { MsalProvider } from '@azure/msal-react';
import msalInstance from './msalInstance';
import { Routes, Route } from 'react-router-dom';
import PageLayout from './Components/PageLayout';
import ErrorBoundary from './Components/ErrorBoundary';
import { NotificationProvider } from './context/NotificationContext';

function App() {
  return (
    <MsalProvider instance={msalInstance}>
      <NotificationProvider>
        <ErrorBoundary>
          <Routes>
            <Route path="/*" element={<PageLayout />} />
          </Routes>
        </ErrorBoundary>
      </NotificationProvider>
    </MsalProvider>
  );
}

export default App;
