import React, { useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import Header from '../../Components/Header';
import './PSP_Quote.css';
import BackToDashboard from '../../Components/BackToDashboard';

const PSP_Quote = () => {
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 200,
      resizable: true,
      editable: false,
      sortable: false,
      filter: true
    };
  });
  const [rowData, setRowData] = useState([
    {
      id: '[DP1]',
      id_content: 'main',
      name: 'High speed endurance test',
      organizationalGroup: 'TR-020-Testrigs',
      initiator: 'M0001',
      workPlan: null,
      relatedTo: null,
      sequence: null,
      ecc_trc: null,
      destructive: null,
      phase: null,
      gate: null,
      remark: null,
      duration: null,
      durationFactor: null,
      durationCalculated: null,
      durationTotal: null,
      costs: null,
      totalCosts: null,
      manager: null,
      performedBy: 'MAGNA',
      appNo: null,
      bc2: null,
      sd1: null,
      sd2: null,
      sd3: '1',
      bSample: null,
      sd4: '1',
      cSample: null,
      sd5: null,
      dSample: null,
      sd6: null
    },
    {
      id: '[DP1]',
      id_content: 'submain',
      name: 'High speed endurance test',
      organizationalGroup: 'TR-020-Testrigs',
      initiator: 'SBB-000_Product Line Verfication',
      workPlan: 'BB-010_New gear set(gears/FD/ratio)',
      relatedTo: null,
      sequence: null,
      ecc_trc: '4M-48V',
      destructive: null,
      phase: 'SD3',
      gate: 'G3',
      remark: 'different',
      duration: '75',
      durationFactor: '1.5',
      durationCalculated: '112.5',
      durationTotal: '112.5',
      costs: null,
      totalCosts: null,
      manager: null,
      performedBy: 'MAGNA',
      appNo: 1,
      bc2: null,
      sd1: null,
      sd2: null,
      sd3: '1',
      bSample: null,
      sd4: null,
      cSample: null,
      sd5: null,
      dSample: null,
      sd6: null
    },
    {
      id: '[DP1]',
      id_content: 'submain',
      name: 'High speed endurance test',
      organizationalGroup: 'TR-020-Testrigs',
      initiator: 'SBB-000_Product Line Verfication',
      workPlan: 'BB-010_New gear set(gears/FD/ratio)',
      relatedTo: null,
      sequence: null,
      ecc_trc: '4M-48V',
      destructive: null,
      phase: 'SD3',
      gate: 'G3',
      remark: 'different',
      duration: '75',
      durationFactor: '1.5',
      durationCalculated: '112.5',
      durationTotal: '112.5',
      costs: null,
      totalCosts: null,
      manager: null,
      performedBy: 'MAGNA',
      appNo: 1,
      bc2: null,
      sd1: null,
      sd2: null,
      sd3: '1',
      bSample: null,
      sd4: '1',
      cSample: null,
      sd5: null,
      dSample: null,
      sd6: null
    },
    {
      id: '[SRV]',
      id_content: 'main',
      name: 'Scuffling resistance',
      organizationalGroup: 'TR-020-Testrigs',
      initiator: 'M0004',
      workPlan: null,
      relatedTo: null,
      sequence: null,
      ecc_trc: null,
      destructive: null,
      phase: null,
      gate: null,
      remark: null,
      duration: null,
      durationFactor: null,
      durationCalculated: null,
      durationTotal: null,
      costs: null,
      totalCosts: null,
      manager: null,
      performedBy: 'MAGNA',
      appNo: null,
      bc2: null,
      sd1: null,
      sd2: null,
      sd3: '1',
      bSample: null,
      sd4: null,
      cSample: null,
      sd5: null,
      dSample: null,
      sd6: null
    },
    {
      id: '[SRV]',
      id_content: 'submain',
      name: 'Scuffling resistance',
      organizationalGroup: 'TR-020-Testrigs',
      initiator: 'SBB-000_Product Line Verfication',
      workPlan: 'BB-010_New gear set(gears/FD/ratio)',
      relatedTo: null,
      sequence: null,
      ecc_trc: '4M-48V',
      destructive: null,
      phase: 'SD3',
      gate: 'G3',
      remark: null,
      duration: '10',
      durationFactor: '1.5',
      durationCalculated: '15',
      durationTotal: '15',
      costs: null,
      totalCosts: null,
      manager: null,
      performedBy: 'MAGNA',
      appNo: 1,
      bc2: null,
      sd1: null,
      sd2: null,
      sd3: '1',
      bSample: null,
      sd4: '1',
      cSample: null,
      sd5: null,
      dSample: null,
      sd6: null
    },
    {
      id: '[SRV]',
      id_content: 'submain',
      name: 'Scuffling resistance',
      organizationalGroup: 'TR-020-Testrigs',
      initiator: 'SBB-000_Product Line Verfication',
      workPlan: 'BB-010_New gear set(gears/FD/ratio)',
      relatedTo: null,
      sequence: null,
      ecc_trc: '4M-48V',
      destructive: null,
      phase: 'SD3',
      gate: 'G3',
      remark: null,
      duration: '10',
      durationFactor: '1.5',
      durationCalculated: '15',
      durationTotal: '15',
      costs: null,
      totalCosts: null,
      manager: null,
      performedBy: 'MAGNA',
      appNo: 1,
      bc2: null,
      sd1: null,
      sd2: null,
      sd3: null,
      bSample: null,
      sd4: null,
      cSample: null,
      sd5: null,
      dSample: null,
      sd6: null
    },
    {
      id: '[DP2_DUROP]',
      id_content: 'main',
      name: 'Dyno load test/ Durability of Roatation',
      organizationalGroup: 'TR-020-Testrigs',
      initiator: 'M0002',
      workPlan: null,
      relatedTo: null,
      sequence: null,
      ecc_trc: null,
      destructive: null,
      phase: null,
      gate: null,
      remark: null,
      duration: null,
      durationFactor: null,
      durationCalculated: null,
      durationTotal: null,
      costs: null,
      totalCosts: null,
      manager: null,
      performedBy: 'MAGNA',
      appNo: null,
      bc2: null,
      sd1: null,
      sd2: null,
      sd3: '1',
      bSample: null,
      sd4: '1',
      cSample: null,
      sd5: null,
      dSample: null,
      sd6: null
    },
    {
      id: '[DP2_DUROP]',
      id_content: 'submain',
      name: 'Dyno load test/ Durability of Roatation',
      organizationalGroup: 'TR-020-Testrigs',
      initiator: 'TR-020-Testrigs',
      workPlan: 'BB-010_New gear set(gears/FD/ratio)',
      relatedTo: null,
      sequence: null,
      ecc_trc: '4M-48V',
      destructive: null,
      phase: 'SD3',
      gate: 'G3',
      remark: 'different',
      duration: 'x',
      durationFactor: '1.5',
      durationCalculated: 'err',
      durationTotal: 'err',
      costs: null,
      totalCosts: null,
      manager: null,
      performedBy: 'MAGNA',
      appNo: 1,
      bc2: null,
      sd1: null,
      sd2: null,
      sd3: '1',
      bSample: null,
      sd4: '1',
      cSample: null,
      sd5: null,
      dSample: null,
      sd6: null
    },
    {
      id: '[DP2_DUROP]',
      id_content: 'submain',
      name: 'Dyno load test/ Durability of Roatation',
      organizationalGroup: 'TR-020-Testrigs',
      initiator: 'TR-020-Testrigs',
      workPlan: 'BB-010_New gear set(gears/FD/ratio)',
      relatedTo: null,
      sequence: null,
      ecc_trc: '4M-48V',
      destructive: null,
      phase: 'SD3',
      gate: 'G3',
      remark: 'different',
      duration: 'x',
      durationFactor: '1.5',
      durationCalculated: 'err',
      durationTotal: 'err',
      costs: null,
      totalCosts: null,
      manager: null,
      performedBy: 'MAGNA',
      appNo: 1,
      bc2: null,
      sd1: null,
      sd2: null,
      sd3: '1',
      bSample: null,
      sd4: null,
      cSample: null,
      sd5: null,
      dSample: null,
      sd6: null
    },
    {
      id: '[DF6]',
      id_content: 'main',
      name: 'Full throttle acceleration',
      organizationalGroup: 'TR-020-Testrigs',
      initiator: 'M0009',
      workPlan: null,
      relatedTo: null,
      sequence: null,
      ecc_trc: null,
      destructive: null,
      phase: null,
      gate: null,
      remark: null,
      duration: null,
      durationFactor: null,
      durationCalculated: null,
      durationTotal: null,
      costs: null,
      totalCosts: null,
      manager: null,
      performedBy: 'MAGNA',
      appNo: null,
      bc2: null,
      sd1: null,
      sd2: null,
      sd3: '1',
      bSample: null,
      sd4: '1',
      cSample: null,
      sd5: null,
      dSample: null,
      sd6: null
    },
    {
      id: '[DF6]',
      id_content: 'submain',
      name: 'Full throttle acceleration',
      organizationalGroup: 'TR-020-Testrigs',
      initiator: 'SBB-000_Product Line Verification',
      workPlan: 'BB-010_New gear set(gears/FD/ratio)',
      relatedTo: null,
      sequence: null,
      ecc_trc: '4M-48V',
      destructive: null,
      phase: 'SD3',
      gate: 'G3',
      remark: null,
      duration: '24',
      durationFactor: '1.5',
      durationCalculated: '36',
      durationTotal: '36',
      costs: null,
      totalCosts: null,
      manager: null,
      performedBy: 'MAGNA',
      appNo: 1,
      bc2: null,
      sd1: null,
      sd2: null,
      sd3: '1',
      bSample: null,
      sd4: '1',
      cSample: null,
      sd5: null,
      dSample: null,
      sd6: null
    },
    {
      id: '[DF6]',
      id_content: 'submain',
      name: 'Full throttle acceleration',
      organizationalGroup: 'TR-020-Testrigs',
      initiator: 'SBB-000_Product Line Verification',
      workPlan: 'BB-010_New gear set(gears/FD/ratio)',
      relatedTo: null,
      sequence: null,
      ecc_trc: '4M-48V',
      destructive: null,
      phase: 'SD3',
      gate: 'G3',
      remark: null,
      duration: '24',
      durationFactor: '1.5',
      durationCalculated: '36',
      durationTotal: '36',
      costs: null,
      totalCosts: null,
      manager: null,
      performedBy: 'MAGNA',
      appNo: 1,
      bc2: null,
      sd1: null,
      sd2: null,
      sd3: '1',
      bSample: null,
      sd4: null,
      cSample: null,
      sd5: null,
      dSample: null,
      sd6: null
    },
    {
      id: '[DP1]',
      id_content: 'main',
      name: 'High speed endurance test',
      organizationalGroup: 'TR-020-Testrigs',
      initiator: 'M0007',
      workPlan: null,
      relatedTo: null,
      sequence: null,
      ecc_trc: null,
      destructive: null,
      phase: null,
      gate: null,
      remark: null,
      duration: null,
      durationFactor: null,
      durationCalculated: null,
      durationTotal: null,
      costs: null,
      totalCosts: null,
      manager: null,
      performedBy: 'MAGNA',
      appNo: null,
      bc2: null,
      sd1: null,
      sd2: null,
      sd3: '1',
      bSample: null,
      sd4: '1',
      cSample: null,
      sd5: null,
      dSample: null,
      sd6: null
    },
    {
      id: '[DP1]',
      id_content: 'submain',
      name: 'High speed endurance test',
      organizationalGroup: 'TR-020-Testrigs',
      initiator: 'SBB-000_Product Line Verfication',
      workPlan: 'BB-010_New gear set(gears/FD/ratio)',
      relatedTo: null,
      sequence: null,
      ecc_trc: '4M-48V',
      destructive: null,
      phase: 'SD3',
      gate: 'G3',
      remark: 'different',
      duration: '75',
      durationFactor: '1.5',
      durationCalculated: '112.5',
      durationTotal: '112.5',
      costs: null,
      totalCosts: null,
      manager: null,
      performedBy: 'MAGNA',
      appNo: 1,
      bc2: null,
      sd1: null,
      sd2: null,
      sd3: '1',
      bSample: null,
      sd4: null,
      cSample: null,
      sd5: null,
      dSample: null,
      sd6: null
    },
    {
      id: '[DP1]',
      id_content: 'submain',
      name: 'High speed endurance test',
      organizationalGroup: 'TR-020-Testrigs',
      initiator: 'SBB-000_Product Line Verfication',
      workPlan: 'BB-010_New gear set(gears/FD/ratio)',
      relatedTo: null,
      sequence: null,
      ecc_trc: '4M-48V',
      destructive: null,
      phase: 'SD3',
      gate: 'G3',
      remark: 'different',
      duration: '75',
      durationFactor: '1.5',
      durationCalculated: '112.5',
      durationTotal: '112.5',
      costs: null,
      totalCosts: null,
      manager: null,
      performedBy: 'MAGNA',
      appNo: 1,
      bc2: null,
      sd1: null,
      sd2: null,
      sd3: '1',
      bSample: null,
      sd4: '1',
      cSample: null,
      sd5: null,
      dSample: null,
      sd6: null
    },
    {
      id: '[SRV]',
      id_content: 'main',
      name: 'Scuffling resistance',
      organizationalGroup: 'TR-020-Testrigs',
      initiator: 'M0012',
      workPlan: null,
      relatedTo: null,
      sequence: null,
      ecc_trc: null,
      destructive: null,
      phase: null,
      gate: null,
      remark: null,
      duration: null,
      durationFactor: null,
      durationCalculated: null,
      durationTotal: null,
      costs: null,
      totalCosts: null,
      manager: null,
      performedBy: 'MAGNA',
      appNo: null,
      bc2: null,
      sd1: null,
      sd2: null,
      sd3: '1',
      bSample: null,
      sd4: null,
      cSample: null,
      sd5: null,
      dSample: null,
      sd6: null
    },
    {
      id: '[SRV]',
      id_content: 'submain',
      name: 'Scuffling resistance',
      organizationalGroup: 'TR-020-Testrigs',
      initiator: 'SBB-000_Product Line Verfication',
      workPlan: 'BB-010_New gear set(gears/FD/ratio)',
      relatedTo: null,
      sequence: null,
      ecc_trc: '4M-48V',
      destructive: null,
      phase: 'SD3',
      gate: 'G3',
      remark: null,
      duration: '10',
      durationFactor: '1.5',
      durationCalculated: '15',
      durationTotal: '15',
      costs: null,
      totalCosts: null,
      manager: null,
      performedBy: 'MAGNA',
      appNo: 1,
      bc2: null,
      sd1: null,
      sd2: null,
      sd3: '1',
      bSample: null,
      sd4: '1',
      cSample: null,
      sd5: null,
      dSample: null,
      sd6: null
    },
    {
      id: '[SRV]',
      id_content: 'submain',
      name: 'Scuffling resistance',
      organizationalGroup: 'TR-020-Testrigs',
      initiator: 'SBB-000_Product Line Verfication',
      workPlan: 'BB-010_New gear set(gears/FD/ratio)',
      relatedTo: null,
      sequence: null,
      ecc_trc: '4M-48V',
      destructive: null,
      phase: 'SD3',
      gate: 'G3',
      remark: null,
      duration: '10',
      durationFactor: '1.5',
      durationCalculated: '15',
      durationTotal: '15',
      costs: null,
      totalCosts: null,
      manager: null,
      performedBy: 'MAGNA',
      appNo: 1,
      bc2: null,
      sd1: null,
      sd2: null,
      sd3: null,
      bSample: null,
      sd4: null,
      cSample: null,
      sd5: null,
      dSample: null,
      sd6: null
    },
    {
      id: '[DP2_DUROP]',
      id_content: 'main',
      name: 'Dyno load test/ Durability of Roatation',
      organizationalGroup: 'TR-020-Testrigs',
      initiator: 'M0003',
      workPlan: null,
      relatedTo: null,
      sequence: null,
      ecc_trc: null,
      destructive: null,
      phase: null,
      gate: null,
      remark: null,
      duration: null,
      durationFactor: null,
      durationCalculated: null,
      durationTotal: null,
      costs: null,
      totalCosts: null,
      manager: null,
      performedBy: 'MAGNA',
      appNo: null,
      bc2: null,
      sd1: null,
      sd2: null,
      sd3: '1',
      bSample: null,
      sd4: '1',
      cSample: null,
      sd5: null,
      dSample: null,
      sd6: null
    },
    {
      id: '[DP2_DUROP]',
      id_content: 'submain',
      name: 'Dyno load test/ Durability of Roatation',
      organizationalGroup: 'TR-020-Testrigs',
      initiator: 'TR-020-Testrigs',
      workPlan: 'BB-010_New gear set(gears/FD/ratio)',
      relatedTo: null,
      sequence: null,
      ecc_trc: '4M-48V',
      destructive: null,
      phase: 'SD3',
      gate: 'G3',
      remark: 'different',
      duration: 'x',
      durationFactor: '1.5',
      durationCalculated: 'err',
      durationTotal: 'err',
      costs: null,
      totalCosts: null,
      manager: null,
      performedBy: 'MAGNA',
      appNo: 1,
      bc2: null,
      sd1: null,
      sd2: null,
      sd3: '1',
      bSample: null,
      sd4: '1',
      cSample: null,
      sd5: null,
      dSample: null,
      sd6: null
    },
    {
      id: '[DP2_DUROP]',
      id_content: 'submain',
      name: 'Dyno load test/ Durability of Roatation',
      organizationalGroup: 'TR-020-Testrigs',
      initiator: 'TR-020-Testrigs',
      workPlan: 'BB-010_New gear set(gears/FD/ratio)',
      relatedTo: null,
      sequence: null,
      ecc_trc: '4M-48V',
      destructive: null,
      phase: 'SD3',
      gate: 'G3',
      remark: 'different',
      duration: 'x',
      durationFactor: '1.5',
      durationCalculated: 'err',
      durationTotal: 'err',
      costs: null,
      totalCosts: null,
      manager: null,
      performedBy: 'MAGNA',
      appNo: 1,
      bc2: null,
      sd1: null,
      sd2: null,
      sd3: '1',
      bSample: null,
      sd4: null,
      cSample: null,
      sd5: null,
      dSample: null,
      sd6: null
    },
    {
      id: '[DF6]',
      id_content: 'main',
      name: 'Full throttle acceleration',
      organizationalGroup: 'TR-020-Testrigs',
      initiator: 'M0008',
      workPlan: null,
      relatedTo: null,
      sequence: null,
      ecc_trc: null,
      destructive: null,
      phase: null,
      gate: null,
      remark: null,
      duration: null,
      durationFactor: null,
      durationCalculated: null,
      durationTotal: null,
      costs: null,
      totalCosts: null,
      manager: null,
      performedBy: 'MAGNA',
      appNo: null,
      bc2: null,
      sd1: null,
      sd2: null,
      sd3: '1',
      bSample: null,
      sd4: '1',
      cSample: null,
      sd5: null,
      dSample: null,
      sd6: null
    },
    {
      id: '[DF6]',
      id_content: 'submain',
      name: 'Full throttle acceleration',
      organizationalGroup: 'TR-020-Testrigs',
      initiator: 'SBB-000_Product Line Verification',
      workPlan: 'BB-010_New gear set(gears/FD/ratio)',
      relatedTo: null,
      sequence: null,
      ecc_trc: '4M-48V',
      destructive: null,
      phase: 'SD3',
      gate: 'G3',
      remark: null,
      duration: '24',
      durationFactor: '1.5',
      durationCalculated: '36',
      durationTotal: '36',
      costs: null,
      totalCosts: null,
      manager: null,
      performedBy: 'MAGNA',
      appNo: 1,
      bc2: null,
      sd1: null,
      sd2: null,
      sd3: '1',
      bSample: null,
      sd4: '1',
      cSample: null,
      sd5: null,
      dSample: null,
      sd6: null
    },
    {
      id: '[DF6]',
      id_content: 'submain',
      name: 'Full throttle acceleration',
      organizationalGroup: 'TR-020-Testrigs',
      initiator: 'SBB-000_Product Line Verification',
      workPlan: 'BB-010_New gear set(gears/FD/ratio)',
      relatedTo: null,
      sequence: null,
      ecc_trc: '4M-48V',
      destructive: null,
      phase: 'SD3',
      gate: 'G3',
      remark: null,
      duration: '24',
      durationFactor: '1.5',
      durationCalculated: '36',
      durationTotal: '36',
      costs: null,
      totalCosts: null,
      manager: null,
      performedBy: 'MAGNA',
      appNo: 1,
      bc2: null,
      sd1: null,
      sd2: null,
      sd3: '1',
      bSample: null,
      sd4: null,
      cSample: null,
      sd5: null,
      dSample: null,
      sd6: null
    }
  ]);

  const [colDefs, setColDefs] = useState([
    {
      headerName: 'Project Structure Plan',
      children: [
        {
          headerName: 'ID',
          field: 'id',
          cellStyle: { textAlign: 'left' },
          pinned: 'left'
        },
        {
          headerName: 'Name',
          field: 'name',
          cellStyle: { textAlign: 'left' },
          pinned: 'left'
        }
      ]
    },
    {
      headerName: 'Organizational Group',
      field: 'organizationalGroup'
    },
    {
      headerName: 'Initiator',
      field: 'initiator'
    },
    {
      headerName: 'Work Plan',
      field: 'workPlan'
    },
    {
      headerName: 'Related To',
      field: 'relatedTo'
    },
    {
      headerName: 'Sequence',
      field: 'sequence'
    },
    {
      headerName: 'ECC/TRC',
      field: 'ecc_trc'
    },
    {
      headerName: 'Destructive',
      field: 'destrutive'
    },
    { headerName: 'Phase', field: 'phase' },
    {
      headerName: 'Gate',
      field: 'gate'
    },
    { headerName: 'Remarks', field: 'remarks' },
    { headerName: 'Duration', field: 'duration' },
    { headerName: 'Duration Factor', field: 'durationFactor' },
    { headerName: 'Duration Calculated', field: 'durationCalculated' },
    { headerName: 'Duration Total', field: 'durationToatal' },
    { headerName: 'Costs', field: 'costs' },
    { headerName: 'Total Cost', field: 'totalCost' },
    { headerName: 'Manager', field: 'manager' },
    { headerName: 'Performed By', field: 'performedBy' },
    { headerName: 'App.No.', field: 'appNo' },
    {
      headerName: 'Option 1',
      children: [
        {
          headerName: 'BMW-7HD3T300',
          children: [
            {
              headerName: 'BC2',
              field: 'bc2',
              width: 50
            },
            {
              headerName: 'SD1',
              field: 'sd1',
              width: 50
            },
            {
              headerName: 'SD2',
              field: 'sd2',
              width: 50
            },
            {
              headerName: 'SD3',
              field: 'sd3',
              width: 50
            },
            {
              headerName: 'B Sample',
              field: 'bSample',
              width: 50
            },
            {
              headerName: 'SD4',
              field: 'sd4',
              width: 50
            },
            {
              headerName: 'C Sample',
              field: 'cSample',
              width: 50
            },
            {
              headerName: 'SD5',
              field: 'sd5',
              width: 50
            },
            {
              headerName: 'D Sample',
              field: 'dSample',
              width: 50
            },
            {
              headerName: 'SD6',
              field: 'sd6',
              width: 50
            }
          ]
        }
      ]
    }
  ]);

  const getRowStyle = (params) => {
    if (params.data.id_content === 'main') {
      return { fontWeight: 'bold' };
    }
    return null;
  };

  return (
    <Box display="flex" height="100vh" flexDirection="column">
      <Box>
        <BackToDashboard />
      </Box>
      <Box
        sx={{
          m: '1rem',
          backgroundColor: '#ebebeb',
          borderRadius: '10px',
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}
      >
        <Box className="root-tab" m={2}>
          <div
            className="ag-theme-alpine"
            style={{ height: 'calc(100vh - 160px)', width: '100%', overflow: 'auto' }}
          >
            <AgGridReact
              rowData={rowData}
              columnDefs={colDefs}
              defaultColDef={defaultColDef}
              domLayout="normal"
              getRowStyle={getRowStyle}
            />
          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default PSP_Quote;
