import {
  Typography,
  Box,
  Button,
  FormControlLabel,
  Checkbox,
  MenuItem,
  MenuList,
  Divider
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { review as reviewData } from '../../Data/review';
import BackToDashboard from '../../Components/BackToDashboard';

const Review = () => {
  const navigate = useNavigate();
  const [checkboxes, setCheckboxes] = useState([
    { label: 'Q2', status: false },
    { label: 'SD1', status: false },
    { label: 'SD2', status: false },
    { label: 'SD3', status: false },
    { label: 'SD4', status: false },
    { label: 'SD5', status: false },
    { label: 'SD6', status: false }
  ]);
  const [softwareArr, setSoftwareArr] = useState([
    'LSA',
    'LSA3',
    'LSC1',
    'LSC2',
    'LSC3',
    'LSC4',
    'LSC5',
    'LSC6',
    'LSC8',
    'LSCS'
  ]);

  const [managerArr, setManagerArr] = useState([]);

  const [selectedItem, setSelectedItem] = useState([]);
  const [selectedClear, setSelectedClear] = useState([]);
  const [selectedWorkPackageID, setSelectedWorkPackageId] = useState([]);
  const [selectedWpItem, setSelectedWpItem] = useState([]);

  useEffect(() => {
    const dataArr = reviewData.map((item) => item.manager);
    setManagerArr(dataArr);
  }, []);

  const handleChecked = (event) => {
    const checked = event.target.checked;
    setCheckboxes(
      checkboxes.map((checkbox) => {
        if (checkbox.label === 'Q2') {
          return checkbox;
        }
        return { ...checkbox, status: checked };
      })
    );
  };

  const handleSelectClick = (data) => {
    setSelectedClear([]);
    if (selectedItem.includes(data)) {
      setSelectedItem((prevItems) => prevItems.filter((i) => i !== data));
    } else {
      setSelectedItem([...selectedItem, data]);
    }
  };

  useEffect(() => {
    if (selectedClear.length < 1) {
      const filData = reviewData.filter((item) => selectedItem.includes(item.manager));
      const workPackageId = filData.flatMap((item) => item.workPackageId);
      setSelectedWorkPackageId(workPackageId);
      setSelectedWpItem(workPackageId);
    }
  }, [selectedItem]);

  const handleClear = (data) => {
    setSelectedItem([]);
    if (data === 'clear') {
      setSelectedWorkPackageId([]);
    } else {
      setSelectedWorkPackageId(softwareArr);
      setSelectedWpItem(softwareArr);
    }
    setSelectedClear(data);
  };

  const handleWPClear = () => {
    setSelectedWpItem([]);
  };
  const handleWpClick = (data) => {
    if (selectedWpItem.includes(data)) {
      setSelectedWpItem((prevItems) => prevItems.filter((i) => i !== data));
    } else {
      setSelectedWpItem([...selectedWpItem, data]);
    }
  };

  return (
    <Box display="flex" flexDirection="column">
      <BackToDashboard />
      <Box display="flex" flexDirection="row" height="80vh" mt="1rem">
        <Box flex="1" mx="1rem" display="flex" justifyContent="space-around">
          <Box>
            <Typography fontWeight="600">Manager</Typography>
            <Box
              sx={{
                maxHeight: '100%',
                overflow: 'auto',
                paddingRight: '1rem',
                scrollbarWidth: 'thin',
                '&::-webkit-scrollbar': {
                  width: '0.4em'
                },
                '&::-webkit-scrollbar-track': {
                  background: '#f1f1f1'
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#c2c1c1',
                  borderRadius: '10px'
                }
              }}
            >
              <MenuList>
                <MenuItem
                  onClick={() => handleClear('clear')}
                  sx={{
                    fontSize: '14px'
                  }}
                >
                  Clear
                </MenuItem>
                <MenuItem
                  onClick={() => handleClear('software')}
                  selected={selectedClear === 'software'}
                  sx={{
                    '&.Mui-selected': {
                      backgroundColor: '#800000',
                      color: '#ffff'
                    },
                    '&.Mui-selected:hover': {
                      backgroundColor: '#800000',
                      color: '#ffff'
                    },
                    border: '1px solid #ffff',
                    fontSize: '14px'
                  }}
                >
                  SOFTWARE
                </MenuItem>
                {managerArr.map((item, index) => (
                  <MenuItem
                    onClick={() => handleSelectClick(item)}
                    selected={selectedItem.includes(item)}
                    multiple
                    key={index}
                    sx={{
                      '&.Mui-selected': {
                        backgroundColor: '#800000',
                        color: '#ffff'
                      },
                      '&.Mui-selected:hover': {
                        backgroundColor: '#800000',
                        color: '#ffff'
                      },
                      border: '1px solid #ffff',
                      fontSize: '14px'
                    }}
                  >
                    {item}
                  </MenuItem>
                ))}
              </MenuList>
            </Box>
          </Box>
          <Box>
            <Divider
              orientation="vertical"
              sx={{
                width: 2,
                height: '100%',
                backgroundColor: '#800000'
              }}
            />
          </Box>
        </Box>
        <Box flex="1" display="flex" justifyContent="space-around">
          <Box>
            <Typography fontWeight="600">Work Package Main ID</Typography>
            <Box
              sx={{
                maxHeight: '100%',
                overflow: 'auto',
                paddingRight: '1rem',
                scrollbarWidth: 'thin',
                '&::-webkit-scrollbar': {
                  width: '0.4em'
                },
                '&::-webkit-scrollbar-track': {
                  background: '#f1f1f1'
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#c2c1c1',
                  borderRadius: '10px'
                }
              }}
            >
              {selectedWorkPackageID.length > 0 && (
                <MenuList>
                  <MenuItem
                    onClick={handleWPClear}
                    sx={{
                      fontSize: '14px'
                    }}
                  >
                    Clear
                  </MenuItem>
                  {selectedWorkPackageID.map((item, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => handleWpClick(item)}
                      selected={selectedWpItem.includes(item)}
                      sx={{
                        '&.Mui-selected': {
                          backgroundColor: '#800000',
                          color: '#ffff'
                        },
                        '&.Mui-selected:hover': {
                          backgroundColor: '#800000',
                          color: '#ffff'
                        },
                        border: '1px solid #ffff',
                        fontSize: '14px'
                      }}
                    >
                      {item}
                    </MenuItem>
                  ))}
                </MenuList>
              )}
            </Box>
          </Box>
          <Box>
            <Divider
              orientation="vertical"
              sx={{
                width: 2,
                height: '100%',
                backgroundColor: '#800000'
              }}
            />
          </Box>
        </Box>
        <Box
          flex="3"
          display="flex"
          flexDirection="row"
          justifyContent="space-evenly"
          alignItems="flex-start"
        >
          <Box mt="1.5rem">
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    color: '#800000',
                    '&.Mui-checked': {
                      color: '#800000'
                    },
                    fontSize: '0.8rem'
                  }}
                  onChange={handleChecked}
                />
              }
              label="BMW - 7HDT300"
            />
          </Box>
          <Box display="flex" flexDirection="row">
            {checkboxes.map((item, index) => (
              <div key={index}>
                <Typography sx={{ fontSize: '0.8rem', fontWeight: '600' }}>{item.label}</Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={index === 0}
                      checked={item.status}
                      sx={{
                        color: '#800000',
                        '&.Mui-checked': {
                          color: '#800000'
                        }
                      }}
                    />
                  }
                />
              </div>
            ))}
          </Box>
        </Box>
      </Box>
      <Box m="0 2rem 0 auto">
        <Button
          variant="contained"
          onClick={() => {
            navigate('/');
          }}
          sx={{
            backgroundColor: '#800000'
          }}
        >
          CLOSE
        </Button>
      </Box>
    </Box>
  );
};

export default Review;
