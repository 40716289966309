import { Box } from '@mui/material';
import React, { useMemo, useState } from 'react';
import BackToDashboard from '../../Components/BackToDashboard';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { psp as pspData } from '../../Data/pre_psp';

const PreQuotePSP = () => {
  const [rowData, setRowData] = useState(pspData);
  const [colDefs, setColDefs] = useState([
    { headerName: 'SubProject', field: 'subProject' },
    { headerName: 'Region', field: 'region' },
    {
      headerName: 'Location',
      field: 'location'
    },
    { headerName: 'Organizational Function', field: 'OrganizationalFunction' },

    {
      headerName: 'Abbr. & Auth.',
      field: 'abbrAndAuth'
    },
    {
      headerName: 'Resource Requirement / Expenditures',
      field: 'resourceRequirement'
    },
    { headerName: 'Planview Work Package', field: 'planViewWorkPackage' },
    {
      headerName: 'Rig Category',
      field: 'rigCategory'
    },
    { headerName: 'Cost Category', field: 'costCategory' },
    {
      headerName: 'Note',
      field: 'note'
    },
    {
      headerName: 'FTE Ø',
      field: 'fteTeta'
    },

    { headerName: 'FTE', field: 'fte' },
    {
      headerName: 'Total $/€',
      field: 'total'
    },
    {
      headerName: '2023',
      children: [
        {
          headerName: 'Q1',
          field: 'year2023.Q1'
        },
        {
          headerName: 'Q2',
          field: 'year2023.Q2'
        },
        {
          headerName: 'Q3',
          field: 'year2023.Q3'
        },
        {
          headerName: 'Q4',
          field: 'year2023.Q4'
        }
      ]
    },
    {
      headerName: '2024',
      children: [
        {
          headerName: 'Q1',
          field: 'year2024.Q1'
        },
        {
          headerName: 'Q2',
          field: 'year2024.Q2'
        },
        {
          headerName: 'Q3',
          field: 'year2024.Q3'
        },
        {
          headerName: 'Q4',
          field: 'year2024.Q4'
        }
      ]
    },
    {
      headerName: '2025',
      children: [
        {
          headerName: 'Q1',
          field: 'year2025.Q1'
        },
        {
          headerName: 'Q2',
          field: 'year2025.Q2'
        },
        {
          headerName: 'Q3',
          field: 'year2025.Q3'
        },
        {
          headerName: 'Q4',
          field: 'year2025.Q4'
        }
      ]
    },
    {
      headerName: '2026',
      children: [
        {
          headerName: 'Q1',
          field: 'year2026.Q1'
        },
        {
          headerName: 'Q2',
          field: 'year2026.Q2'
        },
        {
          headerName: 'Q3',
          field: 'year2026.Q3'
        },
        {
          headerName: 'Q4',
          field: 'year2026.Q4'
        }
      ]
    },
    {
      headerName: '2027',
      children: [
        {
          headerName: 'Q1',
          field: 'year2027.Q1'
        },
        {
          headerName: 'Q2',
          field: 'year2027.Q2'
        },
        {
          headerName: 'Q3',
          field: 'year2027.Q3'
        },
        {
          headerName: 'Q4',
          field: 'year2027.Q4'
        }
      ]
    }
  ]);
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 200,
      resizable: true,
      editable: false,
      sortable: true,
      filter: true,
      wrapText: true
    };
  });

  return (
    <Box display="flex" height="100vh" flexDirection="column">
      <Box>
        <BackToDashboard />
      </Box>
      <Box
        sx={{
          m: '1rem',
          backgroundColor: '#ebebeb',
          borderRadius: '10px',
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}
      >
        <Box className="root-tab" m={2}>
          <div className="ag-theme-alpine" style={{ height: 'calc(100vh - 160px)', width: '100%' }}>
            <AgGridReact
              suppressCellSelection={true}
              rowData={rowData}
              columnDefs={colDefs}
              defaultColDef={defaultColDef}
              domLayout="normal"
            />
          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default PreQuotePSP;
