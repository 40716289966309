import { CloseOutlined } from '@mui/icons-material';
import {
  Box,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid2,
  IconButton,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';

const CockpitBoardView = ({ quoteData }) => {
  const [inWork, setInWork] = useState([]);
  const [inRelease, setInRelease] = useState([]);
  const [released, setReleased] = useState([]);
  const [uploaded, setUploaded] = useState([]);
  const [cancelled, setCancelled] = useState([]);
  const [pvStatus, setPvStatus] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);

  const filterData = (data) => {
    setInWork(data.filter((item) => item.eQuoteStatus === 'in work'));
    setInRelease(data.filter((item) => item.eQuoteStatus === 'in release'));
    setReleased(data.filter((item) => item.eQuoteStatus === 'released'));
    setUploaded(data.filter((item) => item.eQuoteStatus === 'uploaded'));
    setCancelled(data.filter((item) => item.eQuoteStatus === 'cancelled'));
    setPvStatus(data.filter((item) => item.eQuoteStatus === 'pv status'));
  };

  const transformText = (text) => {
    return text.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  useEffect(() => {
    filterData(quoteData);
  }, [quoteData]);

  const handleCardHover = (item) => {
    setSelectedCard(item);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedCard(null);
  };

  const CustomDivider = () => {
    return (
      <Divider
        orientation="vertical"
        flexItem
        sx={{
          minHeight: '70vh',
          width: '1.6px',
          backgroundColor: '#800000',
          margin: '1rem'
        }}
      />
    );
  };

  const cardItems = (items) => (
    <Grid2 container spacing={2}>
      {items.map((item, index) => (
        <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 4, xl: 4 }} key={index}>
          <Card
            style={{
              backgroundColor: '#a9a9a9',
              height: '100%',
              position: 'relative',
              overflow: 'hidden',
              transition: 'transform 0.3s ease, box-shadow 0.3s ease',
              boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
              cursor: 'pointer'
            }}
            onClick={() => handleCardHover(item)}
          >
            <CardContent
              style={{
                padding: '0.5rem',
                display: 'flex',
                flexDirection: 'column',
                height: '100%'
              }}
            >
              <Typography
                variant="body2"
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                {item.eQuoteNumber}
              </Typography>
              <Typography
                variant="body2"
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                {item.projectName}
              </Typography>
            </CardContent>
          </Card>
        </Grid2>
      ))}
    </Grid2>
  );

  const CustomItem = ({ titleText, contentText }) => {
    return (
      <>
        <Typography variant="body1" fontWeight="bold" sx={{ color: '#800000' }}>
          {titleText}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: '#333333',
            overflowWrap: 'break-word',
            wordBreak: 'break-all',
            whiteSpace: 'normal'
          }}
        >
          {contentText}
        </Typography>
      </>
    );
  };

  const CustomGrid = ({ cardHeadingText, cardItemText }) => {
    return (
      <Box minWidth={400}>
        <Typography
          variant="h6"
          fontWeight="bold"
          style={{
            color: '#800000',
            textAlign: 'center',
            marginBottom: '1rem',
            position: 'sticky',
            top: 0,
            backgroundColor: '#fff',
            boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
            borderRadius: '2px',
            zIndex: 1
          }}
        >
          {cardHeadingText}
        </Typography>
        {cardItems(cardItemText)}
      </Box>
    );
  };

  return (
    <Box
      sx={{
        height: 'calc(100vh - 260px)',
        width: '100%',
        overflow: 'scroll'
      }}
    >
      <Box display="flex" flexDirection="row">
        {/* Column for In Work */}
        <CustomGrid cardHeadingText="In Work" cardItemText={inWork} />
        <CustomDivider />
        {/* Column for In Release */}
        <CustomGrid cardHeadingText="In Release" cardItemText={inRelease} />
        <CustomDivider />
        {/* Column for Released */}
        <CustomGrid cardHeadingText="Released" cardItemText={released} />
        <CustomDivider />
        {/* Column for Uploaded */}
        <CustomGrid cardHeadingText="Uploaded" cardItemText={uploaded} />
        <CustomDivider />
        {/* Column for Cancelled */}
        <CustomGrid cardHeadingText="Cancelled" cardItemText={cancelled} />
        <CustomDivider />
        {/* Column for PV-Status */}
        <CustomGrid cardHeadingText="PV Status" cardItemText={pvStatus} />
      </Box>

      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        PaperProps={{
          sx: {
            backgroundColor: '#f5f5f5',
            padding: '16px'
          }
        }}
      >
        <DialogTitle sx={{ p: 2, textAlign: 'center', color: '#800000', fontWeight: 700 }}>
          eQuote Details
        </DialogTitle>
        <Box>
          <IconButton
            onClick={handleCloseDialog}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: '#800000'
            }}
          >
            <CloseOutlined />
          </IconButton>
        </Box>

        <DialogContent>
          {selectedCard && (
            <Box display="grid" gridTemplateColumns="3fr 3fr" gap={2} width="100%">
              <CustomItem titleText="eQuote Number:" contentText={selectedCard.eQuoteNumber} />
              <CustomItem titleText="eQuote Version:" contentText={selectedCard.eQuoteVersion} />
              <CustomItem titleText="Project Name:" contentText={selectedCard.projectName} />
              <CustomItem titleText="eQuote Lead:" contentText={selectedCard.eQuoteLeadName} />
              <CustomItem titleText="eQuote 2nd Lead:" contentText={selectedCard.secondLeadName} />
              <CustomItem titleText="eQuote Type:" contentText={selectedCard.eQuoteType} />
              <CustomItem titleText="Region:" contentText={selectedCard.region} />
              <CustomItem
                titleText="eQuote Short Name:"
                contentText={selectedCard.eQuoteShortName}
              />
              <CustomItem
                titleText="eQuote Description:"
                contentText={selectedCard.eQuoteDescription}
              />
              <CustomItem
                titleText="eQuote Character:"
                contentText={transformText(selectedCard.eQuoteCharacter)}
              />
              <CustomItem titleText="Request Type:" contentText={selectedCard.requestType} />
              <CustomItem
                titleText="Creation Date:"
                contentText={
                  selectedCard.creation_date
                    ? dayjs(selectedCard.creation_date).format('DD-MM-YYYY')
                    : selectedCard.creation_date
                }
              />
              <CustomItem titleText="Creator Name:" contentText={selectedCard.creatorName} />
              <CustomItem
                titleText="eQuote Customer Request Date:"
                contentText={
                  selectedCard.eQuoteCustomerRequestDate
                    ? dayjs(selectedCard.eQuoteCustomerRequestDate).format('DD-MM-YYYY')
                    : selectedCard.eQuoteCustomerRequestDate
                }
              />
              <CustomItem
                titleText="eQuote Kick-off Date:"
                contentText={
                  selectedCard.eQuoteKickoffDate
                    ? dayjs(selectedCard.eQuoteKickoffDate).format('DD-MM-YYYY')
                    : selectedCard.eQuoteKickoffDate
                }
              />
              <CustomItem
                titleText="eQuote Finished Target Date:"
                contentText={
                  selectedCard.quoteFinishTargetDate
                    ? dayjs(selectedCard.quoteFinishTargetDate).format('DD-MM-YYYY')
                    : selectedCard.quoteFinishTargetDate
                }
              />
              <CustomItem
                titleText="eQuote Due Date:"
                contentText={
                  selectedCard.eQuoteDueDate
                    ? dayjs(selectedCard.eQuoteDueDate).format('DD-MM-YYYY')
                    : selectedCard.eQuoteDueDate
                }
              />
              <CustomItem
                titleText="eQuote Status:"
                contentText={transformText(selectedCard.eQuoteStatus)}
              />
              <CustomItem
                titleText="Documentation Status:"
                contentText={selectedCard.DocumentationStatus}
              />
              <CustomItem titleText="Customer Name:" contentText={selectedCard.customerName} />
              <CustomItem
                titleText="Contract Partner:"
                contentText={selectedCard.contractPartner}
              />
              <CustomItem
                titleText="Platform and Program:"
                contentText={selectedCard.platformAndProgram}
              />
              <CustomItem titleText="MPT Product:" contentText={selectedCard.mptProduct} />
              <CustomItem
                titleText="Project Specific:"
                contentText={selectedCard.projectSpecific}
              />
              <CustomItem titleText="Project Lead:" contentText={selectedCard.projectLead} />
              <CustomItem titleText="clusterName:" contentText={selectedCard.clusterName} />
              <CustomItem
                titleText="Project Description:"
                contentText={selectedCard.projectDescription}
              />
              <CustomItem titleText="Simplified Name:" contentText={selectedCard.simplifiedName} />
              <CustomItem titleText="PV Sequence ID:" contentText={selectedCard.PVsequenceID} />
              <CustomItem titleText="CF Number:" contentText={selectedCard.CFNumber} />

              <CustomItem
                titleText="Q1/Q2 Date:"
                contentText={
                  selectedCard.Q1Q2Date
                    ? dayjs(selectedCard.Q1Q2Date).format('DD-MM-YYYY')
                    : selectedCard.Q1Q2Date
                }
              />
              <CustomItem
                titleText="Customer Due Date:"
                contentText={
                  selectedCard.customerDueDate
                    ? dayjs(selectedCard.customerDueDate).format('DD-MM-YYYY')
                    : selectedCard.customerDueDate
                }
              />
              <CustomItem
                titleText="Release Date:"
                contentText={
                  selectedCard.releaseDate
                    ? dayjs(selectedCard.releaseDate).format('DD-MM-YYYY')
                    : selectedCard.releaseDate
                }
              />
              <CustomItem
                titleText="Global Release Date:"
                contentText={
                  selectedCard.quoteGlobalReleaseDate
                    ? dayjs(selectedCard.quoteGlobalReleaseDate).format('DD-MM-YYYY')
                    : selectedCard.quoteGlobalReleaseDate
                }
              />
              <CustomItem titleText="Release Notes:" contentText={selectedCard.releaseNotes} />
              <CustomItem
                titleText="Meeting Participants:"
                contentText={selectedCard.meetingParticipants}
              />
              <CustomItem titleText="fx-Rate:" contentText={selectedCard.fxRate} />
              <CustomItem titleText="Remarks:" contentText={selectedCard.remarks} />
              <CustomItem
                titleText="RS1 Original Currency:"
                contentText={selectedCard.RS1OriginalCurrency}
              />
              <CustomItem
                titleText="RS0 Original Currency:"
                contentText={selectedCard.RS0OriginalCurrency}
              />
              <CustomItem
                titleText="Storage Information:"
                contentText={selectedCard.storageInformation}
              />
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default CockpitBoardView;
