import React, { createContext, useContext, useState } from 'react';
import axios from 'axios';
import msalInstance from '../msalInstance';
import { loginRequest } from '../authConfig';
const NotificationContext = createContext();

export const useNotifications = () => {
  return useContext(NotificationContext);
};

export const NotificationProvider = ({ children }) => {
  const [hasPendingEQuotes, setHasPendingEQuotes] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;

  const getToken = async () => {
    try {
      const tokenResponse = await msalInstance.acquireTokenSilent(loginRequest);
      const accessToken = tokenResponse.idToken;
      return accessToken;
    } catch (error) {
      console.log(error);
    }
  };

  const fetchPendingEQuotes = async () => {
    try {
      const accessToken = await getToken();
      const response = await axios.get(
        `${apiUrl}/api/equotes/approvalStatus?eQuoteNumberApproval=Pending`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      );
      setHasPendingEQuotes(response.data.length > 0);
    } catch (error) {
      console.error('Error fetching pending eQuotes:', error);
    }
  };

  return (
    <NotificationContext.Provider value={{ hasPendingEQuotes, fetchPendingEQuotes }}>
      {children}
    </NotificationContext.Provider>
  );
};
