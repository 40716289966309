export const test1 = [
  {
    main: 'BB-010_Gearset & Differentials',
    sub: [
      {
        code: '[DF10]',
        name: 'Differential Test',
        description: 'Differential Test',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[DF6]',
        name: 'Full throttle acceleration',
        description: 'Full throttle acceleration description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[Diff_not_Rad]',
        name: 'Emergency wheel test',
        description: 'Emergency wheel test description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[Diff_prot]',
        name: 'Functional Developemnt',
        description: 'Functional Developemnt description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[DP1]',
        name: 'High speed endurance test',
        description: 'High speed endurance test description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[DP10]',
        name: 'Durability of the differential',
        description: 'Durability of the differential description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      }
    ]
  },
  {
    main: 'BB-020/-030_Synchro & Shift',
    sub: [
      {
        code: '[DP4]',
        name: 'Shift durability test',
        description: 'Shift durability test description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[FP22_a]',
        name: 'Static gear engagement test',
        description: 'Static gear engagement test description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[FP22_b]',
        name: 'Static gear engagement test',
        description: 'Static gear engagement test description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[FP5_a]',
        name: 'Cold shiftability (engage test)',
        description: 'Cold shiftability (engage test) description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[FP5_b]',
        name: 'Cold shiftability (engage test)',
        description: 'Cold shiftability (engage test) description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[FP8]',
        name: 'Shifting performance',
        description: 'Shifting performance description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      }
    ]
  },
  {
    main: 'BB-050_ClutchesDecoupling',
    sub: [
      {
        code: '[ASFTH]',
        name: 'Alterating Speed Fatigue Test Hubrid',
        description: 'Alterating Speed Fatigue Test Hubrid description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[Burst]',
        name: 'Burst Test',
        description: 'Burst Test description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[CBAT]',
        name: 'Clutch Break Away Test',
        description: 'Clutch Break Away Test description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[CCSWT]',
        name: 'Clutch Carrier Spline Waer Test',
        description: 'Clutch Carrier Spline Waer Test',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[CDTM]',
        name: 'Clutch Drag Torque Mapping',
        description: 'Clutch Drag Torque Mapping description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[CEoL]',
        name: 'Clutch EoL Testing',
        description: 'Clutch EoL Testing description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      }
    ]
  },
  {
    main: 'BB-060_Parklock',
    sub: [
      {
        code: '[DP101]',
        name: 'Park lock system durability',
        description: 'Park lock system durabilit description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[DP102]',
        name: 'Park lock ratcheting test',
        description: 'Park lock ratcheting test description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[DP103]',
        name: 'Park lock system - start-stop test',
        description: 'Park lock system - start-stop test description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[FF101]',
        name: 'Park lock system - Drop in speed...',
        description: 'Park lock system - Drop in speed..description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[FF103]',
        name: 'Park lock system - Engage park ...',
        description: 'Park lock system - Engage park ...description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[FF104]',
        name: 'Park lock system - Abuse drop in...',
        description: 'Park lock system - Abuse drop in...description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      }
    ]
  },
  {
    main: 'BB-080_Hydraulics',
    sub: [
      {
        code: '[CAP_C]',
        name: 'CAP characteristic component test',
        description: 'CAP characteristic component test description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[CAP_s]',
        name: 'CAP characteristic TM test',
        description: 'CAP characteristic TM test description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[CAPPP]',
        name: 'Presurre Pulsation test CA',
        description: 'Presurre Pulsation test CA description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[CASCO]',
        name: 'Clutch Actuatuin System...',
        description: 'Clutch Actuatuin System...description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[CoT]',
        name: 'Contamination Test FLSV',
        description: 'Contamination Test FLSV description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[DPDT]',
        name: 'Dynamic Presure Delay Test',
        description: 'Dynamic Presure Delay Test description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      }
    ]
  },
  {
    main: 'BB-090_ControlHWActuation',
    sub: [
      {
        code: '[VEN_WH_Vibration]',
        name: 'Wiring Harness Vibration Testing...',
        description: 'Wiring Harness Vibration Testing...description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[VEN_EMV_Testing]',
        name: 'EMV Testing',
        description: 'EMV Testing description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      }
    ],
    'BB-110_TractionEMotor': [
      {
        code: '[B2B]',
        name: 'Back to Back test',
        description: 'Back to Back test description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[HYB_EFF]',
        name: 'Hybrid System Efficiency test',
        description: 'Hybrid System Efficiency test description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      }
    ]
  },
  {
    main: 'BB-120_Inverter',
    sub: [
      {
        code: '[INV_Pres_Whisk]',
        name: 'Inverter pressfit & whisker test',
        description: 'nverter pressfit & whisker test description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[INV_PCBA]',
        name: 'Inverter PCBA qualification',
        description: 'Inverter PCBA qualification description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[INV_LV]',
        name: 'Inverter LC test',
        description: 'Inverter LC test description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[INV_HV]',
        name: 'Inverter HV test',
        description: 'Inverter HV test description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[INV_HVs]',
        name: 'Inverter HV safety test',
        description: 'Inverter HV safety test description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[INV_mech]',
        name: 'Inverter mechanical test',
        description: 'Inverter mechanical test description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      }
    ]
  },
  {
    main: 'SYS-000_System Test',
    sub: [
      {
        code: '[CBN_r]',
        name: 'Cold bearing noise test on rig',
        description: 'Cold bearing noise test on rig description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[CBN_v]',
        name: 'Cold bearing noise in vehicle',
        description: 'Cold bearing noise in vehicle description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[Diff_BLoR]',
        name: 'backlash measurement of differential',
        description: 'backlash measurement of differential description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[DSNM_r]',
        name: 'Dynamic seal noise measurement on rig',
        description: 'Dynamic seal noise measurement on rig description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[ExMDA_TM]',
        name: 'Modal analysis of mech components',
        description: 'Modal analysis of mech components description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[ExTPA]',
        name: 'Transfer path analysis in vehicle',
        description: 'Transfer path analysis in vehicle description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      }
    ]
  },
  {
    main: 'PFG-010_DrivingPerDrivability',
    sub: [
      {
        code: '[BMW-DE08]',
        name: 'BMW DE08 (Fahrzeugdauerlauf)',
        description: 'BMW DE08 (Fahrzeugdauerlauf) description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[BMW-DE10]',
        name: 'BMW DE10 Autobhan (Fahrzeug)',
        description: 'BMW DE10 Autobhan (Fahrzeug) description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[BMW-DE14]',
        name: 'BMW DC14 Uberland China (Fahrzeug)',
        description: 'BMW DC14 Uberland China (Fahrzeug) description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[BMW-DE90]',
        name: 'BMW DE90/91 30.000km (Fahrzeug)',
        description: 'BMW DE90/91 30.000km (Fahrzeug) description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[BMW-DF44]',
        name: 'BMW DF44 (Fahrxeugdauerlauf mit...)',
        description: 'BMW DF44 (Fahrxeugdauerlauf mit...) description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[Cus-ins]',
        name: 'Customer transmission inspection',
        description: 'Customer transmission inspection description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      }
    ]
  },
  {
    main: 'PFG-020_Efficinecy',
    sub: [
      {
        code: '[COM]',
        name: 'Creep oscillation mesuraement',
        description: 'Creep oscillation mesuraement description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[FF4]',
        name: 'Driveability / Shift Rating...',
        description: 'Driveability / Shift Rating... description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[FF4s]',
        name: 'FF4 short (AP,post sys test)',
        description: 'FF4 short (AP,post sys test) description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[GCUP]',
        name: 'Getrag Cup',
        description: 'Getrag Cup description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[TEV]',
        name: 'Torsional eigenfrequency in vehicle',
        description: 'Torsional eigenfrequency in vehicle description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[TSV]',
        name: 'Tribo screening vehicle',
        description: 'Tribo screening vehicle description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[VJT]',
        name: 'Vehicle Judder Test',
        description: 'Vehicle Judder Test description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      }
    ]
  },
  {
    main: 'PFG-030',
    sub: [
      {
        code: '[FCM_R]',
        name: 'Fuel Consumption mesurement on...',
        description: 'Fuel Consumption mesurement on... description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[FP10]',
        name: 'Drag Torque',
        description: 'Drag Torque description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[FP2]',
        name: 'Efficiency test',
        description: 'Efficiency test description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[EFF-200]',
        name: 'Target setting -Efficiency & Fuel...',
        description: 'Target setting -Efficiency & Fuel...description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[EFF-300]',
        name: 'Transmission concept comparision',
        description: 'Transmission concept comparision description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[EFF-310]',
        name: 'Torque loss map prediction',
        description: 'Torque loss map prediction description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      }
    ]
  },
  {
    main: 'PFG-040_Lubrication',
    sub: [
      {
        code: '[FF2]',
        name: 'Dynamic oil flow in vehicle',
        description: 'Dynamic oil flow in vehicle description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[FF9]',
        name: 'Vent function vehicle',
        description: 'Vent function vehicle description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[MOVS]',
        name: 'Min oil volume for scrap',
        description: 'Min oil volume for scrap description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[OLM]',
        name: 'Oil Level (Dynamic & Static)',
        description: 'Oil Level (Dynamic & Static) description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[ROV]',
        name: 'Rest oil volume',
        description: 'Rest oil volume description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[VOD]',
        name: 'Visual oil management',
        description: 'Visual oil management description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      }
    ]
  },
  {
    main: 'PFG-050_ThermalManagement',
    sub: [
      {
        code: '[FP1]',
        name: 'Temperature profile (bench)',
        description: 'Temperature profile (bench) description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[HTC]',
        name: 'Heat Transfer Curve',
        description: 'Heat Transfer Curve description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[SC_r]',
        name: 'Special Cases on roller dyno',
        description: 'Special Cases on roller dyno description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[SC_v]',
        name: 'Special cases',
        description: 'Special cases description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[SF1]',
        name: 'Transmission cooling on roller dyno',
        description: 'Transmission cooling on roller dyno description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[SSC]',
        name: 'Short Special Cases',
        description: 'Short Special Cases description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      }
    ]
  },
  {
    main: 'PFG-060-NCH',
    sub: [
      {
        code: '[ASFTH]',
        name: 'Alterating Speed Fatigue Test Hubrid',
        description: 'Alterating Speed Fatigue Test Hubrid description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[B2B]',
        name: 'Back to back test',
        description: 'Back to back test description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[BMW-DE08]',
        name: 'BMW DE08 (Fahrzeugdauerlauf)',
        description: 'BMW DE08 (Fahrzeugdauerlauf) description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[BMW-DE10]',
        name: 'BMW DE10 Autobhan (Fahrzeug)',
        description: 'BMW DE10 Autobhan (Fahrzeug) description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[BMW-DE14]',
        name: 'BMW DC14 Uberland China (Fahrzeug)',
        description: 'BMW DC14 Uberland China (Fahrzeug) description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[BMW-DE90]',
        name: 'BMW DE90/91 30.000km (Fahrzeug)',
        description: 'BMW DE90/91 30.000km (Fahrzeug) description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      }
    ]
  }
];
