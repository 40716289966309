import React from 'react';
import { Container, Typography, Box, Grid, Card, CardContent, Avatar, Button } from '@mui/material';
import magnaLogo from '../../assets/appicon.png';
import { Link } from 'react-router-dom';

const AboutPage = () => {
  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
        <Avatar
          alt="Magna Logo"
          src={magnaLogo}
          sx={{ backgroundColor: 'black', width: 80, height: 80, mr: 2 }}
        />
        <Typography variant="h3" fontWeight="bold">
          About
        </Typography>
      </Box>
      <Typography variant="body1" paragraph>
        MAGNA QuoVaDis is a cutting-edge eQuote and project management tool designed to streamline
        and optimize eQuote workflow. The webapp empower teams with powerful features that simplify
        project tracking, reporting, and collaboration.
      </Typography>
      <Typography variant="body1" paragraph>
        With a user-friendly interface and robust integrations, MAGNA QuoVaDis adapts to the unique
        needs of the organization, helping to stay on top of the projects with ease. From project
        initiation to completion, the tool provides comprehensive support at every stage.
      </Typography>
      <Typography variant="body1" paragraph>
        MAGNA QuoVaDis ensures that every aspect of project is under control, allowing to focus on
        what really matters—delivering results.
      </Typography>

      {/* Page is placeholder and may need updated content based on the client requirement, temporarily hidden */}

      <Box sx={{ display: 'none', mt: 6, mb: 4 }}>
        <Typography variant="h4" gutterBottom align="center">
          Our Core Values
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4}>
            <Card>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Innovation
                </Typography>
                <Typography variant="body2">
                  We believe in constantly innovating to provide the best solutions for our
                  customers. Our tools are designed to evolve with your needs.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Integrity
                </Typography>
                <Typography variant="body2">
                  Trust is the foundation of our business. We are committed to maintaining
                  transparency and honesty in all our interactions.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Collaboration
                </Typography>
                <Typography variant="body2">
                  Success is achieved through teamwork. We foster collaboration both within our
                  company and with our customers to achieve common goals.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ display: 'none', mt: 6 }}>
        <Typography variant="h4" gutterBottom align="center">
          Our Team
        </Typography>
        <Typography variant="body1" paragraph align="center">
          Our team consists of experienced professionals passionate about delivering the best
          project management solutions. With expertise across various domains, we are dedicated to
          supporting your success.
        </Typography>
      </Box>

      <Button component={Link} to="/" variant="contained" color="primary" sx={{ mt: 3 }}>
        Go to Home
      </Button>
    </Container>
  );
};

export default AboutPage;
