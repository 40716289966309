import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  MenuItem,
  MenuList,
  Button,
  DialogTitle,
  Typography,
  TextField,
  Select,
  Card,
  Divider
} from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import React, { useEffect, useMemo, useState } from 'react';
import { Add } from '@mui/icons-material';
import './MyCellComponent.css';
import { useNavigate } from 'react-router-dom';

const MyCellComponent = (p) => {
  const OrganizationalItem = [
    'TR-020-Testrings',
    'TT-020-Testrings',
    'PT-030-Prototype',
    'SF-060-SupportFunction',
    'PFG-090-Sustainability',
    'PFG-080-Electromagnetic Compatibility',
    'PFG-070-FMEA & Safety & Secrity',
    'PFG-060-NVH'
  ];
  const rigItem = ['extern', 'Klima', 'AUTO', 'BOX', 'VEH', 'FCT-SHAKER', 'FCT-LUB', 'FCT-EOL-48V'];
  const performItem = ['OEM', 'MAGNA', 'Supplier'];
  const typeItem = ['tm-type'];
  const effortFreqItem = [
    'week',
    'month',
    'phase',
    'application',
    'sequence 7/5',
    'sequence 8/5',
    'sequence 24/5',
    'sequence 24/7'
  ];
  const linkedSamplItem = [
    'not linked',
    'SW relaease',
    'CAL release',
    'CAN updates',
    'no. of TM',
    'yes',
    'single'
  ];
  const costStruct = [
    'n/a',
    '1.0 Projecktmanagement',
    '2.1 Komponententwicklung (Mechanik)',
    '2.2 Integration (Mechanik)',
    '2.3 Entwiklung Hardware E/E',
    '2.4 Entwicklung Software E/E',
    '2.5 Funktionale Sicherheit',
    '2.6 External Entwicklungsdienstleitungen'
  ];
  const bookingCatItem = [
    'n/a',
    'Actuation Sysytem',
    'Actuator/IPAM',
    'Calculations/Simulations',
    'Concept/Design Development',
    'Concept/Design Development/ FMEA',
    'Connector',
    'Cooler'
  ];
  const xFunctionItem = [
    'n/a',
    'Projecktmanagement',
    'Konfigurationsmanagement',
    'Qualitatssicherung',
    'Testmanagement',
    'Riskomanagement'
  ];
  const [open, setOpen] = useState(false);
  const [selectedOrgValue, setSelectedOrgValue] = useState(OrganizationalItem[0]);
  const [selectedRigVal, setSelectedRigVal] = useState(rigItem[0]);
  const [selectedPerform, setSelectedPerform] = useState(performItem[0]);
  const [selectedType, setSelectedType] = useState(typeItem[0]);
  const [selectedEfforts, setSelectedEfforts] = useState(effortFreqItem[0]);
  const [selectedLinked, setSelectedLinked] = useState(linkedSamplItem[0]);
  const [selectedCost, setSelectedCost] = useState(costStruct[0]);
  const [selectedBooking, setSelectedBooking] = useState(bookingCatItem[0]);
  const [selectedXFunction, setSelectedXFunction] = useState(xFunctionItem[0]);

  const handleAdd = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setSelectedOrgValue(event.target.value);
  };

  const handleRigChange = (event) => {
    setSelectedRigVal(event.target.value);
  };

  const handlePerformanceChange = (event) => {
    setSelectedPerform(event.target.value);
  };

  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
  };

  const handleEffortFreqChange = (event) => {
    setSelectedEfforts(event.target.value);
  };
  const handleLinkedChange = (event) => {
    setSelectedLinked(event.target.value);
  };

  const handleCostChange = (event) => {
    setSelectedCost(event.target.value);
  };

  const handleBookingChange = (event) => {
    setSelectedBooking(event.target.value);
  };

  const handleFunctionChange = (event) => {
    setSelectedXFunction(event.target.value);
  };

  return (
    <>
      <Add
        onClick={handleAdd}
        sx={{ fontSize: '1rem', marginLeft: '-0.5rem', marginRight: '10px' }}
      />
      {p.value}
      <Dialog
        fullWidth={true}
        open={open}
        onClose={handleClose}
        maxWidth="lg"
        aria-labelledby="full-width-dialog-title"
      >
        <Box sx={{ backgroundColor: '#f5f5f5' }}>
          <DialogTitle id="alert-dialog-title" fontWeight="600" color="#800000">
            Creating Work Package
          </DialogTitle>
          <DialogContent>
            <Card sx={{ padding: '1rem', margin: '0 0 1rem 0' }}>
              <DialogContentText className="dialogText" id="alert-dialog-description">
                Basic Information
              </DialogContentText>
              <Box display="flex" flexDirection="row">
                <Box mr="2rem">
                  <Typography className="typoText">ID</Typography>
                  <TextField className="textBox" value={p.data.code} size="small" />
                </Box>
                <Box mr="2rem">
                  <Typography className="typoText">Name</Typography>
                  <TextField className="textBox" value={p.data.name} size="small" />
                </Box>
                <Box mr="2rem">
                  <Typography className="typoText">Description</Typography>
                  <TextField
                    size="md"
                    multiline
                    // rows={2}
                    value={p.data.description}
                    sx={{ width: '350px' }}
                  />
                </Box>
              </Box>
            </Card>
            <Card sx={{ padding: '1rem', margin: '1rem 0 1rem 0' }}>
              <DialogContentText className="dialogText" id="alert-dialog-description">
                Organization
              </DialogContentText>
              <Box display="flex" flexDirection="row">
                <Box mr="2rem">
                  <Typography className="typoText">Organizational Group</Typography>
                  <Select
                    value={selectedOrgValue}
                    onChange={handleChange}
                    className="dropdownSelect"
                  >
                    {OrganizationalItem.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <Box mr="2rem">
                  <Typography className="typoText">Rig Category</Typography>
                  <Select
                    className="dropdownSelect"
                    value={selectedRigVal}
                    onChange={handleRigChange}
                  >
                    {rigItem.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <Box mr="2rem">
                  <Typography className="typoText">Performed By</Typography>
                  <Select
                    value={selectedPerform}
                    onChange={handlePerformanceChange}
                    className="dropdownSelect"
                  >
                    {performItem.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <Box mr="2rem">
                  <Typography className="typoText">GPMS Work Package Number</Typography>
                  <TextField className="textBox" size="small" />
                </Box>
              </Box>
            </Card>
            <Card sx={{ padding: '1rem', margin: '1rem 0 1rem 0' }}>
              <DialogContentText className="dialogText" id="alert-dialog-description">
                Duration
              </DialogContentText>
              <Typography className="typoText">Type</Typography>
              <Select
                value={selectedType}
                onChange={handleTypeChange}
                className="dropdownSelect"
                sx={{ mb: '2rem' }}
              >
                {typeItem.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
              <Box display="flex" flexDirection="row" mb="2rem" alignItems="flex-end">
                <Box>
                  <span>I</span>
                  <TextField className="textBox" size="small" value={'DHD/ HDT / eDM / DCL'} />
                </Box>
                <Box>
                  <Typography className="typoText">Duration</Typography>
                  <TextField className="textBox" size="small" value={'3000'} />h
                </Box>
              </Box>
              <Box display="flex" flexDirection="row" mb="2rem">
                <Box>
                  <span>II</span>
                  <TextField className="textBox" size="small" />
                </Box>
                <Box>
                  <TextField className="textBox" size="small" />h
                </Box>
              </Box>
              <Box display="flex" flexDirection="row" mb="2rem">
                <Box>
                  <span>III</span>
                  <TextField className="textBox" size="small" />
                </Box>
                <Box>
                  <TextField className="textBox" size="small" />h
                </Box>
              </Box>
              <Box display="flex" flexDirection="row" mb="2rem">
                <Box>
                  <span>IV</span>
                  <TextField className="textBox" size="small" />
                </Box>
                <Box>
                  <TextField className="textBox" size="small" />h
                </Box>
              </Box>
              <Box display="flex" flexDirection="row" mb="2rem">
                <Box mr="2rem">
                  <Typography className="typoText">Transmission Type</Typography>
                  <TextField className="textBox" size="small" value={'DHD/ HDT / eDM / DCL'} />
                </Box>
                <Box mr="2rem">
                  <Typography className="typoText">Effort Frequency</Typography>
                  <Select
                    value={selectedEfforts}
                    onChange={handleEffortFreqChange}
                    className="dropdownSelect"
                  >
                    {effortFreqItem.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <Box mr="2rem">
                  <Typography className="typoText">FTT</Typography>
                  <TextField className="textBox" size="small" value={'1.5'} />
                </Box>
              </Box>
            </Card>
            <Card sx={{ padding: '1rem', margin: '1rem 0 1rem 0' }}>
              <DialogContentText className="dialogText" id="alert-dialog-description">
                Linkages
              </DialogContentText>
              <Box display="flex" flexDirection="row">
                <Box mr="2rem">
                  <Typography className="typoText">Predecessor</Typography>
                  <TextField className="textBox" size="small" />
                </Box>
                <Box mr="2rem">
                  <Typography className="typoText">Successor</Typography>
                  <TextField className="textBox" size="small" />
                </Box>
                <Box mr="2rem">
                  <Typography className="typoText">Related To</Typography>
                  <TextField className="textBox" size="small" />
                </Box>
                <Box mr="2rem">
                  <Typography className="typoText">Linked Sample Size</Typography>
                  <Select
                    value={selectedLinked}
                    onChange={handleLinkedChange}
                    className="dropdownSelect"
                  >
                    {linkedSamplItem.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </Box>
            </Card>
            <Card sx={{ padding: '1rem', margin: '1rem 0 1rem 0' }}>
              <DialogContentText className="dialogText" id="alert-dialog-description">
                Classification
              </DialogContentText>
              <Box display="fleex" flexDirection="row">
                <Box mr="2rem">
                  <Typography className="typoText">Cost Structure</Typography>
                  <Select
                    value={selectedCost}
                    onChange={handleCostChange}
                    className="dropdownSelect"
                  >
                    {costStruct.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <Box mr="2rem">
                  <Typography className="typoText">Booking Catergory</Typography>
                  <Select
                    value={selectedBooking}
                    onChange={handleBookingChange}
                    className="dropdownSelect"
                  >
                    {bookingCatItem.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <Box mr="2rem">
                  <Typography className="typoText">X-Functional Activity Type</Typography>
                  <Select
                    value={selectedXFunction}
                    onChange={handleFunctionChange}
                    className="dropdownSelect"
                  >
                    {xFunctionItem.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </Box>
            </Card>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleClose} sx={{ backgroundColor: '#800000' }}>
              New
            </Button>
            <Button
              variant="outlined"
              onClick={handleClose}
              sx={{ borderBlockColor: '#800000', color: '#800000' }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

const WorkPackageItem = (props) => {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [itemList, setItemList] = useState([]);
  const [selectedList, setSelectedList] = useState('all');
  const [tableData, setTableData] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [colDefs, setColDefs] = useState([
    {
      headerName: '',
      field: 'code',
      cellRenderer: MyCellComponent,
      flex: 2.5
    },
    { headerName: '', field: 'name', flex: 2 },
    {
      headerName: 'Q2',
      field: 'q2'
    },
    { headerName: 'SD1', field: 'sd1' },
    { headerName: 'SD2', field: 'sd2' },
    { headerName: 'SD3', field: 'sd3' },
    { headerName: 'SD4', field: 'sd4' },
    { headerName: 'SD5', field: 'sd5' },
    { headerName: 'SD6', field: 'sd6' }
  ]);
  const initator = [
    'EPM-010 Project Management',
    'EPM-010_Design Verfication',
    'BB-000_Product Line Verification',
    'BB-010_Gear Design',
    'BB-010_Gear & Shafts Design'
  ];
  const workplan_1 = [
    'Project Category',
    'General Parameter',
    'PFG-040_Lubrication',
    'BB-010_Gearset',
    'BB-010-Differential',
    'BB-010-sealing'
  ];
  const worlplan_2 = [
    'Project Category',
    '00_Transmission',
    '00_Vehicle',
    '00_Engine',
    '00_Battery',
    'B00_Cooler Fittings'
  ];
  const workplan_3 = ['C0', 'C1', 'C2', 'C3', 'C4', 'C5', 'C6'];

  const [selectedInitiator, setSelectedInitiator] = useState(initator[0]);
  const [selectedWorkplan_1, setSelectedWorkplan_1] = useState(workplan_1[0]);
  const [selectedWorkplan_2, setSelectedWorkplan_2] = useState(worlplan_2[0]);
  const [selectedWorkplan_3, setSelectedWorkplan_3] = useState(workplan_3[0]);

  const handleInititorChange = (event) => {
    setSelectedInitiator(event.target.value);
  };

  const handleWorkPlan1Change = (event) => {
    setSelectedWorkplan_1(event.target.value);
  };

  const handleWorkPlan2Change = (event) => {
    setSelectedWorkplan_2(event.target.value);
  };
  const handleWorkPlan3Change = (event) => {
    setSelectedWorkplan_3(event.target.value);
  };

  useEffect(() => {
    const List = props.item.map((res) => res.main);
    setData(props.item);
    setItemList(List);
    const tableAllData = props.item.flatMap((res) => res.sub);
    setTableData(tableAllData);
    setRowData(tableAllData);
  }, []);

  const handleClick = (item) => {
    if (item === 'all') {
      const tableAllData = props.item.flatMap((res) => res.sub);
      setTableData(tableAllData);
      setRowData(tableAllData);
    } else {
      const tableObj = data.filter((res) => res.main === item);
      const tableItem = tableObj.flatMap((item) => item.sub);
      setTableData(tableItem);
      setRowData(tableItem);
    }
    setSelectedList(item);
  };

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      resizable: true,
      editable: false,
      sortable: false,
      filter: false,
      wrapText: true
    };
  });

  return (
    <Box>
      <Box display="flex" flexDirection="row" height="70vh">
        <Box flex="1" display="flex" justifyContent="space-around">
          <Box
            sx={{
              maxHeight: '100%',
              overflow: 'auto',
              paddingRight: '1rem',
              scrollbarWidth: 'thin',
              '&::-webkit-scrollbar': {
                width: '0.4em'
              },
              '&::-webkit-scrollbar-track': {
                background: '#f1f1f1'
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#c2c1c1',
                borderRadius: '10px'
              }
            }}
          >
            <MenuList>
              <MenuItem
                onClick={() => handleClick('all')}
                selected={selectedList === 'all'}
                sx={{
                  '&.Mui-selected': {
                    backgroundColor: '#800000',
                    color: '#ffff'
                  },
                  '&.Mui-selected:hover': {
                    backgroundColor: '#800000',
                    color: '#ffff'
                  },
                  fontSize: '12px'
                }}
              >
                All
              </MenuItem>
              {itemList.map((item, index) => (
                <MenuItem
                  key={index}
                  onClick={() => handleClick(item)}
                  selected={selectedList === item}
                  sx={{
                    '&.Mui-selected': {
                      backgroundColor: '#800000',
                      color: '#ffff'
                    },
                    '&.Mui-selected:hover': {
                      backgroundColor: '#800000',
                      color: '#ffff'
                    },
                    fontSize: '12px'
                  }}
                >
                  {item}
                </MenuItem>
              ))}
            </MenuList>
          </Box>
          <Box>
            <Divider
              orientation="vertical"
              sx={{
                width: 2,
                height: '100%',
                backgroundColor: '#800000'
              }}
            />
          </Box>
        </Box>

        <Box flex="1" m="1rem" display="flex" justifyContent="space-around">
          <Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              mb="3rem"
            >
              <Typography fontWeight="700" fontSize="14px">
                Option 1
              </Typography>
              <Typography fontWeight="700" fontSize="14px">
                BMW - 7HDT300
              </Typography>
            </Box>
            <Box>
              <Typography fontWeight="700" mb="0.5rem" fontSize="14px">
                Initiator
              </Typography>
              <Select
                value={selectedInitiator}
                onChange={handleInititorChange}
                sx={{ mb: '2rem', width: '200px ', fontSize: '12px' }}
                size="small"
              >
                {initator.map((item, index) => (
                  <MenuItem key={index} value={item} sx={{ fontSize: '12px' }}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Typography fontWeight="700" mb="0.5rem" fontSize="14px">
                Work plan
              </Typography>
              <Select
                value={selectedWorkplan_1}
                onChange={handleWorkPlan1Change}
                sx={{ mb: '1rem', width: '200px ', fontSize: '12px' }}
                size="small"
              >
                {workplan_1.map((item, index) => (
                  <MenuItem key={index} value={item} sx={{ fontSize: '12px' }}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
              <Select
                value={selectedWorkplan_2}
                onChange={handleWorkPlan2Change}
                sx={{ mb: '1rem', width: '200px ', fontSize: '12px' }}
                size="small"
              >
                {worlplan_2.map((item, index) => (
                  <MenuItem key={index} value={item} sx={{ fontSize: '12px' }}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
              <Select
                value={selectedWorkplan_3}
                onChange={handleWorkPlan3Change}
                sx={{ mb: '1rem', width: '200px ', fontSize: '12px' }}
                size="small"
              >
                {workplan_3.map((item, index) => (
                  <MenuItem key={index} value={item} sx={{ fontSize: '12px' }}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>
          <Box>
            <Divider
              orientation="vertical"
              sx={{
                width: 2,
                height: '100%',
                backgroundColor: '#800000'
              }}
            />
          </Box>
        </Box>
        <Box
          className="root-table"
          flex="3"
          sx={{
            overflow: 'auto',
            scrollbarWidth: 'thin',
            '&::-webkit-scrollbar': {
              width: '0.4em'
            },
            '&::-webkit-scrollbar-track': {
              background: '#f1f1f1'
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#c2c1c1',
              borderRadius: '8px'
            }
          }}
        >
          <Box className="ag-theme-alpine">
            <AgGridReact
              rowData={rowData}
              columnDefs={colDefs}
              defaultColDef={defaultColDef}
              domLayout="autoHeight"
            />
          </Box>
        </Box>
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="flex-end">
        <Button
          variant="contained"
          sx={{ marginRight: '1rem', backgroundColor: '#800000' }}
          onClick={() => {
            alert('Work Package created');
          }}
        >
          CONFIRM
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            navigate('/');
          }}
          sx={{
            borderColor: '#800000',
            color: '#800000'
          }}
        >
          CLOSE
        </Button>
      </Box>
    </Box>
  );
};

export default WorkPackageItem;
