import React from 'react';
import { Typography, Button, Container } from '@mui/material';
import { Link } from 'react-router-dom';
import PendingIcon from '@mui/icons-material/Pending';

const TbdPages = () => (
  <Container
    maxWidth="100vw"
    sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      textAlign: 'center',
      padding: 3,
      bgcolor: '#f5f5f5'
    }}
  >
    <PendingIcon sx={{ fontSize: 100, color: 'red', mb: 2 }} />
    <Typography variant="h4" gutterBottom>
      Coming Soon!
    </Typography>
    <Typography variant="body1" sx={{ mb: 4 }}>
      Placeholder page for future/WIP features...
    </Typography>
    <Button
      component={Link}
      to="/"
      variant="contained"
      color="primary"
      sx={{
        textTransform: 'none',
        fontSize: '1rem'
      }}
    >
      Go to Home
    </Button>
  </Container>
);

export default TbdPages;
