import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material';
import Logo from '../../assets/quovadis.png';

const data = {
  options: 1,
  phases: ['Q2', 'SD2', 'SD3', 'SD4', 'SD5', 'SD6', 'Total'],
  boardView: [
    { name: 'Personnel', values: [null, null, 9, 8, null, null, '17 kh'] },
    { name: 'Inh. Testing', values: [null, null, '7.5', null, 7, null, '14.5 kh'] },
    { name: 'Prototypes', values: [null, null, null, null, null, null, 'pc'] },
    { name: 'Ext. Testing', values: [null, null, '0.2', '0.2', null, null, '0.4 kh'] },
    { name: 'Vendor Engg.', values: [null, null, null, null, null, null, 'kh'] }
  ]
};

const Totals = () => {
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate('/');
  };

  return (
    <div>
      <AppBar position="static" sx={{ bgcolor: 'white', color: 'Maroon' }}>
        <Toolbar>
          <button
            onClick={handleLogoClick}
            style={{ background: 'none', border: 'none', cursor: 'pointer', padding: '0 1rem' }}
          >
            <img src={Logo} alt="logo" />
          </button>
          <Typography variant="h4" style={{ fontWeight: 'bold' }}>
            Totals
          </Typography>
        </Toolbar>
      </AppBar>
      <Container maxWidth="md" style={{ marginTop: '50px' }}>
        <Paper style={{ padding: '20px', textAlign: 'center' }}>
          <Typography variant="h6" gutterBottom>
            Options
          </Typography>
          <div
            style={{
              display: 'inline-block',
              backgroundColor: 'grey',
              padding: '5px 10px',
              borderRadius: '4px'
            }}
          >
            <Typography variant="body1">{data.options}</Typography>
          </div>
          <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold' }}>
            PHASE VIEW
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>BOARD VIEW</TableCell>
                  {data.phases.map((phase, index) => (
                    <TableCell key={index} align="center">
                      {phase}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.boardView.map((row, rowIndex) => (
                  <TableRow key={rowIndex}>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    {row.values.map((value, colIndex) => (
                      <TableCell key={colIndex} align="center">
                        {value !== null ? value : ''}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Container>
    </div>
  );
};

export default Totals;
