import { Box } from '@mui/material';
import React from 'react';
import QGuardImg from '../../assets/qguard.jpg';

const QGuard = () => {
  return (
    <Box sx={{ backgroundColor: '#DCDCDC', display: 'flex', height: '100vh' }}>
      <img src={QGuardImg} alt="QGuard" style={{ margin: 'auto' }} />
    </Box>
  );
};

export default QGuard;
