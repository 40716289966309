import { Autocomplete, Box, FormControl, TextField, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import React, { useEffect, useMemo, useState } from 'react';
import BackToDashboard from '../../Components/BackToDashboard';
import { quasiTest } from '../../Data/quasitest';
import { Delete, FileCopy } from '@mui/icons-material';

const subProjects = [
  'SP01 Eng. Project Management',
  'SP02 Quality Assurance',
  'SP03 Gearbox & Mechatronics',
  'SP04 E-Motor',
  'SP05 Inverter',
  'SP06 Software',
  'SP07 Prototyping',
  'SP07 Prototyping & Testing',
  'SP0X Test Operations',
  'SP0X Testrigs',
  'SP0X Expenses'
];

const Testpage = () => {
  const location = useLocation();
  const { eQuoteNumber } = location.state;
  const [data, setData] = useState(() => {
    return quasiTest
      .filter((row) => !row.hasOwnProperty('parentId'))
      .map((row) => ({
        ...row,
        selectedWp: row.workpackage,
        selectedRegion: '',
        selectedLocation: '',
        selectedResReqExp: '',
        selectedSubProject: ''
      }))
      .sort((a, b) => a.sortOrder - b.sortOrder);
  });

  const columns = useMemo(
    () => [
      {
        accessorKey: 'subproject',
        header: 'SubProject',
        Cell: ({ row }) => {
          return (
            <Autocomplete
              options={subProjects}
              value={
                row.original.selectedSubProject === ''
                  ? row.original.subproject?.[0]?.subproject
                  : row.original.selectedSubProject
              }
              disableClearable
              size="small"
              fullWidth
              onChange={(e, newValue) => {
                row.original.selectedSubProject = newValue;
                setData([...data]);
              }}
              renderInput={(params) => (
                <TextField {...params} variant="standard" label="Select SubProject" />
              )}
            />
          );
        }
      },
      {
        accessorKey: 'region',
        header: 'Region',
        size: 200,
        Cell: ({ row }) => {
          const [workPackageDetails, setWorkPackageDetails] = useState(() => {
            if (row.original.selectedWp === row.original.workpackage) {
              return row.original;
            }
            const workpackageId = getWorkPackageId(row.original, row.original.selectedWp);
            return getWorkPackageDetails(workpackageId);
          });

          const uniqueRegions = getUniqueRegions(workPackageDetails?.organizations || []);

          useEffect(() => {
            if (row.original.selectedWp !== row.original.workpackage) {
              const workpackageId = getWorkPackageId(row.original, row.original.selectedWp);
              const updatedWorkPackageDetails = getWorkPackageDetails(workpackageId);
              setWorkPackageDetails(updatedWorkPackageDetails);
            } else {
              setWorkPackageDetails(row.original);
            }
            row.original.selectedRegion = '';
            row.original.selectedLocation = '';
            row.original.selectedResReqExp = '';
          }, [row.original.selectedWp]);

          useEffect(() => {
            if (uniqueRegions.length === 1 && !row.original.selectedRegion) {
              handleRegionChange(workPackageDetails, uniqueRegions[0], row.original);
            }
          }, [uniqueRegions, workPackageDetails]);

          if (uniqueRegions.length === 0 || !workPackageDetails.organizations) {
            return null;
          }

          return (
            <FormControl
              size="small"
              fullWidth
              variant="standard"
              disabled={uniqueRegions.length === 1}
            >
              {uniqueRegions.length === 1 ? (
                <TextField
                  size="small"
                  value={uniqueRegions[0]}
                  disabled
                  variant="standard"
                  fullWidth
                  slotProps={{
                    input: { disableUnderline: true }
                  }}
                  sx={{
                    '& .MuiInputBase-input': {
                      textAlign: 'center'
                    }
                  }}
                />
              ) : (
                <Autocomplete
                  options={uniqueRegions}
                  value={row.original.selectedRegion}
                  disableClearable
                  size="small"
                  fullWidth
                  onChange={(e, newValue) =>
                    handleRegionChange(workPackageDetails, newValue, row.original)
                  }
                  renderInput={(params) => (
                    <TextField {...params} variant="standard" label="Select Region" />
                  )}
                />
              )}
            </FormControl>
          );
        },
        muiTableBodyCellProps: {
          align: 'center'
        }
      },
      {
        accessorKey: 'location',
        header: 'Location',
        size: 200,
        Cell: ({ row }) => {
          const [workPackageDetails, setWorkPackageDetails] = useState(() => {
            if (row.original.selectedWp === row.original.workpackage) {
              return row.original;
            }
            const workpackageId = getWorkPackageId(row.original, row.original.selectedWp);
            return getWorkPackageDetails(workpackageId);
          });

          const selectedRegion = row.original?.selectedRegion;

          const uniqueLocations = getUniqueLocations(
            workPackageDetails?.organizations || [],
            selectedRegion
          );

          useEffect(() => {
            if (row.original.selectedWp !== row.original.workpackage) {
              const workpackageId = getWorkPackageId(row.original, row.original.selectedWp);
              const updatedWorkPackageDetails = getWorkPackageDetails(workpackageId);
              setWorkPackageDetails(updatedWorkPackageDetails);
            } else {
              setWorkPackageDetails(row.original);
            }
          }, [row.original.selectedWp]);

          useEffect(() => {
            if (uniqueLocations.length === 1 && !row.original.selectedLocation) {
              handleLocationChange(workPackageDetails, uniqueLocations[0], row.original);
            }
          }, [uniqueLocations, workPackageDetails]);

          if (uniqueLocations.length === 0 || !workPackageDetails.organizations) {
            return null;
          }

          return (
            <FormControl
              size="small"
              fullWidth
              variant="standard"
              disabled={uniqueLocations.length === 1}
            >
              {uniqueLocations.length === 1 ? (
                <TextField
                  size="small"
                  value={uniqueLocations[0]}
                  disabled
                  variant="standard"
                  fullWidth
                  slotProps={{
                    input: { disableUnderline: true }
                  }}
                  sx={{
                    '& .MuiInputBase-input': {
                      textAlign: 'center'
                    }
                  }}
                />
              ) : (
                <Autocomplete
                  options={uniqueLocations}
                  value={row.original.selectedLocation}
                  disableClearable
                  size="small"
                  fullWidth
                  onChange={(e, newValue) =>
                    handleLocationChange(workPackageDetails, newValue, row.original)
                  }
                  renderInput={(params) => (
                    <TextField {...params} variant="standard" label="Select Location" />
                  )}
                />
              )}
            </FormControl>
          );
        },
        muiTableBodyCellProps: {
          align: 'center'
        }
      },
      {
        accessorKey: 'orgResReqExp',
        header: 'Resource Requirement / Expenditure',
        Cell: ({ row }) => {
          const [workPackageDetails, setWorkPackageDetails] = useState(() => {
            if (row.original.selectedWp === row.original.workpackage) {
              return row.original;
            }
            const workpackageId = getWorkPackageId(row.original, row.original.selectedWp);
            return getWorkPackageDetails(workpackageId);
          });

          const selectedRegion = row.original?.selectedRegion;
          const selectedLocation = row.original?.selectedLocation;

          const uniqueResReqExp = getUniqueResReqExp(
            workPackageDetails?.organizations || [],
            selectedRegion,
            selectedLocation
          );

          useEffect(() => {
            if (row.original.selectedWp !== row.original.workpackage) {
              const workpackageId = getWorkPackageId(row.original, row.original.selectedWp);
              const updatedWorkPackageDetails = getWorkPackageDetails(workpackageId);
              setWorkPackageDetails(updatedWorkPackageDetails);
            } else {
              setWorkPackageDetails(row.original);
            }
          }, [row.original.selectedWp]);

          useEffect(() => {
            if (uniqueResReqExp.length === 1 && !row.original.selectedResReqExp) {
              handleResReqExpChange(workPackageDetails, uniqueResReqExp[0], row.original);
            }
          }, [uniqueResReqExp, workPackageDetails]);

          if (!workPackageDetails.organizations || uniqueResReqExp.length === 0) {
            return null;
          }

          return uniqueResReqExp.length === 1 ? (
            <TextField
              variant="standard"
              size="small"
              fullWidth
              disabled
              value={uniqueResReqExp[0]}
              slotProps={{
                input: { disableUnderline: true }
              }}
            />
          ) : (
            <FormControl fullWidth size="small">
              <Autocomplete
                options={uniqueResReqExp}
                value={row.original.selectedResReqExp}
                disableClearable
                size="small"
                fullWidth
                onChange={(e, newValue) =>
                  handleResReqExpChange(workPackageDetails, newValue, row.original)
                }
                renderInput={(params) => (
                  <TextField {...params} variant="standard" label="Select ResReqExp" />
                )}
              />
            </FormControl>
          );
        }
      },
      {
        accessorKey: 'workpackage',
        header: 'Planview Work Package',
        Cell: ({ row }) => {
          const workpackageId = row.original.workpackageId;

          const childWorkPackages = [
            row.original.workpackage,
            ...quasiTest
              .filter((workPackage) => workPackage.parentId === workpackageId)
              .map((workPackage) => workPackage.workpackage)
          ];

          return childWorkPackages.length === 1 ? (
            <TextField
              value={row.original.selectedWp || row.original.workpackage}
              disabled
              size="small"
              variant="standard"
              sx={{ width: '24vw' }}
              slotProps={{
                input: { disableUnderline: true }
              }}
            />
          ) : (
            <Autocomplete
              options={childWorkPackages}
              value={row.original.selectedWp || row.original.workpackage}
              disableClearable
              size="small"
              fullWidth
              getOptionLabel={(option) => option}
              onChange={(e, newValue) => handleWpChange(row, newValue)}
              renderInput={(params) => (
                <TextField {...params} variant="standard" label="Select Planview Work Package" />
              )}
            />
          );
        }
      },
      {
        accessorKey: 'effortType',
        header: 'Effort Type',
        Cell: ({ row }) => row.original.effortType,
        size: 200,
        muiTableBodyCellProps: {
          align: 'center'
        }
      }
      // {
      //   accessorKey: 'option',
      //   header: 'Option'
      // }
    ],
    []
  );

  const getWorkPackageId = (row, selectedWp) => {
    const workpackageId = row.workpackageId;
    const matchingParents = quasiTest.filter((wp) => wp.parentId === workpackageId);
    const matchedWorkPackage = matchingParents.find((wp) => wp.workpackage === selectedWp);

    if (matchedWorkPackage) {
      return matchedWorkPackage.workpackageId;
    } else {
      return null;
    }
  };

  const getWorkPackageDetails = (workpackageId) => {
    if (workpackageId === null) {
      return null;
    }
    return quasiTest.find((wp) => wp.workpackageId === workpackageId);
  };

  const getUniqueRegions = (organizations) => {
    return Array.from(
      new Set(organizations?.flatMap((org) => org.regions?.map((r) => r.qvRegion)))
    ).filter(Boolean);
  };

  const getUniqueLocations = (organizations, selectedRegion) => {
    return Array.from(
      new Set(
        (organizations || [])
          .filter((org) => org.regions?.some((region) => region.qvRegion === selectedRegion))
          .flatMap((org) => org.locations?.map((loc) => loc.qvLocation))
      )
    ).filter(Boolean);
  };

  const getUniqueResReqExp = (organizations, selectedRegion, selectedLocation) => {
    if (!organizations || organizations.length === 0) {
      return [];
    }

    return Array.from(
      new Set(
        organizations
          .filter((org) => {
            const regionMatch = org.regions?.some((region) => region.qvRegion === selectedRegion);
            const locationMatch =
              !org.locations ||
              org.locations.some((location) => location.qvLocation === selectedLocation);

            return regionMatch && locationMatch;
          })
          .map((org) => org.orgResReqExp)
      )
    ).filter(Boolean);
  };

  const handleWpChange = (row, selectedWp) => {
    row.original.selectedWp = selectedWp;
    setData([...data]);
  };

  const handleRegionChange = (workPackageDetails, selectedRegion, row) => {
    const matchingLocations = workPackageDetails.organizations.filter(
      (org) => org.region?.region === selectedRegion
    );

    const firstLocation = matchingLocations[0]?.location?.location || '';
    const firstResReqExp =
      matchingLocations.find((org) => org.location?.location === firstLocation)?.orgResReqExp || '';

    row.selectedRegion = selectedRegion;
    row.selectedLocation = firstLocation;
    row.selectedResReqExp = firstResReqExp;

    setData([...data]);
  };

  const handleLocationChange = (workPackageDetails, selectedLocation, row) => {
    const matchingResReqExp = workPackageDetails.organizations.find(
      (org) =>
        org.region?.region === workPackageDetails.selectedRegion &&
        org.location?.location === selectedLocation
    )?.orgResReqExp;

    row.selectedLocation = selectedLocation;
    row.selectedResReqExp = matchingResReqExp || '';

    setData([...data]);
  };

  const handleResReqExpChange = (workPackageDetails, selectedResReqExp, row) => {
    row.selectedResReqExp = selectedResReqExp;
    setData([...data]);
  };

  // const handleCopyRow = (rowIndex) => {
  //   const rowToCopy = structuredClone(data[rowIndex]);
  //   const updatedData = [...data];
  //   rowToCopy.uniqueKey = crypto.randomUUID();
  //   updatedData.splice(rowIndex + 1, 0, rowToCopy);
  //   setData(updatedData);
  // };

  // const handleDeleteRow = (rowIndex) => {
  //   const updatedData = [...data];
  //   updatedData.splice(rowIndex, 1);
  //   setData(updatedData);
  // };

  const table = useMaterialReactTable({
    columns,
    data: data,
    getRowId: (row) => row.uniqueKey || `${row.index}-${row.workpackage}`,
    enableSorting: false,
    enableTopToolbar: false,
    enablePagination: false,
    enableStickyHeader: true,
    enableColumnActions: false,
    enableBottomToolbar: false,
    enableDensityToggle: false,
    // enableColumnResizing: true,
    // enableRowActions: true,
    // renderRowActions: ({ row }) => (
    //   <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
    //     <IconButton onClick={() => handleCopyRow(row.index)}>
    //       <FileCopy />
    //     </IconButton>
    //     <IconButton onClick={() => handleDeleteRow(row.index)}>
    //       <Delete />
    //     </IconButton>
    //   </Box>
    // ),
    initialState: {
      columnPinning: { left: ['subproject', 'region', 'location'] },
      density: 'compact'
    },
    muiToolbarAlertBannerProps: {
      sx: {
        display: 'none'
      }
    },
    muiTableHeadCellProps: {
      align: 'center',
      sx: (theme) => ({
        background: '#db9c9c',
        borderRight: '1px solid rgba(224,224,224,1)',
        color: theme.palette.text.primary,
        fontSize: '16px',
        alignContent: 'center'
      })
    },
    muiTableHeadProps: {
      sx: {
        '& .MuiTableRow-root': {
          boxShadow: 'none'
        },
        height: '60px'
      }
    },
    muiTableBodyCellProps: {
      sx: {
        borderRight: '1px solid rgba(224,224,224,1)'
      }
    },
    defaultColumn: {
      minSize: 50,
      maxSize: 400,
      size: 300
    },
    muiTableContainerProps: {
      sx: {
        height: 'calc(100vh - 230px)'
      }
    },
    displayColumnDefOptions: {
      'mrt-row-actions': {
        size: 100,
        grow: false
      }
    }
  });

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Box>
        <BackToDashboard />
      </Box>
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          m: '1rem',
          backgroundColor: '#ebebeb',
          borderRadius: '10px'
        }}
      >
        <Box padding="1rem 0 0 1rem">
          <Typography variant="h5">{eQuoteNumber}</Typography>
        </Box>
        <Box m={2}>
          <MaterialReactTable table={table} />
        </Box>
      </Box>
    </Box>
  );
};

export default Testpage;
