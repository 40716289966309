import React from 'react';
import { Typography, Button, Container } from '@mui/material';
import EngineeringIcon from '@mui/icons-material/Engineering';

const ErrorBoundaryCustomPage = () => (
  <Container
    maxWidth="100vw"
    sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      textAlign: 'center',
      padding: 3,
      bgcolor: '#f5f5f5'
    }}
  >
    <EngineeringIcon sx={{ fontSize: 100, color: '#800000', mb: 2 }} />
    <Typography variant="h4" gutterBottom>
      Oops! An unexpected error occurred.
    </Typography>
    <Typography variant="body1" sx={{ mb: 4 }}>
      Try to reload the page or try again later. If problem persists, please contact QuoVaDis
      developers!
    </Typography>
    <Button
      onClick={() => window.location.reload()}
      variant="contained"
      color="primary"
      sx={{
        bgcolor: '#800000',
        '&:hover': { bgcolor: '#600000' },
        textTransform: 'none',
        fontSize: '1rem'
      }}
    >
      RELOAD
    </Button>
  </Container>
);

export default ErrorBoundaryCustomPage;
