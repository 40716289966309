import { useMsal } from '@azure/msal-react';

const useHasRole = (requiredRoles = []) => {
  const { accounts } = useMsal();
  const account = accounts[0];
  const roles = account?.idTokenClaims?.roles || [];

  return requiredRoles.some((role) => roles.includes(role));
};

export default useHasRole;
