export const task1 = [
  {
    main: 'EPM-010_Project Management',
    sub: [
      {
        code: '[EPE-010]',
        name: 'Budget planning and tracking',
        description: 'Budget planning and tracking description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[EPE-020]',
        name: 'Project steering acc. KP03 Deliverables',
        description: 'Project steering acc. KP03 Deliverable description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[EPE-030]',
        name: 'Support Prototype Coordination',
        description: 'Support Prototype Coordination description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[EPE-040]',
        name: 'EPL/EPE coordination/ communication....',
        description: 'EPL/EPE coordination/ communication.... description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[EPE-050]',
        name: 'Evaluation DCDs /RCT....',
        description: 'Evaluation DCDs /RCT.... description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[EPE-060]',
        name: 'Configuration management set up',
        description: 'Configuration management set up description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      }
    ]
  },
  {
    main: 'EPM-010_Design Verification',
    sub: [
      {
        code: '[DVE-001]',
        name: 'PCTM',
        description: 'PCTM description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[DVE-002]',
        name: 'Design Review Meeting',
        description: 'Design Review Meeting description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[DVE-003]',
        name: 'DV Meeting',
        description: 'DV Meeting description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[DVE-004]',
        name: 'Shopfloor, Growth Curve Meeting',
        description: 'Shopfloor, Growth Curve Meeting description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[DVE-005]',
        name: 'Testing Operation Alignment',
        description: 'Testing Operation Alignment description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      }
    ]
  },
  {
    main: 'BB-000_Producat Line Verfication',
    sub: [
      {
        code: '[PLV-101]',
        name: 'Complexity Efforts',
        description: 'Complexity Efforts description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[PLV-103]',
        name: 'Managing Resources',
        description: 'Managing Resources description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[PLV-104]',
        name: 'System Protection functions',
        description: 'System Protection functions description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[PLV-105]',
        name: 'Transmission coordination...',
        description: 'Transmission coordination... description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      }
    ]
  },
  {
    main: 'BB-010_Gear Design',
    sub: [
      {
        code: '[MAT-001]',
        name: 'Support Supplier (Audits,...)',
        description: 'Support Supplier (Audits,...) description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[MAT-002]',
        name: 'Support Plant and Project team...',
        description: 'Support Plant and Project team description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[MAT-003]',
        name: 'Support design',
        description: 'Support design description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[GEAR-001]',
        name: 'Design pre-gear macro geometry',
        description: 'Design pre-gear macro geometry description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[GEAR-002]',
        name: 'Design final-gear macro geometry',
        description: 'Design final-gear macro geometry description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      }
    ]
  },
  {
    main: 'BB-010_Gear & Shafts Design',
    sub: [
      {
        code: '[G&S-001a]',
        name: 'Team Lead & general peoject support',
        description: 'Team Lead & general peoject support description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[G&S-004]',
        name: 'Change Management',
        description: 'Change Management description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[G&S-010]',
        name: 'Simultaneous Engineering with supplier',
        description: 'Simultaneous Engineering with supplier description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[G&S-011]',
        name: 'Simultaneous Engineering...',
        description: 'Simultaneous Engineering description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[G&S-014]',
        name: 'Customer communication',
        description: 'Customer communication description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      }
    ]
  },
  {
    main: 'BB-010_Differential Design',
    sub: [
      {
        code: '[EPE-010]',
        name: 'Budget planning and tracking',
        description: 'Budget planning and tracking description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[EPE-020]',
        name: 'Project steering acc. KP03 Deliverables',
        description: 'Project steering acc. KP03 Deliverable description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[EPE-030]',
        name: 'Support Prototype Coordination',
        description: 'Support Prototype Coordination description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[EPE-040]',
        name: 'EPL/EPE coordination/ communication....',
        description: 'EPL/EPE coordination/ communication.... description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[EPE-050]',
        name: 'Evaluation DCDs /RCT....',
        description: 'Evaluation DCDs /RCT.... description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[EPE-060]',
        name: 'Configuration management set up',
        description: 'Configuration management set up description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      }
    ]
  },
  {
    main: 'BB-010_Sealing Design',
    sub: [
      {
        code: '[DVE-001]',
        name: 'PCTM',
        description: 'PCTM description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[DVE-002]',
        name: 'Design Review Meeting',
        description: 'Design Review Meeting description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[DVE-003]',
        name: 'DV Meeting',
        description: 'DV Meeting description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[DVE-004]',
        name: 'Shopfloor, Growth Curve Meeting',
        description: 'Shopfloor, Growth Curve Meeting description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[DVE-005]',
        name: 'Testing Operation Alignment',
        description: 'Testing Operation Alignment description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      }
    ]
  },
  {
    main: 'BB-020_Synchronizer',
    sub: [
      {
        code: '[PLV-101]',
        name: 'Complexity Efforts',
        description: 'Complexity Efforts description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[PLV-103]',
        name: 'Managing Resources',
        description: 'Managing Resources description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[PLV-104]',
        name: 'System Protection functions',
        description: 'System Protection functions description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[PLV-105]',
        name: 'Transmission coordination...',
        description: 'Transmission coordination... description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      }
    ]
  },
  {
    main: 'BB-030_Shifting System Design',
    sub: [
      {
        code: '[G&S-001a]',
        name: 'Team Lead & general peoject support',
        description: 'Team Lead & general peoject support description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[G&S-004]',
        name: 'Change Management',
        description: 'Change Management description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[G&S-010]',
        name: 'Simultaneous Engineering with supplier',
        description: 'Simultaneous Engineering with supplier description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[G&S-011]',
        name: 'Simultaneous Engineering...',
        description: 'Simultaneous Engineering description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[G&S-014]',
        name: 'Customer communication',
        description: 'Customer communication description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      }
    ]
  },
  {
    main: 'BB-030_Shifting System',
    sub: [
      {
        code: '[EPE-010]',
        name: 'Budget planning and tracking',
        description: 'Budget planning and tracking description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[EPE-020]',
        name: 'Project steering acc. KP03 Deliverables',
        description: 'Project steering acc. KP03 Deliverable description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[EPE-030]',
        name: 'Support Prototype Coordination',
        description: 'Support Prototype Coordination description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[EPE-040]',
        name: 'EPL/EPE coordination/ communication....',
        description: 'EPL/EPE coordination/ communication.... description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[EPE-050]',
        name: 'Evaluation DCDs /RCT....',
        description: 'Evaluation DCDs /RCT.... description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[EPE-060]',
        name: 'Configuration management set up',
        description: 'Configuration management set up description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      }
    ]
  },
  {
    main: 'BB-040_Housing',
    sub: [
      {
        code: '[G&S-001a]',
        name: 'Team Lead & general peoject support',
        description: 'Team Lead & general peoject support description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[G&S-004]',
        name: 'Change Management',
        description: 'Change Management description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[G&S-010]',
        name: 'Simultaneous Engineering with supplier',
        description: 'Simultaneous Engineering with supplier description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[G&S-011]',
        name: 'Simultaneous Engineering...',
        description: 'Simultaneous Engineering description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[G&S-014]',
        name: 'Customer communication',
        description: 'Customer communication description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      }
    ]
  },
  {
    main: 'BB-050_Clutch System Design',
    sub: [
      {
        code: '[DVE-001]',
        name: 'PCTM',
        description: 'PCTM description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[DVE-002]',
        name: 'Design Review Meeting',
        description: 'Design Review Meeting description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[DVE-003]',
        name: 'DV Meeting',
        description: 'DV Meeting description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[DVE-004]',
        name: 'Shopfloor, Growth Curve Meeting',
        description: 'Shopfloor, Growth Curve Meeting description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[DVE-005]',
        name: 'Testing Operation Alignment',
        description: 'Testing Operation Alignment description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      }
    ]
  },
  {
    main: 'BB-050_Clutch System Verfication',
    sub: [
      {
        code: '[PLV-101]',
        name: 'Complexity Efforts',
        description: 'Complexity Efforts description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[PLV-103]',
        name: 'Managing Resources',
        description: 'Managing Resources description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[PLV-104]',
        name: 'System Protection functions',
        description: 'System Protection functions description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[PLV-105]',
        name: 'Transmission coordination...',
        description: 'Transmission coordination... description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      }
    ]
  },
  {
    main: 'BB-060_Park System Design',
    sub: [
      {
        code: '[MAT-001]',
        name: 'Support Supplier (Audits,...)',
        description: 'Support Supplier (Audits,...) description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[MAT-002]',
        name: 'Support Plant and Project team...',
        description: 'Support Plant and Project team description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[MAT-003]',
        name: 'Support design',
        description: 'Support design description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[GEAR-001]',
        name: 'Design pre-gear macro geometry',
        description: 'Design pre-gear macro geometry description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[GEAR-002]',
        name: 'Design final-gear macro geometry',
        description: 'Design final-gear macro geometry description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      }
    ]
  },
  {
    main: 'BB-060_Park System Verification',
    sub: [
      {
        code: '[G&S-001a]',
        name: 'Team Lead & general peoject support',
        description: 'Team Lead & general peoject support description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[G&S-004]',
        name: 'Change Management',
        description: 'Change Management description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[G&S-010]',
        name: 'Simultaneous Engineering with supplier',
        description: 'Simultaneous Engineering with supplier description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[G&S-011]',
        name: 'Simultaneous Engineering...',
        description: 'Simultaneous Engineering description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[G&S-014]',
        name: 'Customer communication',
        description: 'Customer communication description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      }
    ]
  },
  {
    main: 'BB-070_Oil & Grease',
    sub: [
      {
        code: '[G&S-001a]',
        name: 'Team Lead & general peoject support',
        description: 'Team Lead & general peoject support description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[G&S-004]',
        name: 'Change Management',
        description: 'Change Management description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[G&S-010]',
        name: 'Simultaneous Engineering with supplier',
        description: 'Simultaneous Engineering with supplier description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[G&S-011]',
        name: 'Simultaneous Engineering...',
        description: 'Simultaneous Engineering description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[G&S-014]',
        name: 'Customer communication',
        description: 'Customer communication description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      }
    ]
  },
  {
    main: 'BB-080_Hydraulic System',
    sub: [
      {
        code: '[DVE-001]',
        name: 'PCTM',
        description: 'PCTM description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[DVE-002]',
        name: 'Design Review Meeting',
        description: 'Design Review Meeting description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[DVE-003]',
        name: 'DV Meeting',
        description: 'DV Meeting description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[DVE-004]',
        name: 'Shopfloor, Growth Curve Meeting',
        description: 'Shopfloor, Growth Curve Meeting description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[DVE-005]',
        name: 'Testing Operation Alignment',
        description: 'Testing Operation Alignment description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      }
    ]
  },
  {
    main: 'BB-080_Actuation System',
    sub: [
      {
        code: '[PLV-101]',
        name: 'Complexity Efforts',
        description: 'Complexity Efforts description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[PLV-103]',
        name: 'Managing Resources',
        description: 'Managing Resources description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[PLV-104]',
        name: 'System Protection functions',
        description: 'System Protection functions description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[PLV-105]',
        name: 'Transmission coordination...',
        description: 'Transmission coordination... description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      }
    ]
  },
  {
    main: 'BB-090_Electronic Hardware',
    sub: [
      {
        code: '[MAT-001]',
        name: 'Support Supplier (Audits,...)',
        description: 'Support Supplier (Audits,...) description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[MAT-002]',
        name: 'Support Plant and Project team...',
        description: 'Support Plant and Project team description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[MAT-003]',
        name: 'Support design',
        description: 'Support design description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[GEAR-001]',
        name: 'Design pre-gear macro geometry',
        description: 'Design pre-gear macro geometry description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[GEAR-002]',
        name: 'Design final-gear macro geometry',
        description: 'Design final-gear macro geometry description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      }
    ]
  },
  {
    main: 'BB-110_Hybrid',
    sub: [
      {
        code: '[G&S-001a]',
        name: 'Team Lead & general peoject support',
        description: 'Team Lead & general peoject support description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[G&S-004]',
        name: 'Change Management',
        description: 'Change Management description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[G&S-010]',
        name: 'Simultaneous Engineering with supplier',
        description: 'Simultaneous Engineering with supplier description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[G&S-011]',
        name: 'Simultaneous Engineering...',
        description: 'Simultaneous Engineering description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[G&S-014]',
        name: 'Customer communication',
        description: 'Customer communication description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      }
    ]
  },
  {
    main: 'BB-110_eDrive',
    sub: [
      {
        code: '[EPE-010]',
        name: 'Budget planning and tracking',
        description: 'Budget planning and tracking description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[EPE-020]',
        name: 'Project steering acc. KP03 Deliverables',
        description: 'Project steering acc. KP03 Deliverable description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[EPE-030]',
        name: 'Support Prototype Coordination',
        description: 'Support Prototype Coordination description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[EPE-040]',
        name: 'EPL/EPE coordination/ communication....',
        description: 'EPL/EPE coordination/ communication.... description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[EPE-050]',
        name: 'Evaluation DCDs /RCT....',
        description: 'Evaluation DCDs /RCT.... description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[EPE-060]',
        name: 'Configuration management set up',
        description: 'Configuration management set up description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      }
    ]
  },
  {
    main: 'BB-120_Inverter',
    sub: [
      {
        code: '[DVE-001]',
        name: 'PCTM',
        description: 'PCTM description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[DVE-002]',
        name: 'Design Review Meeting',
        description: 'Design Review Meeting description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[DVE-003]',
        name: 'DV Meeting',
        description: 'DV Meeting description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[DVE-004]',
        name: 'Shopfloor, Growth Curve Meeting',
        description: 'Shopfloor, Growth Curve Meeting description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[DVE-005]',
        name: 'Testing Operation Alignment',
        description: 'Testing Operation Alignment description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      }
    ]
  },
  {
    main: 'BB-200_Software & Control',
    sub: [
      {
        code: '[PLV-101]',
        name: 'Complexity Efforts',
        description: 'Complexity Efforts description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[PLV-103]',
        name: 'Managing Resources',
        description: 'Managing Resources description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[PLV-104]',
        name: 'System Protection functions',
        description: 'System Protection functions description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[PLV-105]',
        name: 'Transmission coordination...',
        description: 'Transmission coordination... description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      }
    ]
  },
  {
    main: 'SYS-000_Application Design',
    sub: [
      {
        code: '[MAT-001]',
        name: 'Support Supplier (Audits,...)',
        description: 'Support Supplier (Audits,...) description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[MAT-002]',
        name: 'Support Plant and Project team...',
        description: 'Support Plant and Project team description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[MAT-003]',
        name: 'Support design',
        description: 'Support design description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[GEAR-001]',
        name: 'Design pre-gear macro geometry',
        description: 'Design pre-gear macro geometry description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[GEAR-002]',
        name: 'Design final-gear macro geometry',
        description: 'Design final-gear macro geometry description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      }
    ]
  },
  {
    main: 'SYS-000_System Engineering',
    sub: [
      {
        code: '[G&S-001a]',
        name: 'Team Lead & general peoject support',
        description: 'Team Lead & general peoject support description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[G&S-004]',
        name: 'Change Management',
        description: 'Change Management description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[G&S-010]',
        name: 'Simultaneous Engineering with supplier',
        description: 'Simultaneous Engineering with supplier description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[G&S-011]',
        name: 'Simultaneous Engineering...',
        description: 'Simultaneous Engineering description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[G&S-014]',
        name: 'Customer communication',
        description: 'Customer communication description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      }
    ]
  },
  {
    main: 'SYS-000_System Function',
    sub: [
      {
        code: '[EPE-010]',
        name: 'Budget planning and tracking',
        description: 'Budget planning and tracking description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[EPE-020]',
        name: 'Project steering acc. KP03 Deliverables',
        description: 'Project steering acc. KP03 Deliverable description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[EPE-030]',
        name: 'Support Prototype Coordination',
        description: 'Support Prototype Coordination description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[EPE-040]',
        name: 'EPL/EPE coordination/ communication....',
        description: 'EPL/EPE coordination/ communication.... description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[EPE-050]',
        name: 'Evaluation DCDs /RCT....',
        description: 'Evaluation DCDs /RCT.... description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[EPE-060]',
        name: 'Configuration management set up',
        description: 'Configuration management set up description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      }
    ]
  },
  {
    main: 'PFG-010_Driving',
    sub: [
      {
        code: '[DVE-001]',
        name: 'PCTM',
        description: 'PCTM description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[DVE-002]',
        name: 'Design Review Meeting',
        description: 'Design Review Meeting description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[DVE-003]',
        name: 'DV Meeting',
        description: 'DV Meeting description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[DVE-004]',
        name: 'Shopfloor, Growth Curve Meeting',
        description: 'Shopfloor, Growth Curve Meeting description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[DVE-005]',
        name: 'Testing Operation Alignment',
        description: 'Testing Operation Alignment description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      }
    ]
  },
  {
    main: 'PFG-010_Vehicle Testing',
    sub: [
      {
        code: '[PLV-101]',
        name: 'Complexity Efforts',
        description: 'Complexity Efforts description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[PLV-103]',
        name: 'Managing Resources',
        description: 'Managing Resources description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[PLV-104]',
        name: 'System Protection functions',
        description: 'System Protection functions description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[PLV-105]',
        name: 'Transmission coordination...',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false,
        description: 'Transmission coordination... description'
      }
    ]
  },
  {
    main: 'PFG-020_Efficiency',
    sub: [
      {
        code: '[MAT-001]',
        name: 'Support Supplier (Audits,...)',
        description: 'Support Supplier (Audits,...) description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[MAT-002]',
        name: 'Support Plant and Project team...',
        description: 'Support Plant and Project team description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[MAT-003]',
        name: 'Support design',
        description: 'Support design description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[GEAR-001]',
        name: 'Design pre-gear macro geometry',
        description: 'Design pre-gear macro geometry description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[GEAR-002]',
        name: 'Design final-gear macro geometry',
        description: 'Design final-gear macro geometry description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      }
    ]
  },
  {
    main: 'PFG-030_CAE',
    sub: [
      {
        code: '[G&S-001a]',
        name: 'Team Lead & general peoject support',
        description: 'Team Lead & general peoject support description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[G&S-004]',
        name: 'Change Management',
        description: 'Change Management description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[G&S-010]',
        name: 'Simultaneous Engineering with supplier',
        description: 'Simultaneous Engineering with supplier description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[G&S-011]',
        name: 'Simultaneous Engineering...',
        description: 'Simultaneous Engineering description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[G&S-014]',
        name: 'Customer communication',
        description: 'Customer communication description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      }
    ]
  },
  {
    main: 'PFG-030_HPDC',
    sub: [
      {
        code: '[EPE-010]',
        name: 'Budget planning and tracking',
        description: 'Budget planning and tracking description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[EPE-020]',
        name: 'Project steering acc. KP03 Deliverables',
        description: 'Project steering acc. KP03 Deliverable description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[EPE-030]',
        name: 'Support Prototype Coordination',
        description: 'Support Prototype Coordination description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[EPE-040]',
        name: 'EPL/EPE coordination/ communication....',
        description: 'EPL/EPE coordination/ communication.... description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[EPE-050]',
        name: 'Evaluation DCDs /RCT....',
        description: 'Evaluation DCDs /RCT.... description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[EPE-060]',
        name: 'Configuration management set up',
        description: 'Configuration management set up description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      }
    ]
  },
  {
    main: 'PFG-030_Durability',
    sub: [
      {
        code: '[DVE-001]',
        name: 'PCTM',
        description: 'PCTM description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[DVE-002]',
        name: 'Design Review Meeting',
        description: 'Design Review Meeting description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[DVE-003]',
        name: 'DV Meeting',
        description: 'DV Meeting description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[DVE-004]',
        name: 'Shopfloor, Growth Curve Meeting',
        description: 'Shopfloor, Growth Curve Meeting description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[DVE-005]',
        name: 'Testing Operation Alignment',
        description: 'Testing Operation Alignment description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      }
    ]
  },
  {
    main: 'PFG-040_Lubrication',
    sub: [
      {
        code: '[PLV-101]',
        name: 'Complexity Efforts',
        description: 'Complexity Efforts description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[PLV-103]',
        name: 'Managing Resources',
        description: 'Managing Resources description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[PLV-104]',
        name: 'System Protection functions',
        description: 'System Protection functions description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[PLV-105]',
        name: 'Transmission coordination...',
        description: 'Transmission coordination... description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      }
    ]
  },
  {
    main: 'PFG-050_Thermal Management',
    sub: [
      {
        code: '[MAT-001]',
        name: 'Support Supplier (Audits,...)',
        description: 'Support Supplier (Audits,...) description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[MAT-002]',
        name: 'Support Plant and Project team...',
        description: 'Support Plant and Project team description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[MAT-003]',
        name: 'Support design',
        description: 'Support design description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[GEAR-001]',
        name: 'Design pre-gear macro geometry',
        description: 'Design pre-gear macro geometry description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[GEAR-002]',
        name: 'Design final-gear macro geometry',
        description: 'Design final-gear macro geometry description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      }
    ]
  },
  {
    main: 'PFG-060_NVH',
    sub: [
      {
        code: '[G&S-001a]',
        name: 'Team Lead & general peoject support',
        description: 'Team Lead & general peoject support description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[G&S-004]',
        name: 'Change Management',
        description: 'Change Management description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[G&S-010]',
        name: 'Simultaneous Engineering with supplier',
        description: 'Simultaneous Engineering with supplier description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[G&S-011]',
        name: 'Simultaneous Engineering...',
        description: 'Simultaneous Engineering description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[G&S-014]',
        name: 'Customer communication',
        description: 'Customer communication description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      }
    ]
  },
  {
    main: 'PFG-070_internal Design Review',
    sub: [
      {
        code: '[EPE-010]',
        name: 'Budget planning and tracking',
        description: 'Budget planning and tracking description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[EPE-020]',
        name: 'Project steering acc. KP03 Deliverables',
        description: 'Project steering acc. KP03 Deliverable description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[EPE-030]',
        name: 'Support Prototype Coordination',
        description: 'Support Prototype Coordination description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[EPE-040]',
        name: 'EPL/EPE coordination/ communication....',
        description: 'EPL/EPE coordination/ communication.... description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[EPE-050]',
        name: 'Evaluation DCDs /RCT....',
        description: 'Evaluation DCDs /RCT.... description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[EPE-060]',
        name: 'Configuration management set up',
        description: 'Configuration management set up description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      }
    ]
  },
  {
    main: 'PFG-070_Functional Safety',
    sub: [
      {
        code: '[DVE-001]',
        name: 'PCTM',
        description: 'PCTM description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[DVE-002]',
        name: 'Design Review Meeting',
        description: 'Design Review Meeting description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[DVE-003]',
        name: 'DV Meeting',
        description: 'DV Meeting description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[DVE-004]',
        name: 'Shopfloor, Growth Curve Meeting',
        description: 'Shopfloor, Growth Curve Meeting description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[DVE-005]',
        name: 'Testing Operation Alignment',
        description: 'Testing Operation Alignment description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      }
    ]
  },
  {
    main: 'PFG-070_FMEA',
    sub: [
      {
        code: '[PLV-101]',
        name: 'Complexity Efforts',
        description: 'Complexity Efforts description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[PLV-103]',
        name: 'Managing Resources',
        description: 'Managing Resources description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[PLV-104]',
        name: 'System Protection functions',
        description: 'System Protection functions description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      },
      {
        code: '[PLV-105]',
        name: 'Transmission coordination...',
        description: 'Transmission coordination... description',
        q2: false,
        sd1: false,
        sd2: false,
        sd3: false,
        sd4: false,
        sd5: false,
        sd6: false
      }
    ]
  }
];
