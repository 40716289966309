import React, { useState } from 'react';
import {
  Grid,
  Paper,
  Checkbox,
  FormControlLabel,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  Box
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './WorkPlans.css';
import BackToDashboard from '../../Components/BackToDashboard';

const checkboxLabels = ['Q2', 'A', 'SD1', 'SD2', 'SD3', 'B', 'SD4', 'C', 'SD5', 'D', 'SD6'];

const mainCategoryItems = [
  'Project Category',
  'General Parameter',
  'PFG-040_Lubrication',
  'BB-010_Gearsets',
  'BB-010_Differentials',
  'BB-010_Sealing',
  'BB-020_Synchro',
  'BB-030_Shifting System',
  'BB-040_Housing',
  'BB-050_Clutches & Decoupling',
  'BB-060_Parklock'
];

const subCategoryItemsMap = {
  'Project Category': ['Project Category'],
  'General Parameter': [
    '00_Tramsmission',
    '00_Vehicle',
    '00_Engine',
    '00_Battery',
    '00_Breather',
    '00_Cooler fittings',
    '00_Cooler Pipe',
    '00_Inverter Inlay'
  ],
  'PFG-040_Lubrication': ['PFG-040_Lubrication'],
  'BB-010_Gearsets': ['BB-010_Gears', 'BB-010_Shafts', 'BB-010_Bearings'],
  'BB-010_Differentials': ['BB-010_Differentials'],
  'BB-010_Sealing': ['BB-010_Sealing'],
  'BB-020_Synchro': ['BB-020_Synchronizer System', 'BB-020_Synchronizer Parts'],
  'BB-030_Shifting System': [
    'BB-030_Actuation Drive',
    'BB-030_Shiftfork/Linkages',
    'BB-030_ Shiftsystem Actuation'
  ],
  'BB-040_Housing': ['BB-040_Housing'],
  'BB-050_Clutches & Decoupling': [
    'BB-050_Clutch',
    'BB-050_Clutch1',
    'BB-050_Clutch2',
    'BB-050_Clutch3',
    'BB-050_Clutch4'
  ],
  'BB-060_Parklock': ['BB-060_Parklock System', 'BB-060_Parklock ', 'BB-060_Parklock']
};

const workPlanItemsMap = {
  'Project Category': ['C1', 'C2', 'C3', 'C4', 'C5', 'C6', 'C7'],
  '00_Tramsmission': [
    '00_Tramsmission',
    '00_Vehicle',
    '00_Engine',
    '00_Battery',
    '00_Breather',
    '00_Cooler fittings',
    '00_Cooler Pipe',
    '00_Inverter Inlay'
  ],
  'PFG-040_Lubrication': ['PFG-040_Lubrication'],
  'BB-010_Gears': ['BB-010_Gears', 'BB-010_Shafts', 'BB-010_Bearings'],
  'BB-010_Differentials': ['BB-010_Differentials'],
  'BB-010_Sealing': ['BB-010_Sealing'],
  'BB-020_Synchronizer System': ['BB-020_Synchronizer System', 'BB-020_Synchronizer Parts'],
  'BB-030_Actuation Drive': [
    'BB-030_Actuation Drive',
    'BB-030_Shiftfork/Linkages',
    'BB-030_ Shiftsystem Actuation'
  ],
  'BB-040_Housing': ['BB-040_Housing'],
  'BB-050_Clutch': [
    'BB-050_Clutch',
    'BB-050_Clutch',
    'BB-050_Clutch',
    'BB-050_Clutch',
    'BB-050_Clutch'
  ],
  'BB-060_Parklock System': ['BB-060_Parklock System', 'BB-060_Parklock ', 'BB-060_Parklock']
};

const WorkPlans = () => {
  const navigate = useNavigate();
  const [selectedMainCategory, setSelectedMainCategory] = useState(mainCategoryItems[0]);
  const [subCategoryItems, setSubCategoryItems] = useState(
    subCategoryItemsMap[selectedMainCategory]
  );
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [planCategoryItems, setPlanCategoryItems] = useState([]);
  const [checkboxState, setCheckboxState] = useState({
    bmwOption: false,
    ...checkboxLabels.reduce((acc, label, index) => {
      acc[`option${index + 1}`] = false;
      return acc;
    }, {})
  });

  const handleMainCategoryClick = (category) => {
    setSelectedMainCategory(category);
    setSubCategoryItems(subCategoryItemsMap[category]);
    setSelectedSubCategory(null);
    setPlanCategoryItems([]);
  };
  const handleSubCategoryClick = (subCategory) => {
    setSelectedSubCategory(subCategory);
    setPlanCategoryItems(workPlanItemsMap[subCategory] || []);
  };

  const handleBMWCheckboxChange = (event) => {
    const checked = event.target.checked;
    setCheckboxState((prevState) => ({
      ...prevState,
      bmwOption: checked,
      ...checkboxLabels.reduce((acc, label, index) => {
        acc[`option${index + 1}`] = checked;
        return acc;
      }, {})
    }));
  };
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckboxState((prevState) => ({
      ...prevState,
      [name]: checked
    }));
  };

  return (
    <div className="mt-2">
      <BackToDashboard />
      <div className="work-plan">
        <Grid container spacing={1} className="grid-container">
          <Grid item xs={6} sm={4} className="grid-item">
            <Paper elevation={0} className="paper">
              <span className="checkbox-title">Option</span>
              <Grid sx={{ marginTop: '11px' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="bmwOption"
                      checked={checkboxState.bmwOption}
                      onChange={handleBMWCheckboxChange}
                      sx={{ transform: 'scale(0.8)' }}
                    />
                  }
                  label={<span style={{ fontSize: '12px' }}>BMW - 7HDT300 - </span>}
                />
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={6} sm={8} className="grid-item">
            <Paper elevation={0} className="paper">
              <Typography className="checkbox-header">
                <span className="checkbox-title">Quotation</span>{' '}
                <span className="checkbox-title">
                  Serial Development of Product & Production (SD)
                </span>
              </Typography>
              <Grid container spacing={2} sx={{ paddingTop: 0 }}>
                <Grid sx={{ marginTop: '2rem' }}>
                  <Typography variant="body2">Cat</Typography>
                  <Typography variant="body2">C0</Typography>
                </Grid>
                {checkboxLabels.map((label, index) => (
                  <Grid item xs={6} sm={1} key={index} className="checkbox-item">
                    <Typography variant="body2" className="checkbox-label">
                      {label}
                    </Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={`option${index + 1}`}
                          checked={checkboxState[`option${index + 1}`]}
                          onChange={handleCheckboxChange}
                          sx={{
                            transform: 'scale(0.8)',
                            marginBottom: '-35px',
                            marginLeft: '-15px',
                            marginRight: 0
                          }}
                        />
                      }
                      label=""
                      className="checkbox-control"
                    />
                  </Grid>
                ))}
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={6} sm={4} className="grid-item">
            <Paper elevation={0} className="paper">
              <span className="checkbox-title">Main Category</span>
              <List className="list">
                {mainCategoryItems.map((item, index) => (
                  <ListItem
                    key={index}
                    className={item === selectedMainCategory ? 'selected-item' : ''}
                    sx={{ padding: 0, marginTop: 0, fontSize: '12px' }}
                    onClick={() => handleMainCategoryClick(item)}
                    disableTypography
                  >
                    <ListItemText
                      primary={item}
                      sx={{
                        fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                        fontSize: '12px',
                        marginLeft: '16px'
                      }}
                      disableTypography
                    />
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Grid>
          <Grid item xs={6} sm={4} className="grid-item">
            <Paper elevation={0} className="paper">
              <span className="checkbox-title">Sub Category</span>
              <List className="list">
                {subCategoryItems.map((item, index) => (
                  <ListItem
                    key={index}
                    className={item === selectedSubCategory ? 'selected-item' : ''}
                    sx={{ padding: 0, marginTop: 0, fontSize: '12px' }}
                    onClick={() => handleSubCategoryClick(item)}
                  >
                    <ListItemText
                      primary={item}
                      sx={{
                        fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                        fontSize: '12px',
                        marginLeft: '16px'
                      }}
                      disableTypography
                    />
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Grid>
          <Grid item xs={6} sm={4} className="grid-item">
            <Paper elevation={0} className="paper">
              <span className="checkbox-title">Work Plan</span>
              <List className="list">
                {planCategoryItems.map((item, index) => (
                  <ListItem
                    key={index}
                    sx={{
                      padding: 0,
                      marginTop: 0,
                      fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                      fontSize: '12px'
                    }}
                  >
                    <ListItemText primary={item} disableTypography />
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Grid>
        </Grid>
      </div>
      <Box display="flex" flexDirection="row" justifyContent="flex-end">
        <Button
          variant="contained"
          sx={{ marginRight: '1rem' }}
          onClick={() => {
            alert('Work Plan created');
          }}
        >
          CONFIRM
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            navigate('/');
          }}
        >
          CLOSE
        </Button>
      </Box>
    </div>
  );
};

export default WorkPlans;
