import React, { useEffect, useState, useCallback, useMemo } from 'react';
import ProgressSpinner from '../../Components/ProgressSpinner';
import BackToDashboard from '../../Components/BackToDashboard';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  Tooltip,
  Zoom
} from '@mui/material';
import AlertMessage from '../../Components/AlertMessage';
import { useNavigate } from 'react-router-dom';
import msalInstance from '../../msalInstance.js';
import { loginRequest } from '../../authConfig.js';
import axios from 'axios';
import { ArrowBack } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import useHasRole from '../../hooks/useHasRole.js';

const CreateNewProject = () => {
  const [customerData, setCustomerData] = useState([]);
  const [platformProgramsOptions, setPlatformProgramsOptions] = useState([]);
  const [mptProductsOptions, setMptProductsOptions] = useState([]);
  const [projectLeads, setProjectLeads] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');
  const clusterNameOptions = [
    'EPL Cluster AWD/4WD EU',
    'EPL Cluster eDrive 1 EU',
    'EPL Cluster eDrive 2 EU',
    'EPL Cluster T/M',
    'System Engineering EU',
    'Gearbox & Mechatronics EU',
    'eMotor',
    'Inverter',
    'Software',
    'EPL Cluster AWD/4WD NA',
    'EPL Cluster eDrive NA',
    'System Engineering NA',
    'Gearbox & Mechatronics NA',
    'Others'
  ];
  const [formValues, setFormValues] = useState({
    customerName: '',
    contractPartner: '',
    mptProduct: '',
    projectSpecific: '',
    projectLead: '',
    platformAndProgram: '',
    projectName: '',
    PVsequenceID: '',
    projectDescription: '',
    clusterName: '',
    CFNumber: ''
  });

  const navigate = useNavigate();
  const isAllowedRoles = useHasRole(['QVD.Admin', 'eQuote.Manager']);
  const apiUrl = process.env.REACT_APP_API_URL;

  const projectName = useMemo(() => {
    const { customerName, contractPartner, platformAndProgram, mptProduct, projectSpecific } =
      formValues;

    const parts = [
      customerName?.label,
      contractPartner?.label,
      platformAndProgram,
      mptProduct,
      projectSpecific
    ];

    return parts
      .filter((part) => part && part.trim() !== '')
      .join('_')
      .replace(/\s+/g, '_');
  }, [formValues]);

  useEffect(() => {
    setFormValues((prevValues) => ({
      ...prevValues,
      projectName
    }));
  }, [projectName]);

  const handleChange = useCallback((event) => {
    const { name, value } = event.target;
    setFormValues((prev) => ({
      ...prev,
      [name]: value
    }));
  }, []);

  const handleAutoCompleteChange = useCallback((name, newValue) => {
    setFormValues((prev) => ({
      ...prev,
      [name]: newValue
    }));
  }, []);

  const getToken = async () => {
    try {
      const tokenResponse = await msalInstance.acquireTokenSilent(loginRequest);
      const accessToken = tokenResponse.idToken;
      return accessToken;
    } catch (error) {
      console.log(error);
    }
  };

  const getCustomerData = async () => {
    try {
      setLoading(true);
      const accessToken = await getToken();
      const response = await axios.get(`${apiUrl}/api/customer`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        }
      });

      if (Array.isArray(response.data)) {
        const formattedData = response.data.map((customer) => ({
          label: customer.customerName,
          value: customer.customerID
        }));
        setCustomerData([...formattedData].sort((a, b) => a.label.localeCompare(b.label)));
      } else {
        throw new Error('Unexpected response format');
      }
    } catch (error) {
      console.error(error);
      const errorMessage =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : 'An error occurred during the API call.';
      setAlertSeverity('error');
      setAlertMessage(errorMessage);
      setAlertOpen(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCustomerData();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setLoading(true);

      const ProjectDetails = {
        customerName: formValues.customerName.label || null,
        projectName: formValues.projectName || null,
        projectDescription: formValues.projectDescription || null,
        clusterName: formValues.clusterName || null,
        PVsequenceID: formValues.PVsequenceID !== '' ? Number(formValues.PVsequenceID) : null,
        CFNumber: formValues.CFNumber !== '' ? Number(formValues.CFNumber) : null,
        contractPartner:
          formValues.contractPartner.label !== '' ? formValues.contractPartner.label : null,
        platformAndProgram: formValues.platformAndProgram || null,
        mptProduct: formValues.mptProduct || null,
        projectLead: formValues.projectLead || null,
        simplifiedName: formValues.simplifiedName || null,
        projectSpecific: formValues.projectSpecific || null
      };

      const accessToken = await getToken();
      await axios.post(`${apiUrl}/api/projects`, ProjectDetails, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        }
      });
      setOpenDialog(true);
    } catch (error) {
      console.error(error);
      const errorMessage =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : 'An error occurred during the API call.';
      setAlertSeverity('error');
      setAlertMessage(errorMessage);
      setAlertOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseDialog = () => {
    navigate('/equote-generate');
  };

  useEffect(() => {
    const fetchPlatformProgramsAndMptProducts = async () => {
      try {
        const accessToken = await getToken();

        const response = await axios.get(
          `${apiUrl}/api/projects/search?mptProduct=mptproduct&platformAndProgram=platformandprogram`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json'
            }
          }
        );

        const { platformAndProgram, mptProduct } = response.data;

        setPlatformProgramsOptions(platformAndProgram || []);
        setMptProductsOptions(mptProduct || []);
      } catch (error) {
        console.error('Error fetching platform and programs and MPT products:', error);
      }
    };
    fetchPlatformProgramsAndMptProducts();
  }, []);

  useEffect(() => {
    const fetchProjectLeads = async () => {
      try {
        const accessToken = await getToken();
        const response = await axios.get(`${apiUrl}/api/user/all`, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });
        setProjectLeads([...response.data].sort((a, b) => a.username.localeCompare(b.username)));
      } catch (error) {
        console.error('Error fetching Project Leads list:', error);
      }
    };

    fetchProjectLeads();
  }, []);

  return (
    <>
      {loading && <ProgressSpinner />}
      <AlertMessage
        open={alertOpen}
        message={alertMessage}
        severity={alertSeverity}
        onClose={() => setAlertOpen(false)}
      />
      <Box
        sx={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#f5f5f5'
        }}
      >
        <Box>
          <BackToDashboard />
        </Box>

        <Box
          sx={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '16px'
          }}
        >
          <Paper
            sx={{
              padding: '16px',
              width: '90vw',
              maxHeight: '80vh',
              overflow: 'auto',
              textAlign: 'center',
              '&::-webkit-scrollbar': {
                width: '0.4em'
              },
              '&::-webkit-scrollbar-track': {
                background: '#f1f1f1'
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#c2c1c1',
                borderRadius: '10px'
              }
            }}
          >
            <Box mb="-3rem" display="flex">
              <IconButton onClick={handleCloseDialog} style={{ color: '#800000' }}>
                <ArrowBack />
                <Typography pl="0.5rem">Back</Typography>
              </IconButton>
            </Box>
            <Box
              component="form"
              autoComplete="off"
              onSubmit={handleSubmit}
              display="flex"
              flexDirection="column"
              alignItems="center"
              mt="2rem"
            >
              <FormControl variant="outlined" margin="normal" sx={{ width: '30vw' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Autocomplete
                    options={customerData}
                    value={formValues.customerName}
                    onChange={(event, newValue) =>
                      handleAutoCompleteChange('customerName', newValue)
                    }
                    renderInput={(params) => (
                      <TextField {...params} required label="Customer Name" />
                    )}
                    sx={{ flexGrow: 1 }}
                  />
                  {isAllowedRoles && (
                    <Tooltip
                      title="Add New Customer"
                      arrow
                      placement="top"
                      TransitionComponent={Zoom}
                      slotProps={{ tooltip: { sx: { fontSize: '0.9em' } } }}
                    >
                      <IconButton
                        onClick={() => {
                          navigate('/add-new-customer');
                        }}
                        sx={{
                          backgroundColor: '#800000',
                          color: 'white',
                          width: 40,
                          height: 40,
                          marginLeft: 1,
                          borderRadius: '50%',
                          '&:hover': {
                            backgroundColor: '#6d1f1f'
                          }
                        }}
                      >
                        <AddIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>
              </FormControl>

              <FormControl variant="outlined" margin="normal" sx={{ width: '30vw' }}>
                <Autocomplete
                  options={customerData}
                  value={formValues.contractPartner}
                  onChange={(event, newValue) =>
                    handleAutoCompleteChange('contractPartner', newValue)
                  }
                  renderInput={(params) => <TextField {...params} label="Contract Partner" />}
                />
              </FormControl>

              <FormControl variant="outlined" margin="normal" sx={{ width: '30vw' }}>
                <Autocomplete
                  options={platformProgramsOptions.sort()}
                  getOptionLabel={(option) => option || ''}
                  value={formValues.platformAndProgram}
                  onChange={(event, newValue) =>
                    handleAutoCompleteChange('platformAndProgram', newValue)
                  }
                  onInputChange={(event, newInputValue) =>
                    handleAutoCompleteChange('platformAndProgram', newInputValue)
                  }
                  freeSolo
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      label="Platform and Programs"
                      variant="outlined"
                    />
                  )}
                />
              </FormControl>

              <FormControl variant="outlined" margin="normal" sx={{ width: '30vw' }}>
                <Autocomplete
                  options={mptProductsOptions.sort()}
                  getOptionLabel={(option) => option || ''}
                  value={formValues.mptProduct}
                  onChange={(event, newValue) => handleAutoCompleteChange('mptProduct', newValue)}
                  onInputChange={(event, newInputValue) =>
                    handleAutoCompleteChange('mptProduct', newInputValue)
                  }
                  freeSolo
                  renderInput={(params) => (
                    <TextField {...params} required label="MPT Products" variant="outlined" />
                  )}
                />
              </FormControl>

              <FormControl variant="outlined" margin="normal" sx={{ width: '30vw' }}>
                <TextField
                  name="projectSpecific"
                  value={formValues.projectSpecific}
                  onChange={handleChange}
                  label="Project Specific"
                />
              </FormControl>

              <FormControl variant="outlined" margin="normal" sx={{ width: '30vw' }}>
                <Tooltip title={projectName} arrow placement="top">
                  <TextField
                    name="projectName"
                    value={projectName}
                    label="Project Name"
                    required
                    InputProps={{
                      readOnly: true,
                      sx: {
                        backgroundColor: 'action.disabledBackground',
                        '& .MuiInputBase-input': {
                          color: 'text.disabled',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap'
                        }
                      }
                    }}
                  />
                </Tooltip>
              </FormControl>

              <FormControl variant="outlined" margin="normal" sx={{ width: '30vw' }}>
                <Autocomplete
                  freeSolo
                  options={projectLeads.map((option) => option.username)}
                  value={formValues.projectLead}
                  onChange={(event, newValue) => handleAutoCompleteChange('projectLead', newValue)}
                  onInputChange={(event, newInputValue) => {
                    if (event && event.type === 'change') {
                      handleAutoCompleteChange('projectLead', newInputValue);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Project Lead" variant="outlined" required />
                  )}
                />
              </FormControl>

              <FormControl variant="outlined" required margin="normal" sx={{ width: '30vw' }}>
                <Autocomplete
                  options={clusterNameOptions.sort()}
                  getOptionLabel={(option) => option || ''}
                  value={formValues.clusterName}
                  onChange={(event, newValue) => handleAutoCompleteChange('clusterName', newValue)}
                  renderInput={(params) => (
                    <TextField {...params} required label="Cluster Name" variant="outlined" />
                  )}
                />
              </FormControl>

              <FormControl variant="outlined" margin="normal" sx={{ width: '30vw' }}>
                <TextField
                  id="projectDescription"
                  name="projectDescription"
                  label="Project Description"
                  variant="outlined"
                  value={formValues.projectDescription}
                  onChange={handleChange}
                />
              </FormControl>

              <FormControl variant="outlined" margin="normal" sx={{ width: '30vw' }}>
                <TextField
                  id="simplifiedName"
                  name="simplifiedName"
                  label="Simplified Name"
                  variant="outlined"
                  value={formValues.simplifiedName}
                  onChange={handleChange}
                />
              </FormControl>

              <FormControl variant="outlined" margin="normal" sx={{ width: '30vw' }}>
                <TextField
                  id="PVsequenceID"
                  name="PVsequenceID"
                  label="PV SequenceID"
                  variant="outlined"
                  value={formValues.PVsequenceID}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value === '' || /^[0-9]+$/.test(value)) {
                      handleChange(e);
                    }
                  }}
                  inputProps={{
                    inputMode: 'numeric',
                    pattern: '[0-9]*'
                  }}
                />
              </FormControl>

              <FormControl variant="outlined" margin="normal" sx={{ width: '30vw' }}>
                <TextField
                  id="CFNumber"
                  name="CFNumber"
                  label="CF Number"
                  variant="outlined"
                  value={formValues.CFNumber}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value === '' || /^[0-9]+$/.test(value)) {
                      handleChange(e);
                    }
                  }}
                  inputProps={{
                    inputMode: 'numeric',
                    pattern: '[0-9]*'
                  }}
                />
              </FormControl>

              <Button
                type="submit"
                variant="contained"
                sx={{
                  p: '0.5rem',
                  mt: '0.5rem'
                }}
              >
                Create Project
              </Button>
            </Box>

            <Box>
              <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle id="success-dialog-title">
                  New Project has been successfully created!
                </DialogTitle>
                <DialogActions sx={{ justifyContent: 'center' }}>
                  <Button
                    variant="contained"
                    onClick={handleCloseDialog}
                    sx={{
                      width: '10vw'
                    }}
                  >
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
            </Box>
          </Paper>
        </Box>
      </Box>
    </>
  );
};

export default CreateNewProject;
