import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ErrorBoundaryCustomPage from './ErrorBoundaryCustomPage';
import msalInstance from '../msalInstance.js';
import { loginRequest } from '../authConfig.js';
import axios from 'axios';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  getToken = async () => {
    try {
      const tokenResponse = await msalInstance.acquireTokenSilent(loginRequest);
      return tokenResponse.accessToken;
    } catch (error) {
      console.error('Error acquiring token:', error);
    }
  };

  async componentDidCatch(error, errorInfo) {
    console.error('Error caught by ErrorBoundary:', error, errorInfo);

    const shouldLogError = process.env.REACT_APP_LOG_ERROR === 'true';

    if (!shouldLogError) {
      return;
    }

    const errorPayload = {
      ErrorMessage: error.message || 'An unexpected error occurred.',
      ErrorStack: error.stack,
      ErrorComponent: this.props.componentName || 'UnknownComponent',
      ErrorInfo: errorInfo?.componentStack || 'No additional info available.'
    };

    try {
      const accessToken = await this.getToken();

      if (accessToken) {
        await axios.post(`${process.env.REACT_APP_API_URL}/api/logError`, errorPayload, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          }
        });

        await this.sendErrorEmail(accessToken, errorPayload);
      }
    } catch (loggingError) {
      console.error('Failed to log error to API:', loggingError);
    }
  }

  sendErrorEmail = async (accessToken, errorPayload) => {
    const emailPayload = {
      message: {
        subject: `Error Report: ${errorPayload.ErrorMessage}`,
        body: {
          contentType: 'Text',
          content: `
            An error occurred in the application:

            Error Message: ${errorPayload.ErrorMessage}
            Error Stack: ${errorPayload.ErrorStack}
            Component: ${errorPayload.ErrorComponent}
            Additional Info: ${errorPayload.ErrorInfo}
          `
        },
        toRecipients: [
          {
            emailAddress: {
              address: 'ijaz.m@partner.magna.com'
            }
          }
        ]
      },
      saveToSentItems: 'true'
    };

    try {
      await axios.post('https://graph.microsoft.com/v1.0/me/sendMail', emailPayload, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        }
      });
      console.log('Error email sent successfully');
    } catch (emailError) {
      console.error('Failed to send error email:', emailError);
    }
  };

  render() {
    if (this.state.hasError) {
      return <ErrorBoundaryCustomPage />;
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  componentName: PropTypes.string
};

export default ErrorBoundary;
