import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
  Zoom,
  Autocomplete
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import BackToDashboard from '../../Components/BackToDashboard';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import axios from 'axios';
import msalInstance from '../../msalInstance.js';
import { loginRequest } from '../../authConfig.js';
import ProgressSpinner from '../../Components/ProgressSpinner.jsx';
import AlertMessage from '../../Components/AlertMessage.jsx';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import useHasRole from '../../hooks/useHasRole.js';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

const eQuoteTypeOptions = [
  { label: 'pre eQuote (ACQ Phase)', value: 'pre-eQuote' },
  { label: 'eQuote light (< €/$ 1 Mils)', value: 'eQuote light' },
  { label: 'eQuote (> €/$ 1 Mils)', value: 'eQuote' },
  { label: 'eQuote estimate', value: 'eQuote estimate' }
];
const regionOptions = ['EU', 'NA', 'CN', 'GL'];
const requestTypeOptions = [
  'New Project(CAP)',
  'DCR/DCD',
  'CTP',
  'Purchasing(Request from PUR)',
  'Operations',
  'Engineering Support',
  'Other Request'
];
const eQuoteCharacterOptions = ['Binding', 'Non-Binding'];

const GenerateQuote = () => {
  const [projectData, setProjectData] = useState([]);
  const [projectNameArr, setProjectNameArr] = useState([]);
  const [quoteDetails, setQuoteDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [formErrors, setFormErrors] = useState({});
  const [usernames, setUsernames] = useState([]);
  const [formValues, setFormValues] = useState({
    region: '',
    eQuoteType: '',
    projectName: '',
    eQuoteCharacter: 'Non-Binding',
    requestType: null,
    quoteFinishTargetDate: null,
    eQuoteCustomerRequestDate: null,
    eQuoteDueDate: null,
    eQuoteKickOffDate: null,
    eQuoteLead: '',
    eQuoteSecondLead: '',
    eQuoteDescription: null,
    eQuoteShortName: ''
  });

  const navigate = useNavigate();
  const isAllowedRoles = useHasRole(['QVD.Admin', 'eQuote.Manager']);
  const apiUrl = process.env.REACT_APP_API_URL;

  const getToken = async () => {
    try {
      const tokenResponse = await msalInstance.acquireTokenSilent(loginRequest);
      const accessToken = tokenResponse.idToken;
      return accessToken;
    } catch (error) {
      console.log(error);
    }
  };

  const getAccessToken = async () => {
    try {
      const tokenResponse = await msalInstance.acquireTokenSilent(loginRequest);
      const accessToken = tokenResponse.accessToken;
      return accessToken;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchUsernames = async () => {
      try {
        const accessToken = await getToken();
        const response = await axios.get(`${apiUrl}/api/user/all`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          }
        });

        const leadUsers = response.data
          .filter((user) => user.qvdRole === 'eQuote.ProjectLead')
          .map((user) => ({ username: user.username, userID: user.userID }));

        setUsernames([...leadUsers].sort((a, b) => a.username.localeCompare(b.username)));
      } catch (error) {
        console.error('Failed to fetch usernames:', error);
        setAlertSeverity('error');
        setAlertMessage('An error occurred while fetching usernames.');
        setAlertOpen(true);
      }
    };

    fetchUsernames();
  }, []);

  const mapUser = (userID) => {
    const user = usernames.find((user) => user.userID === userID);
    return user ? user.username : 'Unknown User';
  };
  const eQuoteLeadUsername = mapUser(quoteDetails.eQuoteLead);

  const fetchProjectData = async () => {
    try {
      setLoading(true);
      const accessToken = await getToken();
      const response = await axios.get(`${apiUrl}/api/projects`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        }
      });

      const data = response.data;

      const activeProjects = data.filter((item) => item.status === 'active');
      setProjectData(activeProjects);

      const filteredNames = activeProjects.map((item) => item.projectName);
      setProjectNameArr(filteredNames);
    } catch (error) {
      console.error(error);
      const errorMessage =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : 'An error occurred during the API call.';
      setAlertSeverity('error');
      setAlertMessage(errorMessage);
      setAlertOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const getUserData = async () => {
    try {
      setLoading(true);
      const accessToken = await getToken();
      const response = await axios.get(`${apiUrl}/api/user`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        }
      });
      const data = response.data;
      setFormValues({
        ...formValues,
        region: data.region
      });
    } catch (error) {
      console.error(error);
      setAlertSeverity('error');
      setAlertMessage('An error occurred during the API call.');
      setAlertOpen(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProjectData();
    getUserData();
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
  };

  const handleDateChange = (name, newValue) => {
    setFormValues({
      ...formValues,
      [name]: newValue
    });
  };

  const validateForm = () => {
    const requiredFields = {
      projectName: 'Project Name is required',
      eQuoteType: 'eQuote Type is required',
      region: 'Region is required',
      eQuoteLead: 'eQuote Lead is required',
      eQuoteShortName: 'eQuote Short Name is required',
      eQuoteCustomerRequestDate: 'Customer Request Date is required',
      eQuoteCharacter: 'eQuote Character is required'
    };

    const errors = {};

    Object.keys(requiredFields).forEach((field) => {
      if (!formValues[field]) {
        errors[field] = requiredFields[field];
      }
    });

    return errors;
  };

  const getProjectId = (projectName) => {
    const item = projectData.find((item) => item.projectName === projectName);
    return item.projectID.toString();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const errors = validateForm();

    if (Object.keys(errors).length !== 0) {
      setFormErrors(errors);
      return;
    }

    try {
      setLoading(true);

      const quoteDetails = {
        region: formValues.region,
        eQuoteType: formValues.eQuoteType,
        projectID: Number(getProjectId(formValues.projectName)),
        ...(formValues.quoteFinishTargetDate && {
          quoteFinishTargetDate: dayjs(formValues.quoteFinishTargetDate).format('YYYY-MM-DD')
        }),
        requestType:
          formValues.requestType !== undefined && formValues.requestType !== ''
            ? formValues.requestType
            : null,
        ...(formValues.eQuoteCharacter && {
          eQuoteCharacter: formValues.eQuoteCharacter.toLowerCase()
        }),
        ...(formValues.eQuoteCustomerRequestDate && {
          eQuoteCustomerRequestDate: dayjs(formValues.eQuoteCustomerRequestDate).format(
            'YYYY-MM-DD'
          )
        }),
        ...(formValues.eQuoteKickOffDate && {
          eQuoteKickoffDate: dayjs(formValues.eQuoteKickOffDate).format('YYYY-MM-DD')
        }),
        ...(formValues.eQuoteDueDate && {
          eQuoteDueDate: dayjs(formValues.eQuoteDueDate).format('YYYY-MM-DD')
        }),
        eQuoteLead: formValues.eQuoteLead,
        secondLead: formValues.eQuoteSecondLead === '' ? null : formValues.eQuoteSecondLead,
        eQuoteDescription:
          formValues.eQuoteDescription !== undefined ? formValues.eQuoteDescription : null,
        eQuoteShortName: formValues.eQuoteShortName
      };

      const accessToken = await getToken();
      const response = await axios.post(`${apiUrl}/api/equotes`, quoteDetails, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        }
      });
      const data = response.data;
      setQuoteDetails(data);

      if (!isAllowedRoles) {
        await sendMail(data);
      }
    } catch (error) {
      console.error(error);
      const errorMessage =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : 'An error occurred during the API call.';
      setAlertSeverity('error');
      setAlertMessage(errorMessage);
      setAlertOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const CustomItem = ({ titleText, contentText }) => {
    return (
      <>
        <Typography variant="body1" fontWeight="bold" sx={{ color: '#800000' }}>
          {titleText}
        </Typography>
        <Typography variant="body1" sx={{ color: '#333333' }}>
          {contentText}
        </Typography>
      </>
    );
  };

  const sendMail = async (quoteDetails) => {
    const accessToken = await getAccessToken();

    const regionEmails = {
      EU: process.env.REACT_APP_ADMIN_MAIL_EU || 'ijaz.m@partner.magna.com',
      CN: process.env.REACT_APP_ADMIN_MAIL_CN || 'ijaz.m@partner.magna.com',
      GL: process.env.REACT_APP_ADMIN_MAIL_GL || 'ijaz.m@partner.magna.com',
      NA: process.env.REACT_APP_ADMIN_MAIL_NA || 'ijaz.m@partner.magna.com'
    };

    const recipientEmail = (regionEmails[quoteDetails.region] || 'ijaz.m@partner.magna.com')
      .split(',')
      .map((email) => ({ emailAddress: { address: email.trim() } }));
    const defaultBccEmail = process.env.REACT_APP_ADMIN_EMAIL_DEF || 'ijaz.m@partner.magna.com';
    const webUrl = `${window.location.origin}/equote-number-approval`;

    try {
      setLoading(true);
      const response = await fetch('https://graph.microsoft.com/v1.0/me/sendMail', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          message: {
            subject: `[QuoVaDis] eQuote Number Approval Request ${quoteDetails.eQuoteNumber}`,
            body: {
              contentType: 'HTML',
              content: `
                <p>Hello,</p>
                <p>New request for eQuote Number approval:</p>
                <ul>
                  <li><strong>eQuote Number:</strong> ${quoteDetails.eQuoteNumber}</li>
                  <li><strong>Region:</strong> ${quoteDetails.region}</li>
                  <li><strong>eQuote Short Name:</strong> ${quoteDetails.eQuoteShortName}</li>
                </ul>
                <p>Please click the button below to approve, edit, or reject the request:</p>
                <a href="${webUrl}" style="
                  display: inline-block;
                  padding: 10px 20px;
                  margin-top: 10px;
                  color: #fff;
                  background-color: #800000;
                  text-decoration: none;
                  border-radius: 5px;
                  font-weight: bold;
                  ">VIEW REQUEST</a> 
              `
            },
            toRecipients: recipientEmail,
            bccRecipients: [
              {
                emailAddress: {
                  address: defaultBccEmail
                }
              }
            ]
          }
        })
      });

      if (response.ok) {
        console.log('Mail sent successfully');
        setAlertSeverity('success');
        setAlertMessage('Notified QVD Admin through mail successfully!');
        setAlertOpen(true);
      } else {
        console.error('Failed to send mail', response);
      }
    } catch (error) {
      console.error('Error sending mail:', error);
      setAlertSeverity('error');
      setAlertMessage(
        'Requested eQuote Number successfully, but error notifying Admin, kindly contact Admin manually'
      );
      setAlertOpen(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <ProgressSpinner />}
      <AlertMessage
        open={alertOpen}
        message={alertMessage}
        severity={alertSeverity}
        onClose={() => setAlertOpen(false)}
      />
      <Box
        sx={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#f5f5f5'
        }}
      >
        <Box>
          <BackToDashboard />
        </Box>

        <Box
          sx={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '16px'
          }}
        >
          {quoteDetails && Object.keys(quoteDetails).length !== 0 ? (
            <Paper elevation={3} style={{ padding: '30px', maxWidth: '60vw' }}>
              <Box display="grid" gridTemplateColumns="2fr 2fr" gap={2} width="100%">
                <CustomItem
                  titleText="eQuote Number:"
                  contentText={!isAllowedRoles ? 'In Request' : quoteDetails.eQuoteNumber}
                />

                <CustomItem titleText="Region:" contentText={quoteDetails.region} />
                <CustomItem
                  titleText="eQuote Short Name:"
                  contentText={quoteDetails.eQuoteShortName}
                />
                <CustomItem titleText="eQuote Version:" contentText={quoteDetails.eQuoteVersion} />
                <CustomItem
                  titleText="Creation Date:"
                  contentText={dayjs(quoteDetails.creation_date).format('YYYY-MM-DD')}
                />
                <CustomItem titleText="eQuote Status:" contentText={quoteDetails.eQuoteStatus} />
                <CustomItem titleText="eQuote Type:" contentText={quoteDetails.eQuoteType} />
                <CustomItem titleText="eQuote Lead:" contentText={eQuoteLeadUsername} />
                <CustomItem
                  titleText="eQuote Character:"
                  contentText={quoteDetails.eQuoteCharacter}
                />
                <CustomItem
                  titleText="eQuote Finished Target Date:"
                  contentText={quoteDetails.quoteFinishTargetDate}
                />
              </Box>
              <Box display="flex" justifyContent="center" mt="2rem">
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    width: '10vw'
                  }}
                  onClick={() => {
                    navigate('/');
                  }}
                >
                  Close
                </Button>
              </Box>
            </Paper>
          ) : (
            <Paper
              elevation={3}
              sx={{
                padding: '16px',
                width: '80vw',
                maxHeight: '70vh',
                overflow: 'auto',
                textAlign: 'center',
                '&::-webkit-scrollbar': {
                  width: '0.4em'
                },
                '&::-webkit-scrollbar-track': {
                  background: '#f1f1f1'
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#c2c1c1',
                  borderRadius: '10px'
                }
              }}
            >
              {isAllowedRoles && (
                <Box display="flex" justifyContent="flex-end">
                  <Tooltip
                    title="Create New Project"
                    arrow
                    placement="top"
                    TransitionComponent={Zoom}
                    slotProps={{ tooltip: { sx: { fontSize: '0.9em' } } }}
                  >
                    <IconButton
                      onClick={() => {
                        navigate('/create-project');
                      }}
                      sx={{
                        backgroundColor: '#800000',
                        color: 'white',
                        width: 40,
                        height: 40,
                        borderRadius: '50%',
                        '&:hover': {
                          backgroundColor: '#6d1f1f'
                        }
                      }}
                    >
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit}>
                  <Box display="flex">
                    <Box flex="1" pr="1rem">
                      <FormControl fullWidth margin="normal">
                        <Autocomplete
                          id="projectName"
                          options={projectNameArr.sort()}
                          value={formValues.projectName || null}
                          onChange={(event, newValue) =>
                            handleChange({
                              target: { name: 'projectName', value: newValue ? newValue : null }
                            })
                          }
                          renderInput={(params) => (
                            <Tooltip title="If Project is not visible, kindly contact eQuote Support team">
                              <TextField
                                {...params}
                                label="Project Name"
                                placeholder="Select Project Name"
                                error={!!formErrors.projectName}
                                required
                              />
                            </Tooltip>
                          )}
                          sx={{ textAlign: 'left' }}
                          isOptionEqualToValue={(option, value) => option === value}
                        />
                        {formErrors.projectName && (
                          <FormHelperText error>{formErrors.projectName}</FormHelperText>
                        )}
                      </FormControl>
                      <FormControl
                        fullWidth
                        margin="normal"
                        required
                        error={!!formErrors.eQuoteType}
                      >
                        <InputLabel id="eQuoteType-label">eQuote Type</InputLabel>
                        <Select
                          labelId="eQuoteType-label"
                          id="eQuoteType"
                          name="eQuoteType"
                          value={formValues.eQuoteType}
                          onChange={handleChange}
                          label="eQuote Type"
                          sx={{ textAlign: 'left' }}
                        >
                          {eQuoteTypeOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                        {formErrors.eQuoteType && (
                          <FormHelperText error>{formErrors.eQuoteType}</FormHelperText>
                        )}
                      </FormControl>

                      <FormControl
                        fullWidth
                        margin="normal"
                        required
                        error={!!formErrors.eQuoteLead}
                      >
                        <Autocomplete
                          id="eQuoteLead"
                          options={usernames}
                          getOptionLabel={(option) => option.username}
                          value={
                            usernames.find((user) => user.userID === formValues.eQuoteLead) || null
                          }
                          onChange={(event, newValue) => {
                            handleChange({
                              target: {
                                name: 'eQuoteLead',
                                value: newValue ? newValue.userID : ''
                              }
                            });
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="eQuote Lead"
                              required
                              error={!!formErrors.eQuoteLead}
                              helperText={formErrors.eQuoteLead}
                            />
                          )}
                          isOptionEqualToValue={(option, value) => option.userID === value}
                        />
                      </FormControl>

                      <FormControl fullWidth margin="normal">
                        <Autocomplete
                          id="eQuoteSecondLead"
                          options={usernames}
                          getOptionLabel={(option) => option.username}
                          value={
                            usernames.find((user) => user.userID === formValues.eQuoteSecondLead) ||
                            null
                          }
                          onChange={(event, newValue) => {
                            handleChange({
                              target: {
                                name: 'eQuoteSecondLead',
                                value: newValue ? newValue.userID : ''
                              }
                            });
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="eQuote 2nd Lead"
                              error={!!formErrors.eQuoteSecondLead}
                              helperText={formErrors.eQuoteSecondLead}
                            />
                          )}
                          isOptionEqualToValue={(option, value) => option.userID === value}
                        />
                      </FormControl>

                      <FormControl fullWidth margin="normal" required>
                        <DatePicker
                          label="eQuote Customer Request Date"
                          value={formValues.eQuoteCustomerRequestDate}
                          onChange={(newValue) =>
                            handleDateChange('eQuoteCustomerRequestDate', newValue)
                          }
                          slotProps={{
                            textField: {
                              required: true,
                              error: !!formErrors.eQuoteCustomerRequestDate
                            }
                          }}
                        />
                        {formErrors.eQuoteCustomerRequestDate && (
                          <FormHelperText error>
                            {formErrors.eQuoteCustomerRequestDate}
                          </FormHelperText>
                        )}
                      </FormControl>

                      <FormControl fullWidth margin="normal">
                        <DatePicker
                          label="eQuote Kick-Off Date"
                          value={formValues.eQuoteKickOffDate}
                          onChange={(newValue) => handleDateChange('eQuoteKickOffDate', newValue)}
                        />
                      </FormControl>

                      <FormControl fullWidth margin="normal">
                        <DatePicker
                          label="eQuote Finished Target Date"
                          value={formValues.quoteFinishTargetDate}
                          onChange={(newValue) =>
                            handleDateChange('quoteFinishTargetDate', newValue)
                          }
                        />
                      </FormControl>
                    </Box>

                    <Box flex="1">
                      <FormControl fullWidth margin="normal" required error={!!formErrors.region}>
                        <InputLabel id="region-label">Region</InputLabel>
                        <Select
                          labelId="region-label"
                          id="region"
                          name="region"
                          value={formValues.region}
                          onChange={handleChange}
                          label="Region"
                          sx={{ textAlign: 'left' }}
                        >
                          {regionOptions.map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                        {formErrors.region && (
                          <FormHelperText error>{formErrors.region}</FormHelperText>
                        )}
                      </FormControl>

                      {/* <FormControl fullWidth margin="normal" disabled>
                        <TextField
                          id="eQuoteStatus"
                          label="eQuote Status"
                          value="In Work"
                          variant="outlined"
                          InputProps={{
                            readOnly: true
                          }}
                          disabled
                        />
                      </FormControl> */}

                      <FormControl fullWidth margin="normal" error={!!formErrors.requestType}>
                        <InputLabel id="requestType-label">Request Type</InputLabel>
                        <Select
                          labelId="requestType-label"
                          id="requestType"
                          name="requestType"
                          value={formValues.requestType}
                          onChange={handleChange}
                          label="Request Type"
                          sx={{ textAlign: 'left' }}
                        >
                          {requestTypeOptions.map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                          <MenuItem value="">-- None --</MenuItem>
                        </Select>
                        {formErrors.requestType && (
                          <FormHelperText error>{formErrors.requestType}</FormHelperText>
                        )}
                      </FormControl>

                      <FormControl
                        fullWidth
                        margin="normal"
                        required
                        error={!!formErrors.eQuoteCharacter}
                      >
                        <InputLabel id="eQuoteCharacter-label">eQuote Character</InputLabel>
                        <Select
                          labelId="eQuoteCharacter-label"
                          id="eQuoteCharacter"
                          name="eQuoteCharacter"
                          value={formValues.eQuoteCharacter}
                          onChange={handleChange}
                          label="eQuote Character"
                          sx={{ textAlign: 'left' }}
                        >
                          {eQuoteCharacterOptions.map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                        {formErrors.eQuoteCharacter && (
                          <FormHelperText error>{formErrors.eQuoteCharacter}</FormHelperText>
                        )}
                      </FormControl>

                      <FormControl
                        fullWidth
                        margin="normal"
                        required
                        error={!!formErrors.eQuoteShortName}
                      >
                        <Tooltip title="Visible Line in Planview, e.g. DCD-Number or Name (max. char: 50)">
                          <TextField
                            id="eQuoteShortName"
                            name="eQuoteShortName"
                            label="eQuote Short Name"
                            value={formValues.eQuoteShortName}
                            onChange={handleChange}
                            inputProps={{ maxLength: 50 }}
                            required
                            error={!!formErrors.eQuoteShortName}
                          />
                        </Tooltip>
                        {formErrors.eQuoteShortName && (
                          <FormHelperText error>{formErrors.eQuoteShortName}</FormHelperText>
                        )}
                      </FormControl>

                      <FormControl fullWidth margin="normal">
                        <DatePicker
                          label="eQuote Due Date"
                          value={formValues.eQuoteDueDate}
                          onChange={(newValue) => handleDateChange('eQuoteDueDate', newValue)}
                        />
                      </FormControl>

                      <FormControl fullWidth margin="normal">
                        <TextField
                          id="equoteDescription"
                          name="equoteDescription"
                          label="eQuote Description"
                          value={formValues.eQuoteDescription}
                          onChange={handleChange}
                        />
                      </FormControl>
                    </Box>
                  </Box>

                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      width: '10vw'
                    }}
                  >
                    Submit
                  </Button>
                </Box>
              </LocalizationProvider>
            </Paper>
          )}
        </Box>
      </Box>
    </>
  );
};

export default GenerateQuote;
