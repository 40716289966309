import React from 'react';
import {
  Container,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Link as MuiLink
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';

const FaqPage = () => {
  const faqItems = [
    {
      question: 'What is MAGNA QuoVaDis?',
      answer: 'MAGNA QuoVaDis is a tool designed to help manage projects and eQuotes efficiently.'
    },
    {
      question: 'How can I contact support?',
      answer: (
        <>
          You can contact support by email at{' '}
          <MuiLink href="mailto:support@eqgna.com">equoting.eu.mpt@magna.com</MuiLink> or directly
          from our{' '}
          <MuiLink component={Link} to="/contact-support">
            Support Page
          </MuiLink>
          .
        </>
      )
    }
  ];

  /* Page is placeholder and may need updated content based on the client requirement! */

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Typography variant="h3" gutterBottom align="center">
        Frequently Asked Questions
      </Typography>
      {faqItems.map((item, index) => (
        <Accordion key={index}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
          >
            <Typography variant="h6">{item.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{item.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
      <Button component={Link} to="/" variant="contained" color="primary" sx={{ mt: 3 }}>
        Go to Home
      </Button>
    </Container>
  );
};

export default FaqPage;
