import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import {
  Container,
  Typography,
  Button,
  Box,
  IconButton,
  Select,
  MenuItem,
  Avatar
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import PersonIcon from '@mui/icons-material/Person';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { loginRequest } from '../../authConfig.js';
import ProgressSpinner from '../../Components/ProgressSpinner.jsx';
import AlertMessage from '../../Components/AlertMessage.jsx';
import BackToDashboard from '../../Components/BackToDashboard.jsx';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';

const ProfilePage = () => {
  const { accounts, instance: msalInstance } = useMsal();
  const loggedInAccount = accounts[0];
  const roles = loggedInAccount?.idTokenClaims?.roles || [];
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [alertOpen, setAlertOpen] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;

  const [profilePicture, setProfilePicture] = useState(null);

  const roleMapping = {
    'eQuote.Manager': 'eQuote Manager',
    'eQuote.ProjectLead': 'eQuote Lead',
    'eQuote.Reviewer': 'eQuote Reviewer',
    'eQuote.Approver': 'eQuote Approver',
    'eQuote.ReportReader': 'eQuote Report Reader',
    'eQuote.Uploader': 'eQuote Uploader',
    'QVD.Admin': 'QVD Admin'
  };

  const regions = ['EU', 'NA', 'CN', 'GL'];

  const [userDetails, setUserDetails] = useState({
    role: '',
    region: ''
  });

  const [isEditing, setIsEditing] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState('');

  const getToken = async () => {
    try {
      const tokenResponse = await msalInstance.acquireTokenSilent(loginRequest);
      const accessToken = tokenResponse.idToken;
      return accessToken;
    } catch (error) {
      console.log('Error acquiring token:', error);
    }
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        setLoading(true);
        const accessToken = await getToken();
        const response = await axios.get(`${apiUrl}/api/user`, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });

        const userData = response.data;

        setUserDetails({
          role: userData.qvdRole,
          region: userData.region
        });
        setSelectedRegion(userData.region);
      } catch (error) {
        console.error('Error fetching user details:', error);
        setAlertSeverity('error');
        setAlertMessage('An error occurred during the API call.');
        setAlertOpen(true);
      } finally {
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, []);

  const handleRegionChange = async () => {
    try {
      setLoading(true);
      const accessToken = await getToken();
      await axios.patch(
        `${apiUrl}/api/user`,
        { region: selectedRegion },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      );

      setUserDetails((prevDetails) => ({
        ...prevDetails,
        region: selectedRegion
      }));
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating region:', error);
      setAlertSeverity('error');
      setAlertMessage('An error occurred while editing region, try later!');
      setAlertOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const mappedRoles = roles.map((role) => roleMapping[role] || role).join(', ');

  const getProfilePicture = async () => {
    try {
      const tokenResponse = await msalInstance.acquireTokenSilent({
        scopes: ['User.Read']
      });

      const accessToken = tokenResponse.accessToken;
      const response = await axios.get('https://graph.microsoft.com/v1.0/me/photo/$value', {
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        responseType: 'blob'
      });

      const imageUrl = URL.createObjectURL(response.data);
      setProfilePicture(imageUrl);
    } catch (error) {
      console.error('Error fetching profile picture:', error);
    }
  };

  useEffect(() => {
    getProfilePicture();
  }, []);

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#ebebeb'
      }}
    >
      <Box>
        <BackToDashboard />
      </Box>
      <Container
        maxWidth="md"
        sx={{
          marginTop: '20px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          flexGrow: 1
        }}
      >
        {loading && <ProgressSpinner />}
        <AlertMessage
          open={alertOpen}
          message={alertMessage}
          severity={alertSeverity}
          onClose={() => setAlertOpen(false)}
        />
        <Box
          sx={{
            width: '100%',
            padding: '30px',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            borderRadius: '10px',
            display: 'flex',
            bgcolor: 'white',
            flexDirection: 'column'
          }}
        >
          <Box sx={{ display: 'flex', marginBottom: '20px' }}>
            <Box sx={{ marginRight: '20px' }}>
              {profilePicture ? (
                <Avatar
                  src={profilePicture}
                  alt="Profile"
                  sx={{ width: 100, height: 100, boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)' }}
                />
              ) : (
                <Avatar
                  sx={{ width: 100, height: 100, boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)' }}
                >
                  <PersonIcon sx={{ fontSize: 40 }} />
                </Avatar>
              )}
            </Box>
            <Box sx={{ flex: 1 }}>
              <Box sx={{ display: 'flex', marginBottom: '10px' }}>
                <Typography variant="body1" fontWeight="bold" sx={{ width: '80px' }}>
                  Name:
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    maxWidth: { xs: '150px', sm: '300px', md: '700px' }
                  }}
                >
                  {loggedInAccount.name}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', marginBottom: '10px' }}>
                <Typography variant="body1" fontWeight="bold" sx={{ width: '80px' }}>
                  Email:
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    maxWidth: { xs: '150px', sm: '300px', md: '400px' }
                  }}
                >
                  {loggedInAccount.username}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', marginBottom: '10px' }}>
                <Typography variant="body1" fontWeight="bold" sx={{ width: '80px' }}>
                  Role:
                </Typography>
                <Typography variant="body1">{mappedRoles || 'No roles assigned'}</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1" fontWeight="bold" sx={{ width: '80px' }}>
                  Region:
                </Typography>
                {isEditing ? (
                  <Box display="flex" alignItems="center">
                    <Select
                      value={selectedRegion}
                      onChange={(e) => setSelectedRegion(e.target.value)}
                      sx={{ marginRight: '10px', minWidth: '100px' }}
                    >
                      {regions.map((region) => (
                        <MenuItem key={region} value={region}>
                          {region}
                        </MenuItem>
                      ))}
                    </Select>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ mr: 1 }}
                      onClick={handleRegionChange}
                    >
                      Save
                    </Button>
                    <Button variant="outlined" color="primary" onClick={() => setIsEditing(false)}>
                      Cancel
                    </Button>
                  </Box>
                ) : (
                  <Box display="flex" alignItems="center">
                    <Typography variant="body1">{userDetails.region}</Typography>
                    <IconButton
                      size="small"
                      onClick={() => setIsEditing(true)}
                      sx={{ marginLeft: '10px' }}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>

          {!isEditing && (
            <Button
              component={Link}
              to="/"
              variant="contained"
              color="primary"
              sx={{ mt: 3, alignSelf: 'flex-start' }}
            >
              Go to Home
            </Button>
          )}
        </Box>
      </Container>

      <Box sx={{ padding: '20px', textAlign: 'center' }}>
        <Tooltip title="Profile details are retrieved from the Microsoft Graph API linked with MAGNA. Any changes are subject to the organization's policy restrictions.">
          <IconButton>
            <InfoIcon color="action" />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default ProfilePage;
