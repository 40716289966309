import { Box, CircularProgress } from '@mui/material';
import React from 'react';

const ProgressSpinner = () => {
  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        zIndex: 1000
      }}
    >
      <CircularProgress
        style={{
          color: '#FFFFFF',
          backgroundImage: `url(${window.location.origin}/progressspinner.svg)`,
          backgroundSize: 'cover',
          backgroundColor: '#800000',
          backgroundPosition: 'center',
          borderRadius: '50%',
          padding: '4px',
          boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.3)'
        }}
      />
    </Box>
  );
};

export default ProgressSpinner;
