export const psp = [
  {
    subProject: 'SP01 Eng. Project Management',
    region: ['EU', 'NA', 'CN'],
    location: '',
    OrganizationalFunction: 'Prototyping',
    abbrAndAuth: 'NA PT-P',
    resourceRequirement: 'Travel',
    planViewWorkPackage: '',
    rigCategory: '',
    costCategory: '',
    note: '',
    fteTeta: '',
    fte: '',
    total: '',
    year2023: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2024: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2025: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2026: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2027: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    }
  },
  {
    subProject: 'SP02 System Eng. & PFG',
    region: ['EU', 'NA', 'CN'],
    location: 'TRY, RAM',
    OrganizationalFunction: 'Expenditures',
    abbrAndAuth: 'TRV',
    resourceRequirement: 'Material',
    planViewWorkPackage: '',
    rigCategory: '',
    costCategory: '',
    note: '',
    fteTeta: '',
    fte: '',
    total: '',
    year2023: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2024: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2025: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2026: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2027: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    }
  },
  {
    subProject: 'SP03 Gearbox & Mechatronics',
    region: ['EU', 'NA', 'CN'],
    location: '',
    OrganizationalFunction: 'Prototyping',
    abbrAndAuth: 'MAT',
    resourceRequirement: 'Purchased Services - Component Dev',
    planViewWorkPackage: '',
    rigCategory: '',
    costCategory: '',
    note: '',
    fteTeta: '',
    fte: '',
    total: '',
    year2023: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2024: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2025: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2026: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2027: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    }
  },
  {
    subProject: 'SP04 E-Motor',
    region: ['GEE', 'CN'],
    location: 'TRY,RAM',
    OrganizationalFunction: 'Expenditure',
    abbrAndAuth: '',
    resourceRequirement: 'Purcahsed Services - External Testing',
    planViewWorkPackage: '',
    rigCategory: '',
    costCategory: '',
    note: '',
    fteTeta: '',
    fte: '',
    total: '',
    year2023: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2024: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2025: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2026: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2027: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    }
  },
  {
    subProject: 'SP05 Inverter',
    region: ['GEE'],
    location: 'TRY, RAM',
    OrganizationalFunction: 'Prototyping',
    abbrAndAuth: 'PET',
    resourceRequirement: 'Purchased Services - External Workforce',
    planViewWorkPackage: '',
    rigCategory: '',
    costCategory: '',
    note: '',
    fteTeta: '',
    fte: '',
    total: '',
    year2023: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2024: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2025: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2026: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2027: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    }
  },
  {
    subProject: 'SP06 Software',
    region: ['GEE', 'CN'],
    location: 'TRY, RAM',
    OrganizationalFunction: 'Global eDrive Engineering AE',
    abbrAndAuth: 'PEW',
    resourceRequirement: 'Travel',
    planViewWorkPackage: '',
    rigCategory: '',
    costCategory: '',
    note: '',
    fteTeta: '',
    fte: '',
    total: '',
    year2023: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2024: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2025: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2026: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2027: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    }
  },
  {
    subProject: 'SP07 Prototyping',
    region: ['EU'],
    location: 'TRY',
    OrganizationalFunction: 'Software',
    abbrAndAuth: 'NA PT-P',
    resourceRequirement: 'Material',
    planViewWorkPackage: '',
    rigCategory: '',
    costCategory: '',
    note: '',
    fteTeta: '',
    fte: '',
    total: '',
    year2023: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2024: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2025: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2026: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2027: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    }
  },
  {
    subProject: 'SP08 Protyping & Testing',
    region: ['NA', 'CN'],
    location: 'RAM',
    OrganizationalFunction: 'E-Motor',
    abbrAndAuth: 'TRV',
    resourceRequirement: 'Purchased Services - Component Dev',
    planViewWorkPackage: '',
    rigCategory: '',
    costCategory: '',
    note: '',
    fteTeta: '',
    fte: '',
    total: '',
    year2023: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2024: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2025: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2026: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2027: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    }
  },
  {
    subProject: 'SP09 Expenditures',
    region: ['CN'],
    location: 'TRY',
    OrganizationalFunction: 'AE ePropulsion',
    abbrAndAuth: 'PCD',
    resourceRequirement: 'Purchased Services - External Testing',
    planViewWorkPackage: '',
    rigCategory: '',
    costCategory: '',
    note: '',
    fteTeta: '',
    fte: '',
    total: '',
    year2023: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2024: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2025: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2026: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2027: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    }
  },
  {
    subProject: 'SP10 Gearbox & Mechatronics',
    region: ['GEE', 'CN'],
    location: '',
    OrganizationalFunction: 'PT Prototypying Eng.',
    abbrAndAuth: 'PET',
    resourceRequirement: 'Purchased Services - External Workforce',
    planViewWorkPackage: '',
    rigCategory: '',
    costCategory: '',
    note: '',
    fteTeta: '',
    fte: '',
    total: '',
    year2023: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2024: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2025: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2026: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2027: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    }
  },
  {
    subProject: 'SP11 Eng. Project Management',
    region: ['EU', 'NA', 'CN'],
    location: '',
    OrganizationalFunction: 'Prototyping',
    abbrAndAuth: 'NA PT-P',
    resourceRequirement: 'Travel',
    planViewWorkPackage: '',
    rigCategory: '',
    costCategory: '',
    note: '',
    fteTeta: '',
    fte: '',
    total: '',
    year2023: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2024: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2025: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2026: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2027: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    }
  },
  {
    subProject: 'SP12 System Eng. & PFG',
    region: ['EU', 'NA', 'CN'],
    location: 'TRY, RAM',
    OrganizationalFunction: 'Expenditures',
    abbrAndAuth: 'TRV',
    resourceRequirement: 'Material',
    planViewWorkPackage: '',
    rigCategory: '',
    costCategory: '',
    note: '',
    fteTeta: '',
    fte: '',
    total: '',
    year2023: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2024: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2025: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2026: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2027: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    }
  },
  {
    subProject: 'SP13 Gearbox & Mechatronics',
    region: ['EU', 'NA', 'CN'],
    location: '',
    OrganizationalFunction: 'Prototyping',
    abbrAndAuth: 'MAT',
    resourceRequirement: 'Purchased Services - Component Dev',
    planViewWorkPackage: '',
    rigCategory: '',
    costCategory: '',
    note: '',
    fteTeta: '',
    fte: '',
    total: '',
    year2023: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2024: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2025: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2026: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2027: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    }
  },
  {
    subProject: 'SP14 E-Motor',
    region: ['GEE', 'CN'],
    location: 'TRY,RAM',
    OrganizationalFunction: 'Expenditure',
    abbrAndAuth: '',
    resourceRequirement: 'Purcahsed Services - External Testing',
    planViewWorkPackage: '',
    rigCategory: '',
    costCategory: '',
    note: '',
    fteTeta: '',
    fte: '',
    total: '',
    year2023: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2024: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2025: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2026: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2027: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    }
  },
  {
    subProject: 'SP15 Inverter',
    region: ['GEE'],
    location: 'TRY, RAM',
    OrganizationalFunction: 'Prototyping',
    abbrAndAuth: 'PET',
    resourceRequirement: 'Purchased Services - External Workforce',
    planViewWorkPackage: '',
    rigCategory: '',
    costCategory: '',
    note: '',
    fteTeta: '',
    fte: '',
    total: '',
    year2023: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2024: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2025: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2026: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2027: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    }
  },
  {
    subProject: 'SP16 Software',
    region: ['GEE', 'CN'],
    location: 'TRY, RAM',
    OrganizationalFunction: 'Global eDrive Engineering AE',
    abbrAndAuth: 'PEW',
    resourceRequirement: 'Travel',
    planViewWorkPackage: '',
    rigCategory: '',
    costCategory: '',
    note: '',
    fteTeta: '',
    fte: '',
    total: '',
    year2023: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2024: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2025: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2026: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2027: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    }
  },
  {
    subProject: 'SP17 Prototyping',
    region: ['EU'],
    location: 'TRY',
    OrganizationalFunction: 'Software',
    abbrAndAuth: 'NA PT-P',
    resourceRequirement: 'Material',
    planViewWorkPackage: '',
    rigCategory: '',
    costCategory: '',
    note: '',
    fteTeta: '',
    fte: '',
    total: '',
    year2023: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2024: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2025: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2026: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2027: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    }
  },
  {
    subProject: 'SP18 Protyping & Testing',
    region: ['NA', 'CN'],
    location: 'RAM',
    OrganizationalFunction: 'E-Motor',
    abbrAndAuth: 'TRV',
    resourceRequirement: 'Purchased Services - Component Dev',
    planViewWorkPackage: '',
    rigCategory: '',
    costCategory: '',
    note: '',
    fteTeta: '',
    fte: '',
    total: '',
    year2023: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2024: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2025: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2026: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2027: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    }
  },
  {
    subProject: 'SP19 Expenditures',
    region: ['CN'],
    location: 'TRY',
    OrganizationalFunction: 'AE ePropulsion',
    abbrAndAuth: 'PCD',
    resourceRequirement: 'Purchased Services - External Testing',
    planViewWorkPackage: '',
    rigCategory: '',
    costCategory: '',
    note: '',
    fteTeta: '',
    fte: '',
    total: '',
    year2023: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2024: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2025: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2026: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2027: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    }
  },
  {
    subProject: 'SP20 Gearbox & Mechatronics',
    region: ['GEE', 'CN'],
    location: '',
    OrganizationalFunction: 'PT Prototypying Eng.',
    abbrAndAuth: 'PET',
    resourceRequirement: 'Purchased Services - External Workforce',
    planViewWorkPackage: '',
    rigCategory: '',
    costCategory: '',
    note: '',
    fteTeta: '',
    fte: '',
    total: '',
    year2023: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2024: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2025: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2026: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2027: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    }
  },
  {
    subProject: 'SP21 Expenditures',
    region: ['CN'],
    location: 'TRY',
    OrganizationalFunction: 'AE ePropulsion',
    abbrAndAuth: 'PCD',
    resourceRequirement: 'Purchased Services - External Testing',
    planViewWorkPackage: '',
    rigCategory: '',
    costCategory: '',
    note: '',
    fteTeta: '',
    fte: '',
    total: '',
    year2023: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2024: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2025: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2026: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2027: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    }
  },
  {
    subProject: 'SP22 Gearbox & Mechatronics',
    region: ['GEE', 'CN'],
    location: '',
    OrganizationalFunction: 'PT Prototypying Eng.',
    abbrAndAuth: 'PET',
    resourceRequirement: 'Purchased Services - External Workforce',
    planViewWorkPackage: '',
    rigCategory: '',
    costCategory: '',
    note: '',
    fteTeta: '',
    fte: '',
    total: '',
    year2023: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2024: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2025: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2026: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2027: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    }
  },
  {
    subProject: 'SP23 Eng. Project Management',
    region: ['EU', 'NA', 'CN'],
    location: '',
    OrganizationalFunction: 'Prototyping',
    abbrAndAuth: 'NA PT-P',
    resourceRequirement: 'Travel',
    planViewWorkPackage: '',
    rigCategory: '',
    costCategory: '',
    note: '',
    fteTeta: '',
    fte: '',
    total: '',
    year2023: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2024: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2025: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2026: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    },
    year2027: {
      Q1: '',
      Q2: '',
      Q3: '',
      Q4: ''
    }
  }
];
