import React, { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../authConfig';
import {
  Container,
  Box,
  Typography,
  Button,
  CssBaseline,
  Avatar,
  Alert,
  Stack
} from '@mui/material';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import magnaLogo from '../../assets/appicon.png';

const LoginPage = () => {
  const { instance } = useMsal();
  const showMaintenanceMessage = process.env.REACT_APP_MAINTENANCE_MESSAGE === 'true';
  const showDeveloperBuild = process.env.REACT_APP_DEVELOPER_BUILD_TAG === 'true';

  const handleLogin = () => {
    instance.loginRedirect(loginRequest);
  };

  useEffect(() => {
    sessionStorage.removeItem('tabValue');
    localStorage.removeItem('clearTabData');
  }, []);

  const DeveloperBuildBanner = () => (
    <Box
      sx={{
        width: '100%',
        bgcolor: '#800000',
        py: 1,
        position: 'fixed',
        top: 0,
        zIndex: 1100,
        animation: 'fadeInOut 2s infinite',
        '@keyframes fadeInOut': {
          '0%': { opacity: 0.7 },
          '50%': { opacity: 1 },
          '100%': { opacity: 0.7 }
        }
      }}
    >
      <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
        <DeveloperModeIcon sx={{ color: 'white' }} />
        <Typography
          variant="subtitle2"
          sx={{
            color: 'white',
            fontWeight: 'bold',
            letterSpacing: 1
          }}
        >
          DEVELOPER BUILD
        </Typography>
        <DeveloperModeIcon sx={{ color: 'white' }} />
      </Stack>
    </Box>
  );

  return (
    <>
      <CssBaseline />
      {showDeveloperBuild && <DeveloperBuildBanner />}
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: showDeveloperBuild ? 12 : 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: 'white',
            padding: 4,
            borderRadius: 1,
            boxShadow: 3
          }}
        >
          <Avatar src={magnaLogo} sx={{ width: 56, height: 56, m: 1, bgcolor: 'black' }}></Avatar>
          <Typography component="h1" variant="h5" align="center">
            Single sign-on to MAGNA
          </Typography>
          <Typography component="p" align="center" sx={{ mt: 2 }}>
            Authenticate your account by logging into MAGNA's single sign-on provider to access
            QuoVaDis.
          </Typography>
          {showMaintenanceMessage && (
            <Alert severity="info" sx={{ mt: 3 }}>
              Note: QuoVaDis is under maintenance for feature enhancement. Some existing features
              may not work as intended. Contact QuoVaDis developers if you need further information!
            </Alert>
          )}
          <Button
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3 }}
            onClick={handleLogin}
          >
            Continue
          </Button>
        </Box>
      </Container>
    </>
  );
};

export default LoginPage;
