import { TabContext, TabPanel, TabList } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import React from 'react';
import WorkPackageItem from '../../Components/WorkPackageItem';
import { test1 as testData } from '../../Data/test';
import { task1 as taskData } from '../../Data/task';
import BackToDashboard from '../../Components/BackToDashboard';

const WorkPackage = () => {
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <BackToDashboard />
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList
            onChange={handleChange}
            sx={{
              '& .MuiTab-root.Mui-selected': {
                fontWeight: 700
              }
            }}
          >
            <Tab label="Test" value="1" />
            <Tab label="Task" value="2" />
            <Tab label="Vendor" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1" sx={{ padding: '1rem' }}>
          <WorkPackageItem item={testData} />
        </TabPanel>
        <TabPanel value="2" sx={{ padding: '1rem' }}>
          <WorkPackageItem item={taskData} />
        </TabPanel>
        <TabPanel value="3" sx={{ padding: '1rem' }}>
          <WorkPackageItem item={testData} />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default WorkPackage;
